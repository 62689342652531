import { createSlice } from '@reduxjs/toolkit'
import {prepareStudyingOnlineLessonsParams} from '../../shared/functions'
import {getStudyingOnlineLessonsFromBD} from "../../api/studying";


const initialState = {
	lessons: [],
	loading: true,

}

export const studyingOnlineLessonSlice = createSlice({
	name: 'studyingOnlineLesson',
	initialState,
	reducers: {
		SET_LESSONS: (state, action) => {
			state.lessons = action.payload
			state.loading = false
		},
		SET_LOADING: (state, action) => {
			state.loading = action.payload
		},
	},
})

export const getAllLessons = (id) => async (dispatch, getState) => {
	dispatch(SET_LOADING(true))
	const { lang } = getState().global
	const params = prepareStudyingOnlineLessonsParams(lang, id)
	const response = await getStudyingOnlineLessonsFromBD(params)
	dispatch(SET_LESSONS(response))

}

export const { SET_LOADING, SET_LESSONS  } = studyingOnlineLessonSlice.actions

export default studyingOnlineLessonSlice.reducer
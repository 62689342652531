import React, { useEffect } from 'react'
import "./style.scss"
import PastLesson from './PastLesson/PastLesson'
import FutureLesson from './FutureLesson/FutureLesson'
import { useDispatch, useSelector } from 'react-redux'
import { getAllCourses, getCurrCourse } from '../../../store/teacherCabinet/teacherOnlineLesson/teacherOnlineLessonSlice'
import { useNavigate } from 'react-router'
import MuiLoader from '../../../shared/ui/MuiLoader/MuiLoader'

export default function OnlineLesson() {

    let dispatch = useDispatch()
    let navigate = useNavigate()

    let Lang = useSelector(state => state.global.lang)
    let courses = useSelector(state => state.teacherOnlineLesson.courses)
    let currCourse = useSelector(state => state.teacherOnlineLesson.currCourse)
    let currCourseId = useSelector(state => state.teacherOnlineLesson.currCourseId)
    let loading = useSelector(state => state.teacherOnlineLesson.loading)
    let CCLoading = useSelector(state => state.teacherOnlineLesson.CCLoading)
    

    useEffect(() => {
        dispatch(getAllCourses())
    }, [])

    return (
        <div className="OnlineLesson">
            <div className="OnlineLessonSidebar_w">
                <div className="OnlineLessonSidebar">
                    <div className="top">
                        Выберите курс
                    </div>
                    <div className="content">
                        {
                            loading ?
                            <MuiLoader color={'#2ECC71'}/>
                            :
                            courses?.content?.length > 0 ?
                                courses?.content?.map((course) => {
                                    return (
                                        <button
                                            key={course.courseId}
                                            className={((course.courseId == currCourseId) ? "selected" : "")}
                                            onClick={() => dispatch(getCurrCourse(Lang, course.courseId))}
                                        >
                                            <span>{course.courseName}</span>
                                        </button>
                                    )
                                })
                                :
                                <button><span>Нет курсов</span></button>
                        }
                    </div>
                    <div className="bottom"></div>
                </div>
            </div>
            <div className="OnlineLessonContent_w">
                <div className="OnlineLessonHeader">
                    <h3 className="title">Онлайн Уроки</h3>
                    <button className="createLesson"
                        onClick={() => navigate('create')}
                    >
                        <span>Создать урок</span>
                        <div className="icon">
                            <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <line x1="9" y1="1.5" x2="9" y2="17.5" stroke="#2ECC71" strokeWidth="2" strokeLinecap="round"/>
                                <line x1="17" y1="9.5" x2="1" y2="9.5" stroke="#2ECC71" strokeWidth="2" strokeLinecap="round"/>
                            </svg>
                        </div>
                    </button>
                </div>
                <div className="OnlineLessonContent">
                    <div className="pastLessons">
                        {
                            CCLoading ?
                            <MuiLoader color={'#2ECC71'}/>
                            :
                            currCourse?.map((lesson) => {
                                if (new Date(lesson.meetingDate).getTime() < Date.now()) {
                                    return (
                                        <PastLesson
                                            key={lesson.meetingsId}
                                            courseName={lesson.levelLanguageName}
                                            lesson={lesson}
                                        />
                                    )
                                }
                            })
                        }
                    </div>
                    <div className="futureLessons">
                        <h3 className="title">Предстоящие уроки</h3>
                        <div className="futureLessons_content">
                            {
                                CCLoading ?
                                <MuiLoader color={'#2ECC71'}/>
                                :
                                currCourse?.map((lesson) => {
                                    if (new Date(lesson.meetingDate).getTime() > Date.now()) {
                                        return (
                                            <FutureLesson
                                                key={lesson.meetingsId}
                                                lesson={lesson}
                                            />
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import { useCheckAuth } from "../../../shared/functions/useCheckAuth";
import Breadcrumbs from "../../../shared/ui/breadcrumbs/Breadcrumbs";
import ListImg from "../../../shared/ui/img/QuickCheckPage/listImg.svg"
import CardImg from "../../../shared/ui/img/QuickCheckPage/cardImg.svg"
import Certificate from "../../../shared/ui/img/QuickCheckPage/bg2CertificateImg.png"
import {getExamIndexInfoFromBD} from "../../../api/exam";

import RegistrationForm from "../../RegistrationForm/RegistrationForm";
import {SET_FORMACTIVE, SET_SIGNUP} from "../../../store/registrationForm/registrationFormSlice";

import "./style.scss"

export default function ExamPage() {

	// Проверка залогинен пользователь или нет
    useCheckAuth()

	let navigate = useNavigate()
	let dispatch = useDispatch()

	let Lang = useSelector(state => state.global.lang)
	let [examIndexInfo, setExamIndexInfo] = useState()



	useEffect(() => {
		async function getExamIndexInfo() {
			const response = await getExamIndexInfoFromBD(3, Lang)
			setExamIndexInfo(response)
		}
		getExamIndexInfo()
	},[Lang])

	let breadcrumbsItems = [
		{
			slug: '/',
			title: 'Главная',
		},
		{
			slug: '/test',
			title: 'Экзамен',
		},
	]

	return (
		<div className="quick-check">

			<div className="quick-check__breadcrumbs">
				<Breadcrumbs items={breadcrumbsItems} />
			</div>



			<div className="quick-check__content">

				<h2 className="quick-check__title">Пройдите экзамен <br/> <span>Получите Диплом о знании Гагаузского языка </span></h2>

				<div className="quick-check__bg1">
					<div className="quick-check__bg1__number">
						<div className="quick-check__bg1__number__number">90</div>
						<div className="quick-check__bg1__number__desc">
							<span className="quick-check__bg1__number__desc__min">MIN</span>
							<span>HIZLI <br/> CONTROL</span>
						</div>
					</div>
					<div className="quick-check__bg1__desc">
						<div className="quick-check__bg1__desc__title">{examIndexInfo?.value}</div>
						<ul className="quick-check__bg1__desc__list">
							{
								examIndexInfo?.details?.map((item) => {
									return (
										<li key={item.testTimeTypeDetailId}>
											<img src={ListImg}/>
											<span>{item.value}</span>
										</li>
									)
								})
							}
						</ul>
						<button className="quick-check__button" onClick={() => navigate('/usercabinet/exam')}>Записаться на экзамен</button>
					</div>
				</div>

				<h2 className="quick-check__title quick-check__title_green">Зачем проходить наш Гагаузский экзамен?</h2>

				<div className="quick-check__desc-row">
				<div className="quick-check__card">
						<div className="quick-check__card__img">
							<img src={CardImg}/>
						</div>
						<div className="quick-check__card__title">1</div>
						<div className="quick-check__card__desc">
							Помогает лучше и с большей пользой использовать гагаузский язык.
						</div>
					</div>
					<div className="quick-check__card">
						<div className="quick-check__card__img">
							<img src={CardImg}/>
						</div>
						<div className="quick-check__card__title">2</div>
						<div className="quick-check__card__desc">
							Позволяет определить уровень владения гагаузским языком.
						</div>
					</div>
					<div className="quick-check__card">
						<div className="quick-check__card__img">
							<img src={CardImg}/>
						</div>
						<div className="quick-check__card__title">3</div>
						<div className="quick-check__card__desc">
							Дает возможность получить сертификат.
						</div>
					</div>
				</div>

			</div>

			<div className="quick-check__bg2">
				<div className="quick-check__bg2__content">
					<h2 className="quick-check__title quick-check__title_white">Начните обучение на нашей <br/> платформе и получите официальный <br/> сертификат</h2>
					<div className="quick-check__bg2__content__desc">
						По окончании уровня обучения и прохождения экзамена вы получаете сертификат с уровнем знания гагаузского языка. С ним вы можете продолжить обучение на следующем уровне. Также можете легко добавить его в свое резюме.
					</div>
					<button className="quick-check__button" onClick={() => {navigate('/usercabinet/courses')}}>Начать обучение</button>
				</div>
				<div className="quick-check__bg2__img">
					<img src={Certificate}/>
				</div>
			</div>

		</div>
	)
}
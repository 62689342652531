import { createSlice } from '@reduxjs/toolkit'
import { getDatesForExamFromDB, getExamTypesFromDB, saveUserExamDate } from '../../api/exam'
import { getCookie, getNextTestQuestion, prepareParamsForExamCalendar, prepareParamsForExamTypes } from '../../shared/functions'
/* import { gettestFromBD } from '../../api/test' */


const initialState = {
    currMonthDates: [],
    currDayTimes: [],
	examTypes: [],
    meetingTypeId: [],
	selectedMonthPeriod: [],
    selectedDayPeriod: [],
	meetingsId: null,
    loading: false,
}

export const examCalendar = createSlice({
    name: 'examCalendar',
    initialState,
    reducers: {
        SET_CURRMONTHDATES: (state, action) => {
            state.currMonthDates = action.payload
            state.loading = false
        },
        SET_CURRDAYTIMES: (state, action) => {
            state.currDayTimes = action.payload
        },
        SET_EXAMTYPES: (state, action) => {
            state.examTypes = action.payload
        },
        SET_MEETINGTYPEID: (state, action) => {
            state.meetingTypeId = action.payload
        },
        SET_SELECTEDMONTHPERIOD: (state, action) => {
            state.selectedMonthPeriod = action.payload
        },
        SET_SELECTEDDAYPERIOD: (state, action) => {
            state.selectedDayPeriod = action.payload
        },
        SET_MEETINGSID: (state, action) => {
            state.meetingsId = action.payload
        },
        SET_LOADING: (state, action) => {
            state.loading = action.payload
        },
    },
})


export const getDatesFromServer = (lang) => async (dispatch, getState) => {
    dispatch(SET_LOADING(true))
	const { selectedMonthPeriod, meetingTypeId } = getState().examCalendar
	let params = prepareParamsForExamCalendar(selectedMonthPeriod, meetingTypeId, lang)
    console.log(params, 'allDates')
    const response = await getDatesForExamFromDB(params)
    console.log(response)
	dispatch(SET_CURRMONTHDATES(response))
}
export const getTimesForCurrDayFromServer = (lang) => async (dispatch, getState) => {
    const { selectedDayPeriod, meetingTypeId } = getState().examCalendar
	let params = prepareParamsForExamCalendar(selectedDayPeriod, meetingTypeId, lang)
    console.log(params, 'currDate')
    const response = await getDatesForExamFromDB(params)
    console.log(response)
	dispatch(SET_CURRDAYTIMES(response))
}
export const getExamTypesFromServer = (lang) => async (dispatch, getState) => {
    dispatch(SET_LOADING(true))
	let params = prepareParamsForExamTypes(lang)
    const response = await getExamTypesFromDB(params)
    console.log(response)
	dispatch(SET_EXAMTYPES(response))
}
export const enrollUserDateToDB = () => async (dispatch, getState) => {
    const { meetingsId } = getState().examCalendar
    const response = await saveUserExamDate({
        meetingsId: meetingsId
    }, getCookie('userToken'))
    console.log(response)
	dispatch(SET_EXAMTYPES(response))
}

export const { SET_CURRMONTHDATES, SET_CURRDAYTIMES, SET_EXAMTYPES, SET_MEETINGTYPEID, SET_SELECTEDDAYPERIOD, SET_SELECTEDMONTHPERIOD, SET_MEETINGSID, SET_LOADING } = examCalendar.actions

export default examCalendar.reducer
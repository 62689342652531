import React, {useEffect, useState} from "react";

import UserImg from "../../shared/ui/img/UserCabinet/userImg.svg"
import NotificationImg from "../../shared/ui/img/UserCabinet/notificationImg.svg"

import "./style.scss"
import MuiTabs from "../../widgets/muiTabs";
import CoursesPage from "./Courses/CoursesPage";
import ProfilePage from "./Profile/ProfilePage";
import DiplomsPage from "./Diploms/DiplomsPage";
import ExamPage from "./Exam/ExamPage";
import { useCheckAuth } from "../../shared/functions/useCheckAuth";
import { useSelector } from "react-redux";
import {useLocation} from "react-router-dom";
import {Route, Routes, useNavigate} from "react-router";

export default function UserCabinet() {

	// Проверка залогинен пользователь или нет
    useCheckAuth()

	let location = useLocation()
	let navigate = useNavigate()
	let loggedUser = useSelector(state => state.global.loggedUser)

	console.log(loggedUser)

	let TabsSX = (window.innerWidth > 500)
		?
		{
			'& .MuiTabs-scroller': {
				marginLeft: '30px',
			},
			'& .MuiTabs-indicator': {
				backgroundColor: '#2ECC71',
				filter: 'blur(1px)',
				height: '5px',
			}
		}
		:
		{
			'& .MuiTabs-scroller': {
				marginLeft: '0',
				height: 'fit-content',
			},
			'& .MuiTabs-indicator': {
				backgroundColor: '#2ECC71',
				filter: 'blur(1px)',
				height: '5px',
			}
		}
	let TabSX = (window.innerWidth > 500)
		?
		{
			fontFamily: 'Gilroy',
			fontStyle: 'normal',
			fontWeight: 300,
			fontSize: '20px',
			lineHeight: '24px',
			color: ' #888888',
			fontVariationSettings: 'slnt 0',
			textTransform: 'unset',

			'&.Mui-selected': {
				color: '#2ECC71',
				fontWeight: 400,
			},

			'&.MuiTab-root': {
				padding: '11px 30px'
			},
		}
		:
		{
			minHeight: 'fit-content',
			fontFamily: 'Gilroy',
			fontStyle: 'normal',
			fontWeight: 200,
			fontSize: '18px',
			lineHeight: '20px',
			color: ' #888888',
			fontVariationSettings: 'slnt 0',
			textTransform: 'unset',


			'&.Mui-selected': {
				color: '#2ECC71',
				fontWeight: 300,
			},

			'&.MuiTab-root': {
				padding: '11px 15px'
			},
		}
	let mainBoxSX = (window.innerWidth > 500)
		?
		{
			width: '100%',
			marginBottom: '100px',
		}
		:
		{
			width: '100%',
			marginBottom: '50px',

			'& .MuiTabs-root': {
				minHeight: '0.8vw',

			},
			'& .css-19kzrtu': {
				padding: 0
			}
		}


	let [defaultTab, setDefaultTab] = useState(0)

	useEffect(() => {
		if (location.pathname.split('/').length == 2) {
			navigate('courses')
		}
		if (location.pathname.split('/')[2] == 'exam') {
			setDefaultTab(3)
		} else if (location.pathname.split('/')[2] == 'diplomas') {
			setDefaultTab(2)
		} else if (location.pathname.split('/')[2] == 'profile') {
			setDefaultTab(1)
		} else if (location.pathname.split('/')[2] == 'courses') {
			setDefaultTab(0)
		}
	}, [])

	return (
		<div className="user-cabinet">
			<div className="user-cabinet__header">
				<div className="user-cabinet__header-user">
					<img src={UserImg}/>
					<div className="user-cabinet__header-user-desc">
						<h4 className="user-cabinet__header-user-desc-name">{loggedUser?.fam + ' ' + loggedUser?.im}</h4>
						<div className="user-cabinet__header-user-desc-id"><span>User ID</span> {loggedUser?.accountId}</div>
					</div>
				</div>
				<div className="user-cabinet__header-notification">
					<button className="user-cabinet__header-notification-button">
						<img src={NotificationImg}/>
					</button>
				</div>
			</div>
			<MuiTabs
				tabs={[
					{
						label: 'Курсы',
					},
					{
						label: 'Профиль',
					},
					{
						label: 'Дипломы',
					},
					{
						label: 'Экзамен',
					},
				]}
				getCurrTabIndex={(tabIndex) => {
					if (tabIndex == 3) {
						navigate('exam')
					} else if (tabIndex == 2) {
						navigate('diplomas')
					} else if (tabIndex == 1) {
						navigate('profile')
					} else if (tabIndex == 0) {
						navigate('courses')
					}
				}}
				defaultTab={defaultTab}
				tabPanels={[
					// <CoursesPage/>,
					// <ProfilePage/>,
					// <DiplomsPage/>,
					// <ExamPage/>,
					<Routes>
						<Route path="courses" element={<CoursesPage/>} />
					</Routes>,
					<Routes>
						<Route path="profile" element={<ProfilePage/>} />
					</Routes>,
					<Routes>
						<Route path="diplomas" element={<DiplomsPage/>} />
					</Routes>,
					<Routes>
						<Route path="exam" element={<ExamPage/>} />
					</Routes>,

				]}
				TabsSX={TabsSX}
				TabSX={TabSX}
				mainBoxSX={mainBoxSX}
			/>
		</div>
	)
}
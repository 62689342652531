import React from "react";

import Breadcrumbs from "../../shared/ui/breadcrumbs/Breadcrumbs";
import CertificatePhone from "../../shared/ui/img/certificatePhone.png"
import Image1 from "../../shared/ui/img/certificatePageImg1.png"
import Image2 from "../../shared/ui/img/certificatePageImg2.png"
import ListImg from "../../shared/ui/img/certificatePageListImg.svg"

import "./style.scss"
import {Link, useNavigate} from "react-router-dom";

export default function CertificatePage() {

	let navigate = useNavigate()

	let breadcrumbsItems = [
		{
			slug: '/',
			title: 'Главная',
		},
		{
			slug: '/certificate',
			title: 'Сертификация',
		},
	]

	return (
		<div className="certificate_wrapper">
			<div className="certificate_breadcrumbs">
				<Breadcrumbs items={breadcrumbsItems}/>
			</div>
			<div className="certificate_bg">
				<div className="certificate_bg_content">
					<h1 className="certificate_bg_content_title">DIL Certificate™</h1>
					<span className="certificate_bg_content_desc">Пройдите наш 90-минутный экзамен и получите ссылку на ваш индивидуальный результат, подтверждающий уровень владения Гагаузским.</span>
					<button className="certificate_bg_content_button" onClick={() => navigate('/usercabinet/courses')}>Начать обучение</button>
				</div>
				<div className="certificate_bg_img">
					<img src={CertificatePhone}/>
				</div>
			</div>
			<div className="certificate_content">
				<div className="certificate_content1">
					<h2 className="certificate_content1_title">Получите <span>сертификат уровня</span> владения
						Гагаузским, <br/> признанный в Республике Молдова</h2>
					<div className="certificate_content1_container">
						<img src={Image1}/>
						<ul className="certificate_content1_container_list">
							<li>
								<div>
									<svg width="32" height="38" viewBox="0 0 32 38" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M14.1713 8.80402L28.5474 22.9483C28.803 23.1998 28.803 23.606 28.5474 23.8575L17.6733 34.5563C17.4176 34.8078 17.0047 34.8078 16.7491 34.5563L3.19172 21.2176C2.93609 20.9661 2.93609 20.5598 3.19172 20.3083L8.43483 15.1498C8.69046 14.8982 9.1034 14.8982 9.35903 15.1498L17.9253 23.5779M23.2882 6.51759L26.8634 3" stroke="#22C6EB" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round"/>
									</svg>
								</div>
								<Link to="#">Ссылка на ваш индивидуальный результат</Link>
							</li>
							<li>
								<div>
									<svg width="32" height="38" viewBox="0 0 32 38" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M14.1713 8.80402L28.5474 22.9483C28.803 23.1998 28.803 23.606 28.5474 23.8575L17.6733 34.5563C17.4176 34.8078 17.0047 34.8078 16.7491 34.5563L3.19172 21.2176C2.93609 20.9661 2.93609 20.5598 3.19172 20.3083L8.43483 15.1498C8.69046 14.8982 9.1034 14.8982 9.35903 15.1498L17.9253 23.5779M23.2882 6.51759L26.8634 3" stroke="#22C6EB" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round"/>
									</svg>
								</div>
								<Link to="#">Доступно в PDF внутри вашего личного кабинета</Link>
							</li>
							<li>
								<div>
									<svg width="32" height="38" viewBox="0 0 32 38" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M14.1713 8.80402L28.5474 22.9483C28.803 23.1998 28.803 23.606 28.5474 23.8575L17.6733 34.5563C17.4176 34.8078 17.0047 34.8078 16.7491 34.5563L3.19172 21.2176C2.93609 20.9661 2.93609 20.5598 3.19172 20.3083L8.43483 15.1498C8.69046 14.8982 9.1034 14.8982 9.35903 15.1498L17.9253 23.5779M23.2882 6.51759L26.8634 3" stroke="#22C6EB" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round"/>
									</svg>
								</div>
								<Link to="#">Легко добавить в профиль в соц. сетях</Link>
							</li>
							<li>
								<div>
									<svg width="32" height="38" viewBox="0 0 32 38" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M14.1713 8.80402L28.5474 22.9483C28.803 23.1998 28.803 23.606 28.5474 23.8575L17.6733 34.5563C17.4176 34.8078 17.0047 34.8078 16.7491 34.5563L3.19172 21.2176C2.93609 20.9661 2.93609 20.5598 3.19172 20.3083L8.43483 15.1498C8.69046 14.8982 9.1034 14.8982 9.35903 15.1498L17.9253 23.5779M23.2882 6.51759L26.8634 3" stroke="#22C6EB" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round"/>
									</svg>
								</div>
								<Link to="#">Результат соотвествует признаному уровню</Link>
							</li>
							<li>
								<div>
									<svg width="32" height="38" viewBox="0 0 32 38" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M14.1713 8.80402L28.5474 22.9483C28.803 23.1998 28.803 23.606 28.5474 23.8575L17.6733 34.5563C17.4176 34.8078 17.0047 34.8078 16.7491 34.5563L3.19172 21.2176C2.93609 20.9661 2.93609 20.5598 3.19172 20.3083L8.43483 15.1498C8.69046 14.8982 9.1034 14.8982 9.35903 15.1498L17.9253 23.5779M23.2882 6.51759L26.8634 3" stroke="#22C6EB" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round"/>
									</svg>
								</div>
								<Link to="#">Бесплатно с регистрацией</Link>
							</li>
						</ul>
					</div>
				</div>
				<div className="certificate_content2">
					<div className="certificate_content2_container">
						<h2 className="certificate_content2_container_title">Кем разработан данный <span>сертификат?</span></h2>
						<span className="certificate_content2_container_desc">DİL Certificate разработан специалистами Научно-исследовательского центра Гагаузии им. М.В. Маруневич.</span>
					</div>
					<img src={Image2}/>
				</div>
			</div>
		</div>
	)
}
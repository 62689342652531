import React, { useEffect, useState } from "react";

import {CircularAudioLoading} from "../../../shared/ui/CircularAudioLoading";

import BookImg from "../../../shared/ui/img/ResultPage/listBookImg.svg"
import HeadphonesImg from "../../../shared/ui/img/ResultPage/listHeadphonesImg.svg"
import ExamDoneImg from "../../../shared/ui/img/ResultPage/examDone.svg"
import ExamFalseImg from "../../../shared/ui/img/ResultPage/examFolse.svg"

import {LoadingTestPage} from "../../../shared/ui/LoadingTestPage";

import "./style.scss"
import {useLocation} from "react-router-dom";
import { useCheckAuth } from "../../../shared/functions/useCheckAuth";
import { useDispatch, useSelector } from "react-redux";
import { examResults } from "./exatResults";
import { deleteCookie, getCookie } from "../../../shared/functions";
import { saveUserExamResultsToBD } from "../../../api/exam";

export default function ExamResultPage() {

	// Проверка залогинен пользователь или нет
    useCheckAuth()

	const location = useLocation()
	let dispatch = useDispatch()

	let userTest = useSelector(state => state.exam.exam)
	// let userTest = examResults

	let Lang = useSelector(state => state.global.lang)
	let loggedUser = useSelector(state => state.global.loggedUser)

	let [userTestResults, setUserTestResults] = useState(null)
	let [saveResultError, setSaveResultError] = useState({
		isError: false,
		text: ''
	})
	let [loading, setLoading] = useState(true)

	let minResult = 20
	let [ExamDone, setExamDone] = useState()
	useEffect(() => {
		setExamDone(userTestResults?.total > minResult)
	}, [userTestResults])

	async function analisingResults() {
		setLoading(true)
		function getResults(chapter) {
			let allPoints = 0
			let userPoints = 0
			console.log(userTest)
			userTest?.[chapter]?.forEach((task) => {
				task.questions.forEach((question) => {
					question.variants.forEach((variant) => {
						if (variant.isCorrect) {
							allPoints++
						}
					})
				})
				task.questions.forEach((question) => {
					question.userAnswer.forEach((variant) => {
						if (variant.isCorrect) {
							userPoints++
						}
					})
				})
			})
			return {
				[chapter + 'All']: allPoints == 0 ? 1 : allPoints,
				[chapter + 'User']: userPoints
			}
		}
		let {readingAll, readingUser} = getResults('reading')
		let {listeningAll, listeningUser} = getResults('listening')

		let testResults = {
			reading: (readingUser / readingAll * 100).toFixed(2),
			listening: (listeningUser / listeningAll * 100).toFixed(2),
			total: ((readingUser + listeningUser) / (readingAll + listeningAll) * 100).toFixed(2)
		}

		if (loggedUser) {
			if (+testResults.reading !== NaN) {
				setUserTestResults(testResults)
				let resultToServer = {
					"email": loggedUser.email,
					"examId": userTest.examId,
					"courseId": location.pathname.split('/')[2],
					...testResults
				}
				console.log(resultToServer)
				// let response = await saveUserExamResultsToBD(resultToServer)
				let response = true
				console.log(response)
				if (response) {
					setLoading(false)
				} else {
					setSaveResultError({
						isError: true,
						text: 'Не удалось сохранить результаты'
					})
				}
			} else {
				setUserTestResults({
					reading: 0,
					listening: 0,
					total: 0,
				})
				setSaveResultError({
					isError: true,
					text: 'Непредвиденная ошибка(('
				})
			}
		} else {
			if (+testResults.reading !== NaN) {
				setUserTestResults(testResults)
			} else {
				setUserTestResults({
					reading: 0,
					listening: 0,
					total: 0,
				})
			}
			setLoading(false)
		}
	}

	useEffect(() => {
		if (getCookie('examTime') && getCookie('currExamId')) {
			deleteCookie('examTime')
			deleteCookie('currExamId')
		}
	}, [])

	useEffect(() => {
		analisingResults()
	}, [loggedUser])


	// console.log(userTestResults)

	return (
		<>
			{
				loading
					?
					<LoadingTestPage location={location.pathname} error={saveResultError} />
					:
					<>
						<div className="result-page">
							<div className="result-page__left">
								<div className="result-page__left-content">
									<h2 className="result-page__left-content-title">Ваш результат :</h2>
									<span
										className="result-page__left-content-desc">{userTestResults?.total >= 1 && userTestResults?.total <= 60 ? 'Начинающий' : userTestResults?.total >= 61 && userTestResults?.total <= 86 ? 'Средний' : userTestResults?.total >= 87 ? 'Продвинутый' : 'Тест не пройден'}</span>
									<div className={ExamDone ? 'result-page__left-content-result' : 'result-page__left-content-result result-page__left-content-result_false'}>
										<CircularAudioLoading
											progress={userTestResults?.total}
											strokeWidth={10}
											sqSize={200}
										/>
										<div className="result-page__left-content-result-border"></div>
										<div className="number">{userTestResults?.total}%</div>
									</div>
								</div>
								<div className="result-page__left-footer">
									<button
										disabled={!ExamDone}
										className={ExamDone ? 'result-page__left-footer-button result-page__left-footer-button_white' : 'result-page__left-footer-button result-page__left-footer-button_white result-page__left-footer-button_disabled'}>Отправить
										на E-Mail
									</button>
									<button
										disabled={!ExamDone}
										className={ExamDone ? 'result-page__left-footer-button result-page__left-footer-button_green' : 'result-page__left-footer-button result-page__left-footer-button_green result-page__left-footer-button_disabled'}>Скачать
										диплом (PDF)
									</button>
									<h4 className="result-page__left-footer-title result-page__left-footer-title_black">Поделиться
										своим результатом :</h4>
									<div
										className="result-page__left-footer-buttons">
										<button>
											<svg width="39" height="39" viewBox="0 0 39 39" fill="none"
												 xmlns="http://www.w3.org/2000/svg">
												<path fillRule="evenodd" clipRule="evenodd"
													  d="M19.5 39C14.3283 39 9.36839 36.9455 5.71143 33.2886C2.05447 29.6316 9.75057e-06 24.6717 9.75057e-06 19.5C-0.00501136 14.4758 1.92938 9.64355 5.3999 6.01065C8.87043 2.37774 13.6092 0.224564 18.6284 0H19.5C22.0608 0 24.5965 0.504384 26.9623 1.48435C29.3282 2.46432 31.4778 3.90067 33.2886 5.71142C35.0993 7.52216 36.5357 9.67182 37.5157 12.0377C38.4956 14.4035 39 16.9392 39 19.5C39 22.0608 38.4956 24.5965 37.5157 26.9623C36.5357 29.3282 35.0993 31.4778 33.2886 33.2886C31.4778 35.0993 29.3282 36.5357 26.9623 37.5156C24.5965 38.4956 22.0608 39 19.5 39ZM6.69698 32.3227C8.80479 34.4326 11.4004 35.9901 14.2539 36.8573C17.1073 37.7245 20.1306 37.8746 23.056 37.2943C25.9813 36.714 28.7184 35.4213 31.0249 33.5306C33.3313 31.6399 35.1359 29.2096 36.2788 26.4549C37.4217 23.7003 37.8676 20.7063 37.577 17.7381C37.2865 14.7699 36.2685 11.9192 34.6131 9.43849C32.9577 6.95773 30.7161 4.92348 28.0869 3.51592C25.4576 2.10836 22.5217 1.37092 19.5394 1.36894C17.1582 1.36829 14.8002 1.83682 12.6002 2.74777C10.4001 3.65871 8.40112 4.99421 6.71737 6.67796C5.03361 8.36171 3.69811 10.3607 2.78717 12.5608C1.87623 14.7608 1.4077 17.1188 1.40834 19.5C1.40019 21.8788 1.86138 24.2359 2.76551 26.4362C3.66964 28.6365 4.99894 30.6369 6.67728 32.3227H6.69698Z"
													  fill="#4F4F4F"/>
												<path fillRule="evenodd" clipRule="evenodd"
													  d="M23.5069 10.041H15.4902C14.0453 10.0436 12.6603 10.6188 11.6385 11.6405C10.6168 12.6622 10.0417 14.0472 10.0391 15.4921V23.5088C10.0417 24.9537 10.6168 26.3387 11.6385 27.3605C12.6603 28.3822 14.0453 28.9573 15.4902 28.9599H23.5069C24.9518 28.9573 26.3368 28.3822 27.3585 27.3605C28.3802 26.3387 28.9554 24.9537 28.958 23.5088V15.4921C28.9554 14.0472 28.3802 12.6622 27.3585 11.6405C26.3368 10.6188 24.9518 10.0436 23.5069 10.041ZM27.0277 23.5088C27.0264 24.4422 26.655 25.337 25.995 25.997C25.335 26.657 24.4402 27.0283 23.5069 27.0297H15.4902C14.5568 27.0283 13.662 26.657 13.002 25.997C12.342 25.337 11.9707 24.4422 11.9694 23.5088V15.4921C11.9707 14.5588 12.342 13.664 13.002 13.004C13.662 12.344 14.5568 11.9726 15.4902 11.9713H23.5069C24.4402 11.9726 25.335 12.344 25.995 13.004C26.655 13.664 27.0264 14.5588 27.0277 15.4921V23.5088Z"
													  fill="#4F4F4F"/>
												<path fillRule="evenodd" clipRule="evenodd"
													  d="M19.5018 15.1074C18.6331 15.1074 17.7838 15.365 17.0615 15.8477C16.3392 16.3303 15.7762 17.0163 15.4437 17.8189C15.1113 18.6216 15.0243 19.5047 15.1938 20.3568C15.3633 21.2088 15.7816 21.9915 16.3959 22.6058C17.0102 23.22 17.7928 23.6384 18.6449 23.8079C19.4969 23.9774 20.3801 23.8904 21.1827 23.5579C21.9853 23.2255 22.6713 22.6625 23.154 21.9402C23.6366 21.2178 23.8942 20.3686 23.8942 19.4999C23.8929 18.3353 23.4297 17.2188 22.6063 16.3954C21.7828 15.5719 20.6663 15.1087 19.5018 15.1074ZM19.5018 21.8733C19.0324 21.8733 18.5735 21.7341 18.1832 21.4733C17.7928 21.2125 17.4886 20.8418 17.309 20.4081C17.1293 19.9744 17.0823 19.4972 17.1739 19.0368C17.2655 18.5764 17.4916 18.1535 17.8235 17.8215C18.1554 17.4896 18.5783 17.2636 19.0388 17.172C19.4992 17.0804 19.9764 17.1274 20.4101 17.307C20.8438 17.4867 21.2145 17.7909 21.4753 18.1812C21.7361 18.5715 21.8753 19.0304 21.8753 19.4999C21.8753 20.1293 21.6252 20.733 21.1801 21.1781C20.735 21.6233 20.1313 21.8733 19.5018 21.8733Z"
													  fill="#4F4F4F"/>
												<path fillRule="evenodd" clipRule="evenodd"
													  d="M24.427 13.2022C24.1687 13.2012 23.916 13.2769 23.7007 13.4197C23.4855 13.5625 23.3175 13.7659 23.2179 14.0043C23.1184 14.2426 23.0919 14.5052 23.1417 14.7586C23.1915 15.0121 23.3154 15.2451 23.4977 15.428C23.68 15.611 23.9125 15.7358 24.1657 15.7866C24.419 15.8373 24.6816 15.8118 24.9203 15.7131C25.1591 15.6145 25.3632 15.4473 25.5068 15.2326C25.6504 15.0179 25.727 14.7654 25.727 14.5071C25.727 14.1618 25.5902 13.8307 25.3466 13.5861C25.1029 13.3415 24.7723 13.2035 24.427 13.2022Z"
													  fill="#4F4F4F"/>
											</svg>
										</button>
										<button>
											<svg width="39" height="39" viewBox="0 0 39 39" fill="none"
												 xmlns="http://www.w3.org/2000/svg">
												<path fillRule="evenodd" clipRule="evenodd"
													  d="M19.5 39C14.3283 39 9.36839 36.9455 5.71143 33.2886C2.05447 29.6316 9.75057e-06 24.6717 9.75057e-06 19.5C-0.00501136 14.4758 1.92938 9.64355 5.3999 6.01065C8.87043 2.37774 13.6092 0.224564 18.6284 0V0H19.5C24.6717 0 29.6316 2.05446 33.2886 5.71142C36.9455 9.36838 39 14.3283 39 19.5C39 24.6717 36.9455 29.6316 33.2886 33.2886C29.6316 36.9455 24.6717 39 19.5 39ZM6.69698 32.3227C8.80537 34.4332 11.4018 35.991 14.2562 36.858C17.1106 37.725 20.1348 37.8746 23.0608 37.2934C25.9868 36.7121 28.7243 35.4181 31.0306 33.5259C33.3369 31.6338 35.1408 29.2019 36.2825 26.4458C37.4243 23.6898 37.8685 20.6946 37.5758 17.7259C37.2832 14.7571 36.2627 11.9063 34.6049 9.4262C32.9471 6.9461 30.703 4.91325 28.0717 3.50779C25.4403 2.10234 22.5029 1.36769 19.5197 1.36894C17.1369 1.3657 14.7768 1.83232 12.5744 2.74211C10.3721 3.65189 8.37077 4.98698 6.68493 6.67099C4.99909 8.355 3.66183 10.3549 2.74966 12.5562C1.83748 14.7576 1.3683 17.1172 1.36895 19.5C1.36597 21.8816 1.83337 24.2403 2.74432 26.4408C3.65527 28.6412 4.99182 30.6401 6.67728 32.3227H6.69698Z"
													  fill="#4F4F4F"/>
												<path fillRule="evenodd" clipRule="evenodd"
													  d="M24.7074 9.97613L10.1562 18.3769C9.89888 18.5244 9.68938 18.7428 9.55274 19.0061C9.41609 19.2694 9.35806 19.5664 9.38557 19.8618C9.41308 20.1571 9.52497 20.4383 9.70789 20.6719C9.89081 20.9054 10.137 21.0813 10.4172 21.1788L14.2778 22.5231L23.0085 15.6833L16.9517 23.4538V27.9742C16.9522 28.0801 16.9837 28.1835 17.0423 28.2717C17.101 28.3598 17.1841 28.4288 17.2816 28.4702C17.379 28.5116 17.4865 28.5235 17.5906 28.5045C17.6948 28.4855 17.7911 28.4364 17.8676 28.3633L20.1377 26.1917L23.0331 28.8311C23.2475 29.0203 23.51 29.1465 23.7916 29.1959C24.0732 29.2453 24.363 29.2159 24.629 29.111C24.8949 29.006 25.1267 28.8296 25.2987 28.6012C25.4707 28.3728 25.5762 28.1013 25.6036 27.8167L27.0119 11.4485C27.0334 11.1686 26.9786 10.8882 26.8534 10.637C26.7281 10.3859 26.537 10.1734 26.3005 10.0223C26.0639 9.87121 25.7908 9.78711 25.5103 9.77897C25.2297 9.77084 24.9522 9.83898 24.7074 9.97613Z"
													  fill="#4F4F4F"/>
											</svg>

										</button>
										<button>
											<svg width="39" height="39" viewBox="0 0 39 39" fill="none"
												 xmlns="http://www.w3.org/2000/svg">
												<path fillRule="evenodd" clipRule="evenodd"
													  d="M19.5 39C14.3283 39 9.36838 36.9455 5.71143 33.2886C2.05447 29.6316 9.75057e-06 24.6717 9.75057e-06 19.5C-0.00501136 14.4758 1.92938 9.64355 5.3999 6.01065C8.87043 2.37774 13.6092 0.224564 18.6284 0H19.5C22.0608 0 24.5965 0.504384 26.9623 1.48435C29.3282 2.46432 31.4778 3.90067 33.2886 5.71142C35.0993 7.52216 36.5357 9.67182 37.5156 12.0377C38.4956 14.4035 39 16.9392 39 19.5C39 24.6717 36.9455 29.6316 33.2886 33.2886C29.6316 36.9455 24.6717 39 19.5 39ZM6.69698 32.3227C8.80478 34.4326 11.4004 35.9901 14.2539 36.8573C17.1073 37.7245 20.1306 37.8746 23.056 37.2943C25.9813 36.714 28.7184 35.4213 31.0249 33.5306C33.3313 31.6399 35.1359 29.2096 36.2788 26.4549C37.4216 23.7003 37.8676 20.7063 37.577 17.7381C37.2865 14.7699 36.2684 11.9192 34.6131 9.43848C32.9577 6.95773 30.7161 4.92348 28.0869 3.51592C25.4576 2.10835 22.5217 1.37092 19.5394 1.36894C17.1582 1.36829 14.8002 1.83682 12.6002 2.74776C10.4001 3.65871 8.40111 4.99421 6.71736 6.67796C5.0336 8.36171 3.6981 10.3607 2.78716 12.5608C1.87622 14.7608 1.40769 17.1188 1.40834 19.5C1.40018 21.8788 1.86138 24.2359 2.76551 26.4362C3.66963 28.6365 4.99894 30.6369 6.67728 32.3227H6.69698Z"
												fill="#4F4F4F"/>
												<path fillRule="evenodd" clipRule="evenodd"
													  d="M24.7028 9.20383L21.6645 9.13489C21.0724 9.12171 20.4836 9.22734 19.933 9.44555C19.3824 9.66376 18.8812 9.99013 18.4588 10.4053C18.0323 10.8177 17.6938 11.3121 17.4636 11.8588C17.2334 12.4055 17.1163 12.9932 17.1194 13.5864V16.4622H14.2781C14.222 16.4622 14.1681 16.4845 14.1284 16.5242C14.0887 16.5639 14.0664 16.6177 14.0664 16.6739V20.2932C14.0664 20.3208 14.0719 20.3481 14.0826 20.3735C14.0933 20.3989 14.109 20.422 14.1287 20.4412C14.1484 20.4605 14.1718 20.4756 14.1975 20.4857C14.2232 20.4958 14.2506 20.5007 14.2781 20.5H17.0997V29.6542C17.0997 29.7104 17.122 29.7642 17.1618 29.8039C17.2015 29.8436 17.2553 29.8659 17.3115 29.8659H21.044C21.1002 29.8659 21.1541 29.8436 21.1938 29.8039C21.2335 29.7642 21.2558 29.7104 21.2558 29.6542V20.5H24.1266C24.178 20.5005 24.2278 20.4823 24.2668 20.4488C24.3058 20.4153 24.3312 20.3687 24.3384 20.3178L24.8062 16.6985C24.8106 16.6695 24.8085 16.6398 24.8 16.6117C24.7914 16.5836 24.7767 16.5577 24.7569 16.536C24.7372 16.5135 24.713 16.4953 24.6859 16.4826C24.6588 16.4699 24.6293 16.4629 24.5993 16.4622H21.2853V14.6353C21.239 14.1379 21.3756 13.6407 21.6694 13.2368C21.7841 13.1178 21.923 13.0249 22.0769 12.9646C22.2307 12.9042 22.3957 12.8778 22.5607 12.8872H24.7077C24.7353 12.8878 24.7627 12.883 24.7884 12.8729C24.814 12.8628 24.8374 12.8476 24.8571 12.8284C24.8768 12.8091 24.8925 12.7861 24.9032 12.7607C24.9139 12.7352 24.9194 12.7079 24.9194 12.6803V9.41557C24.9201 9.38715 24.915 9.3589 24.9043 9.33256C24.8936 9.30622 24.8776 9.28237 24.8572 9.2625C24.8369 9.24263 24.8127 9.22717 24.7861 9.21707C24.7595 9.20698 24.7312 9.20247 24.7028 9.20383Z"
													  fill="#4F4F4F"/>
											</svg>
										</button>
									</div>
								</div>
							</div>
							<div className="result-page__right-wrapper">
								{
									ExamDone ?
										<div className="result-page__right-wrapper-header">
											<img src={ExamDoneImg}/>
											<h1 className="result-page__right-wrapper-header-title">Поздравляем, Вы
												сдали экзамен!</h1>
										</div>
										:
										<div className="result-page__right-wrapper-header">
											<img src={ExamFalseImg}/>
											<h1 className="result-page__right-wrapper-header-title result-page__right-wrapper-header-title_red">К
												сожалению, Вы не сдали экзамен!</h1>
										</div>
								}

								<div className="result-page__right">
									<h2 className="result-page__right-title">Объяснение результатов :</h2>
									<div className="result-page__right-desc">Ваш уровень владения Гагаузским языком
										находится в
										диапазоне от B1 INTERMEDIATE до B2 UPPER INTERMEDIATE, в соответствии с
										рекомендациями,
										установленными системой оценивания .
										<br/>
										<br/>
										Вы понимаете основные моменты того, что слышите и читаете на Гагаузском языке, и
										начинаете с
										относительной уверенностью понимать абстрактные темы.
									</div>
									<div className="result-page__right-conclusion">
										<div className="result-page__right-conclusion-result">
											<div
												className="result-page__left-content-result result-page__left-content-result_green">
												<CircularAudioLoading
													progress={userTestResults?.reading}
													strokeWidth={10}
													sqSize={200}
												/>
												<div className="result-page__left-content-result-border"></div>
												<div className="number">{userTestResults?.reading}%</div>
											</div>
											<span
												className="result-page__right-conclusion-result-desc">{userTestResults?.reading >= 1 && userTestResults?.reading <= 60 ? 'Начинающий' : userTestResults?.reading >= 61 && userTestResults?.reading <= 86 ? 'Средний' : userTestResults?.reading >= 87 ? 'Продвинутый' : 'Тест не пройден'}</span>
										</div>
										<ul className="result-page__right-conclusion-list">
											<li className="result-page__right-conclusion-list-title">
												<img src={BookImg}/>
												<span className="result-page__right-conclusion-list-title_green">Чтение</span>
											</li>
											<li className="result-page__right-conclusion-list-desc">Вы понимаете
												основные
												положения
												более абстрактных письменных текстов и смысл незнакомой лексики.
											</li>
										</ul>
									</div>
									<div className="result-page__right-conclusion2">
										<div className="result-page__right-conclusion-result">
											<div
												className="result-page__left-content-result result-page__left-content-result_blue">
												<CircularAudioLoading
													progress={userTestResults?.listening}
													strokeWidth={10}
													sqSize={200}
												/>
												<div className="result-page__left-content-result-border"></div>
												<div className="number">{userTestResults?.listening}%</div>
											</div>
											<span
												className="result-page__right-conclusion-result-desc">{userTestResults?.listening >= 1 && userTestResults?.listening <= 60 ? 'Начинающий' : userTestResults?.listening >= 61 && userTestResults?.listening <= 86 ? 'Средний' : userTestResults?.listening >= 87 ? 'Продвинутый' : 'Тест не пройден'}</span>
										</div>
										<ul className="result-page__right-conclusion-list">
											<li className="result-page__right-conclusion-list-title">
												<img src={HeadphonesImg}/>
												<span className="result-page__right-conclusion-list-title_blue">Аудирование</span>
											</li>
											<li className="result-page__right-conclusion-list-desc">Вы понимаете
												основные
												положения
												более абстрактных письменных текстов и смысл незнакомой лексики.
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</>
			}

		</>
	)
}
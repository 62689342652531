import React from 'react'

import ButtonImg from "../img/buttonImg.svg"
import ButtonImg1 from "../img/buttonImg1.svg"

import "./style.scss"

export let CollapseButton = ({func, style, show}) => {
	return (
		<button className="collapseButton" style={style} onClick={() => func()}>
			{show ?
				<div className="collapseButton_content">
					<span>Свернуть</span>
					<img src={ButtonImg}/>
				</div>
				:
				<div className="collapseButton_content">
					<span>Развернуть</span>
					<img src={ButtonImg1}/>
				</div>
			}
		</button>
	)
}
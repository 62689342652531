import { createSlice } from '@reduxjs/toolkit'
import { getCurrTestByIdFromBD, getQuickCheckInfoFromBD, getTestFromBD } from '../../api/test'
import { getCookie, setCookie } from '../../shared/functions'
import {testHardCode} from "./testHardCode";

const initialState = {
    test: {},
    testTime: {
        start: null,
        end: null,
    },
    loading: false,
}

export const testSlice = createSlice({
    name: 'test',
    initialState,
    reducers: {
        SET_TEST: (state, action) => {
            state.test = action.payload
            state.loading = false
        },
        UPDATEANSWER_TEST: (state, action) => {
            state.test[action.payload.currChapter]?.map((item) => {
                if (item.testQuestionId == action.payload.currQuestion.testQuestionId) {
                    item.userAnswer = action.payload.answer
                }
            })
        },
        UPDATEANSWERED_FLAG: (state, action) => {
            state.test[action.payload.currChapter]?.map((item) => {
                if (item.testQuestionId == action.payload.currQuestion.testQuestionId) {
                    item['answered'] = action.payload.answered
                }
            })
        },
        UPDATEUDIOAMOUNT: (state, action) => {
            state.test[action.payload.currChapter]?.map((item) => {
                if (item.testQuestionId == action.payload.currQuestion.testQuestionId) {
                    if (item.audioAmount) {
                        item.audioAmount += action.payload.audioAmount
                    } else {
                        item['audioAmount'] = action.payload.audioAmount
                    }
                }
            })
        },
        SET_LOADING: (state, action) => {
            state.loading = action.payload
        },
        SET_TESTTIME: (state, action) => {
            state.testTime = action.payload
        }
    },
})


export const getTest = (id, lang) => async (dispatch, getState) => {
    dispatch(SET_LOADING(true))

    let testId = getCookie('currTestId')
    if (testId) {
        // const testResponse = await getCurrTestByIdFromBD(testId, lang)
        const testResponse = testHardCode
        const testInfo = await getQuickCheckInfoFromBD(id, lang)
        testResponse['testTime'] = testInfo.testTime
        dispatch(SET_TEST(testResponse))
    } else {
        // const testResponse = await getTestFromBD(id, lang)
        const testResponse = testHardCode
        const testInfo = await getQuickCheckInfoFromBD(id, lang)
        testResponse['testTime'] = testInfo.testTime
        dispatch(SET_TEST(testResponse))
        setCookie('currTestId', testResponse.testId, Date.now() + testInfo.testTime * 60 * 1000)
    }
}

export const { SET_TEST, UPDATEANSWER_TEST, SET_LOADING, SET_TESTTIME, UPDATEANSWERED_FLAG, UPDATEUDIOAMOUNT } = testSlice.actions

export default testSlice.reducer
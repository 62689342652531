import React, { useEffect, useState } from "react";

import {CircularAudioLoading} from "../../../shared/ui/CircularAudioLoading";

import InstagramImg from "../../../shared/ui/img/ResultPage/instagramImg.svg"
import TelegramImg from "../../../shared/ui/img/ResultPage/telegramImg.svg"
import FacebookImg from "../../../shared/ui/img/ResultPage/facebookImg.svg"
import BookImg from "../../../shared/ui/img/ResultPage/listBookImg.svg"
import HeadphonesImg from "../../../shared/ui/img/ResultPage/listHeadphonesImg.svg"

import {LoadingTestPage} from "../../../shared/ui/LoadingTestPage";

import "./style.scss"
import {useLocation} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { testResults } from "./testResult";
import { saveUserTestResultsToBD } from "../../../api/test";

export default function ResultPage() {

	const location = useLocation()
	let dispatch = useDispatch()

	let Lang = useSelector(state => state.global.lang)
	let loggedUser = useSelector(state => state.global.loggedUser)
	let userTest = useSelector(state => state.test.test)
	// let userTest = testResults

	let [userTestResults, setUserTestResults] = useState(null)
	let [saveResultError, setSaveResultError] = useState({
		isError: false,
		text: ''
	})

	console.log(loggedUser)

	let [loading, setLoading] = useState(true)

	async function analisingResults() {
		setLoading(true)
		function getResults(chapter) {
			let allPoints = 0
			let userPoints = 0
			userTest?.[chapter]?.forEach((question) => {
				question.variants.forEach((variant) => {
					if (variant.isCorrect) {
						allPoints++
					}
				})
				question.userAnswer.forEach((variant) => {
					if (variant.isCorrect) {
						userPoints++
					}
				})
			})
			return {
				[chapter + 'All']: allPoints,
				[chapter + 'User']: userPoints
			}
		}
		let {readingAll, readingUser} = getResults('reading')
		let {listeningAll, listeningUser} = getResults('listening')

		let testResults = {
			reading: (readingUser / readingAll * 100).toFixed(2),
			listening: (listeningUser / listeningAll * 100).toFixed(2),
			total: ((readingUser + listeningUser) / (readingAll + listeningAll) * 100).toFixed(2)
		}
		

		if (loggedUser) {
			if (+testResults.reading) {
				setUserTestResults(testResults)
				let response = await saveUserTestResultsToBD({...testResults, email: loggedUser.email})
				console.log(response)
				if (response) {
					setLoading(false)
				} else {
					setSaveResultError({
						isError: true,
						text: 'Не удалось сохранить результаты('
					})
				}
			} else {
				setUserTestResults({
					reading: 0,
					listening: 0,
					total: 0,
				})
				setSaveResultError({
					isError: true,
					text: 'Непредвиденная ошибка(('
				})
			}
		} else {
			console.log('logged out')
			if (+testResults.reading) {
				setUserTestResults(testResults)
			} else {
				setUserTestResults({
					reading: 0,
					listening: 0,
					total: 0,
				})
			}
			setLoading(false)
		}
	}

	useEffect(() => {
		analisingResults()
	}, [loggedUser])

	return (
		<>
			{
				loading
					?
					<LoadingTestPage location={location.pathname} error={saveResultError}/>
					:
					<div className="result-page__wrapper">
						<div className="result-page">
							<div className="result-page__left">
								<div className="result-page__left-content">
									<h2 className="result-page__left-content-title">Ваш результат :</h2>
									<span
										className="result-page__left-content-desc">{userTestResults?.total >= 1 && userTestResults?.total <= 40 ? 'Начинающий' : userTestResults?.total >= 41 && userTestResults?.total <= 60 ? 'Средний' : userTestResults?.total >= 61 ? 'Продвинутый' : 'Тест не пройден'}</span>
									<div className="result-page__left-content-result">
										<CircularAudioLoading
											progress={userTestResults?.total}
											strokeWidth={10}
											sqSize={200}
										/>
										<div className="result-page__left-content-result-border"></div>
										<div className="number">{userTestResults?.total}%</div>
									</div>
								</div>
								<div className="result-page__left-footer">
									<h4 className="result-page__left-footer-title">Поделиться своим результатом :</h4>
									<div className="result-page__left-footer-buttons">
										<button>
											<img src={InstagramImg}/>
										</button>
										<button>
											<img src={TelegramImg}/>
										</button>
										<button>
											<img src={FacebookImg}/>
										</button>
									</div>
								</div>
							</div>
							<div className="result-page__right">
								<h2 className="result-page__right-title">Объяснение результатов :</h2>
								<div className="result-page__right-desc">Ваш уровень владения Гагаузским языком
									находится в
									диапазоне от B1 INTERMEDIATE до B2 UPPER INTERMEDIATE, в соответствии с
									рекомендациями,
									установленными системой оценивания .
									<br/>
									<br/>
									Вы понимаете основные моменты того, что слышите и читаете на Гагаузском языке, и
									начинаете с
									относительной уверенностью понимать абстрактные темы.
								</div>
								<div className="result-page__right-conclusion">
									<div className="result-page__right-conclusion-result">
										<div
											className="result-page__left-content-result result-page__left-content-result_green">
											<CircularAudioLoading
												progress={userTestResults?.reading}
												strokeWidth={9}
												sqSize={100}
											/>
											<div className="result-page__left-content-result-border"></div>
											<div className="number">{userTestResults?.reading}%</div>
										</div>
										<span
											className="result-page__right-conclusion-result-desc">{userTestResults?.reading >= 1 && userTestResults?.reading <= 40 ? 'Начинающий' : userTestResults?.reading >= 41 && userTestResults?.reading <= 60 ? 'Средний' : userTestResults?.reading >= 61 ? 'Продвинутый' : 'Тест не пройден'}</span>
									</div>
									<ul className="result-page__right-conclusion-list">
										<li className="result-page__right-conclusion-list-title">
											<img src={BookImg}/>
											<span>Чтение</span>
										</li>
										<li className="result-page__right-conclusion-list-desc">Вы понимаете основные
											положения
											более абстрактных письменных текстов и смысл незнакомой лексики.
										</li>
									</ul>
								</div>
								<div className="result-page__right-conclusion2">
									<div className="result-page__right-conclusion-result">
										<div
											className="result-page__left-content-result result-page__left-content-result_blue">
											<CircularAudioLoading
												progress={userTestResults?.listening}
												strokeWidth={9}
												sqSize={100}
											/>
											<div className="result-page__left-content-result-border"></div>
											<div className="number">{userTestResults?.listening}%</div>
										</div>
										<span
											className="result-page__right-conclusion-result-desc">{userTestResults?.listening >= 1 && userTestResults?.listening <= 40 ? 'Начинающий' : userTestResults?.listening >= 41 && userTestResults?.listening <= 60 ? 'Средний' : userTestResults?.listening >= 61 ? 'Продвинутый' : 'Тест не пройден'}</span>
									</div>
									<ul className="result-page__right-conclusion-list">
										<li className="result-page__right-conclusion-list-title">
											<img src={HeadphonesImg}/>
											<span>Аудирование</span>
										</li>
										<li className="result-page__right-conclusion-list-desc">Вы понимаете основные
											положения
											более абстрактных письменных текстов и смысл незнакомой лексики.
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="result-page__bottom">
							<div className="result-page__bottom-content">
								<h2 className="result-page__bottom-title">Таблица уровней :</h2>
								<div className="result-page__bottom-table">
									<div className="result-page__bottom-table-desc-main">
										<span className="result-page__bottom-table-desc-main-title">Уровень</span>
										<span>Результат</span>
									</div>
									<div
										className={userTestResults?.total >= 1 && userTestResults?.total <= 40 ? 'result-page__bottom-table-desc result-page__bottom-table-desc_active' : 'result-page__bottom-table-desc'}>
										<span className="result-page__bottom-table-desc-title">Начинающий</span>
										<span>1 - 40%</span>
									</div>
									<div
										className={userTestResults?.total >= 41 && userTestResults?.total <= 60 ? 'result-page__bottom-table-desc result-page__bottom-table-desc_active' : 'result-page__bottom-table-desc'}>
										<span className="result-page__bottom-table-desc-title">Средний</span>
										<span>41 - 60%</span>
									</div>
									<div
										className={userTestResults?.total >= 61 ? 'result-page__bottom-table-desc result-page__bottom-table-desc_active' : 'result-page__bottom-table-desc'}>
										<span className="result-page__bottom-table-desc-title">Продвинутый</span>
										<span>61 - 100%</span>
									</div>
								</div>
							</div>
						</div>
					</div>
			}

		</>
	)
}




import React, { useEffect, useRef, useState } from 'react'
import PracticLessonTest from './PracticLessonTest/PracticLessonTest'
import "./style.scss"
import sound1 from "../../../shared/sounds/sound1.mp3"
import { useNavigate, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { getOneLesson } from '../../../store/studying/studyingLessonsSlice'
import { domen } from '../../../api/domen'

export default function StudyingLesson() {

    let params = useParams()
    let dispatch = useDispatch()
    let navigate = useNavigate()
    let videoRef = useRef(null)

    let lessons = useSelector(state => state.studyingLessons.lessons)
    let currLesson = useSelector(state => state.studyingLessons.currLesson)

    useEffect(() => {
        dispatch(getOneLesson(params.id))
    }, [params, lessons])

    useEffect(() => {
        videoRef.current.innerHTML = currLesson?.video
    }, [currLesson])

    console.log(currLesson)

    return (
        <div className="StudyingLesson">
            <h1>{currLesson?.lessonName}</h1>
            <div className="video" ref={videoRef}>
                {/* <video width="400" height="300" controls="controls" poster="video/duel.jpg">
                    <source src="video/duel.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
                </video> */}
            </div>
            <div className="underVideoBtns">
                <button onClick={() => navigate('/forum')}>
                    <div className="icon">
                        <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.13333 21.0001C4.9389 20.9999 4.75249 20.9226 4.615 20.7851C4.47752 20.6476 4.40019 20.4612 4.4 20.2668V16.6001H2.2C0.987067 16.6001 0 15.6131 0 14.4001V2.6668C0 1.45386 0.987067 0.466797 2.2 0.466797H19.8C21.0129 0.466797 22 1.45386 22 2.6668V14.4001C22 15.6131 21.0129 16.6001 19.8 16.6001H10.8907L5.59167 20.8395C5.46162 20.9437 5.29994 21.0003 5.13333 21.0001ZM2.2 1.93346C1.7952 1.93346 1.46667 2.26273 1.46667 2.6668V14.4001C1.46667 14.8042 1.7952 15.1335 2.2 15.1335H5.13333C5.32783 15.1335 5.51435 15.2107 5.65188 15.3483C5.7894 15.4858 5.86667 15.6723 5.86667 15.8668V18.7415L10.175 15.2941C10.3051 15.19 10.4667 15.1334 10.6333 15.1335H19.8C20.2048 15.1335 20.5333 14.8042 20.5333 14.4001V2.6668C20.5333 2.26273 20.2048 1.93346 19.8 1.93346H2.2Z" fill="#424242"/>
                            <path d="M16.8651 7.80065H5.13177C4.93728 7.80065 4.75075 7.72339 4.61323 7.58586C4.4757 7.44834 4.39844 7.26181 4.39844 7.06732C4.39844 6.87283 4.4757 6.6863 4.61323 6.54877C4.75075 6.41125 4.93728 6.33398 5.13177 6.33398H16.8651C17.0596 6.33398 17.2461 6.41125 17.3836 6.54877C17.5212 6.6863 17.5984 6.87283 17.5984 7.06732C17.5984 7.26181 17.5212 7.44834 17.3836 7.58586C17.2461 7.72339 17.0596 7.80065 16.8651 7.80065ZM10.9984 10.734H5.13177C4.93728 10.734 4.75075 10.6567 4.61323 10.5192C4.4757 10.3817 4.39844 10.1951 4.39844 10.0007C4.39844 9.80616 4.4757 9.61963 4.61323 9.48211C4.75075 9.34458 4.93728 9.26732 5.13177 9.26732H10.9984C11.1929 9.26732 11.3795 9.34458 11.517 9.48211C11.6545 9.61963 11.7318 9.80616 11.7318 10.0007C11.7318 10.1951 11.6545 10.3817 11.517 10.5192C11.3795 10.6567 11.1929 10.734 10.9984 10.734Z" fill="#424242"/>
                        </svg>
                    </div>
                    <span>Обсудить</span>
                </button>
                {/* <button 
                    onClick={() => {}}
                >
                    <div className="icon">
                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.1807 11.1147L12.2188 14.0774V6.46875C12.2188 6.072 11.8968 5.75 11.5 5.75C11.1033 5.75 10.7812 6.072 10.7812 6.46875V14.0774L7.81929 11.1155C7.53825 10.8344 7.08329 10.8344 6.80296 11.1155C6.52193 11.3965 6.52193 11.8515 6.80296 12.1317L10.8689 16.1977C11.0414 16.3702 11.2772 16.4234 11.5008 16.3839C11.7236 16.4234 11.96 16.3695 12.1325 16.1977L16.1985 12.1317C16.4795 11.8507 16.4795 11.3958 16.1985 11.1155C15.9167 10.8344 15.4618 10.8344 15.1807 11.1147ZM11.5 0C5.14914 0 0 5.14839 0 11.5C0 17.8516 5.14914 23 11.5 23C17.8509 23 23 17.8509 23 11.5C23 5.14914 17.8516 0 11.5 0ZM11.5 21.5625C5.94261 21.5625 1.4375 17.0574 1.4375 11.5C1.4375 5.94261 5.94261 1.4375 11.5 1.4375C17.0574 1.4375 21.5625 5.94261 21.5625 11.5C21.5625 17.0574 17.0574 21.5625 11.5 21.5625Z" fill="#424242"/>
                        </svg>
                    </div>
                    <span>Сохранить</span>
                </button> */}
            </div>
            <hr />
            <div className="Block">
                <h4>Текст видео</h4>
                <div className="textBlockContent">
                    {currLesson?.lessonText}
                </div>
            </div>
            <hr />
            <div className="Block">
                <h4>Материалы для самостоятельного изучения</h4>
                <div className="materialsBlockContent">
                    <table>
                        <thead>
                            <tr>
                                <th>Тип</th>
                                <th>Название</th>
                                <th>Формат файла</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                currLesson?.materials?.length > 0
                                &&
                                currLesson?.materials?.map((material) => {
                                    return (
                                        <tr key={material.lessonMaterialId}>
                                            <td>Лекция</td>
                                            <td>{material.documentName}</td>
                                            <td>{material.lessonMaterialsType.lessonMaterialsTypeName?.split('.')[0]}</td>
                                            <td>
                                                {/* <button>Скачать</button> */}
                                                <a href={ domen.url + '/lesson_materials/' /* "https://dil.md/lesson_materials/" */ + material.documentName} target="_blank"><button>Открыть</button></a>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    {
                        currLesson?.materials?.length == 0 &&
                        <div className="noStudents">Нет материалов</div>
                    }
                </div>
            </div>
            <hr />
            <div className="Block">
                {/* <h4>Практическая работа</h4>
                <div className="practicWorkContent">
                    <PracticLessonTest
                        questions={currLesson?.questions}
                    />
                </div> */}
            </div>
        </div>
    )
}
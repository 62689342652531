import React, { useEffect } from 'react';
import "./style.scss"
import { useTimer } from 'react-timer-hook';

function Timer({ expiryTimestamp, getLeftTime, TimeExpired }) {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({ expiryTimestamp, onExpire: () => TimeExpired(), autoStart: true });

  useEffect(() => {
    let a = seconds + (minutes * 60) + (hours * 60 * 60)
    getLeftTime(a)
  }, [seconds, minutes, hours])

  return (
    <div className="timerNumbers">
      <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
    </div>
  );
}

export let TestTimer = ({ limit, getCurrTime, TimeExpired }) => {
  const time = new Date();
  time.setSeconds(time.getSeconds() + limit); // 10 minutes timer
  return (
    <div>
      <Timer 
        expiryTimestamp={time}
        getLeftTime={getCurrTime}
        TimeExpired={TimeExpired}
      />
    </div>
  );
}
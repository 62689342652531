import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { PieDiagramm } from '../../../../shared/ui/PieDiagramm'
import { TestTimer } from '../../../../shared/ui/TestTimer'
import "./style.scss"

export let TestTimerWrapper = ({ testTime }) => {

    let navigate = useNavigate()
    let params = useParams()

    let [CurrTime, setCurrTime] = useState()
    let [limit, setLimit] = useState()

    useEffect(() => {
        setLimit((testTime.end - Date.now()) / 1000)
    }, [testTime])

    let TimeExpired = () => {
        console.log('time expired')
        navigate('/exam/' + params.exam_id + '/result')
        // set to cookie
    }

    return (
        <div className="TestTimerWrapper">
            {
                limit ?
                <>
                    <div className="time">
                        <TestTimer
                            limit={limit}
                            getCurrTime={(time) => setCurrTime(((testTime.end - testTime.start) / 1000) - time)}
                            TimeExpired={TimeExpired}
                        />
                    </div>
                    <div className="timeDiagram">
                        <PieDiagramm
                            limit={(testTime.end - testTime.start) / 1000}
                            passedTime={CurrTime}
                        />
                    </div>
                </>
                : ""
            }
            
        </div>
    )
}

import {createSlice} from "@reduxjs/toolkit";
import {prepareTeacherCabinetStudentParams, prepareTeacherCabinetStudentsParams} from "../shared/functions";
import {getStudentsFromDB} from "../api/teacherCabinet";

const initialState = {
	students: {},
	student: {},
	pagination: {
		totalPages: 1,
		pageNumber: 1,
	},
	filter: [],
	loading: true,
	search: [],
}

export const chooseUserSlice = createSlice({
	name: 'chooseUser',
	initialState,
	reducers: {
		SET_STUDENTS: (state, action) => {
			state.students = action.payload.content
			state.pagination = {
				totalPages: action.payload.totalPages,
				pageNumber: action.payload.pageable.pageNumber + 1,
			}
		},
		SET_STUDENT: (state, action) => {
			state.student = action.payload.content[0]
		},
		SET_PAGE: (state, action) => {
			state.pagination.pageNumber = action.payload
		},
		SET_SEARCH: (state, action) => {
			state.search = action.payload
		},
		SET_LOADING: (state, action) => {
			state.loading = action.payload
		},
	}
})

export const getStudents = (lang) => async (dispatch, getState) => {
	dispatch(SET_LOADING(true))
	const {filter, pagination, search} = getState().chooseUser
	const params = prepareTeacherCabinetStudentsParams(pagination, lang, [...filter, ...search])
	const response = await getStudentsFromDB(params)
	dispatch(SET_STUDENTS(response))
	dispatch(SET_LOADING(false))
}

export const getSingleStudent = (lang, id) => async (dispatch) => {
	let filter = {
		key:  "accountId",
		value: id,
	}
	const params = prepareTeacherCabinetStudentParams(lang, filter)
	const response = await getStudentsFromDB(params)
	dispatch(SET_STUDENT(response))
}



export const {SET_STUDENTS, SET_STUDENT, SET_PAGE, SET_LOADING, SET_SEARCH} = chooseUserSlice.actions

export default chooseUserSlice.reducer
import React, { useEffect } from "react";
import { domen } from "../../../../../api/domen";
import { downloadLessonMaterial } from "../../../../../api/studying";
import "./style.scss"

export default function MaterialRow({material}) {

	let downloadMaterial = (id) => {
		let func = async () => {
			let result = await downloadLessonMaterial(id)
		} 
		func()
	}

	/* useEffect(() => {
		downloadMaterial(7)
	}, []) */

	return (
		<>
			<tr className="material-row">
				<td>{material.lessonName}</td>
				<td>{material.documentName.split('.')[0]}</td>
				<td>{material.lessonMaterialsType.lessonMaterialsTypeName}</td>
				<td className="material-row__button">
					<a href={domen.url + '/lesson_materials/' + material.documentName} target="_blank" onClick={(e) => {/* e.preventDefault(); downloadMaterial(material.lessonMaterialId) */}}><button>Скачать</button></a>
				</td>
			</tr>
			<tr><td></td></tr>
		</>
	)
}
import React, {useEffect, useState} from "react";
import Breadcrumbs from "../../shared/ui/breadcrumbs/Breadcrumbs";
import ListImg from "../../shared/ui/img/QuickCheckPage/listImg.svg"
import CardImg from "../../shared/ui/img/QuickCheckPage/cardImg.svg"
import Certificate from "../../shared/ui/img/QuickCheckPage/bg2CertificateImg.png"
import {useNavigate} from "react-router-dom";
import {getQuickCheckInfoFromBD} from "../../api/test";
import {useDispatch, useSelector} from "react-redux";

import "./style.scss"
import { SET_FORMACTIVE, SET_SIGNUP } from "../../store/registrationForm/registrationFormSlice";

export default function QuickCheckPage() {

	let navigate = useNavigate()
	let dispatch = useDispatch()
	let Lang = useSelector(state => state.global.lang)
	let [quickCheckInfo, setQuickCheckInfo] = useState()


	useEffect(() => {
		async function getQuickChekInfo() {
			const response = await getQuickCheckInfoFromBD(1, Lang)
			setQuickCheckInfo(response)
		}
		getQuickChekInfo()
	},[])

	let breadcrumbsItems = [
		{
			slug: '/',
			title: 'Главная',
		},
		{
			slug: '/test',
			title: 'Быстрый Тест',
		},
	]

	return (
		<div className="quick-check">

			<div className="quick-check__breadcrumbs">
				<Breadcrumbs items={breadcrumbsItems} />
			</div>

			<div className="quick-check__content">

				<h2 className="quick-check__title">Пройди 15-минутный тест по <br/> <span>Гагаузскому языку</span></h2>

				<div className="quick-check__bg1">
					<div className="quick-check__bg1__number">
						<div className="quick-check__bg1__number__number">15</div>
						<div className="quick-check__bg1__number__desc">
							<span className="quick-check__bg1__number__desc__min">MIN</span>
							<span>HIZLI <br/> CONTROL</span>
						</div>
					</div>
					<div className="quick-check__bg1__desc">
						<div className="quick-check__bg1__desc__title">{quickCheckInfo?.value}</div>
						<ul className="quick-check__bg1__desc__list">
							{
								quickCheckInfo?.details?.map((item) => {
									return (
										<li key={item.testTimeTypeDetailId}>
											<img src={ListImg}/>
											<span>{item.value}</span>
										</li>
									)
								})
							}
						</ul>
						<button className="quick-check__button" onClick={() => navigate('/test/1/checkaudio')}>Начать тест</button>
					</div>
				</div>

				<h2 className="quick-check__title quick-check__title_green">Зачем проходить наш Гагаузский тест?</h2>

				<div className="quick-check__desc-row">
				<div className="quick-check__card">
						<div className="quick-check__card__img">
							<img src={CardImg}/>
						</div>
						<div className="quick-check__card__title">1</div>
						<div className="quick-check__card__desc">
							Позволяет оценить словарный запас и языковые навыки
						</div>
					</div>
					<div className="quick-check__card">
						<div className="quick-check__card__img">
							<img src={CardImg}/>
						</div>
						<div className="quick-check__card__title">2</div>
						<div className="quick-check__card__desc">
							После завершения тестирования вы получите информацию об уровне владения гагаузским языком
						</div>
					</div>
					<div className="quick-check__card">
						<div className="quick-check__card__img">
							<img src={CardImg}/>
						</div>
						<div className="quick-check__card__title">3</div>
						<div className="quick-check__card__desc">
							Тестирование охватывает все ключевые аспекты гагаузского языка
						</div>
					</div>
				</div>

			</div>

			<div className="quick-check__bg2">
				<div className="quick-check__bg2__content">
					<h2 className="quick-check__title quick-check__title_white">Начните обучение на нашей <br/> платформе и получите официальный <br/> сертификат</h2>
					<div className="quick-check__bg2__content__desc">
					По окончании уровня обучения и прохождения быстрого 15 минутного теста вы получаете сертификат с уровнем знания гагаузского языка. С ним вы можете продолжить обучение на следующем уровне. Также можете легко добавить его в свое резюме.
					</div>
					<button className="quick-check__button" onClick={() => {navigate('/usercabinet/courses')}}>Начать обучение</button>
				</div>
				<div className="quick-check__bg2__img">
					<img src={Certificate}/>
				</div>
			</div>

		</div>
	)
}
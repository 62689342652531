import { configureStore, combineReducers } from '@reduxjs/toolkit'
import globalReducer from "./globalSlice"
import testReducer from "./test/testSlice"
import examReducer from "./exam/examSlice"
import registrationForm from "./registrationForm/registrationFormSlice"
import examCalendar from "./examCalendar/examCalendarSlice"
import teacherOnlineLesson from "./teacherCabinet/teacherOnlineLesson/teacherOnlineLessonSlice"
import StudentsReducer from "./teacherCabinet/teacherStudents/StudentsSlice"
import teacherOneLesson from "./teacherCabinet/teacherOneLesson/teacherOneLessonSlice"
/* import teacherLessonCalendarReducer from "./teacherOneLesson/teacherLessonCalendarSlice" */
import forumThemes from "./forum/forumThemesSlice"
import forumQuestions from "./forum/forumQuestionsSlice"
import forumAskaquestion from "./forum/forumAskaquestionSlice"
import studyingLessons from "./studying/studyingLessonsSlice"
import studyingInfo from "./studying/StudyingInfoSlice"
import studyingMaterials from "./studying/StudyingMaterialsSlice"
import commentsReducer from "./forum/commentsSlice"
import studyingOnlineLessonReducer from "./studying/studyingOnlineLessonSlice";
import chooseUserReducer from "./chooseUserSlice"


const rootReducer = combineReducers({
    global: globalReducer,
    test: testReducer,
    exam: examReducer,
    registrationForm: registrationForm,
    examCalendar: examCalendar,
    teacherOnlineLesson: teacherOnlineLesson,
    students: StudentsReducer,
    teacherOneLesson: teacherOneLesson,
    forumThemes: forumThemes,
    forumQuestions: forumQuestions,
    forumAskaquestion: forumAskaquestion,
    studyingLessons: studyingLessons,
    studyingInfo: studyingInfo,
    studyingMaterials: studyingMaterials,
    comments: commentsReducer,
    studyingOnlineLesson: studyingOnlineLessonReducer,
    chooseUser: chooseUserReducer,
    /* teacherLessonCalendar: teacherLessonCalendarReducer, */
})

export default configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { AnswerItem } from '../../AnswerItem'
import "./style.scss"

export let QuestionAnswer = ({ currQuestion, answersArr, inputType, setUserAnswer, userAnswer, allowClick, showAnswer }) => {

    let params = useParams()

    /* let [radioAnswer, setRadioAnswer] = useState([])
    let [CheckboxAnswer, setCheckboxAnswer] = useState([])

    useEffect(() => {
        if (userAnswer || userAnswer?.length > 0) {
            setCheckboxAnswer([...userAnswer])
        } else {
            setRadioAnswer([])
            setCheckboxAnswer([])
        }
    }, [params]) */

    let getAnswer = (answer) => {
        if (inputType == 'RADIO') {
            setUserAnswer([answer])
        } else if (inputType == 'CHECKBOX') {
            if (answer.action == 'delete') {
                if (userAnswer !== null) {
                    let arr = userAnswer.filter((item) => item.testAnswerId !== answer.answer.testAnswerId)
                    setUserAnswer([...arr])
                }
            } else if (answer.action == 'add') {
                console.log(userAnswer, answer)
                if (userAnswer) {
                    setUserAnswer([...userAnswer, answer.answer])
                } else {
                    setUserAnswer([answer.answer])
                }
                
            }
        }
    }

    /* useEffect(() => {
        if (CheckboxAnswer) {
            setUserAnswer(CheckboxAnswer)
        }
    }, [CheckboxAnswer])
    useEffect(() => {
        if (radioAnswer) {
            setUserAnswer(radioAnswer)
        }
    }, [radioAnswer]) */

    let [ansIds, setAnsIds] = useState([])

    useEffect(() => {
        if (userAnswer != null) {
            let arr = []
            userAnswer.forEach(item => {
                arr.push(item.testAnswerId)
            });
            setAnsIds(arr)
        }
    }, [userAnswer])

    /* useEffect(() => {
        let correctAns = answersArr?.filter((ans) => ans.correct)
        let isEqual = JSON.stringify(correctAns) === JSON.stringify(userAnswer);
        console.log(isEqual);
    }, [showAnswer]) */

    return (
        <div className="answers">
            {answersArr?.map((item) => {
                return (
                    <AnswerItem
                        key={item.testAnswerId}
                        answer={item}
                        inputType={inputType}
                        getAnswer={getAnswer}
                        radioAnswer={userAnswer?.includes(item)}
                        selected={ansIds.includes(item.testAnswerId)}
                        allowClick={allowClick}
                        isTrue={item.isCorrect}
                        showAnswer={showAnswer}
                    />
                )
            })}
        </div>
    )
}
import React from "react";
import "./style.scss"
import MaterialRow from "./MaterialRow/MaterialRow";
import MuiLoader from "../../../../shared/ui/MuiLoader/MuiLoader";


export default function NoteTable({materials, loading}) {

	return (
		<div className="materials-table">
			<table>
				<thead>
				<tr>
					<th>
						<span>№ Урока</span>
					</th>
					<th>
						<span>Название</span>
					</th>
					<th>
						<span>Формат файла</span>
					</th>
					<th>
						<span></span>
					</th>
				</tr>
				</thead>

				<tbody>
				{loading ? (
					<tr>
						<td></td>
						<td></td>
						<td>
							<MuiLoader color={'#2ECC71'}/>
						</td>
					</tr>
				) : materials?.length > 0 ? (
					materials.map((material) => {
						return <MaterialRow key={material.lessonMaterialId} material={material}/>
					})
				) : (
					<tr>
						<td></td>
						<td></td>
						<td className="noMaterials">Нет материалов</td>
					</tr>
				)}
				</tbody>
			</table>
		</div>
	)
}
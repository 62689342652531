import React from 'react'
import { useNavigate } from 'react-router'
import { getDateByTimeStamp, getTimePeriod } from '../../../../shared/functions'
import "./style.scss"

export default function FutureLesson({ lesson }) {

    let navigate = useNavigate()

    return (
        <div className="FutureLesson">
            <div className="top">
                <div className="item alignRight">
                    <h5>{lesson.title}</h5>
                    <p>Тема: {lesson.desctiption}</p>
                </div>
                <div className="item">
                    <h5>{lesson.activeCount} / {lesson.maxCount}</h5>
                    <p>Количество мест</p>
                </div>
                <div className="item">
                    <h5>{getDateByTimeStamp(new Date(lesson.meetingDate).getTime(), '.')}</h5>
                    <p>Дата</p>
                </div>
                <div className="item">
                    <h5>{getTimePeriod(new Date(lesson.meetingDate))}</h5>
                    <p>Время</p>
                </div>
                <div className="item">
                    <div className="icon"
                        onClick={() => navigate('' + lesson.courseId + '_' + lesson.meetingsId)}
                    >
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M26.1324 2.53568L24.4564 0.858585C23.9051 0.308568 23.1584 0 22.38 0C21.6016 0 20.855 0.308568 20.3036 0.858585L18.8706 2.29209L17.5023 3.66151L3.5216 17.6453L3.52487 17.6486V17.6489C3.47054 17.7015 3.42719 17.7647 3.3978 17.8345L1.30568 23.0552L0.0403862 26.2281C-0.0290846 26.4016 -0.00800715 26.5979 0.0964963 26.7526C0.201296 26.9073 0.37557 27 0.562309 27C0.633561 27 0.70422 26.9863 0.770426 26.9602L3.94232 25.6945L9.16126 23.6017C9.23103 23.572 9.29426 23.5289 9.34681 23.4743L9.35008 23.4775L23.3315 9.49219L25.4949 7.32805L25.4899 7.3236C26.4105 6.35485 26.906 5.4841 26.9903 4.67091V4.67061C27.061 3.86281 26.7424 3.06954 26.1326 2.53529L26.1324 2.53568ZM8.9535 22.2824L7.23159 20.5602L20.4173 7.37091L22.1392 9.0934L8.9535 22.2824ZM6.43622 19.7643L4.71431 18.0418L17.9 4.85248L19.6219 6.57794L6.43622 19.7643ZM3.86908 24.5113L2.48766 23.1295L4.12407 19.0439L7.953 22.8741L3.86908 24.5113ZM22.9343 8.29761L18.696 4.05845L19.6659 3.09L23.9043 7.32978L22.9343 8.29761Z" fill="black"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div className="bottom">
                <h5>Ссылка на мероприятие:</h5>
                <div className="link">
                {
                    lesson.linkUrl ?
                        <a href={lesson.linkUrl}>{lesson.linkUrl}</a>
                    :
                        <p>Введите сслыку на ваше мероприятие !</p>
                }
                </div>
            </div>
        </div>
    )
}
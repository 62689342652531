import React, {useEffect, useState} from "react";
import MainBgStar from "../../shared/ui/img/mainBgstar.png"
import MainContent2Shield from "../../shared/ui/img/mainContent2Shield.svg"
import MainContent2Tele from "../../shared/ui/img/mainContent2Tele.svg"
import MainContent2Gift from "../../shared/ui/img/mainContent2Gift.svg"
import Content4img from "../../shared/ui/img/MainPage/content4img.png"
import Content4arrow from "../../shared/ui/img/MainPage/content4arrow.svg"
import Content5arrow from "../../shared/ui/img/MainPage/content5arrow.svg"
import SliderImg from "../../shared/ui/img/MainPage/sliderImg.jpg"
import Logo from "../../shared/ui/img/mainContent2Logo.svg"
import Button from "../../shared/ui/Button";
import AntdAccordion from "../../shared/ui/AntdAccordion";
import {Link, useNavigate} from "react-router-dom";
import {Slider1} from "../../shared/ui/Slider1";
import "./style.scss"
import { SET_FORMACTIVE, SET_SIGNUP } from "../../store/registrationForm/registrationFormSlice";
import { useDispatch } from "react-redux";

export const MainPage = () => {

	let navigate = useNavigate()
	let dispatch = useDispatch()

	let slidesData = [
		{
			id: 1,
			img: SliderImg,
		},
		{
			id: 2,
			img: SliderImg,
		},
		{
			id: 3,
			img: SliderImg,
		},
		{
			id: 4,
			img: SliderImg,
		},
	]

	let [slides, setSlides] = useState([])

	useEffect(() => {
		let arr = slidesData.map((slide) => {
			return (
				<div className="slide" key={slide.id}>
					<img src={slide.img}/>
				</div>
			)
		})
		setSlides(arr)
	}, [])

	return (
		<>
			<div className="wrapper">
				<div className="content1">
					<div className="content_left">
						<h1 className="title">Бесплатная стандартизированная онлайн-платформа для изучения Гагаузского Языка</h1>
						<span className="description">Пройдите тест, чтобы оценить навыки чтения и аудирования. Тест доступен в любое время в любом месте.</span>
						<div className="content1_buttons">
							{/*<Button text={"Пройти Тест"} style={{color: "#2ECC71", marginRight: "44px", minWidth: "350px"}}/>*/}
							{/*<Button text={"Начать Обучение"}*/}
							{/*		style={{color: "#ffff", background: "transparent", border: "3px solid #FFFFFF", minWidth: "350px"}}/>*/}
							<button className="content1_buttons__button content1_buttons__button_white" onClick={() => navigate('test')}>Пройти Тест</button>
							<button className="content1_buttons__button" onClick={() => {navigate('/usercabinet/courses')}}>Начать Обучение</button>
						</div>
					</div>
					<div className="content_right">
						<img src={MainBgStar}/>
					</div>
				</div>
				<div className="content2">
					<div className="content2_center">
						<div className="content2_card">
							<img src={MainContent2Shield}/>
							<h4 className="content2_card_title">Стандарт качества</h4>
							<span className="content2_card_desc">ANA DİLİ  разрабатывался <br/> профессиональной командой  научно-исследовательского центра им. М.В. Маруневич.</span>
						</div>
						<div className="center">
							<div className="content2_card">
								<span className="content2_card_desc_center">ПОЧЕМУ</span>
								<img src={Logo}/>
							</div>
							<div className="content2_card">
								<img src={MainContent2Tele}/>
								<h4 className="content2_card_title">Стандарт качества</h4>
								<span className="content2_card_desc">ANA DİLİ  можно пройти с любого устройства: компьютера, планшета или смартфона. </span>
							</div>
						</div>
						<div className="content2_card">
							<img src={MainContent2Gift}/>
							<h4 className="content2_card_title">Стандарт качества</h4>
							<span className="content2_card_desc">Платформа является абсолютно бесплатной, для обучение вам понадобится всего лишь время и желаение!</span>
						</div>
					</div>
				</div>
				<div className="content3">
					<h2 className="content3_title">Протестируйте свой уровень Гагаузского языка</h2>
					<div className="content3_card_container">
						<div className="content3_card">
							<div className="content3_card_header">
								<div className="content3_card_header_number">15</div>
								<div className="content3_card_header_desc">
									<span className="content3_card_header_desc_up">мин</span>
									<span className="content3_card_header_desc_down">HIZLI CONTROL</span>
								</div>
							</div>
							<div className="content3_card_desc">
								<h4 className="content3_card_desc_title">Проверь свой уровень Гагаузского с помощью быстрого теста</h4>
								<ul className="content3_card_desc_ul">
									<li>Бесплатно. Регистрация не нужна. Можно начать в любой момент.</li>
									<li>Результаты отображаются соотвественно шкале .</li>
									<li>Поделитесь своим результатом в социальных сетях</li>
								</ul>
							</div>
							<button className="content3_card_button" onClick={() => navigate('/test')}>Перейти</button>
						</div>
						<div className="content3_card">
							<div className="content3_card_header">
								<div className="content3_card_header_number">90</div>
								<div className="content3_card_header_desc">
									<span className="content3_card_header_desc_up">мин</span>
									<span className="content3_card_header_desc_down">EGZAMEN</span>
								</div>
							</div>
							<div className="content3_card_desc">
								<h4 className="content3_card_desc_title">Проверь свой уровень Гагаузского с помощью быстрого теста</h4>
								<ul className="content3_card_desc_ul">
									<li>Бесплатно с обязательной регистрацей.</li>
									<li>Результаты соотвествуют <br/> уровням .</li>
									<li>Диплом сохраняется в вашем личном кабинете.</li>
								</ul>
							</div>
							<button className="content3_card_button" onClick={() => navigate('/exam')}>Перейти</button>
						</div>
					</div>
				</div>
				<div className="content4">
					<img src={Content4img}/>
					<div className="content4_content">
						<h2 className="content4_content_title">Решение для организаций</h2>
						<span className="content4_content_desc"> Платформа ANA DİLİ позволяет государственным и частным учереждениям тестировать и обучать персонал Гагагаузскому языку  совершенно бесплатно!  </span>
						<Link className="content4_content_link" to="#">
							<span className="content4_content_link_desc">Подробнее</span>
							<img src={Content4arrow}/>
						</Link>
					</div>
				</div>
				<div className="content5">
					<div className="content5_content">
						<h2 className="content5_content_title">ANA DİLİ Certificate</h2>
						<span className="content5_content_desc">Запишитесь и пройдите наш 90-минутный экзамен и получите димлом , подтверждающий уровень владения Гагаузским языком.</span>
						<Link className="content5_content_link" to="#">
							<span className="content5_content_link_desc">Подробнее</span>
							<img src={Content5arrow}/>
						</Link>
					</div>
					<div className="content5_slider">
						<Slider1
							sliderClass={'mainPageSlider'}
							slides={slides}
						/>
					</div>
				</div>
				<div className="content6">
					<h2 className="content6_title">Часто задаваемые <span>вопросы</span></h2>
					<AntdAccordion
						className={'FaqAccordion'}
						data={[
							{
								title: '1.	Что такое платформа DİL.MD?',
								text: 'Платформа «DİL.MD» - это уникальная Платформа, благодаря которой Вы сможете получить доступ к различным инструментам, предполагающим текущую оценку знаний, выбор нужной тематики, использования в процессе обучения эффективных тренажеров, аудио и видеоматериалов для изучения гагаузского языка на профессиональном уровне.',
							},
							{
								title: '2.	Какую цель преследует эта платформа?',
								text: 'Если вы поставили перед собой твердую цель выучить гагаузский язык и предпочитаете заниматься самостоятельно (или не можете позволить себе оплату преподавателю), и в то же время хотите попрактиковаться в живом общении с носителями языка или другими людьми, изучающими этот язык, то представленные в обзоре платформа и онлайн площадки для самостоятельного изучения гагаузского языка будут наиболее оптимальным вариантом для вас. ',
							},
							{
								title: '3.	Что предоставляет платформа DİL.MD?',
								text: 'Вам предлагается самостоятельно изучить учебные и дидактические материалы, получать от администратора Платформы советы по улучшению навыков владения гагаузским языком и многое другое.',
							},
							{
								title: '4.	В каком формате проходят занятия?',
								text: 'Изучение гагаузского языка проходит по двум уровням А1 и А2. Занятия проводятся квалифицированными преподавателями в онлайн формате. По окончании уровней А1 и А2 будет продолжено изучение языка по уровням В1 и В2.',
							},
							{
								title: '5.	С каким уровнем знания гагаузского языка лучше приходить на занятия?',
								text: 'С любым! Лучших результатов добиваются пользователи с начальным и средним уровнем языка (А1-В1). Владеющие уровнем С1 либо С2 также смогут узнать много нового.',
							},
						]}
					/>
				</div>

			</div>
		</>
	)
}
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getCourseMaterials } from "../../../store/studying/StudyingMaterialsSlice";

import NoteTable from "./StudyingNoteTable/NoteTable";

import A_1_iş_tefteri from "../../../shared/lesson_materials/A_1_iş_tefteri.pdf"
import A_1_üürenmäk_kiyadı from "../../../shared/lesson_materials/A_1_üürenmäk_kiyadı.pdf"

import "./style.scss"

export default function StudyingNote() {

	const dispatch = useDispatch()
	let params = useParams()

	// let Course = useSelector(state => state.studyingMaterials.materials)
	let [Course, setCourse] = useState([])
	let loading = useSelector(state => state.studyingMaterials.loading)

	useEffect(() => {
		/* dispatch(getCourseMaterials(params.courseId)) */
		if (params.courseId == 1) {
			setCourse([
				{
					lessonMaterialId: 1,
					lessonName: 'Урок 1',
					documentName: 'A_1_iş_tefteri.pdf',
					document: A_1_iş_tefteri,
					lessonMaterialsType: {
						lessonMaterialsTypeName: 'pdf'
					},
				},
				{
					lessonMaterialId: 2,
					lessonName: 'Урок 1',
					documentName: 'A_1_üürenmäk_kiyadı.pdf',
					document: A_1_üürenmäk_kiyadı,
					lessonMaterialsType: {
						lessonMaterialsTypeName: 'pdf'
					},
				},
			])
		} else if (params.courseId == 2) {
			setCourse([
				{
					lessonMaterialId: 1,
					lessonName: 'Урок 1',
					documentName: 'A_2_iş_tefteri.pdf',
					lessonMaterialsType: {
						lessonMaterialsTypeName: 'pdf'
					},
				},
				{
					lessonMaterialId: 2,
					lessonName: 'Урок 1',
					documentName: 'A_2_üürenmäk_kiyadı.pdf',
					lessonMaterialsType: {
						lessonMaterialsTypeName: 'pdf'
					},
				},
			])
		} else if (params.courseId == 3) {
			setCourse([
				{
					lessonMaterialId: 1,
					lessonName: 'Урок 1',
					documentName: 'B_1_iş_tefteri.pdf',
					lessonMaterialsType: {
						lessonMaterialsTypeName: 'pdf'
					},
				},
				{
					lessonMaterialId: 2,
					lessonName: 'Урок 1',
					documentName: 'B_1_üürenmäk_kiyadı.pdf',
					lessonMaterialsType: {
						lessonMaterialsTypeName: 'pdf'
					},
				},
			])
		} else if (params.courseId == 4) {
			setCourse([
				{
					lessonMaterialId: 1,
					lessonName: 'Урок 1',
					documentName: 'B_2_iş_tefteri.pdf',
					lessonMaterialsType: {
						lessonMaterialsTypeName: 'pdf'
					},
				},
				{
					lessonMaterialId: 2,
					lessonName: 'Урок 1',
					documentName: 'B_2_üürenmäk_kiyadı.pdf',
					lessonMaterialsType: {
						lessonMaterialsTypeName: 'pdf'
					},
				},
			])
		}
	}, [])

	console.log(Course)

	return (
		<div className="studying-note">
			<h1 className="studying-note__title">Материалы курса {Course?.courseName}</h1>
			<div className="studying-note__table">
				<NoteTable 
					materials={Course}
					loading={loading}
				/>
			</div>
		</div>
	)
}
import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

export default function MuiPagination({ customSX, pagesCount, setPage }) {

    let customPagesStyles = (customSX !== undefined)
    ?
        customSX
    :
        (window.innerWidth > 500)
        ?
        {
            '& .MuiPagination-ul': {
                gap: '0.4375vw'
            },
            '& .MuiPaginationItem-root.MuiPaginationItem-page': {
                width: '1.5625vw',
                height: '1.5625vw',
                backgroundColor: '#fff',
                borderRadius: '50%',
                fontFamily: 'Gilroy',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '0.9vw',
                lineHeight: '1.25vw',
                textAlign: 'center',
                color: '#000000',
                /* margin: '0 0.27778vw', */
                cursor: 'pointer',
                transition: '0.3s',
            },

            '& .MuiPaginationItem-root.MuiPaginationItem-page.Mui-selected': {
                backgroundColor: '#18AC00',
                color: '#fff',
            },
            '& .MuiPaginationItem-root.MuiPaginationItem-previousNext': {

                width: '1.5625vw',
                height: '1.5625vw',
                backgroundColor: '#b1b2b36e',
                borderRadius: '50%',

                '& .MuiSvgIcon-root': {
                    width: '100%',
                    height: '100%',
                    borderRadius: '50%',
                    fill: '#fff',
                }
            }
        }
        :
        {
            '& .MuiPagination-ul': {
                gap: '10px'
            },
            '& .MuiPaginationItem-root.MuiPaginationItem-page': {
                width: '30px',
                height: '30px',
                backgroundColor: '#fff',
                borderRadius: '50%',
                fontFamily: 'Gilroy',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '18px',
                lineHeight: '24px',
                textAlign: 'center',
                color: '#000000',
                cursor: 'pointer',
                transition: '0.3s',
            },

            '& .MuiPaginationItem-root.MuiPaginationItem-page.Mui-selected': {
                backgroundColor: '#18AC00',
                color: '#fff',
            },
            '& .MuiPaginationItem-root.MuiPaginationItem-previousNext': {

                width: '30px',
                height: '30px',
                backgroundColor: '#b1b2b36e',
                borderRadius: '50%',

                '& .MuiSvgIcon-root': {
                    width: '100%',
                    height: '100%',
                    borderRadius: '50%',
                    fill: '#fff',
                }
            }
        }

    return (
        <Stack spacing={2}>
            <Pagination sx={customPagesStyles} count={pagesCount} onChange={(e, a) => setPage(a)} />
        </Stack>
    );
}
import React, { useEffect, useState } from "react";
import {Box, MenuItem, TextField} from "@mui/material";
import {useInput} from "../../../shared/functions/useInput";
import "./style.scss"
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import VisionImg from "../../../shared/ui/img/RegistrationForm/visionImg.svg"
import { regNewUser } from "../../../api/auth";
import { deleteCookie, getCookie, parseJwt, setCookie } from "../../../shared/functions";
import { useDispatch } from "react-redux";
import { useCheckAuth } from "../../../shared/functions/useCheckAuth";
import { SET_LOGGEDUSER } from "../../../store/globalSlice";

export default function FormPage() {

	let navigate = useNavigate()
	let dispatch = useDispatch()
	let params = useParams()

	const email = useInput('', {isEmail: true})
	const name = useInput('', {isEmpty: true, minLength: 1})
	const surname = useInput('', {isEmpty: true, minLength: 1})
	const city = useInput('', {isEmpty: true, minLength: 1})
	const checkbox = useInput('', {isCheckbox: true})
	const password = useInput('', {isEmpty: true, minLength: 5})

	const [showPassword, setShowPassword] = useState(false)
	const [gender, setGender] = React.useState('');
	const [country, setCountry] = React.useState('');
	const [authMessage, setAuthMessage] = useState({
		show: false,
		success: false,
		error: false,
		successText: 'Вы успешно зарегистрировались!',
		errorText: 'Ошибка регистрации! Проверьте введенные данные и попробуйте еще раз'
	})

	useEffect(() => {
		if (getCookie('testTime') && getCookie('currTestId')) {
			deleteCookie('testTime')
			deleteCookie('currTestId')
		}
	}, [])

	const handleChangeGender = (event) => {
		setGender(event.target.value);
	};

	const handleChangeCountry = (event) => {
		setCountry(event.target.value);
	};

	async function onSubmitFunc(e) {
		e.preventDefault()
		let fielsNames = ['fam', 'im', 'ot', 'town', 'countryId', 'gender', 'email', 'password']
		let formData = {}
		let form = e.target
		Array.from(form).forEach((field) => {
			if (field.nodeName == 'INPUT' && fielsNames.includes(field.name)) {
				formData[field.name] = field.value
			}
		})
		let result = await regNewUser(formData)
		/* let result = {
			"token": "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJtYXZyaWt2b3ZhQGdtYWlsLmNvbSIsImlhdCI6MTY4MDAyMjQ2MSwiZXhwIjoxNjgwMDIzOTAxfQ.cBXxXhIc__qNZM9p0hTdJKm9DNWDJ-zi8K6S19b3hGs"
		} */
		if (result) {
			setAuthMessage({...authMessage, show: true, error: false, success: true})
			let tokenData = parseJwt(result.token)
			dispatch(SET_LOGGEDUSER(tokenData))
			setCookie('userToken', result.token, tokenData.exp * 1000)
			setTimeout(() => {
				setAuthMessage({...authMessage, show: false, error: false, success: false})
				navigate('/test/' + params.test_id + '/result')
			}, 1000);
		} else {
			setAuthMessage({...authMessage, show: true, error: true, success: false})
		}
	}

	return (
		<div className="form-page">
			<div className="form-page__container">
				<h2 className="form-page__container-title">Почти всё ...</h2>
				<span className="form-page__container-desc">Преред просмотром результатов тестирования , пожалуйста , расскажите нам немного о себе и зарегистрируйтесь. Эта информация будет использована только для научних исследований по изучений Гагаузского языка. </span>
				<form className="form-page__form" onSubmit={onSubmitFunc}>
					{(name.isDirty && name.isEmptyError) && <div className="form-page__form-error">Поле не может быть пустым</div>}
					<input onChange={e => name.onChange(e)} value={name.value} onBlur={e => name.onBlur(e)} type="text" name="im" placeholder="Имя" className="form-page__form-input"/>
					{(surname.isDirty && surname.isEmptyError) && <div className="form-page__form-error">Поле не может быть пустым</div>}
					<input onChange={e => surname.onChange(e)} value={surname.value} onBlur={e => surname.onBlur(e)} type="text" name="fam" placeholder="Фамилия" className="form-page__form-input"/>
					{(email.isDirty && email.isEmailError) && <div className="form-page__form-error">Не корректный емайл</div>}
					<input onChange={e => email.onChange(e)} value={email.value} onBlur={e => email.onBlur(e)} type="email" name="email" placeholder="Эл. почта" className="form-page__form-input"/>
					<input type="text" placeholder="Год рождения" onFocus={(e) => e.target.type = "date"} onBlur={(e) => (e.target.type = "text")} className="form-page__form-input"/>
					<Box width="350px" sx={{marginBottom: '20px'}}>
						<TextField
							label="Страна прибывания"
							select
							name={'countryId'}
							value={country}
							onChange={handleChangeCountry}
							fullWidth
						>
							<MenuItem value={2}>Россия</MenuItem>
							<MenuItem value={3}>Молдова</MenuItem>
						</TextField>
					</Box>
					{(city.isDirty && city.isEmptyError) && <div className="form-page__form-error">Поле не может быть пустым</div>}
					<input onChange={e => city.onChange(e)} value={city.value} onBlur={e => city.onBlur(e)} type="text" name={'town'} placeholder="Город" className="form-page__form-input"/>
					<Box width="350px" sx={{marginBottom: '26px'}}>
						<TextField
							label="Пол"
							select
							name={'gender'}
							value={gender}
							onChange={handleChangeGender}
							fullWidth
						>
							<MenuItem value={1}>Мужской</MenuItem>
							<MenuItem value={0}>Женский</MenuItem>
						</TextField>
					</Box>
					{(password.isDirty && password.minLengthError) &&
					<div className="form-page__form-error">Не корректная длинна</div>}
					<div className="formPage__form_password signin-form-password_container">
						<input 
							onChange={e => {
								password.onChange(e)
								console.log(e.target.value)
							}} 
							value={password.value} 
							onBlur={e => password.onBlur(e)}
							type={showPassword ? "text" : "password"}
							name="password"
							placeholder="Придумайте пароль"
							className="signin-form__input signin-form__input_last"
							id="password"
						/>
						<button onClick={() => setShowPassword(!showPassword)} type='button' className="signin-form-password_container_button"><img src={VisionImg}/></button>
					</div>
					<div className="form-page__form-checkbox">
						<div className={checkbox.inputValid ? 'form-page__form-checkbox-star' : 'form-page__form-checkbox-star_disabled'}>*</div>
						<input onChange={e => checkbox.onCheckboxChange(e)} value={checkbox.value} onBlur={e => checkbox.onBlur(e)} name={'checkbox'} type="checkbox" id="check"/>
						<label htmlFor="check">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus eget est aliquam, maximus dolor.</label>
					</div>
					{(email.inputValid || name.inputValid || surname.inputValid || checkbox.inputValid || city.inputValid) ? <div className="form-page__form-error">заполнитe форму</div> : ''}
					<button disabled={email.inputValid || name.inputValid || surname.inputValid || checkbox.inputValid || city.inputValid} type="submit" className={(email.inputValid || name.inputValid || surname.inputValid || checkbox.inputValid || city.inputValid) ? 'form-page__form-button_disabled' : 'form-page__form-button'}>Продолжить</button>
				</form>
				{
					authMessage.show &&
					<div className="authMessage">
						{
							authMessage.success ?
							<span className="success">{authMessage.successText}</span> :
							<span className="error">{authMessage.errorText}</span>
						}
					</div>
				}
				<Link to={'/test/' + params.test_id + '/result'}><p className="skip">Пропустить</p></Link>
			</div>
		</div>
	)
}
import React, { useEffect, useState } from 'react'
import "./style.scss"

export let SignBtnBurger = ({onClick}) => {

    let [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        onClick(isOpen)
    }, [isOpen])

    return (
        <button className="SignBtnBurger"
            onClick={() => {
                setIsOpen(!isOpen)
            }}
        >
            <div className={"icon " + ((isOpen) ? 'open' : '')}>
                <svg viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M11.7904 0.0189456C10.0565 0.239142 8.66119 0.894655 7.45944 2.05361C4.80311 4.61534 4.51878 8.76567 6.79874 11.6973C8.99192 14.5173 12.9974 15.3124 16.0753 13.5386C18.7945 11.9716 20.1984 8.92817 19.6166 5.86152C19.0801 3.03319 16.7971 0.726697 13.9581 0.14471C13.418 0.0339565 12.2195 -0.0355304 11.7904 0.0189456ZM6.65939 15.1063C4.24329 15.5305 2.02697 17.0305 0.64476 19.1769C0.352729 19.6303 0 20.3987 0 20.5813C0 20.9402 1.03815 22.4003 2.01965 23.4219C4.41769 25.918 7.37396 27.4056 10.7806 27.8305C11.4898 27.919 13.4673 27.918 14.1648 27.8288C16.9255 27.4759 19.3817 26.4448 21.5065 24.7468C22.1647 24.2209 23.4626 22.8806 23.9718 22.201C24.5469 21.4335 25 20.6972 25 20.5301C25 20.368 24.6916 19.6963 24.4264 19.2807C23.4944 17.8205 22.1955 16.6334 20.7371 15.909C19.6728 15.3803 18.3466 15.0131 17.6767 15.0615C17.4427 15.0784 17.2932 15.1296 17.0752 15.2672C16.2858 15.7653 16.0383 15.9042 15.5986 16.0954C14.5155 16.5663 13.4085 16.7736 12.2271 16.7266C10.7185 16.6667 9.4554 16.2619 8.1952 15.4344C7.92102 15.2543 7.62909 15.092 7.54651 15.0736C7.46386 15.0552 7.35944 15.0324 7.31441 15.0229C7.26938 15.0134 6.97462 15.0509 6.65939 15.1063Z" fill="#2ECC71" />
                </svg>
            </div>
            <div className={"close " + ((isOpen) ? 'open' : '')}>
                <div></div>
                <div></div>
            </div>
        </button>
    )
}
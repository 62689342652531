export let examHardCode1 = {
    "examId": 1,
    "examName": "НОмер уну",
    "reading": [
        {
            "examTaskId": 1,
            "value": "Aşaada 5 başka-başka cümlä verili. Bu cümlelerä görä 3 variantın arasından dooru düşünmeyi belli ediniz.",
            "audioValue": "tet",
            "questions": [
                {
                    "examQuestionId": 1,
                    "value": "Kiyat tiparlamasına, kompyuter kullanmasına görä kaligrafiya kaybetti kendi ilk paalılıını da şindi onu kablederlär estetika fenomeni gibi.",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 1,
                            "value": "A) Büünkü yaşamamızda kaligrafiya paalılıı kaldı geri, nicä bir şaşılacak incäzanaat işi.",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 1,
                            "value": "B) Kiyat tiparlaması kaligrafiyadan hem kompyuterdan taa paalı.",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 1,
                            "value": "C) Kaligrafiya heptän kaybeldi ortalıktan, peydalandıınan tiparlı kiyatlar.",
                            "isCorrect": 0
                        }
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 2,
                    "value": "Yıl yıldan Komrat Devlet Universitetindä üürenmää isteyennerin sayısı büüyer, uzmannarın hazırlanılması taa üüsek uurda geçer.",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 2,
                            "value": "A) Üürenmää isteyennerin sayısı yıl yıldan Komrat Devlet Universitetindä diişilmeer.",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 2,
                            "value": "B) Komrat Devlet Universitetindä uzmannarı herzaman gibi hazırlêêrlar.",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 2,
                            "value": "C) Komrat Devlet Universiteti yıl yıldan olêr taa populär, üüretim taa kaliteli.",
                            "isCorrect": 1
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 3,
                    "value": "Garantiya sertifikatı - o satıcının bir dokumenti, angısı gösterer , ani belli bir zaman bu elektrik malı işleyecek, ama bozularsa, yakışêr onu geri vermää yada diiştirmää.",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 3,
                            "value": "A) Elektrik malının garantiya sertifikatı satıcının en önemni dokumenti sattıktan sora.",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 3,
                            "value": "B) Garantiya sertifikatı alıcıya önemni, zerä raatlık verer, elektrik malının kalitesini gösterer.",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 3,
                            "value": "C) Garantiya sertifikatı olmadaan da elektirk malını korkusuz alarız.",
                            "isCorrect": 0
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 4,
                    "value": "Zaman hayır olsun, Mariya, ayırıca sana bir haber vereyim, ani gagauzça  kursta başarılı olan üürenicilär gideceklär Türkiyaya.",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 4,
                            "value": "A) Bu haberi Mariyadan kaarä başka insannara da söledilär.",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 4,
                            "value": "B) Bu haberi kim verdiysä, o hesaplêêr kim Türkiyäyä gidecek.",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 4,
                            "value": "C) Ayırıca Mariyanın dikatını çektilär ona, ani Türkiyeyä gidecek salt ii bilennär gaguazçayı.",
                            "isCorrect": 1
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 5,
                    "value": "İlki, Hederlez ayın 5-dä 2017-ci  yılda Gagauziyada başladı kendi işini Bütündünnä gagauzların IV-cü Kongresi, ikincisi, Kongres açıldı Moldovanın hem Gagauziyanın gimnalarınnan hem Kongresin gimna türküsünnän.",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 5,
                            "value": "A) IV-cü Kongresinin başlandıı datası hem Kongres angı gimnalarlan açıldı belli oldu.",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 5,
                            "value": "B) İlk hem ikinci haberlär Kongresinin aktual soruşlarını açıklêêrlar. ",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 5,
                            "value": "C) Baalı IV-cü Kongresa pek meraklı işlär beklener. ",
                            "isCorrect": 0
                        },
                    ],
                    "userAnswer": []
                },
            ],
            "texts": []
        },
        {
            "examTaskId": 2,
            "value": "Aşaada 5 engelli kişi hem onnarın en üüsek başarıları için informaţiya verili. Bu başarıları verili A-F variantlarda seçin da kişilärlän aşaada tabliţada uydurun. Herbir engelli kişi için salt bir dooru variant verili. Bir variant zeedä verili.",
            "audioValue": "tet",
            "questions": [
                {
                    "examQuestionId": 1,
                    "value": "Taa üstünä Marianna Tihu pek gözäl bir insan hem pek ii bir ana. 2017 yılda 'Sınırsız Güzellik' yarışmasında taradı.",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 1,
                            "value": "A",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 1,
                            "value": "B",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 1,
                            "value": "C",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 1,
                            "value": "D",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 1,
                            "value": "E",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 1,
                            "value": "F",
                            "isCorrect": 1
                        }
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 2,
                    "value": "Erşef Armagan, görmeyän, paasız resimnär yaradan bir geniy.",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 2,
                            "value": "A",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 2,
                            "value": "B",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 2,
                            "value": "C",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 2,
                            "value": "D",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 5,
                            "examQuestionId": 2,
                            "value": "E",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 6,
                            "examQuestionId": 2,
                            "value": "F",
                            "isCorrect": 0
                        }
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 3,
                    "value": "Yavaş-yavaş Petri bir anılmış muzıkacı oldu, onun görmä engelliinä bakmayarak.",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 3,
                            "value": "A",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 3,
                            "value": "B",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 3,
                            "value": "C",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 3,
                            "value": "D",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 5,
                            "examQuestionId": 3,
                            "value": "E",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 6,
                            "examQuestionId": 3,
                            "value": "F",
                            "isCorrect": 0
                        }
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 4,
                    "value": "Kompozitor Lüdvig van Bethoven ilk konţertini 8 yaşında verdi.",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 4,
                            "value": "A",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 4,
                            "value": "B",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 4,
                            "value": "C",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 4,
                            "value": "D",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 5,
                            "examQuestionId": 4,
                            "value": "E",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 6,
                            "examQuestionId": 4,
                            "value": "F",
                            "isCorrect": 0
                        }
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 5,
                    "value": "Buna görä Êrik Weihenmayer dünnenin en becerikli kanara tırmanıcısı sayılêr.",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 5,
                            "value": "A",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 5,
                            "value": "B",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 5,
                            "value": "C",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 5,
                            "value": "D",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 5,
                            "examQuestionId": 5,
                            "value": "E",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 6,
                            "examQuestionId": 5,
                            "value": "F",
                            "isCorrect": 0
                        }
                    ],
                    "userAnswer": []
                },
            ],
            "texts": [
                {
                    "examTextId": 1,
                    "value": "A. Parmaklarınnan görän adam. Onun resimneri koyulu hem Türkiyedä, hem devlet aşırı geçän sergilerdä dä.O duumasından görmeyän bir resimci."
                },
                {
                    "examTextId": 2,
                    "value": "B. Amerikalı monahın istoriyası. Gagauz dili üürenmesini o  bir büük başarı sayêr. Lafeder inglizçä, türkçä, gagauzça, taa başka dillärlän da uuraşêr."
                },
                {
                    "examTextId": 3,
                    "value": "C. Amerikalı  kör atlet hem gezici. Dünnedä Everestin en üüsek tepesinä tırmanan ilk görmeyän adam oldu."
                },
                {
                    "examTextId": 4,
                    "value": "D. «Kör muzıkacı». Onnarın çıraa İohim, angısı beygirleri güdärdi, üüretti uşaa taa küçüktän kavalda çalmaa. Ama pianinoda da, angısını ona mamusu satın aldı, üürendi pek becerikli çalmaa."
                },
                {
                    "examTextId": 5,
                    "value": "E. İşitmeyän muzika yazıcısı. Bütün dünnedä anılmış hem sevgili kompozitor oldu, opera, dramatik spektaklilerä muzikayı, horo yaratmaları hem taa çok türlü uurlarda yazdı."
                },
                {
                    "examTextId": 6,
                    "value": "F. «Kaavi ruhlu karı». Rumınyada paralimpik oyunnara gibi yarışmalara katıldı. Yadro atmasında üçüncü eri aldı, okçuluk yaraşmasında birinci eri kazandı."
                },
            ]
        },
        {
            "examTaskId": 3,
            "value": "Soruşlara «Haydar kilim festivali» tekstä görä cuvap verin.",
            "audioValue": "tet",
            "questions": [
                {
                    "examQuestionId": 1,
                    "value": "Kilim festivali hederlez ayında başka-başka küülerdä geçer.",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 1,
                            "value": "Dooru",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 1,
                            "value": "Yannış",
                            "isCorrect": 1
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 2,
                    "value": "Neeti eski kilimcilik zanaatını cannandırmaa.",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 2,
                            "value": "Dooru",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 2,
                            "value": "Yannış",
                            "isCorrect": 0
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 3,
                    "value": "Kilimnerin platı taa çoyu boz rengindä.",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 3,
                            "value": "Dooru",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 3,
                            "value": "Yannış",
                            "isCorrect": 1
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 4,
                    "value": "Kilimnerdä insannarı da gösterän süjetlar razgeler.",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 4,
                            "value": "Dooru",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 4,
                            "value": "Yannış",
                            "isCorrect": 0
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 5,
                    "value": "Herbir rengin var kendi simvolu.",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 5,
                            "value": "Dooru",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 5,
                            "value": "Yannış",
                            "isCorrect": 0
                        },
                    ],
                    "userAnswer": []
                },
            ],
            "texts": [
                {
                    "examTextId": 1,
                    "value": "Haydar kilim festivali"
                },
                {
                    "examTextId": 2,
                    "value": "Kilim festivali 2013 -cü yıldan beeri geçer avgust ayında Haydar küüyündä, zerä bu küüdä eveldän anılmış kilim ustacılıı vardı. Bu festivali kurdu asoţiatiya  ‘Haydarın gençleri’ Bakannık Komitetin hem Bilim Merkezin yardımınnan. Festivalin neeti cannandırmaa eski kilimcilik zanaatını, gagauz kulturasını bildirmää bütün dünneyä hem gençleri da ilgilemää evelki zanaatlara. Bu festivalä katılannar yakışêr sergiyä çıkarsınnar salt el işlerini, ani dokundular evelki düzennerdä, koyun yapaasından, doal boyalrlan, original dizaynnan, millet koloritınnan. Bu festivalda ne türlü kilim yok? Kara platta büük kırmızı gül buketleri. Kilimnerin kenarlarında türlü resimnär hem gerciklär. Kilimin platı taa siirek var nicä olsun boz , sarı, turuncu, maavi. Kilimnerdä taa sık flora hem fauna dünnesi gösteriler, ama razgeler insannarı da gösterän süjetlar. Kırmızı, eşil, maavi, sarı, turuncu, zambak, biyaz, kara – hepsi bu renklär hem onnarın gölgeleri var gagauz halk kilimnerindä. Herbir rengin var kendi maanası, simvolu.     En kaavi renk – kırmızı, onun türlü benezleri kilimdä var. Kırmızı  - o kısmetin hem sevinmeliin simvolu, başarı simvolu. Çiçeklerin renkleri, eşilliklär, hayvan hem kuş  dünnesi – hepsi taşıyêr kendi simvolunu. Aşırı devletlerdän gelän insannar şaşêrlar, nekadar gözäl, biri birinä uygun erleştirili renklär kilimnerdä.  Kilimnerdän karä festivaldä insanın dikatını çeker taa çok halk zanaatlarının el işleri, halk imeeleri hem içkileri. Gözäl, şen, ilin geçer bu yortu. "
                },
            ]
        },
        {
            "examTaskId": 4,
            "value": "Tekstin boş brakılan erlerinä uygun lafı variantların arasından seçin da erleştirin.",
            "audioValue": "tet",
            "questions": [
                {
                    "examQuestionId": 1,
                    "value": "16",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 1,
                            "value": "A) 14 -dündä",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 1,
                            "value": "B) 7 -sindä",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 1,
                            "value": "C) 16 -sında",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 1,
                            "value": "D) 8 -zindä",
                            "isCorrect": 0
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 2,
                    "value": "17",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 2,
                            "value": "A) beklenärmiş",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 2,
                            "value": "B) bakılırmış",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 2,
                            "value": "C) satılırmış",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 2,
                            "value": "D) oynanırmış",
                            "isCorrect": 0
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 3,
                    "value": "18",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 3,
                            "value": "A) kaynadêrlar",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 3,
                            "value": "B) denerlär",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 3,
                            "value": "C) sarêrlar",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 3,
                            "value": "D) yazêrlar",
                            "isCorrect": 1
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 4,
                    "value": "19",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 4,
                            "value": "A) altına",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 4,
                            "value": "B) üstünä",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 4,
                            "value": "C) içinä",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 4,
                            "value": "D) yanına",
                            "isCorrect": 0
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 5,
                    "value": "20",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 5,
                            "value": "A) neetlärdilär",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 5,
                            "value": "B) yakardılar",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 5,
                            "value": "C) sıbıdardılar",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 5,
                            "value": "D) toplardılar",
                            "isCorrect": 0
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 6,
                    "value": "21",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 6,
                            "value": "A) dädu",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 6,
                            "value": "B) babu",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 6,
                            "value": "C) kızçaaz",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 6,
                            "value": "D) çocucak",
                            "isCorrect": 0
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 7,
                    "value": "22",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 7,
                            "value": "A) daaldıınan",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 7,
                            "value": "B) piştiinän",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 7,
                            "value": "C) yandıınan",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 7,
                            "value": "D) kaynadıınan",
                            "isCorrect": 0
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 8,
                    "value": "23",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 8,
                            "value": "A) gideceymiş",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 8,
                            "value": "B) 7 kalaceymış",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 8,
                            "value": "C) büüyeceymiş",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 8,
                            "value": "D) olaceymış",
                            "isCorrect": 1
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 9,
                    "value": "24",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 9,
                            "value": "A) şaşıracek",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 9,
                            "value": "B) olacek",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 9,
                            "value": "C) kaybelecek",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 9,
                            "value": "D) gidecek",
                            "isCorrect": 0
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 10,
                    "value": "25",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 10,
                            "value": "A) üüsürer",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 10,
                            "value": "B) düşer",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 10,
                            "value": "C) uyêêr",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 10,
                            "value": "D) ansırêr",
                            "isCorrect": 1
                        },
                    ],
                    "userAnswer": []
                },
            ],
            "texts": [
                {
                    "examTextId": 1,
                    "value": "Eni yıl eskiicä"
                },
                {
                    "examTextId": 2,
                    "value": "Gagauzlar Eni yılı kutlêêrlar Büük ayın ___________(16). Eni yıl yortusu gagauzların en  sevilän  yortusundan biri.  İlerdän  bu yortu üç gün ____________(17). Şindi iki gün bakılêr.  Eni yıla karşı____________ (18) döşemä  (plaçinta).  Döşemeyi  yazarkana ___________(19) koyarmışlar  para, çıbıcak,  saman  parçası, tüü. “İleri  döşemenin  işinä  para, çıbık hem saman parçası  koyardık». Parayı ______________(20). İçerdä  varsaydı ____________ (21),  neetleerlär  kısmeti  çıksın”. Döşemä _______________ (22)  onu hepsi evcimannara  payederlär. Sayılêr, ani  kimä ne düşärmiş o da bu yılda o işi becereciimiş.  Kimä  para düşärmiş – o ev saabi _____________(23). Çıbıı bulanın – hayvandan kısmeti______________ (24). “Eni  yıla  karşı  kızlar  ayak kabını ev üstünä  atêrlar – neyanı  dooru  ayak kabın  burnusu  düşecek oyanı evlenecek”. Eni  yıl  gecesindä  kim  ilkin ____________ (25), ona bir  kuzu  adêêrlar."
                },
            ]
        },
        {
            "examTaskId": 5,
            "value": "Tekstin boş brakılan erlerinä uygun lafı variantların arasından seçin da erleştirin.",
            "audioValue": "tet",
            "questions": [
                {
                    "examQuestionId": 1,
                    "value": "Okuduunuz tekstä en uygun başlık aşaadakı variantlardan seçin?",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 1,
                            "value": "A) Yaşlı dilenci",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 1,
                            "value": "B) Kız hem çocuk",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 1,
                            "value": "C) Çirkin ölüm",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 1,
                            "value": "D) Unudulmaz sevda",
                            "isCorrect": 1
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 2,
                    "value": "Teksttä razgelän saaz lafın maanası nedir?",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 2,
                            "value": "A) Papur",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 2,
                            "value": "B) Muzika imstrumenti",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 2,
                            "value": "C) Baaşış",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 2,
                            "value": "D) Sopa",
                            "isCorrect": 0
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 3,
                    "value": "Aşaadan angı taabet ilişmeer Tahirä?",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 3,
                            "value": "A) Korkak",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 3,
                            "value": "B) Çalgıcı",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 3,
                            "value": "C) Sevän",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 3,
                            "value": "D) Girgin",
                            "isCorrect": 0
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 4,
                    "value": "Teksttä aşaada verilän bilgilerin angısı vardır?",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 4,
                            "value": "A) kısmetli sevda",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 4,
                            "value": "B) döner geri kapana",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 4,
                            "value": "C) büütmuşlär sarayda",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 4,
                            "value": "D) padişahı öldürdülär",
                            "isCorrect": 0
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 5,
                    "value": "Aşaadan angı çizili sözlerin birisi açmêêr «kendi canını kıyêr» sözün maanasını?",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 5,
                            "value": "A) pişman olêr yaptıına",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 5,
                            "value": "B) kendini öldürer",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 5,
                            "value": "C) kendini yokeder",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 5,
                            "value": "D) kendini keser",
                            "isCorrect": 0
                        },
                    ],
                    "userAnswer": []
                },
            ],
            "texts": [
                {
                    "examTextId": 1,
                    "value": "........................"
                },
                {
                    "examTextId": 2,
                    "value": "Eski zamannarda bir padişah varmış. Onun varmış buuk  zenginnii, ama yokmuş uşaa. Vizirin dä uşaa yokmuş. Padişah vizirinnän karar alêr yola çıkmaa, kendi dertlerinä çıkış bulmaa. Bir aacın altında yaşlı dilenci oturarmış. Yolcular ona acılarını annadêrlar. Dilenci çıkarmış koynusundan bir alma da yaarmış onu ikiyä. Birisini padişaha, öburunu vizirä vermiş. Verdii zaman sölemiş, ani birisinin kızı, öburun dä oolu olacek. Büüduktän sora onnarın evlenmesini nasaat etmiş. Padişah vizirlän evlerinä dönmuş. Dokuz aydan sora padişahın bir kızı, vizirin dä bir çocuu olêr. Onnarın adlarını koymuşlar Tahir hem Zuhra. İkisini dä büütmuşlär sarayda birliktä. Gunnär geçmiş, uşaklar büümuşlär da biri-birini sevmişlär. Zuhrаnin mamusu istemiş evermää kızını diil Tahirä, ama bir padişahın ooluna. Bobası da savaşarmış bozmaa  gençlerin arasında sevgiyi. Tahiri zındana koymuş. Genç oolan durmuş kapanda edi yıl. Bu vakıt Zuhrayı evererlär bir padişaha, duunä hazırlanêrlar. Tahir, işittiynän bunu, kaçȇr kapandan, geler Zuhranın evinä saazlan. Burada herkezi türkü söleer, ama onu kimsey bu uurda enseyämeer. Zuhra, Tahiri görup, pek seviner. Karar alêr kaçmaa. Hep o gundä Tahir yolda iki beygirlän beklärdi. Ama kölä Karadikän gördu onu da verdi haber padişaha. Tahiri yakaladılar da öldurdulär. Zuhra Tahirä yaklaşêr da kahırdan öler. Çirak Karadikän da kendi canını kıyêr.Gençleri gömerlär yannaşık mezarlara. Zuhrаnın mezarında büümuş pembä, Tahirin mezarında kırmızı gül. Karadikänın sa mezarında çıkmış çalı."
                },
            ]
        },
    ],
    "listening": [
        /* {
            "examTaskId": 2,
            "value": "ttt",
            "audioValue": "ttt",
            "questions": [
                {
                    "examQuestionId": "1ycKuFe1o3D",
                    "value": "Кто завел мотор",
                    "status": null,
                    "questionType": "CHECKBOX",
                    "variants": [
                        {
                            "examQuestionAnswerId": "1zQ50Ao3mC0",
                            "examQuestionId": 1,
                            "value": "1",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": "2jKTiJxndUO",
                            "examQuestionId": 1,
                            "value": "23",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": "3CamzCv9Iey",
                            "examQuestionId": 1,
                            "value": "5",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": "4uuc4cmKyMA",
                            "examQuestionId": 1,
                            "value": "4",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": "53bIYcWW6F3",
                            "examQuestionId": 1,
                            "value": "6",
                            "isCorrect": 1
                        }
                    ],
                    "userAnswer": []
                }
            ],
            "texts": []
        } */
    ],
    "examTime": 90
}
export let examHardCode2 = {
    "examId": 2,
    "examName": "Номер дой",
    "reading": [
        {
            "examTaskId": 1,
            "value": "Aşaada 5 başka-başka cümlä verili. Bu cümlelerä görä 3 variantın arasından dooru düşünmeyi belli ediniz.",
            "audioValue": "tet",
            "questions": [
                {
                    "examQuestionId": 1,
                    "value": "Çok devletlerdä kafä misafirciliin simvolu oldu, ama peydalanan Türkiyäda taa 16-ncı asirdӓ Suleyman sultanı vakıdında, türk kafesi dünnedӓ en anılmış kafä olduu deyni, 2013 yılda UNESCO listesinä girdi.",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 1,
                            "value": "A) Kafä misafirciliin simvolu oldu da bu iş için UNESCO listesinä girdi.",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 1,
                            "value": "B) Türk kafäsi sultan Süleyman vakıdında ortaya çıktı da bu beterä UNESCO listesinä girdi.",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 1,
                            "value": "C) Türk kafesi dünnedӓ en anılmış kafä olduu deyni UNESCO listesinä girdi.",
                            "isCorrect": 1
                        }
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 2,
                    "value": "Vladimir İvanoviç Dal sevӓrdi gezmӓӓ Rusiya topraklarında da pek islӓӓ bilӓrdi rus halkının yaşamasını, bunu geniştӓn hem havezlӓn yazdırardı kendi yaratmalarında, ama Dalin en önemni başarısı  « Yaşayan Büük Rus Dilinin Açıklayıcı Sözlüü », angısını o kurdu 1861-inci yılda.",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 2,
                            "value": "A) Dalin en önemni başarısı - « Yaşayan Büük Rus Dilinin Açıklayıcı Sözlüü »,",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 2,
                            "value": "B) Vladimir İvanoviç Dal sevӓrdi gezmӓӓ Rusiya topraklarında da pek islӓӓ bilӓrdi rus halkının yaşamasını.",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 2,
                            "value": "C) 1861-inci yılda «Yaşayan Büük Rus Dilinin Açıklayıcı Sözlüünü » kurdu.",
                            "isCorrect": 0
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 3,
                    "value": "Moldovada var birkaç keramika usta odası, neredӓ var nicӓ üürenmӓӓ yapmaa sadӓ predmetleri çamurdan, da çok kişi geler da bu işleri yapa bilerlär, çünkü burada diil lӓӓzım maasuz ustalık hem becermӓk.",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 3,
                            "value": "A) Moldovada var nicӓ kolay üürenmӓӓ yapmaa özel predmetleri çamurdan.",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 3,
                            "value": "B) Çok kişi keramika usta odalarında yapa bilerlär sadä predmetleri.",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 3,
                            "value": "C) Moldovada var çok keramika usta odası.",
                            "isCorrect": 0
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 4,
                    "value": "Uçarkan “V” formasında, uçan kuşun kanat çırpması gerdän uçan kuş için onu kaldıran bir hava akımını kurȇr, da  uçan turnalar kendi uçmak hızlılıını etmiş proţentä kaldırêrlar.",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 4,
                            "value": "A) Turna sürüsleri uçêr “V” formasında.",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 4,
                            "value": "B) Uçan kuşun kanat çırpması hava akımını kurȇr.",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 4,
                            "value": "C) Uçan turnaların uçmak hızlılıına etkileer sürünün“V” forması.",
                            "isCorrect": 1
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 5,
                    "value": "İki sıra yılda  - küçük ayın hem sürmäk ayın arasında Braziliyada, Amazonkanın aazında gelän Аtlantik okeanın tuzlu, taa aar  suyu karşılaşêr derä akıntısınnan, geri itirer onu, kaynayarak çıkêr deredän yukarı dooru, da bitki- bitkiya peydalanêr karşı dalgalar 6 metra üüseklii.",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 5,
                            "value": "A) Amazonkada iki sıra.yılda okean suyu karşılaşêr derä akıntısınnan.",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 5,
                            "value": "B) Amazonkada peydalanêr pek üüsek karşı dalgalar.",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 5,
                            "value": "C)  Amazonka bulunêr Braziliyada.",
                            "isCorrect": 0
                        },
                    ],
                    "userAnswer": []
                },
            ],
            "texts": []
        },
        {
            "examTaskId": 2,
            "value": "«Benim için beygir – bir kişiliktir».",
            "audioValue": "tet",
            "questions": [
                {
                    "examQuestionId": 1,
                    "value": "Beygirlerä sevgi bobasından hem dädularından kaldı.",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 1,
                            "value": "Dooru",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 1,
                            "value": "Yannış",
                            "isCorrect": 0
                        },

                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 2,
                    "value": "Sport yapmaa hem  çalışmaa vakıdi yoktu.",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 2,
                            "value": "Dooru",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 2,
                            "value": "Yannış",
                            "isCorrect": 1
                        }
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 3,
                    "value": "Salt Karamanov Tehnik okulunu başardı.",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 3,
                            "value": "Dooru",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 3,
                            "value": "Yannış",
                            "isCorrect": 1
                        }
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 4,
                    "value": "Moldovada bir bölä beygir ferması var.",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 4,
                            "value": "Dooru",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 4,
                            "value": "Yannış",
                            "isCorrect": 0
                        }
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 5,
                    "value": "Beygirleri Konstantin Gagauzların kultura mirasını olarak sayêr.",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 5,
                            "value": "Dooru",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 5,
                            "value": "Yannış",
                            "isCorrect": 0
                        }
                    ],
                    "userAnswer": []
                },
            ],
            "texts": [
                {
                    "examTextId": 1,
                    "value": "Çadırlı Konstantin Vasilyeviç Keleş, 1960-ci yılın duuması – kırk yıl oldu sevdii işini yapêr, beygir büüder. Çadırda «At -Prolin» beygir fermasını açtı. Onun bobası da däduları da hayvancılıı sevärdilär. O da küçüktän beygirleri sevdi. Sekizinci klastan sora Karamanov Tehnik okulunu başardı, zootehnik oldu. Tehnik okulunda sportlan kendini kaavileştirdi, üürenmesini üüsek uurda götürdü. Hem üürenärdi, hem çalışardı. Zaoçno Odesa institutunu bitirdi da 1992 -ci yılda zootehnik – selekţioner diplomunu kabletti. Orlov rısaklarını başladı büütmää, zerä o beygir soyunu pek beendi. Orlov rısakları pek hızlı gezerlär, güüdeleri gözäl, incä, graţioz  basêrlar . Beygir ferması «At- Prolin» bir Moldovada. Bir beygirin adını koymaa deyini, düşünüptä, sevgiylän seçer, sansın bir insan adını. Şaşêrlar, ani sürüdä herbir beygiri suratça tanıyêr. «Benim için beygir – bir kişiliktir, diil salt damga numarası», - söleer Konstantin. Beygirleri sever hem bakêr, sansın kendi uşaklarını. Beygir fermasında razgeler işleer hayvan doktoru da, zootehnik – selekţioner da, çilingir da, atlı gezmäk treneri da. Gagauz kulturasında beygir pek önemni bir er tutêr. Eveldän ayledä varsa beygir, o aylä üüsek statuslu sayılardı. Konstantin beygirleri Gagauzların kultura mirasını olarak sayêr. O becerer beygirlärlän lafetmää hem terbietmää onnarı. Bu beterä hepsi beygirlär yavaş hem bakımnı. «At -Prolin» fermasında var etmişä yakın beygir. Beygir ferması turistlerin en sevdii eri oldu."
                },

            ]
        },
        {
            "examTaskId": 3,
            "value": "Aşaada şaşırtıcı tabiat olaylarının gerçekleştii 5 devlet  verili. 5 turist lääzım ayırsın bir devlet, neredä isteer görmää meraklı natura oluşunu. Turistlerin yazdırmasına görä ayırın da seçin, angı devlet için söz gider. ",
            "audioValue": "tet",
            "questions": [
                {
                    "examQuestionId": 1,
                    "value": "Sanda: Gerçektän pek siirek hem şaştırıcı  bir natura  olayı  - «Kara Güneşi» görmää isteerim! Ama mart yada Çiçek ayında  o devletä gitmäm lääzım.",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 1,
                            "value": "A. Daniya",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 1,
                            "value": "C. Gonduras",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 1,
                            "value": "B. Venesuela",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 1,
                            "value": "E. Marokko",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 5,
                            "examQuestionId": 1,
                            "value": "D. Braziliya",
                            "isCorrect": 0
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 2,
                    "value": "Vladi: Hayvan yaamuru – siirek bir meteorologiya fenomenı, makarki bu oluşlar çok devletlerdä insannık istoriya boyunca var razgeldii. Yaamur durgunduktan sora erdä üzlärlän diri balık kalêr. Bu devlettä bölä şaşılacak yaamur herzaman yaayêêr. Görmää isteerim!",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 2,
                            "value": "A. Daniya",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 2,
                            "value": "C. Gonduras",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 2,
                            "value": "B. Venesuela",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 2,
                            "value": "E. Marokko",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 5,
                            "examQuestionId": 2,
                            "value": "D. Braziliya",
                            "isCorrect": 0
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 3,
                    "value": "Tanas: Bän isteerim görmää, nicä Katatumbo  deresi  hem  Marakaybo gölü buluştuu erindä 160 gün  yılda  çimçiriklӓr  çakêr. Çimçirik  çakması, naturanın meteorologiya  durumu olarak, on saadın içindä bir erdä çakêr. Bu çimçiriklär 5 kilometra üüsektä şafk ederlär. Gemicilӓr çimçirikleri kullanêrlar mayak gibi. Pek meraklanêrım!",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 3,
                            "value": "A. Daniya",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 3,
                            "value": "C. Gonduras",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 3,
                            "value": "B. Venesuela",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 3,
                            "value": "E. Marokko",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 5,
                            "examQuestionId": 3,
                            "value": "D. Braziliya",
                            "isCorrect": 0
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 4,
                    "value": "Dari: Bu devlet  - dünnenin bir tek devleti, neredä ot kıtlıın beterinä, keçilär fidannara tırmaşêrlar da orada bütün sürülärlän otlêêrlar. O aacın cevizlerini iyerlär. «Keçi pindi dallara…» türkümüz da aklıma geler. Orayı isteerim gitmää.",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 4,
                            "value": "A. Daniya",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 4,
                            "value": "C. Gonduras",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 4,
                            "value": "B. Venesuela",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 4,
                            "value": "E. Marokko",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 5,
                            "examQuestionId": 4,
                            "value": "D. Braziliya",
                            "isCorrect": 0
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 5,
                    "value": "Todi: Dalgaları 6 metra üüseklii isteerim görmää. Bu fenomen yarım saat sürteer,  buna ‘pororoka’ deyerlär. Köpüreräk, su duvarı büük gürültüülän 25 kilometra saatta derä akıntısına karşı gider, 800 kilometra derä aazından  yukarı kalkaarak. Derä taşêêr, kenarlarını aşındırêr, gürültü uzaktan işidiler. Mayıl bişey!",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 5,
                            "value": "A. Daniya",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 5,
                            "value": "C. Gonduras",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 5,
                            "value": "B. Venesuela",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 5,
                            "value": "E. Marokko",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 5,
                            "examQuestionId": 5,
                            "value": "D. Braziliya",
                            "isCorrect": 0
                        },
                    ],
                    "userAnswer": []
                },
            ],
            "texts": [
                {
                    "examTextId": 1,
                    "value": "Venesuelada Güney Amerikanın en büyük gölü bulunêr – Marakaybo. Katatumbo deresi bu gölä akêr. Derenin gölä aktıı erindä siirek hem meraklı bir natura olayı  geçer. Bu olay 160 gün yılda sürer. Bu temaya görӓ yapıldı türlü açıklamaklar, da bilim insannar geldilӓr bir karara: Andı daalarından gelӓn hava, birleşerӓk sıcak havaylan gölün üstündä, oluşturêr elektrik alanını."
                },
                {
                    "examTextId": 2,
                    "value": "Gondurasa deyni bu fenomen herzamankı.  Her yıl hederlez hem kirez ayın arasında göktä bir kara bulut peydalanêr, çimçiriklär çakêr, gürüldeer, kuvetli bir lüzgär eser hem 2-3 saat büük yaamur döker."
                },
                {
                    "examTextId": 3,
                    "value": "Marokko, Afrikanın en hızlı büyüyän devletlerdän  biridir. Marokko, Afrikanın en zengin devletlerdän biridir. Onikinci erdä bulunêr.  Marokko  - dünnenin bir tek devleti, neredä büüyer‘Arganiya’ aacı. Bu aacın cevizlerindän kokulu oloy yapêrlar."
                },
                {
                    "examTextId": 4,
                    "value": "İki sıra yılda  - küçük ayın hem sürmäk ayın arasında Braziliyada, Amazonkanın aazında gelän Аtlantik okeanın tuzlu, taa aar  suyu karşılaşêr derä akıntısınnan, geri itirer onu, kaynayarak çıkêr deredän yukarı dooru, da bitki- bitkiya peydalanêr karşı dalgalar."
                },
                {
                    "examTextId": 5,
                    "value": "İlkyazda Daniyada inanılmaz bir fenomen olêr: bir miliondan zeedä Evropa sıırcıkları, gün kauşmasına bir saat kalaarak, büük sürülerä uçuşup,  bireri toplanêrlar. Siiretmää bunu var nicä ilkyazın çeketmesindä Daniyanın batı tarafında baltalara yakın, Mart ayından Çiçek ayın ortasına kadar."
                },
            ]
        },
        {
            "examTaskId": 4,
            "value": "Hristofor Kolumb",
            "audioValue": "tet",
            "questions": [
                {
                    "examQuestionId": 1,
                    "value": "Angı informaţiya tekstä görä dooru?",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 1,
                            "value": "A) İstoriyacılar hem bilim adamnarı  bilerlär, neredän çıktı Kolumb aylä soyu.",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 1,
                            "value": "B) İlk teklifini üzmӓӓ deyni kabletmedilӓr.",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 1,
                            "value": "C) Üüretimi Hristofor kabletti universitettä.",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 1,
                            "value": "D) Anılmış denizçi Hristofor Kolumb neredӓ duudu biliner.",
                            "isCorrect": 0
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 2,
                    "value": "Teksttä angı aşadaakı soruşların cuvabı yoktur?",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 2,
                            "value": "A) Hristofor Kolumbun kaç uşaa vardı?",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 2,
                            "value": "B) İlk ekspediţiyasına Hristofor Kolumb kaç gemi hazırladı?",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 2,
                            "value": "C) Neredä Hristofor üüretimi kabletti?",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 2,
                            "value": "D) Hristofor Kolumb ilk êkspediţiyasına kaç kişi aldı?",
                            "isCorrect": 0
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 3,
                    "value": "Hristofor Kolombun en önemni başarısı nesoy?",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 3,
                            "value": "A) Evropalı insan ilk kerä bastı Bagam adalarına.",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 3,
                            "value": "B) İlk ekspediţiyasına Hristofor Santa Mariya gemiyi aldı.",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 3,
                            "value": "C) Bir kerä Kolumb etişti nesoy sa adaya da erleştirdi orada Кastilya bayraanı.",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 3,
                            "value": "D) Hristofor Kolumb bu geziylän evropalı insana Eni Dünneyi açtı.",
                            "isCorrect": 1
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 4,
                    "value": "Tekstä görä adını Hristofor Kolumbadan angı ada kabletti?",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 4,
                            "value": "A) Kuba",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 4,
                            "value": "B) Gaiti",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 4,
                            "value": "C) Bagam.",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 4,
                            "value": "D) San-Salvador",
                            "isCorrect": 1
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 5,
                    "value": "Teksttä razgelän «başladı İspaniya ekspansiyası» cümlenin maanası angısıydır?",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 5,
                            "value": "A) İspaniya birleşmesi başladı.",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 5,
                            "value": "B) İspaniya daalması başladı.",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 5,
                            "value": "C) İspaniya ilerlemesi başladı.",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 5,
                            "value": "D) İspaniya genişlemesi başladı.",
                            "isCorrect": 0
                        },
                    ],
                    "userAnswer": []
                }
            ],
            "texts": [
                {
                    "examTextId": 1,
                    "value": "İstoriyacılar hem bilim adamnarı büün da taa bilmeerlär, neredän çıktı Kolumb aylä soyu. Anılmış denizçi Hristofor Kolumb neredӓ duudu da bilinmeer. Ama onun gezileri hem yaptıkları için çok informaţiya korundu. Üüretimi Hristofor kabletti aylesindӓ. O üürendi matematikayı, dilleri hem başka da o zamannarda olan önemni bilimneri. İlk teklifini üzmӓӓ deyni İndiyaya batı yolundan Hristofor Kolumb yaptı kendi hükümetinä hem Genuya kasabasının alış-verişçilerinӓ. Onun teklifini kabletmedilӓr. O yolladı taa birkaç teklif zengin insannara. Bitki-bitkiyӓ onun teklifini kablettilӓr. İlk ekspediţiyasına Hristofor Kolumb hazırladı üç gemi: Santa Mariya, Pinta hem Ninya. Kendi grupasına o aldı 100 kişi. Evropalı insan ilk kerä bastı Karib denizin adalarına: Bagam adaları, Gaiti hem Kuba. Bu geziylӓn Eni Dünneyӓ başladı İspaniya ekspansiyası. Bir kerä Kolumb etişti nesoy sa adaya da erleştirdi orada Кastilya bayraanı. Bu adayı o adladı San-Salvador. Burada yaşayan senselelӓr başladılar Kolumba «kuru yapraklrı» – tütün yapraa. Bu gezidӓn Hristofor Kolumb getirdi İspaniyaya taa ileri Evropada görülmedik büüyümneri, biyaz altını, meyvaları hem kuş tüülerini. Ama Hristofor Kolumb hep istӓrdi onun açtıı topraklardan bulmaa eni yolu Üülen Aziyaya. O bilӓrdi, ani bölӓ yol var. Kral bitki-bitkiyӓ kayıl oldu da verdi izin Kolumbun dördüncü ekspediţiyasına. 1506-ncı yılda Hristofor Kolumb pek hastalandı da 55 yaşında geçindi. Onun son laflarıydı: “Senin ellerinӓ, Allahım, benim ruhumu vererim”."
                }
            ]
        },
        {
            "examTaskId": 5,
            "value": "Eski günnerdä komşuluk. Aşaadakı teksttä boş brakılan erlerä 4 laftan bir uygun variantı seçin da erleştirin.",
            "audioValue": "tet",
            "questions": [
                {
                    "examQuestionId": 1,
                    "value": "21",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 1,
                            "value": "A) barabar",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 1,
                            "value": "B) birliktä",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 1,
                            "value": "C) kavgada",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 1,
                            "value": "D) yalnızlıkta",
                            "isCorrect": 0
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 2,
                    "value": "22",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 2,
                            "value": "A) derdi",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 2,
                            "value": "B) varlıı",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 2,
                            "value": "C) hayvanı",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 2,
                            "value": "D) kuvedi",
                            "isCorrect": 0
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 3,
                    "value": "23",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 3,
                            "value": "A) yakındı",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 3,
                            "value": "B) uzaktı",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 3,
                            "value": "C) yapışıktı",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 3,
                            "value": "D) başkaydı",
                            "isCorrect": 0
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 4,
                    "value": "24",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 4,
                            "value": "A) taştan",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 4,
                            "value": "B) samandan",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 4,
                            "value": "C) taftadan",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 4,
                            "value": "D) topalaktan",
                            "isCorrect": 1
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 5,
                    "value": "25",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 5,
                            "value": "A) gaz plitası",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 5,
                            "value": "B) êlektrik plitası",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 5,
                            "value": "C) gaz primusları",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 5,
                            "value": "D) gaz balonu",
                            "isCorrect": 0
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 6,
                    "value": "26",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 6,
                            "value": "A) bereket",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 6,
                            "value": "B) altın",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 6,
                            "value": "C) gümüş",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 6,
                            "value": "D) kahır",
                            "isCorrect": 0
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 7,
                    "value": "27",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 7,
                            "value": "A) suuktu",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 7,
                            "value": "B)   sıcaktı",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 7,
                            "value": "C) faydasızdı",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 7,
                            "value": "D) boştu",
                            "isCorrect": 0
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 8,
                    "value": "28",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 8,
                            "value": "A) aul",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 8,
                            "value": "B) duvar",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 8,
                            "value": "C) örtü",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 8,
                            "value": "D) tokat",
                            "isCorrect": 0
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 9,
                    "value": "29",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 9,
                            "value": "A) kardaşlık",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 9,
                            "value": "B) kavga",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 9,
                            "value": "C) duygu ",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 9,
                            "value": "D) saygı",
                            "isCorrect": 1
                        },
                    ],
                    "userAnswer": []
                },
                {
                    "examQuestionId": 10,
                    "value": "30",
                    "status": null,
                    "questionType": "RADIO",
                    "variants": [
                        {
                            "examQuestionAnswerId": 1,
                            "examQuestionId": 10,
                            "value": "A) atardılar",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 2,
                            "examQuestionId": 10,
                            "value": "B)  çaaradılar",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": 3,
                            "examQuestionId": 10,
                            "value": "C) koyardılar",
                            "isCorrect": 1
                        },
                        {
                            "examQuestionAnswerId": 4,
                            "examQuestionId": 10,
                            "value": "D) sayardılar",
                            "isCorrect": 0
                        },
                    ],
                    "userAnswer": []
                },
            ],
            "texts": [
                {
                    "examTextId": 1,
                    "value": "Eskidӓn maalelerdӓ yaşardılar______________(21). Maalelerdӓ herkez biri-birini tanıyardı, bilӓrdi kimin ne__________(22), ne sıkıntısı var. Evlӓr biri-birinӓ pek_______________(23). Eski komşuluk ilişkilerini bilennӓr bölӓ annadêrlar: “ Evlerimiz ___________ (24),  kerpiçtän yapılardı. Büünkü imää yapmaa tertiplerin erinӓ __________________  (25)  kullanardılar, oturmaa deyni dä yastıkları. İyecek, içecek hem ruba vardı az. Musaafirӓ çok önem verilӓrdi. İnsanda vardı inan “Musaafir geldii evӓ ___________ (26) gelir”. Bütün bu durumnara görӓ eskidӓn insan ilişkileri taa _____________ (27).Türlü evlär vardı: bir katlı, kimileri üç içerli, kimileri iki içerli, uzun hayatlan. Uzun zaman bu yapılarda iki komşuya yapılardı bir _______ (28). Evlerin üstleri saazlan örtülüydü. Komşular brakardı biri-birinӓ mallarını, evlerini, uşaklarını. Komşular arasında __________ (29) vardı. Onnar biri-birinin kusurlarını ayıp görmäzdilär. Ortada bir yannışlık varsa, maalä büükleri insannarı dooru yola _______________ (30)."
                },
            ]
        },
    ],
    "listening": [

    ],
    "examTime": 90
}





/* export let examHardCode3 = {
    "examId": 3,
    "examName": "НОмер уну",
    "reading": [
        {
            "examTaskId": 1,
            "value": "tst",
            "audioValue": "tet",
            "questions": [
                {
                    "examQuestionId": "1RfQwRUv1re",
                    "value": "Кто завел мотор",
                    "status": null,
                    "questionType": "CHECKBOX",
                    "variants": [
                        {
                            "examQuestionAnswerId": "1XKdioEqVEr",
                            "examQuestionId": 1,
                            "value": "1",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": "2SiJq50I3BG",
                            "examQuestionId": 1,
                            "value": "23",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": "3c4D4dfHrbu",
                            "examQuestionId": 1,
                            "value": "5",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": "4znXz8mHjcd",
                            "examQuestionId": 1,
                            "value": "4",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": "5H1TOjZ2b0Y",
                            "examQuestionId": 1,
                            "value": "6",
                            "isCorrect": 1
                        }
                    ],
                    "userAnswer": []
                }
            ],
            "texts": [
                {
                    "examTextId": 1,
                    "value": "Вышел я на белый конь мэй"
                },
                {
                    "examTextId": 2,
                    "value": "Не заводится мотор мэй"
                }
            ]
        }
    ],
    "listening": [
        {
            "examTaskId": 2,
            "value": "ttt",
            "audioValue": "ttt",
            "questions": [
                {
                    "examQuestionId": "1ycKuFe1o3D",
                    "value": "Кто завел мотор",
                    "status": null,
                    "questionType": "CHECKBOX",
                    "variants": [
                        {
                            "examQuestionAnswerId": "1zQ50Ao3mC0",
                            "examQuestionId": 1,
                            "value": "1",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": "2jKTiJxndUO",
                            "examQuestionId": 1,
                            "value": "23",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": "3CamzCv9Iey",
                            "examQuestionId": 1,
                            "value": "5",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": "4uuc4cmKyMA",
                            "examQuestionId": 1,
                            "value": "4",
                            "isCorrect": 0
                        },
                        {
                            "examQuestionAnswerId": "53bIYcWW6F3",
                            "examQuestionId": 1,
                            "value": "6",
                            "isCorrect": 1
                        }
                    ],
                    "userAnswer": []
                }
            ],
            "texts": []
        }
    ],
    "examTime": 90
} */
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
    const { pathname } = useLocation();

    let arr = pathname.split('/')

    useEffect(() => {
        if (!arr.includes('questions')) {
            window.scrollTo(0, 0);
        }
    }, [pathname]);

    return null;
}
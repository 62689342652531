import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { getForumQuestions, SET_QSORT } from '../../../../store/forum/forumQuestionsSlice'
import { getForumThemes, SET_SORT } from '../../../../store/forum/forumThemesSlice'
import "./style.scss"

export let ForumListHeader = ({ params }) => {

    let [selected, setSelected] = useState(1)

    let dispatch = useDispatch()
    let location = useLocation()

    let Lang = useSelector(state => state.global.lang)

    const onTabAllThemes = () => {
        let obj = {
            sortField: "createdTime",
            sortDir: "DESC"
        }
        dispatch(SET_SORT(obj))
        dispatch(getForumThemes(Lang))
    }
    const onTabAllQuestions = () => {
        let obj = {
            sortField: "createdTime",
            sortDir: "DESC"
        }
        dispatch(SET_QSORT(obj))
        dispatch(getForumQuestions(Lang, params.theme_id.split('_')[1]))
    }
    const onTabPopular = () => {
        let isForum = location.pathname.split('/').length < 3
        if (isForum) {
            let obj = {
                sortField: "forumQuestionCount",
                sortDir: "DESC"
            }
            dispatch(SET_SORT(obj))
            dispatch(getForumThemes(Lang))
        } else {
            let obj = {
                sortField: "forumQuestionsCommentsCount",
                sortDir: "DESC"
            }
            dispatch(SET_QSORT(obj))
            dispatch(getForumQuestions(Lang, params.theme_id.split('_')[1]))
        }
    }

    return (
        <div className="forumListHeader">
            <div className="left">
                {(!params.theme_id) ? 
                    <button className={"btn " + ((selected == 1) ? "selected" : "")} onClick={() => {
                        setSelected(1)
                        onTabAllThemes()
                    }}>Все темы</button>
                :
                    <button className={"btn " + ((selected == 1) ? "selected" : "")} onClick={() => {
                        setSelected(1)
                        onTabAllQuestions()
                    }}>Вопросы</button>
                }
                <button className={"btn " + ((selected == 2) ? "selected" : "")} onClick={() => setSelected(2)}>Избранное</button>
                <button className={"btn " + ((selected == 3) ? "selected" : "")} onClick={() => setSelected(3)}>Мои вопросы</button>
            </div>
            <div className="right">
                {/* <button className={"btn " + ((selected == 4) ? "selected" : "")} onClick={() => setSelected(4)}>Новые</button> */}
                <button className={"btn " + ((selected == 5) ? "selected" : "")} onClick={() => {
                    setSelected(5)
                    onTabPopular()
                }}>Популярые</button>
            </div>
        </div>
    )
}
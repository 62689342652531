import React, { useEffect } from "react";

import CourseInfoImg from "../../../shared/ui/img/Studying/studyingImg.png"

import "./style.scss"
import CardImg from "../../../shared/ui/img/QuickCheckPage/cardImg.svg";
import { useDispatch, useSelector } from "react-redux";
import { getCourseInfo } from "../../../store/studying/StudyingInfoSlice";
import { useParams } from "react-router";

export default function CourseInfo() {

	let dispatch = useDispatch()
	let params = useParams()

	let course = useSelector(state => state.studyingInfo.info)

	useEffect(() => {
		dispatch(getCourseInfo(params.courseId))
	}, [])

	console.log(course)

	return (
		<div className="course-info">
			<div className="course-info__content1">
				<div className="course-info__content1-desc">
					<h1>{course?.details?.[0]?.labelValue}</h1>
					<span>{course?.details?.[0]?.contentValue}</span>
				</div>
				<img src={CourseInfoImg}/>
			</div>
			<div className="quick-check__desc-row">
				{
					course?.details?.map((item, i) => {
						if (i !== 0) {
							return (
								<div className="quick-check__card">
									<div className="quick-check__card__img">
										<img src={CardImg}/>
									</div>
									<div className="quick-check__card__title quick-check__card__title_green">{item.labelValue}</div>
									<div className="quick-check__card__desc">{item.contentValue}</div>
								</div>
							)
						}
					})
				}
			</div>
		</div>
	)
}
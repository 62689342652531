import { createSlice } from '@reduxjs/toolkit'
import { getOneCourseByIdFromBD } from '../../api/studying'
import { getCookie } from '../../shared/functions'

const initialState = {
    materials: [],
    loading: false,
}

export const StudyingMaterialsSlice = createSlice({
    name: 'studyingMaterials',
    initialState,
    reducers: {
        SET_MATERIALS: (state, action) => {
            state.materials = action.payload
            state.loading = false
        },
        SET_LOADING: (state, action) => {
            state.loading = action.payload
        }
    },
})

export const getCourseMaterials = (id) => async (dispatch, getState) => {
    dispatch(SET_LOADING(true))

    let token = getCookie('userToken')
    const Response = await getOneCourseByIdFromBD(id, token)
    let arr = []
    Response.lessons.forEach((lesson) => {
        lesson.materials.forEach((material) => {
            arr.push({ ...material, lessonName:lesson.lessonName })
        })
    })
    let obj = {
        courseName: Response.courseName,
        materials: arr
    }
    dispatch(SET_MATERIALS(obj))
}

export const { SET_MATERIALS, SET_LOADING } = StudyingMaterialsSlice.actions

export default StudyingMaterialsSlice.reducer
import React, { Component } from "react";
import Slider from "react-slick";
import "./style.scss"

export class Slider1 extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }
    render() {
        const settings = {
            className: "center",
            dots: true,
            centerMode: true,
            infinite: true,
            centerPadding: "90px",
            slidesToShow: 1,
            speed: 500,
            arrows: false,
            variableWidth: true
        };
        return (
            <div className={"Slider1 " + this.props.sliderClass}>
                <Slider ref={c => (this.slider = c)} {...settings}>
                    {
                    (this.props.slides.length > 0)
                    ?
                    this.props.slides.map((slide) => {
                        return slide
                    })
                    :
                    <p>нет картинок</p>
                    }
                </Slider>
                <div className="controls">
                    <button className="button" onClick={this.previous}>
                        <svg width="11" height="19" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.5 18L2.41421 10.9142C1.63317 10.1332 1.63317 8.86683 2.41421 8.08579L9.5 1" stroke="white" strokeWidth="2" strokeLinecap="round" />
                        </svg>
                    </button>
                    <button className="button" onClick={this.next}>
                        <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L8.08579 8.08579C8.86683 8.86683 8.86684 10.1332 8.08579 10.9142L1 18" stroke="white" strokeWidth="2" strokeLinecap="round" />
                        </svg>
                    </button>
                </div>
            </div>
        );
    }
}
import { createSlice } from '@reduxjs/toolkit'
import { getForumCategsFromBD, getForumThemesFromBD } from '../../api/forum'
import { getCookie, prepareCategsParams, prepareForumThemesParams } from '../../shared/functions'
/* import { gettestFromBD } from '../../api/test' */

/* {
    sortField: "forumQuestionCount",
    sortDir: "DESC"
} */

const initialState = {
    themes: {},
    categories: {},
    /* tabsFilter: null, */
    sort: {
        sortField: "createdTime",
        sortDir: "DESC"
    },
    categsFilter: null,
    search: null,
    pagination: {
        totalPages: 1,
        pageNumber: 1,
    },
    loading: false,
}

export const forumThemesSlice = createSlice({
    name: 'forumThemes',
    initialState,
    reducers: {
        SET_FORUMLIST: (state, action) => {
            state.themes = action.payload
            state.pagination = {
                totalPages: action.payload.totalPages,
                pageNumber: action.payload.pageable.pageNumber + 1,
            }
            state.loading = false
        },
        SET_PAGE: (state, action) => {
            state.pagination.pageNumber = action.payload
        },
        /* SET_TABSFILTER: (state, action) => {
            state.tabsFilter = action.payload
        }, */
        SET_SORT: (state, action) => {
            state.sort = action.payload
        },
        SET_CATEGSFILTER: (state, action) => {
            state.categsFilter = action.payload
        },
        SET_SEARCH: (state, action) => {
            state.search = action.payload
        },
        SET_CATEGORIES: (state, action) => {
            state.categories = action.payload
        },
        SET_LOADING: (state, action) => {
            state.loading = action.payload
        }
    },
})



export const getForumThemes = (lang) => async (dispatch, getState) => {
    dispatch(SET_LOADING(true))
    const { pagination, sort, categsFilter, search } = getState().forumThemes

    let filter = []
    if (search != null) {
        filter.push(search)
    }
    if (categsFilter != null) {
        filter.push(categsFilter)
    }

    const params = prepareForumThemesParams(pagination, filter, sort, lang)
    let token = getCookie('userToken')
    const themesResponse = await getForumThemesFromBD(params, token)
    dispatch(SET_FORUMLIST(themesResponse))
}
export const getCategs = (lang) => async (dispatch, getState) => {
    dispatch(SET_LOADING(true))

    const params = prepareCategsParams(lang)
    let token = getCookie('userToken')
    const categsResponse = await getForumCategsFromBD(params, token)
    dispatch(SET_CATEGORIES(categsResponse))
}

export const { SET_FORUMLIST, SET_LOADING, /* SET_TABSFILTER, */ SET_CATEGORIES, SET_PAGE, SET_SEARCH, SET_CATEGSFILTER, SET_SORT } = forumThemesSlice.actions

export default forumThemesSlice.reducer
import React from 'react';
import { Collapse } from 'antd';
import "./style.scss"
const { Panel } = Collapse;
const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;
const AntdAccordion = ({ className, data }) => {
    const onChange = (key) => {
        // console.log(key);
    };
    return (

            <Collapse 
                /* defaultActiveKey={['1']} */ 
                onChange={onChange}
                expandIconPosition={'end'}
                className={className}
                ghost={true}
                expandIcon={() => {
                    return (
                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <line x1="10" y1="1" x2="10" y2="18" stroke="url(#paint0_linear_522_6220)" strokeWidth="2" strokeLinecap="round"/>
                            <line x1="18" y1="10" x2="1" y2="10" stroke="url(#paint1_linear_522_6220)" strokeWidth="2" strokeLinecap="round"/>
                            <defs>
                                <linearGradient id="paint0_linear_522_6220" x1="9" y1="9.5" x2="8" y2="9.5" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#2ECC71"/>
                                    <stop offset="1" stopColor="#21C5EF"/>
                                </linearGradient>
                                <linearGradient id="paint1_linear_522_6220" x1="9.5" y1="9" x2="9.5" y2="8" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#2ECC71"/>
                                    <stop offset="1" stopColor="#21C5EF"/>
                                </linearGradient>
                            </defs>
                        </svg>
                    )
                }}
            >
                {data.map((item, i) => {
                    return (
                        <Panel className="title" header={item.title} key={i}>
                            <p className="text">{item.text}</p>
                        </Panel>
                    )
                })}
            </Collapse>

    );
};
export default AntdAccordion;
import { createSlice } from '@reduxjs/toolkit'
import { getForumThemesFromBD, saveNewQuestionToDB } from '../../api/forum'
import { getCookie } from '../../shared/functions'


const initialState = {
    themes: {},
    formData: {},
    loading: false,
    isNewQSaved: null,
}

export const forumAskaquestionSlice = createSlice({
    name: 'forumAskaquestion',
    initialState,
    reducers: {
        SET_THEMES: (state, action) => {
            state.themes = action.payload
            state.loading = false
        },
        SET_FORMDATA: (state, action) => {
            let params = action.payload
            let formData = {...state.formData}
            switch (params.field) {
                case 'images':
                    if (params.action == 'add') {
                        if (!formData.images) {
                            formData['images'] = []
                        }
                        formData.images.push({
                            "forumQuestionsImageId": params.value.forumQuestionsImageId,
                            "forumQuestionsId":"",
                            "value":"",
                            "status":"ENABLE"
                        })
                    } else if (params.action == 'update') {
                        formData.images.forEach((img) => {
                            console.log(params)
                            if (img.forumQuestionsImageId == params.value.forumQuestionsImageId) {
                                img.value = params.value.imgHash
                            }
                        })
                    } else if (params.action == 'delete') {
                        console.log(params.value.forumQuestionsImageId)
                        formData.images = formData.images.filter((img) => img.forumQuestionsImageId !== params.value.forumQuestionsImageId)
                    }
    
                    break;
                default:
                    formData[params.field] = params.value

                    break;
            }
            state.formData = formData
        },
        SET_ISNEWQSAVED: (state, action) => {
            state.isNewQSaved = action.payload
        },
        SET_LOADING: (state, action) => {
            state.loading = action.payload
        }
    },
})



export const getForumThemesForQuestion = (lang) => async (dispatch, getState) => {
    dispatch(SET_LOADING(true))

    const params = {
        page: 0,
        size: 100000,
        criteria: [
            {
                key: "languageCode",
                value: lang
            },
        ]
    }
    let token = getCookie('userToken')
    const themesResponse = await getForumThemesFromBD(params, token)
    dispatch(SET_THEMES(themesResponse))
}
export const saveNewQuestion = (lang) => async (dispatch, getState) => {
    const { formData } = getState().forumAskaquestion

    let form = {...formData}
    let images = []
    form.images?.forEach((img) => {
        let arr = Object.entries(img).filter((field) => field[0] !== 'forumQuestionsImageId')
        images.push(Object.fromEntries(arr))
    })
    form.images = images

    let token = getCookie('userToken')
    let response = await saveNewQuestionToDB(form, token)
    if (response) {
        dispatch(SET_ISNEWQSAVED(true))
    } else {
        dispatch(SET_ISNEWQSAVED(false))
    }
}

export const { SET_THEMES, SET_LOADING, SET_FORMDATA, SET_ISNEWQSAVED } = forumAskaquestionSlice.actions

export default forumAskaquestionSlice.reducer
import React, { useEffect, useState } from 'react'
import "./style.scss"

export let PieDiagramm = ({ limit, passedTime }) => {

    let [remaining, setRemaining] = useState()

    useEffect(() => {
        let left = passedTime / limit * 107
        setRemaining(left)
    }, [limit, passedTime])

    return (
        <div className="PieDiagramm">
            <svg viewBox='0 0 32 32'>
                <circle r='17' cx='17' cy='17' strokeDasharray={remaining + " 107"}></circle>
            </svg>
        </div>
    )
}

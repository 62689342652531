import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SET_LANG } from '../../../store/globalSlice'
import "./style.scss"

export default function SelectLang({ place, icon }) {

    let dispatch = useDispatch()
    let dropdownRef = useRef()

    const languages = [
		{label: 'Русский', id: 1, value: 'RU'},
		{label: 'English', id: 2, value: 'EN'},
		{label: 'Romana', id: 3, value: 'MD'},
		{label: 'Gagauzian', id: 4, value: 'GE'},
	]

    let Lang = useSelector(state => state.global.lang)

    let [showHeaderLangDrop, setShowHeaderLangDrop] = useState(false)

    document.addEventListener('click', function (event) {
        let target = event.target;
        let is_block = target === dropdownRef.current || dropdownRef.current.contains(target)
        /* let is_menu = target === btn || btn.contains(target) */
        let block_is_active = (dropdownRef.current) ? true : false;
    
        if (block_is_active) {
            if (is_block /* || is_menu */) {
    
            } else {
                setShowHeaderLangDrop(false)
            }
        }
    })

    return (
        <div className="SelectLang" ref={dropdownRef}>
            {
                place == 'header'
                ?
                <>
                    <button 
                        className="Language_button"
                        onClick={() => setShowHeaderLangDrop(!showHeaderLangDrop)}
                    >
                        <img src={icon}/>
                    </button>
                    {
                        showHeaderLangDrop
                        &&
                        <div className="headerLangDrop">
                            {languages.map((lang) => {
                                return (
                                    <button 
                                        key={lang.id}
                                        className={lang.value == Lang ? "selected" : ""}
                                        onClick={() => dispatch(SET_LANG(lang.value))}
                                    >
                                        {lang.label}
                                    </button>
                                )
                            })}
                        </div>
                    }
                </>
                : place == 'footer' ?
                <div className="language">
                    <select name="lang" defaultValue={'ru'} onChange={(e) => dispatch(SET_LANG(e.target.value))}>
                        {languages.map((lang) => {
                            return <option key={lang.id} value={lang.value}>{lang.label}</option>
                        })}
                    </select>
                </div>
                : ""
            }
        </div>
    )
}
import React, { useEffect, useState } from 'react';
import "./style.scss"
import Calendar, { MonthView } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

export let MuiCalendar = ({ CalendarClass, onChangeDate, getCurrMonth, onRangePositionChange, getCurrYear }) => {
  const [value, onChange] = useState(new Date());

  let changeDate = (date) => {
    onChange(date)
    onChangeDate(date)
    getCurrMonth(date.getMonth() + 1)
    getCurrYear(date.getFullYear())
  }

  useEffect(() => {
    getCurrMonth(new Date().getMonth() + 1)
    getCurrYear(new Date().getFullYear())
  }, [])

  return (
    <Calendar 
      className={CalendarClass}
      onChange={changeDate}
      onRangePositionChange={onRangePositionChange}
      defaultActiveStartDate={new Date()}
      value={value}
      onActiveStartDateChange={({ action, activeStartDate, value, view }) => {
        getCurrMonth(activeStartDate.getMonth() + 1)
        getCurrYear(activeStartDate.getFullYear())
      }}
    />
  );
}
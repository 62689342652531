import React from 'react';
import { Collapse } from 'antd';
import "./style.scss"
const { Panel } = Collapse;

const AntdAccordionMenu = ({ className, data, activeKey }) => {
    const onChange = (key) => {
        // console.log(key);
    };
    return (

            <Collapse 
                accordion
                activeKey={[activeKey]} 
                onChange={onChange}
                expandIconPosition={'end'}
                className={className}
                ghost={true}
                showArrow={false}
                expandIcon={() => {
                    return (
                        <div className="arrow">
                            <svg viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 7L6.5 2L1 7" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
                            </svg>
                        </div>
                    )
                }}
            >
                {data.map((item, i) => {
                    return (
                        <Panel className="title" header={item.title} key={i}>
                            {item.content}
                        </Panel>
                    )
                })}
            </Collapse>

    );
};
export default AntdAccordionMenu;
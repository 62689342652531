import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box>
					{children}
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

export default function MuiTabs({ tabs, tabPanels, TabsSX, TabSX, mainBoxSX, getCurrTabIndex, defaultTab }) {
	const [value, setValue] = React.useState((defaultTab !== undefined) ? defaultTab : 0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
		getCurrTabIndex(newValue);
	};

	React.useEffect(() => {
		setValue(defaultTab)
	}, [defaultTab])

	return (
		<Box sx={mainBoxSX}>
			<Box sx={{ borderBottom: 1, borderColor: '#BDBDBD', marginBottom: '50px' }}>
				<Tabs 
					value={value} 
					onChange={handleChange} 
					aria-label="basic tabs example"
					sx={TabsSX}
					variant="scrollable"
					scrollButtons="auto"
				>
					{tabs.map((tab, i) => {
						return (<Tab key={tab.label} label={tab.label} {...a11yProps(i)}
									 sx={TabSX}
						/>)
					})}
				</Tabs>
			</Box>
			{tabPanels.map((tabPanel, i) => {
				return (
					<TabPanel key={i} value={value} index={i}>
						{tabPanel}
					</TabPanel>
				)
			})}
		</Box>
	);
}
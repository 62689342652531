export let testHardCode = {
    "testId": 12,
    "testName": null,
    "testText": "test tet some text of test",
    "reading": [
        {
            "testQuestionId": 9,
            "testQuestionTypeId": 1,
            "testQuestionTypeValue": " Чтение",
            "questionType": "CHECKBOX",
            "value": "Bobası oollarına yolculuk başka yoktu ne versin.",
            "audioValue": "lkshjfbglsjhlfgjhsk",
            "status": null,
            "variants": [
                {
                    "testAnswerId": 15,
                    "value": "Dooru",
                    "isCorrect": 1
                },
                {
                    "testAnswerId": 16,
                    "value": "Yannış",
                    "isCorrect": 0
                }
            ]
        },
        {
            "testQuestionId": 8,
            "testQuestionTypeId": 1,
            "testQuestionTypeValue": " Чтение",
            "questionType": "CHECKBOX",
            "value": "Masalların en büük özelli -fantaziya.",
            "audioValue": "lkshjfbglsjhlfgjhsk",
            "status": null,
            "variants": [
                {
                    "testAnswerId": 11,
                    "value": "Dooru",
                    "isCorrect": 1
                },
                {
                    "testAnswerId": 12,
                    "value": "Yannış",
                    "isCorrect": 0
                }
            ]
        },
        {
            "testQuestionId": 1,
            "testQuestionTypeId": 1,
            "testQuestionTypeValue": " Чтение",
            "questionType": "CHECKBOX",
            "value": "Koyun sürüsünün saabisi verdi dilenci däduya bir parça piinir.",
            "audioValue": null,
            "status": "DISABLE",
            "variants": [
                {
                    "testAnswerId": 23,
                    "value": "Dooru",
                    "isCorrect": 0
                },
                {
                    "testAnswerId": 24,
                    "value": "Yannış",
                    "isCorrect": 1
                }
            ]
        },
        {
            "testQuestionId": 100,
            "testQuestionTypeId": 1,
            "testQuestionTypeValue": " Чтение",
            "questionType": "CHECKBOX",
            "value": "İvançunun agaları pek ii adamnar.",
            "audioValue": "lkshjfbglsjhlfgjhsk",
            "status": null,
            "variants": [
                {
                    "testAnswerId": 25,
                    "value": "Dooru",
                    "isCorrect": 0
                },
                {
                    "testAnswerId": 26,
                    "value": "Yannış",
                    "isCorrect": 1
                }
            ]
        },
        {
            "testQuestionId": 101,
            "testQuestionTypeId": 1,
            "testQuestionTypeValue": " Чтение",
            "questionType": "CHECKBOX",
            "value": "İvançuya gelini vermää deyni, denedilar onun kısmetini.",
            "audioValue": "lkshjfbglsjhlfgjhsk",
            "status": null,
            "variants": [
                {
                    "testAnswerId": 27,
                    "value": "Dooru",
                    "isCorrect": 1
                },
                {
                    "testAnswerId": 28,
                    "value": "Yannış",
                    "isCorrect": 0
                }
            ]
        },
        {
            "testQuestionId": 102,
            "testQuestionTypeId": 1,
            "testQuestionTypeValue": " Чтение",
            "questionType": "CHECKBOX",
            "value": "Bakırda kaynayan horozlar hem pipilär uçtular bakırdan.",
            "audioValue": "lkshjfbglsjhlfgjhsk",
            "status": null,
            "variants": [
                {
                    "testAnswerId": 29,
                    "value": "Dooru",
                    "isCorrect": 1
                },
                {
                    "testAnswerId": 30,
                    "value": "Yannış",
                    "isCorrect": 0
                }
            ]
        },
        {
            "testQuestionId": 103,
            "testQuestionTypeId": 1,
            "testQuestionTypeValue": " Чтение",
            "questionType": "CHECKBOX",
            "value": "Dädunun saa enindän akêr gümüş para.",
            "audioValue": "lkshjfbglsjhlfgjhsk",
            "status": null,
            "variants": [
                {
                    "testAnswerId": 31,
                    "value": "Dooru",
                    "isCorrect": 0
                },
                {
                    "testAnswerId": 32,
                    "value": "Yannış",
                    "isCorrect": 1
                }
            ]
        },
        {
            "testQuestionId": 104,
            "testQuestionTypeId": 1,
            "testQuestionTypeValue": " Чтение",
            "questionType": "CHECKBOX",
            "value": "Dädunun sol enindän akêr gümüş para, saa enindän dä altın para.",
            "audioValue": "lkshjfbglsjhlfgjhsk",
            "status": null,
            "variants": [
                {
                    "testAnswerId": 33,
                    "value": "Dooru",
                    "isCorrect": 1
                },
                {
                    "testAnswerId": 34,
                    "value": "Yannış",
                    "isCorrect": 0
                }
            ]
        }
    ],
    "listening": [
        /* {
            "testQuestionId": 11,
            "testQuestionTypeId": 2,
            "testQuestionTypeValue": "Audio",
            "questionType": "CHECKBOX",
            "value": "Тестовый listening вопрос 2",
            "audioValue": "lkshjfbglsjhlfgjhsk",
            "status": null,
            "variants": [
                {
                    "testAnswerId": 25,
                    "value": "Правильно",
                    "isCorrect": 1
                },
                {
                    "testAnswerId": 26,
                    "value": "Правильно",
                    "isCorrect": 1
                },
                {
                    "testAnswerId": 27,
                    "value": "Не Правильно",
                    "isCorrect": 0
                },
                {
                    "testAnswerId": 28,
                    "value": "Не Правильно",
                    "isCorrect": 0
                }
            ]
        },
        {
            "testQuestionId": 10,
            "testQuestionTypeId": 2,
            "testQuestionTypeValue": "Audio",
            "questionType": "CHECKBOX",
            "value": "Тестовый listening вопрос 123456",
            "audioValue": "lkshjfbglsjhlfgjhsk",
            "status": null,
            "variants": [
                {
                    "testAnswerId": 19,
                    "value": "Правильно",
                    "isCorrect": 1
                },
                {
                    "testAnswerId": 20,
                    "value": "Правильно",
                    "isCorrect": 1
                },
                {
                    "testAnswerId": 21,
                    "value": "Не Правильно",
                    "isCorrect": 0
                },
                {
                    "testAnswerId": 22,
                    "value": "Не Правильно",
                    "isCorrect": 0
                }
            ]
        } */
    ],
    "testTime": 15
}
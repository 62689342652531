import { createSlice } from '@reduxjs/toolkit'
import { getCourseOnlineLessonsFromBD, getCoursesFromBD } from '../../../api/teacherCabinet'
import { getNextTestQuestion, prepareCourseOnlineLessonsParams, prepareCoursesParams } from '../../../shared/functions'


const initialState = {
    courses: [],
    loading: true,
    currCourseId: null,
    currCourse: [],
    CCLoading: true
}

export const teacherOnlineLessonSlice = createSlice({
    name: 'teacherOnlineLesson',
    initialState,
    reducers: {
        SET_COURSES: (state, action) => {
            state.courses = action.payload
            state.loading = false
        },
        SET_CURRCOURSE: (state, action) => {
            state.currCourse = action.payload
            state.CCLoading = false
        },
        SET_CURRCOURSEID: (state, action) => {
            state.currCourseId = action.payload
        },
        SET_LOADING: (state, action) => {
            state.loading = action.payload
        },
        SET_CCLOADING: (state, action) => {
            state.CCLoading = action.payload
        },
    },
})


export const getAllCourses = () => async (dispatch, getState) => {
    dispatch(SET_LOADING(true))
    const { lang } = getState().global
    const { currCourseId } = getState().teacherOnlineLesson
    const params = prepareCoursesParams()
    const testResponse = await getCoursesFromBD(params)
    dispatch(SET_COURSES(testResponse))
    if (currCourseId == null) {
        dispatch(SET_CURRCOURSEID(testResponse.content[0].courseId))
    }

    const currLessonParams = prepareCourseOnlineLessonsParams(lang, testResponse.content[0].courseId)
    const currLessonResponse = await getCourseOnlineLessonsFromBD(currLessonParams)
    dispatch(SET_CURRCOURSE(currLessonResponse))
}
export const getCurrCourse = (lang, id) => async (dispatch, getState) => {
    dispatch(SET_CCLOADING(true))
    const params = prepareCourseOnlineLessonsParams(lang, id)
    const testResponse = await getCourseOnlineLessonsFromBD(params)
    dispatch(SET_CURRCOURSEID(id))
    dispatch(SET_CURRCOURSE(testResponse))
}

export const { SET_COURSES, SET_CURRCOURSE, SET_LOADING, SET_CCLOADING, SET_CURRCOURSEID } = teacherOnlineLessonSlice.actions

export default teacherOnlineLessonSlice.reducer
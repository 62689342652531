import React, {useEffect, useState} from "react";

import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

import {getUserCabinetCoursesByTokenFromBD} from "../../../api/userCabinet";
import {getCookie, prepareUserCabinetCoursesParams} from "../../../shared/functions";

import CoursCard from "./CoursCard/CoursCard";

import "./style.scss"
import { hardCodeCourses } from "../../../shared/hardCodeCourses/hardCodeCourses";

export default function CoursesPage() {

	let navigate = useNavigate()

	let lang = useSelector(state => state.global.lang)

	let [userCourses, setUserCourses] = useState([])

	useEffect(() => {
		setUserCourses(hardCodeCourses)
	}, [])

	console.log(userCourses)

	/* useEffect(() => {
		async function getUserCabinetCourses() {
			let token = getCookie('userToken')
			const params = prepareUserCabinetCoursesParams(lang)
			const response = await getUserCabinetCoursesByTokenFromBD(params, token)
			setUserCourses(response)
		}
		getUserCabinetCourses()
	}, [lang]) */


	return (
		<div className="courses">

				{userCourses ?
					userCourses.map((cours) => {
						return (
							<CoursCard key={cours.courseId}
									   title={cours.labelValue}
									   content={cours.contentValue}
									   category={cours.labelValue.split(' ')[cours.labelValue.split(' ').length - 1]}
									   buttonText={cours.accountCourseProgressStatus === "Not start" ? "Начать Обучение" : cours.accountCourseProgressStatus === "Process" ? "Продолжить обучение" : cours.accountCourseProgressStatus === "Finish" ? "Записаться на экзамен" : ""}
									   onClick={() => navigate('/studying/' + cours.courseId)}
							/>
						)
					})
					:
					''
				}

		</div>
	)
}
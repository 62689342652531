import {createSlice} from "@reduxjs/toolkit";
import {getCommentsFromDB, saveNewCommentToDB} from "../../api/forum";
import {getCookie, prepareCommentsParams} from "../../shared/functions";


const initialState = {
	comments: [],
	loading: true,
	filter: [],
	formData: {}
}

export const commentsSlice = createSlice({
	name: 'comments',
	initialState,
	reducers: {
		SET_COMMENTS: (state, action) => {
			state.comments = action.payload
			state.loading = false
		},
		SET_FORMDATATOEMPTY: (state) => {
			state.formData = {}
		},
		SET_FORMDATA: (state, action) => {
			let params = action.payload
			let formData = {...state.formData}
			switch (params.field) {
				case 'images':
					if (params.action == 'add') {
						if (!formData.images) {
							formData['images'] = []
						}
						formData.images.push({
							"forumQuestionsCommentsImageid": params.value.forumQuestionsCommentsImageid,
							"forumQuestionsCommentsId":"",
							"value":"",
							"status":"ENABLE"
						})
					} else if (params.action == 'update') {
						formData.images.forEach((img) => {
							console.log(params)
							if (img.forumQuestionsCommentsImageid == params.value.forumQuestionsCommentsImageid) {
								img.value = params.value.imgHash
							}
						})
					} else if (params.action == 'delete') {
						console.log(params.value.forumQuestionsImageId)
						formData.images = formData.images.filter((img) => img.forumQuestionsCommentsImageid !== params.value.forumQuestionsCommentsImageid)
					}

					break;
				default:
					formData[params.field] = params.value

					break;
			}
			state.formData = formData
		},
		SET_LOADING: (state, action) => {
			state.loading = action.payload
		},
	}
})

export const getComments = (id) => async (dispatch, getState) => {
	dispatch(SET_LOADING(true))
	const {filter} = getState().comments
	const params = prepareCommentsParams(filter, id)
	let token = getCookie('userToken')
	const commentsResponse = await getCommentsFromDB(params, token)
	dispatch(SET_COMMENTS(commentsResponse))
}

export const saveNewComment = () => async (dispatch, getState) => {
	const { formData } = getState().comments

	let form = {...formData}
	let images = []
	form.images?.forEach((img) => {
		let arr = Object.entries(img).filter((field) => field[0] !== 'forumQuestionsCommentsImageid')
		images.push(Object.fromEntries(arr))
	})
	form.images = images

	let token = getCookie('userToken')

	let response = await saveNewCommentToDB(form, token)
	if (response) {
		dispatch(SET_FORMDATATOEMPTY())
	} else {
		console.log("error")
	}
	//console.log(response)
}

export const { SET_COMMENTS, SET_LOADING, SET_FORMDATA, SET_FORMDATATOEMPTY } = commentsSlice.actions

export default commentsSlice.reducer
// import { gapi } from 'gapi-script'
import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
// import { GoogleLogIn } from '../features/LoginBtns/google/GoogleLogIn/ui/GoogleLogIn'
// import { GoogleLogOut } from '../features/LoginBtns/google/GoogleLogOut/ui/GoogleLogOut'
import Router from '../pages/router'
import ScrollToTop from '../shared/ui/ScrollToTop'

import './styles/index.scss'

// const clientId = '192174907384-df0kgapaogjmpeeep34qkgrlnhbhg3nl.apps.googleusercontent.com'

// Google Client ID: 192174907384-df0kgapaogjmpeeep34qkgrlnhbhg3nl.apps.googleusercontent.com
// Google Client Secret: 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

function App() {

    /* useEffect(() => {
        gapi.load('client:auth2', function () {
            gapi.client.init({
                'clientId': '192174907384-df0kgapaogjmpeeep34qkgrlnhbhg3nl.apps.googleusercontent.com',
            }).then(
                () => {
                    console.log('init Ok')
                },
                () => console.log('init Error')
            )
        })
    }, [])

    let signIn = () => {
        let _authOk = (user) => [
            console.log('Auth Ok', user)
        ]
        let _authErr = () => {
            console.log('Auth Err')
        }

        let GoogleAuth = gapi.auth2.getAuthInstance()
        GoogleAuth.signIn(
            {
                scope: 'profile email',
            }
        ).then(_authOk, _authErr)
    }
    let signOut = () => {
        let GoogleAuth = gapi.auth2.getAuthInstance()
        GoogleAuth.signOut()
    } */
    
    return (
        <BrowserRouter>
            <div className="App">

                {/* <button onClick={() => signIn()}>Sign In</button>
                <button onClick={() => signOut()}>Sign Out</button> */}

                <ScrollToTop />
                {/* <GoogleLogIn/>
                <GoogleLogOut/> */}
                <Router />
            </div>
        </BrowserRouter>
    )
}

export default App
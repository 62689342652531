import React from 'react'
import SingleQuestionPage from "../../SingleQuestionPage/SingleQuestionPage";
import ForumPage from '../../ForumThemesPage/ForumThemesPage';
import AskaquestionPage from '../../AskaquestionPage/AskaquestionPage';
import ExamPage from "../../ExamPages/ExamPage/ExamPage";
import ExamRulesPage from "../../ExamPages/ExamRules/ExamRulesPage";
import ExamResultPage from "../../ExamPages/ExamResultPage/ExamResultPage";
import ExamQuestions from '../../ExamPages/ExamQuestions/ExamQuestions';
import UserCabinet from "../../UserCabinetPages/UserCabinet";
import TeacherCabinet from '../../TeacherCabinetPages/TeacherCabinet';
import Studying from '../../Studying/Studying';
import ForumQuestionsPage from '../../ForumQuestionsPage/ForumQuestionsPage';
import { Route, Routes } from 'react-router';
import AdminCabinet from '../../AdminCabinetPages/AdminCabinet';

export default function PrivateRouter() {

    return (
        <Routes>
            <Route path="/forum" element={<ForumPage />} />
            <Route path="/forum/:theme_id" element={<ForumQuestionsPage />} />
            <Route path="/forum/:theme_id/:question_id" element={<SingleQuestionPage />} />
            <Route path="/forum/askaquestion" element={<AskaquestionPage />} />
            <Route path="/exam" element={<ExamPage />} />
            <Route path="/exam/:exam_id/rules" element={<ExamRulesPage />} />
            <Route path="/exam/:exam_id/result" element={<ExamResultPage />} />
            <Route path="/exam/:exam_id/questions" element={<ExamQuestions />} />
            <Route path="/exam/:exam_id/questions/:id" element={<ExamQuestions />} />
            <Route path="/usercabinet/*" element={<UserCabinet />} />
            <Route path="/teachercabinet/*" element={<TeacherCabinet />} />
            <Route path="/admincabinet/*" element={<AdminCabinet />} />
            <Route path="/studying/:courseId/*" element={<Studying />} />
        </Routes>
    )
}
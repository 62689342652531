import React, {useState} from "react";
import { useNavigate, useParams } from "react-router";
import { useCheckAuth } from "../../../shared/functions/useCheckAuth";

import BookImg from "../../../shared/ui/img/Rules/bookImg.svg"
import HeadphonesImg from "../../../shared/ui/img/Rules/headphonesImg.png"
import ListImg from "../../../shared/ui/img/Rules/listImg.svg"

import "./style.scss"

export default function Rules() {

	let navigate = useNavigate()
	let params = useParams()

	return (
		<div className="rules">
			<h1 className="rules__title">Правила теста :</h1>
			<span className="rules__desc">Данный тест состоит из двух разделов : Чтение и Аудирование. Общая продолжительность теста : 15 минут.</span>
			<div className="rules__container">
				<div className="rules__container-list">
					<h4 className="rules__container-list-title"><img src={BookImg}/>Чтение</h4>
					<ul className="rules__list">
						<li>
							<img src={ListImg}/>
							<span>На раздел чтение выдаеляется : 5 минут.</span>
						</li>
						<li>
							<img src={ListImg}/>
							<span>Выбирайте слово из предложенного списка, которое дополняет предложение !</span>
						</li>
					</ul>
				</div>
				<div className="rules__container-list">
					<h4 className="rules__container-list-title"><img src={HeadphonesImg}/>Аудирование</h4>
					<ul className="rules__list">
						<li>
							<img src={ListImg}/>
							<span>На раздел аудирование выдаеляется : 10 минут.</span>
						</li>
						<li>
							<img src={ListImg}/>
							<span>Выберите лучший вариант того, что будет дальше в разговоре.</span>
						</li>
						<li>
							<img src={ListImg}/>
							<span>Вы можете воспроизвести запись всего <div className="rules__list-text_bg">ДВА</div> раза !</span>
						</li>
					</ul>
				</div>
			</div>
			<button className="rules__button"
				onClick={() => navigate('/test/' + params.test_id + '/questions')}
			>Начать Тест</button>
		</div>
	)
}
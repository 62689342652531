import { createSlice } from '@reduxjs/toolkit'
import { addLessonParticipantsInBD, createLessonInBD, getCourseOnlineLessonsFromBD, getCoursesFromBD, getEnrolledStudentsFromBD, removeLessonParticipantsInBD, updateLessonInBD } from '../../../api/teacherCabinet'
import { getCookie, getNextTestQuestion, prepareCourseOnlineLessonParams, prepareCoursesParams, prepareEnrolledStudentsParams } from '../../../shared/functions'
/* import { gettestFromBD } from '../../api/test' */

const initialState = {
    lesson: {},
    courses: [],
    students: {
        content: []
    },
    addedStudents: [],
    delStudents: [],
    loading: false,
    saveMessage: {
		show: false,
		success: false,
		error: false,
		successText: 'Сохранено!',
		errorText: 'Ошибка сохранения! Проверьте введенные данные и попробуйте еще раз'
	},
}

export const teacherOneLessonSlice = createSlice({
    name: 'teacherOneLesson',
    initialState,
    reducers: {
        SET_LESSON: (state, action) => {
            state.lesson = action.payload
            state.loading = false
        },
        SET_COURSES: (state, action) => {
            state.courses = action.payload
        },
        SET_STUDENTS: (state, action) => {
            state.students = action.payload
        },
        SET_ADDEDSTUDENTS: (state, action) => {
            if (!state.delStudents.includes(action.payload.accountId)) {
                state.addedStudents.push(action.payload)
            }
            state.students.content.unshift(action.payload)
        },
        SET_DELSTUDENTS: (state, action) => {
            if (action.payload.action == 'add') {
                let arr = state.addedStudents.map(item => item.accountId)
                if (!arr.includes(action.payload.value)) {
                    state.delStudents.push(action.payload.value)
                }
                state.addedStudents = state.addedStudents.filter(item => item.accountId != action.payload.value)
                state.students.content = state.students.content.filter((item) => item.accountId != action.payload.value)
            } else if (action.payload.action == 'remove') {
                state.delStudents = state.delStudents.filter((item) => item != action.payload.value)
            }
        },
        UPDATE_LESSON: (state, action) => {
            state.lesson[action.payload.field] = action.payload.value
        },
        SET_LOADING: (state, action) => {
            state.loading = action.payload
        },
        SET_SAVEMESSAGE: (state, action) => {
            state.saveMessage = action.payload
        },
        RESET: (state, action) => {
            state.lesson = {}
            state.courses = []
            state.students = {
                content: []
            }
            state.addedStudents = []
            state.delStudents = []
            state.loading = false
            state.saveMessage = {...state.saveMessage, show: false, error: false, success: false}
        },
    },
})

export const getLesson = (lang, id, lessonId) => async (dispatch, getState) => {
    dispatch(SET_LOADING(true))
    if (lessonId != 'create') {
        const params = prepareCourseOnlineLessonParams(lang, id, lessonId)
        const testResponse = await getCourseOnlineLessonsFromBD(params)
        dispatch(SET_LESSON(testResponse[0]))
    } else {
        const testResponse = {
            "title": "",
            "desctiption": "",
            "meetingDate": new Date().toISOString(),
            "meetingTypeId": 6,
            "courseId": null,
            "type": "Online lesson",
            "maxCount": 0,
            "value": "Онлайн урок",
            "languageCode": lang,
            "linkUrl": "",
            "accountId": null,
            "recordedVideo": "",
        }
        dispatch(SET_LESSON(testResponse))
    }
}
export const getAllCourses = () => async (dispatch, getState) => {
    const params = prepareCoursesParams()
    const testResponse = await getCoursesFromBD(params)
    dispatch(SET_COURSES(testResponse))
}
export const getEnrolledStudents = (id) => async (dispatch, getState) => {
    const params = prepareEnrolledStudentsParams()
    const testResponse = await getEnrolledStudentsFromBD(params, id)
    dispatch(SET_STUDENTS(testResponse))
}

let addParticipants = (type, newLesson) => async (dispatch, getState) => {
    let token = getCookie('userToken')
    if (token) {
        if (type == 'create') {
            const { addedStudents } = getState().teacherOneLesson
            let arr = addedStudents.map((item) => item.accountId)
            let obj = {
                meetingsId: newLesson.meetingsId,
                accountIdList: arr
            }
            const testResponse = await addLessonParticipantsInBD(obj, token)
            console.log(testResponse)
        } else {
            const { lesson, addedStudents } = getState().teacherOneLesson
            let arr = addedStudents.map((item) => item.accountId)
            let obj = {
                meetingsId: lesson.meetingsId,
                accountIdList: arr
            }
            const testResponse = await addLessonParticipantsInBD(obj, token)
            console.log(testResponse)
        }
    }
}
let removeParticipants = () => async (dispatch, getState) => {
    let token = getCookie('userToken')
    if (token) {
        const { lesson, delStudents } = getState().teacherOneLesson
        let obj = {
            meetingsId: lesson.meetingsId,
            accountIdList: delStudents
        }
        const testResponse = await removeLessonParticipantsInBD(obj, token)
        console.log(testResponse)
    }
}
export const updateLesson = () => async (dispatch, getState) => {
    let token = getCookie('userToken')
    if (token) {
        const { lesson, addedStudents, delStudents, saveMessage } = getState().teacherOneLesson
        let obj = {...lesson}
        obj.meetingDate = new Date(new Date(lesson.meetingDate).setHours(new Date(lesson.meetingDate).getHours() + 3)).toISOString().substring(0, 19)
        const testResponse = await updateLessonInBD(obj, token)
        console.log(testResponse)
        if (testResponse) {
            dispatch(SET_SAVEMESSAGE({...saveMessage, show: true, error: false, success: true}))
        } else {
            dispatch(SET_SAVEMESSAGE({...saveMessage, show: true, error: true, success: false}))
        }
        setTimeout(() => {
            dispatch(SET_SAVEMESSAGE({...saveMessage, show: false, error: false, success: false}))
        }, 5000)
        if (addedStudents.length > 0) {
            console.log('add participant')
            dispatch(addParticipants(obj))
        }
        if (delStudents.length > 0) {
            console.log('remove participant')
            dispatch(removeParticipants(obj))
        }
    }
}
export const createLesson = () => async (dispatch, getState) => {
    let token = getCookie('userToken')
    if (token) {
        const { lesson, addedStudents, delStudents, saveMessage } = getState().teacherOneLesson
        let obj = {...lesson}
        obj.meetingDate = new Date(new Date(lesson.meetingDate).setHours(new Date(lesson.meetingDate).getHours() + 3)).toISOString().substring(0, 19)
        const testResponse = await createLessonInBD(obj, token)
        if (testResponse) {
            dispatch(SET_SAVEMESSAGE({...saveMessage, show: true, error: false, success: true}))
        } else {
            dispatch(SET_SAVEMESSAGE({...saveMessage, show: true, error: true, success: false}))
        }
        setTimeout(() => {
            dispatch(SET_SAVEMESSAGE({...saveMessage, show: false, error: false, success: false}))
        }, 5000)
        if (addedStudents.length > 0) {
            dispatch(addParticipants('create', testResponse))
        }
    }
}

export const { SET_LESSON, UPDATE_LESSON, SET_LOADING, SET_COURSES, SET_STUDENTS, SET_ADDEDSTUDENTS, SET_DELSTUDENTS, SET_SAVEMESSAGE, RESET } = teacherOneLessonSlice.actions

export default teacherOneLessonSlice.reducer
import http from "./index"

export let getExamIndexInfoFromBD = (id, lang, token) => {
    return http.get(
        `/time-test-type/` + id,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                "languageCode": lang,
                'AuthorizationToken': token
            }
        }
    )
    .then(function (response) {
        // handle success
        // console.log(response.data[0])
        if (response.data) {
            return response.data
        }
    })
    .catch(function (error) {
        // handle error
        console.log(error)
    })
    .finally(function () {
        // always executed

    });
}
export let getExamFromBD = (id, lang, token) => {
    return http.get(
        `/test/course/` + id,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                "languageCode": lang,
                'AuthorizationToken': token
            }
        }
    )
    .then(function (response) {
        // handle success
        // console.log(response.data[0])
        if (response.data) {
            return response.data
        }
    })
    .catch(function (error) {
        // handle error
        console.log(error)
    })
    .finally(function () {
        // always executed

    });
}
export let getCurrExamByIdFromBD = (id, lang, token) => {
    return http.get(
        `/test/exam/` + id,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                "languageCode": lang,
                'AuthorizationToken': token
            }
        }
    )
    .then(function (response) {
        // handle success
        // console.log(response.data[0])
        if (response.data) {
            return response.data
        }
    })
    .catch(function (error) {
        // handle error
        console.log(error)
    })
    .finally(function () {
        // always executed

    });
}
export let saveUserExamResultsToBD = (params) => {
    return http.post(
        `/account/exam/set-result`,
        params,
        {
            headers: {
                'Access-Control-Allow-Origin': '*'
            }
        }
    )
    .then(function (response) {
        // handle success
        // console.log(response.data[0])
        if (response.data) {
            return response.data
        }
    })
    .catch(function (error) {
        // handle error
        console.log(error)
    })
    .finally(function () {
        // always executed

    });
}








export let getDatesForExamFromDB = (params) => {
    return http.post(
        `/meeting/list`,
        params,
        {
            headers: {
                'Access-Control-Allow-Origin': '*'
            }
        }
    )
    .then(function (response) {
        // handle success
        // console.log(response.data[0])
        if (response.data) {
            return response.data
        }
    })
    .catch(function (error) {
        // handle error
        console.log(error)
    })
    .finally(function () {
        // always executed

    });
}
export let getExamTypesFromDB = (params) => {
    return http.post(
        `/meeting/type/list`,
        params,
        {
            headers: {
                'Access-Control-Allow-Origin': '*'
            }
        }
    )
    .then(function (response) {
        // handle success
        // console.log(response.data[0])
        if (response.data) {
            return response.data
        }
    })
    .catch(function (error) {
        // handle error
        console.log(error)
    })
    .finally(function () {
        // always executed

    });
}
export let saveUserExamDate = (params, token) => {
    console.log(params, token)
    return http.post(
        `/meeting/link`,
        params,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'AuthorizationToken': token
            }
        }
    )
    .then(function (response) {
        // handle success
        // console.log(response.data[0])
        if (response.data) {
            return response.data
        }
    })
    .catch(function (error) {
        // handle error
        console.log(error)
    })
    .finally(function () {
        // always executed

    });
}
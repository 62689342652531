    import React, { useRef } from 'react'
import { useState } from 'react'
import "./UploadImg.scss"

export default function UploadImg({ item, userImg, imgId, getImg, deleteImg, id, allowEdit }) {

    let PhotoRef = useRef(null)
    let [ImgChooseIcon, setImgChooseIcon] = useState((userImg) ? false : true)

    let handleChange = (e) => {
        let files = e.target.files;
        let fileReader = new FileReader()
        fileReader.onload = function () {
            setImgChooseIcon(false)
            PhotoRef.current.src = fileReader.result
            getImg(fileReader.result, imgId)
        }
        fileReader.readAsDataURL(files[0])
    }

    return (
        <div className="coverImg">
            <div className="img">
                
                <input
                    disabled={!allowEdit}
                    type="file"
                    name="img"
                    id={id + '_' + imgId}
                    onChange={(e) => { handleChange(e) }}
                />
                <img
                    ref={PhotoRef}
                    src={userImg}
                    style={{
                        display: ImgChooseIcon ? 'none' : ' block'
                    }}
                />
                {
                    (userImg && !ImgChooseIcon)
                    ?
                    ''
                    :
                    <div className="noMediafile" title="ImgChooseIcon">
                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.25 0H2.74997C1.23362 0 0 1.23362 0 2.74997V16.25C0 17.7664 1.23362 19 2.74997 19H16.25C17.7664 19 19 17.7664 19 16.25V2.74997C19 1.23362 17.7662 0 16.25 0ZM18 16.2502C18 17.2151 17.2149 18.0001 16.25 18.0001H2.74997C1.78507 18.0001 0.999993 17.2151 0.999993 16.2502V14.1921L5.59813 9.08335L11.4138 14.3703C11.5088 14.4569 11.6294 14.5004 11.75 14.5004C11.8635 14.5004 11.9773 14.462 12.07 14.3845L15.125 11.8387L18 14.2345L18 16.2502ZM18 12.9327L15.4452 10.8035C15.2596 10.6489 14.9905 10.6489 14.805 10.8035L11.7643 13.3372L5.89882 8.00506C5.80033 7.91564 5.66918 7.86912 5.53754 7.87556C5.40458 7.88232 5.27986 7.94155 5.19078 8.04052L1.00001 12.6969V2.74979C1.00001 1.78489 1.78509 0.999816 2.74999 0.999816H16.25C17.2149 0.999816 18 1.78489 18 2.74979L18 12.9327Z"/>
                            <path d="M13.4375 2.8125C11.9211 2.8125 10.6875 4.04612 10.6875 5.56247C10.6875 7.07883 11.9211 8.31244 13.4375 8.31244C14.9538 8.31244 16.1874 7.07883 16.1874 5.56247C16.1874 4.04612 14.9537 2.8125 13.4375 2.8125ZM13.4375 7.31224C12.4726 7.31224 11.6875 6.52716 11.6875 5.56226C11.6875 4.59736 12.4726 3.81228 13.4375 3.81228C14.4024 3.81228 15.1874 4.59736 15.1874 5.56226C15.1874 6.52716 14.4024 7.31224 13.4375 7.31224Z"/>
                        </svg>
                        <span>Добавить медиафайл</span>
                    </div>
                }
                
                <label htmlFor={id + '_' + imgId}></label>
            </div>
            <div className="imgBtns">
                <button onClick={() => {
                    deleteImg();
                    PhotoRef.current.src = ''
                    setImgChooseIcon(true)
                }} disabled={!allowEdit} title="Удалить фото">
                    <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.4679 17H14.5322L15.4296 3.53772H17V2.58809L12.4385 2.5879V0H4.56152V2.5879H0V3.53753H1.57039L2.4679 17ZM13.6099 16.0502H3.39017L2.56771 3.53763H14.4324L13.6099 16.0502ZM5.55864 0.949823H11.4415V2.58809H5.55864V0.949823Z" fill="#BD5252" />
                        <path d="M5.95789 6.57715H6.95485V12.9879H5.95789V6.57715Z" fill="#BD5252" />
                        <path d="M10.0457 6.57715H11.0426V12.9879H10.0457V6.57715Z" fill="#BD5252" />
                    </svg>
                </button>
            </div>
        </div>
    )
}
import React, { useEffect, useRef, useState } from 'react'
import "./style.scss"

import expandIcon from "../../../shared/img/expandIcon.svg"
import { PlaySound } from '../../../shared/ui/PlaySound'
import { useNavigate, useParams } from 'react-router'
import { useCheckAuth } from '../../../shared/functions/useCheckAuth'

export default function CheckAudio() {

    let navigate = useNavigate()
    let params = useParams()

    let [userHaveSound, setUserHaveSound] = useState(true)
    let [ClassShow, setClassShow] = useState('')
    /* let [Expand, setExpand] = useState(false) */

    useEffect(() => {
        setTimeout(() => {
            setClassShow("show") 
        }, 1000)
    }, [userHaveSound])

    return (
        <div className="CheckAudio">
            <div className={"CheckAudioContent " /* + ((Expand) ? "expand" : "") */}>
                <div className="close"
                    /* onClick={() => setExpand(false)} */
                >&#x2715;</div>
                <h1>Проверка аудио</h1>
                <p>Прежде чем начать, проверьте настройки звука на вашем устройстве.</p>
                <PlaySound/>
                <div className="btns">
                    <button className="no" onClick={() => setUserHaveSound(false)}>Нет</button>
                    <button className="yes" onClick={() => navigate('/test/' + params.test_id + '/rules')}>Да, продолжить тест</button>
                </div>
            </div>
            <div className={"noSoundWrapper " + ((userHaveSound) ? "" : "show")}>
                <div className={"noSound " + 
                    ((userHaveSound) ? 
                        "" 
                    : 
                        ClassShow
                    )
                }>
                    <h3>Пожалуйста, проверьте следующее:</h3>
                    <div className="recomendationsList">
                        <div className="row">
                            <div className="icon">
                            <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="9.60742" y="-0.607422" width="15" height="15" rx="3" transform="rotate(45 9.60742 -0.607422)" fill="white"/>
                            </svg>
                            </div>
                            <span>Звук компьютера или устройства не отключен и работает на разумной громкости.</span>
                        </div>
                        <div className="row">
                            <div className="icon">
                            <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="9.60742" y="-0.607422" width="15" height="15" rx="3" transform="rotate(45 9.60742 -0.607422)" fill="white"/>
                            </svg>
                            </div>
                            <span>Динамики или наушники подключены правильно.</span>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* <button className="expandBtn"
                onClick={() => setExpand(true)}
            >
                <img src={expandIcon} alt="" />
            </button> */}
        </div>
    )
}
import React from 'react';
import "./style.scss"
import {Link} from "react-router-dom";
import InstagramIcon from "../../../shared/ui/img/instagram.svg"
import TelegramIcon from "../../../shared/ui/img/telegram.svg"
import FacebookIcon from "../../../shared/ui/img/facebook.svg"
import YoutubeIcon from "../../../shared/ui/img/youtube.svg"
import SelectLang from '../../../features/SelectLang/ui/SelectLang';

export const Footer = () => {
	return (
		<div className="footer">
			<div className="footer_content">
				<div className="footer_content_column">
					<h6 className="footer_content_column_title">DIL</h6>
					<Link className="footer_content_column_desc" to="about">О нас</Link>
					<Link className="footer_content_column_desc" to="solution">Решение для оценки</Link>
					<Link className="footer_content_column_desc" to="contacts">Контакты</Link>
					<Link className="footer_content_column_desc" to="faq">FAQ</Link>
				</div>
				<div className="footer_content_column">
					<h6 className="footer_content_column_title">Обучение</h6>
					<Link className="footer_content_column_desc" to="certificate">Сертификация</Link>
					<Link className="footer_content_column_desc" to="#">Шкала оценок</Link>
				</div>
				<div className="footer_content_column">
					<h6 className="footer_content_column_title">Форум</h6>
					<Link className="footer_content_column_desc" to="#">Технические вопросы</Link>
					<Link className="footer_content_column_desc" to="#">Курс A1</Link>
					<Link className="footer_content_column_desc" to="#">Развитие платформы</Link>
					<Link className="footer_content_column_desc" to="#">Курс B1</Link>
				</div>
				<div className="footer_content_column">
					<h6 className="footer_content_column_title">Тесты</h6>
					<Link className="footer_content_column_desc" to="test">HIZLI CONTROL</Link>
					<Link className="footer_content_column_desc" to="exam">EGZAMEN</Link>
				</div>
			</div>
			<div className="footer_row">
				<div className="footer_row_left">
					<div className="footer_row_left_inc">
						© DIL, Inc. 2022
					</div>
					<div className="footer_row_left_desc">
						<Link to="#" className="footer_row_left_desc_link">Помощь</Link>
						<Link to="#" className="footer_row_left_desc_link">Условия соглашение</Link>
						<Link to="#" className="footer_row_left_desc_link">Политика конфиденциальности</Link>
					</div>
				</div>
				<div className="footer_row_right">
					<button className="footer_row_right_img">
						<img src={InstagramIcon}/>
					</button>
					<button className="footer_row_right_img">
						<img src={TelegramIcon}/>
					</button>
					<button className="footer_row_right_img">
						<img src={FacebookIcon}/>
					</button>
					<button className="footer_row_right_img">
						<img src={YoutubeIcon}/>
					</button>

					<SelectLang
						place={'footer'}
					/>
				</div>
			</div>
		</div>
	);
};


import {createSlice} from "@reduxjs/toolkit";


const initialState = {
	formActive: false,
	signIn: false,
	signUp: false,
	confirmPhone: false,
}

export const registrationFormSlice = createSlice({
	name: 'registrationForm',
	initialState,
	reducers: {
		SET_FORMACTIVE: (state, action) => {
			state.formActive = action.payload
		},
		SET_SIGNIN: (state, action) => {
			state.formActive = true
			state.signUp = false
			state.signIn = action.payload
		},
		SET_SIGNUP: (state, action) => {
			state.formActive = true
			state.signIn = false
			state.signUp = action.payload
		},
		SET_CONFIRMPHONE: (state, action) => {
			state.formActive = true
			state.signIn = false
			state.signUp = false
			state.confirmPhone = action.payload
		}
	}
})

export const {SET_FORMACTIVE, SET_SIGNIN, SET_SIGNUP, SET_CONFIRMPHONE} = registrationFormSlice.actions

export default registrationFormSlice.reducer
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    lang: 'RU',
    loggedUser: null,
}

export const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        SET_LANG: (state, action) => {
            state.lang = action.payload
        },
        SET_LOGGEDUSER: (state, action) => {
            state.loggedUser = action.payload
        }
    },
})

export const { SET_LANG, SET_LOGGEDUSER } = globalSlice.actions

export default globalSlice.reducer
import http from "./index"

export let getQuestionFromDB = (id, token) => {
    return http.get(
        `/forum-question/` + id,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'AuthorizationToken': token
            }
        }
    )
        .then(function (response) {
            // handle success
            // console.log(response.data[0])
            if (response.data) {
                return response.data
            }
        })
        .catch(function (error) {
            // handle error
            console.log(error)
        })
        .finally(function () {
            // always executed

        });
}
export let getCommentsFromDB = (params, token) => {
    return http.post(
        `/forum-question-comment/page`,
        params, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'AuthorizationToken': token
            }
        }
    )
        .then(function (response) {
            // handle success
            // console.log(response.data[0])
            if (response.data) {
                return response.data
            }
        })
        .catch(function (error) {
            // handle error
            console.log(error)
        })
        .finally(function () {
            // always executed

        });
}
export let saveNewCommentToDB = (params, token) => {
    return http.post(
        `/forum-question-comment`,
        params,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'AuthorizationToken': token
            }
        }
    )
        .then(function (response) {
            // handle success
            // console.log(response.data[0])
            if (response.data) {
                return response.data
            }
        })
        .catch(function (error) {
            // handle error
            console.log(error)
        })
        .finally(function () {
            // always executed

        });
}
export let getForumThemesFromBD = (params, token) => {
    return http.post(
        `forum`,
        params,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'AuthorizationToken': token
            }
        }
    )
    .then(function (response) {
        // handle success
        // console.log(response.data[0])
        if (response.data) {
            return response.data
        }
    })
    .catch(function (error) {
        // handle error
        console.log(error)
    })
    .finally(function () {
        // always executed

    });
}
export let getForumQuestionsFromBD = (params, token) => {
    return http.post(
        `forum-question/page`,
        params,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'AuthorizationToken': token
            }
        }
    )
    .then(function (response) {
        // handle success
        // console.log(response.data[0])
        if (response.data) {
            return response.data
        }
    })
    .catch(function (error) {
        // handle error
        console.log(error)
    })
    .finally(function () {
        // always executed

    });
}
export let getForumCategsFromBD = (params, token) => {
    return http.post(
        `/forum/type-detail-page`,
        params,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'AuthorizationToken': token
            }
        }
    )
    .then(function (response) {
        // handle success
        // console.log(response.data[0])
        if (response.data) {
            return response.data
        }
    })
    .catch(function (error) {
        // handle error
        console.log(error)
    })
    .finally(function () {
        // always executed

    });
}
export let saveNewQuestionToDB = (params, token) => {
    return http.post(
        `/forum-question`,
        params,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'AuthorizationToken': token
            }
        }
    )
    .then(function (response) {
        // handle success
        // console.log(response.data[0])
        if (response.data) {
            return response.data
        }
    })
    .catch(function (error) {
        // handle error
        console.log(error)
    })
    .finally(function () {
        // always executed

    });
}
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {SET_FORMACTIVE} from "../../store/registrationForm/registrationFormSlice";
import CloseImg from "../../shared/ui/img/RegistrationForm/closeImg.svg"
import SignUp from "./SignUp/SignUp";
import SignIn from "./SignIn/SignIn";
import ConfirmPhone from "./ConfirmPhone/ConfirmPhone";

import "./style.scss"

export default function RegistrationForm() {
	const dispatch = useDispatch()

	let formActive = useSelector(state => state.registrationForm.formActive)
	let signIn = useSelector(state => state.registrationForm.signIn)
	let signUp = useSelector(state => state.registrationForm.signUp)
	let confirmPhone = useSelector(state => state.registrationForm.confirmPhone)

	return (
		<div className={formActive ? 'registration-form registration-form_active' : 'registration-form'}
			onClick={() => dispatch(SET_FORMACTIVE(false))}>
			<div className="registration-form__content" onClick={(e) => e.stopPropagation()}>
				<button className="registration-form__content-close" onClick={() => dispatch(SET_FORMACTIVE(false))}>
					<img src={CloseImg}/>
				</button>
				{
					signIn ? <SignIn/> : signUp ? <SignUp /> : confirmPhone ? <ConfirmPhone/> : ''
				}
			</div>
		</div>
	)
}
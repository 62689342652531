import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import env from "./env";

import { Provider } from "react-redux";
import store from "./store/index"

// import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    {/* <GoogleOAuthProvider clientId={'192174907384-df0kgapaogjmpeeep34qkgrlnhbhg3nl.apps.googleusercontent.com'}>
      <App />
    </GoogleOAuthProvider> */}
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

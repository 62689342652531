import http from "./index"


export let authUser = (params) => {
    return http.post(
        `/auth/authenticate`,
        params,
        {
            headers: {
                'Access-Control-Allow-Origin': '*'
            }
        }
    )
    .then(function (response) {
        // handle success
        // console.log(response.data[0])
        if (response.data) {
            return response.data
        }
    })
    .catch(function (error) {
        // handle error
        console.log(error)
    })
    .finally(function () {
        // always executed

    });
}
export let getUserByTokenFromDB = (token) => {
    return http.get(
        `/account/me`,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'AuthorizationToken': token
            }
        }
    )
    .then(function (response) {
        // handle success
        // console.log(response.data[0])
        if (response.data) {
            return response.data
        }
    })
    .catch(function (error) {
        // handle error
        console.log(error)
    })
    .finally(function () {
        // always executed

    });
}
export let updateUserByTokenToDB = (params, token) => {
    return http.put(
        `/account/me`,
        params,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'AuthorizationToken': token
            }
        }
    )
    .then(function (response) {
        // handle success
        // console.log(response.data[0])
        if (response.data) {
            return response.data
        }
    })
    .catch(function (error) {
        // handle error
        console.log(error)
    })
    .finally(function () {
        // always executed

    });
}
export let regNewUser = (params) => {
    return http.post(
        `/auth/register`,
        params,
        {
            headers: {
                'Access-Control-Allow-Origin': '*'
            }
        }
    )
    .then(function (response) {
        // handle success
        // console.log(response.data[0])
        if (response.data) {
            return response.data
        }
    })
    .catch(function (error) {
        // handle error
        console.log(error)
    })
    .finally(function () {
        // always executed

    });
}
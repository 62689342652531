import React, {useEffect, useState} from "react";

import "./style.scss"
import {useDispatch, useSelector} from "react-redux";
import StudentRow from "./StudentRow/StudentRow";
import {StudentsPagination} from "../../../../features/TeacherCabinet/StudentsPagination";
import {getStudents, SET_PAGE} from "../../../../store/teacherCabinet/teacherStudents/StudentsSlice";



export default function StudentsTable() {

	const dispatch = useDispatch()

	const students = useSelector(state => state.students.students)
	const pagination = useSelector(state => state.students.pagination)
	const loading = useSelector(state => state.students.loading)
	const lang = useSelector(state => state.global.lang)

	const onPageChange = (pageNumber) => {
		dispatch(SET_PAGE(pageNumber))
		dispatch(getStudents(lang))
	}

	useEffect(() => {
		dispatch(getStudents(lang))
	}, [])

	return (
		<div className="students-table">
			<table>
				<thead>
				<tr>
					<th>
						<span></span>
					</th>
					<th>
						<span>ID</span>
					</th>
					<th>
						<span>ФИО</span>
					</th>
					<th>
						<span>Дата Рождения</span>
					</th>
					<th>
						<span>E-mail</span>
					</th>
					<th>
						<span>Последний визит</span>
					</th>
				</tr>
				</thead>

				<tbody>
				{loading ? (
					<tr>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td>Загрузка</td>
					</tr>
				) : students?.length > 0 ? (
					students?.map((student) => {
						return <StudentRow key={student.accountId} student={student} />
					})
				) : (
					<tr>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td className="noStudents">Нет студентов</td>
					</tr>
				)}
				</tbody>
			</table>

			<div className="pagination">
				<StudentsPagination pagination={pagination} onPageChange={onPageChange} />
			</div>
		</div>
	)
}
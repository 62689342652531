import React, {useState} from "react";

import GoogleImg from "../../../shared/ui/img/RegistrationForm/googleImg.svg"
import FacebookImg from "../../../shared/ui/img/RegistrationForm/facebookImg.svg"
import AppleImg from "../../../shared/ui/img/RegistrationForm/appleImg.svg"
import VkImg from "../../../shared/ui/img/RegistrationForm/vkImg.svg"
import VisionImg from "../../../shared/ui/img/RegistrationForm/visionImg.svg"

import {useDispatch} from "react-redux";
import {SET_CONFIRMPHONE, SET_FORMACTIVE, SET_SIGNIN} from "../../../store/registrationForm/registrationFormSlice";
import {useInput} from "../../../shared/functions/useInput";

/* import { GoogleLogin } from "@react-oauth/google";
import { googleLogout } from '@react-oauth/google'; */

/* import { GoogleLogin } from "@react-oauth/google";
import { googleLogout } from '@react-oauth/google'; */

import "./style.scss"
import { getUserByTokenFromDB, regNewUser } from "../../../api/auth";
import { parseJwt, setCookie } from "../../../shared/functions";
import { SET_LOGGEDUSER } from "../../../store/globalSlice";
import { useNavigate } from "react-router";

export default function SignUp() {

	const dispatch = useDispatch()
	const navigate = useNavigate()

	const [showPassword, setShowPassword] = useState(false)

	const surname = useInput('', {isEmpty: true})
	const name = useInput('', {isEmpty: true})
	const email = useInput('', {isEmail: true})
	const phone = useInput('', {minLength: 7})
	const password = useInput('', {minLength: 5})

	const [authMessage, setAuthMessage] = useState({
		show: false,
		success: false,
		error: false,
		successText: 'Вы успешно зарегистрировались!',
		errorText: 'Ошибка регистрации! Проверьте введенные данные и попробуйте еще раз'
	})

	async function onSubmitFunc(e) {
		e.preventDefault()
		let fielsNames = ['fam', 'im', 'ot', 'town', 'countryId', 'gender', 'email', 'password']
		let formData = {}
		let form = e.target
		Array.from(form).forEach((field) => {
			if (field.nodeName == 'INPUT' && fielsNames.includes(field.name)) {
				formData[field.name] = field.value
			}
		})
		console.log(formData)
		let result = await regNewUser(formData)
		/* let result = {
			"token": "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJtYXZyaWt2b3ZhQGdtYWlsLmNvbSIsImlhdCI6MTY4MDAyMjQ2MSwiZXhwIjoxNjgwMDIzOTAxfQ.cBXxXhIc__qNZM9p0hTdJKm9DNWDJ-zi8K6S19b3hGs"
		} */
		console.log(result)
		if (result) {
			setAuthMessage({...authMessage, show: true, error: false, success: true})
			let User = await getUserByTokenFromDB(result.token)
			let tokenData = parseJwt(result.token)
			dispatch(SET_LOGGEDUSER({...tokenData, ...User}))
			setCookie('userToken', result.token, tokenData.exp * 1000)
			let path = await User?.roles[0]?.accountRoleName == 'STUDENT' ? '/usercabinet' : User?.roles[0]?.accountRoleName == 'TEACHER' && '/teachercabinet'
			setTimeout(() => {
				dispatch(SET_FORMACTIVE(false))
				setAuthMessage({...authMessage, show: false, error: false, success: false})
				navigate(path)
			}, 1000);
		} else {
			setAuthMessage({...authMessage, show: true, error: true, success: false})
		}
		// dispatch(SET_CONFIRMPHONE(true))
	}

	return (
		<>
			<h2 className="signup-title">Добро пожаловать !</h2>
			<form className="signup-form" id="form" onSubmit={onSubmitFunc}>
				<label htmlFor="surname">Фамилия</label>
				{(surname.isDirty && surname.isEmptyError) && <div className="form-page__form-error">Поле не может быть пустым</div>}
				<input onChange={e => surname.onChange(e)} value={surname.value} name={'fam'} onBlur={e => surname.onBlur(e)} type="text" className="signup-form__input" id="surname"/>

				<label htmlFor="name">Имя</label>
				{(name.isDirty && name.isEmptyError) && <div className="form-page__form-error">Поле не может быть пустым</div>}
				<input onChange={e => name.onChange(e)} value={name.value} name={'im'} onBlur={e => name.onBlur(e)} type="text" className="signup-form__input" id="name"/>

				<label htmlFor="email">Электронный адрес</label>
				{(email.isDirty && email.isEmailError) && <div className="form-page__form-error">Не корректный емайл</div>}
				<input onChange={e => email.onChange(e)} value={email.value} name={'email'} onBlur={e => email.onBlur(e)} type="email" placeholder="example@yahoo.com" className="signup-form__input" id="email"/>

				<label htmlFor="tel">Телефон</label>
				{(phone.isDirty && phone.minLengthError) && <div className="form-page__form-error">Не корректная длинна</div>}
				<input onChange={e => phone.onChange(e)} value={phone.value} name={'phone'} onBlur={e => phone.onBlur(e)} type="tel" pattern="[0-9]{8}" placeholder="68-555-666" className="signup-form__input" id="tel"/>

				<label htmlFor="password">Пароль</label>
				{(password.isDirty && password.minLengthError) && <div className="form-page__form-error">Не корректная длинна</div>}
				<div className="signup-form-password_container">
				<input onChange={e => password.onChange(e)} value={password.value} name={'password'} onBlur={e => password.onBlur(e)} type={showPassword ? "text" : "password"} placeholder="Придумайте пароль" className="signup-form__input signup-form__input_last" id="password"/>
				<button onClick={() => setShowPassword(!showPassword)} type="button" className="signup-form-password_container_button"><img src={VisionImg}/></button>
				</div>
				{/* <button type="button" className="signup-form__button-forgot">Забыли пароль ?</button> */}
			</form>
			<button disabled={surname.inputValid || name.inputValid || email.inputValid || phone.minLengthError || password.minLengthError} type="submit" className={surname.inputValid || name.inputValid || email.inputValid || phone.minLengthError || password.minLengthError ? "signup-form__button-submit signup-form__button-submit_disabled" : "signup-form__button-submit"} form="form" /* onClick={() => dispatch(SET_CONFIRMPHONE(true))} */>Зарагестрироваться</button>
			{
				authMessage.show &&
				<div className="authMessage">
					{
						authMessage.success ?
						<span className="success">{authMessage.successText}</span> :
						<span className="error">{authMessage.errorText}</span>
					}
				</div>
			}
			<span className="signup-divider">или</span>
			<div className="signup-footer">
				<span className="signup-footer__desc">Продолжить с помощью :</span>
				<div className="signup-footer__link">
					<img src={GoogleImg}/>
					<img src={FacebookImg}/>
					<img src={AppleImg}/>
					<img src={VkImg}/>
				</div>
			</div>
			<div className="signup-footer-bottom">
				<span className="signup-footer-bottom-desc">Уже есть аккаунт ?</span>
				<button className="signup-footer-bottom-btn" onClick={() => dispatch(SET_SIGNIN(true))}><span>Войти</span></button>
			</div>
		</>
	)
}
import React from "react";

import {useDispatch} from "react-redux";
import {SET_SIGNUP} from "../../../store/registrationForm/registrationFormSlice";
import {useInput} from "../../../shared/functions/useInput";

import "./style.scss"

export default function ConfirmPhone() {

	const confirmPhone = useInput('', {minLength: 3})

	const dispatch = useDispatch()

	return (
		<>
			<h2 className="confirm-phone-title">Подвердите номер{} телефона</h2>
			<form className="confirm-phone-form" id="form">

				<label htmlFor="phone">Телефон</label>
				<input type="tel" pattern="[0-9]{8}" placeholder="68-555-666" className="confirm-phone-form__input" id="phone"/>

				<label htmlFor="number">Код</label>
				{(confirmPhone.isDirty && confirmPhone.minLengthError) &&
					<div className="form-page__form-error">Не корректная длинна</div>}
				<input onChange={e => confirmPhone.onChange(e)} value={confirmPhone.value} onBlur={e => confirmPhone.onBlur(e)} type="text" placeholder="Введите СМС-код" className="confirm-phone-form__input signin-form__input_last" id="number"/>

			</form>
			<button disabled={confirmPhone.minLengthError} type="submit" className={confirmPhone.minLengthError ? "confirm-phone-form__button-submit confirm-phone-form__button-submit_disabled" : "confirm-phone-form__button-submit"} form="form">Войти</button>

			<div className="confirm-phone-footer-bottom">
				<span className="confirm-phone-footer-bottom-desc">Ввели неверные данные ?</span>
				<button className="confirm-phone-footer-bottom-btn" onClick={() => dispatch(SET_SIGNUP(true))}><span>Вернуться назад</span></button>
			</div>
			<div className="confirm-phone-footer-bottom2">
				<span>Я принимаю Условия использования и Уведомление о конфиденциальности DIL. Возникли проблемы со входом в аккаунт? Центр поддержки учащихся</span>
			</div>
		</>
	)
}
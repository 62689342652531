import React from 'react'

import ButtonIcon from "../img/buttonIcon.svg"

import "./style.scss"

export let AnswerButton = ({text, func, style}) => {
	return (
		<button className="answerButton" style={style} onClick={() => func()}>
			<img src={ButtonIcon}/>
			{text}
		</button>
	)
}
import React from 'react'
import {useNavigate} from 'react-router'
import {getDateByTimeStamp, getTimePeriod} from '../../../../shared/functions'
import "./style.scss"

export default function FutureLesson({lesson}) {

	let navigate = useNavigate()

	return (
		<div className="FutureLesson">
			<div className="top">
				<div className="item alignRight">
					<h5>{lesson.title}</h5>
					<p>Тема: {lesson.desctiption}</p>
				</div>
				<div className="item">
					<h5>{lesson.activeCount} / {lesson.maxCount}</h5>
					<p>Количество мест</p>
				</div>
				<div className="item">
					<h5>{getDateByTimeStamp(new Date(lesson.meetingDate).getTime(), '.')}</h5>
					<p>Дата</p>
				</div>
				<div className="item">
					<h5>{getTimePeriod(new Date(lesson.meetingDate))}</h5>
					<p>Время</p>
				</div>
				<div className="item">
					{/* <button className="item__button"
							onClick={() => navigate('' + lesson.id)}
					>Записаться на курс
					</button> */}
				</div>
			</div>
			<div className="bottom">
				<h5>Ссылка на мероприятие:</h5>
				<div className="link">
					{
						lesson.linkUrl ?
							<a href={lesson.linkUrl}>{lesson.linkUrl}</a>
							:
							<p>Введите сслыку на ваше мероприятие !</p>
					}
				</div>

			</div>
		</div>
	)
}
import { createSlice } from '@reduxjs/toolkit'
import { getForumCategsFromBD, getForumQuestionsFromBD, getForumThemesFromBD } from '../../api/forum'
import { getCookie, prepareCategsParams, prepareForumQuestionsParams, prepareForumThemesParams } from '../../shared/functions'
/* import { gettestFromBD } from '../../api/test' */


const initialState = {
    questions: {},
    categories: {},
    tabsFilter: null,
    sort: {
        sortField: "createdTime",
        sortDir: "DESC"
    },
    search: null,
    pagination: {
        totalPages: 1,
        pageNumber: 1,
    },
    loading: false,
}

export const forumQuestionsSlice = createSlice({
    name: 'forumQuestions',
    initialState,
    reducers: {
        SET_QUESTIONS: (state, action) => {
            let Q = action.payload
            // Q.content = Q.content.sort((a, b) => new Date(b.createdTime) - new Date(a.createdTime))
            state.questions = Q
            state.pagination = {
                totalPages: action.payload.totalPages,
                pageNumber: action.payload.pageable.pageNumber + 1,
            }
            state.loading = false
        },
        SET_PAGE: (state, action) => {
            state.pagination.pageNumber = action.payload
        },
        SET_TABSFILTER: (state, action) => {
            state.tabsFilter = action.payload
        },
        SET_QSORT: (state, action) => {
            state.sort = action.payload
        },
        SET_SEARCH: (state, action) => {
            state.search = action.payload
        },
        SET_CATEGORIES: (state, action) => {
            state.categories = action.payload
        },
        SET_LOADING: (state, action) => {
            state.loading = action.payload
        }
    },
})



export const getForumQuestions = (lang, Qid) => async (dispatch, getState) => {
    dispatch(SET_LOADING(true))
    const { pagination, tabsFilter, sort, search } = getState().forumQuestions

    let filter = []
    if (tabsFilter != null) {
        filter.push(tabsFilter)
    } else if (search != null) {
        filter.push(search)
    }

    const params = prepareForumQuestionsParams(pagination, filter, sort, lang, Qid)
    console.log(params)
    let token = getCookie('userToken')
    const themesResponse = await getForumQuestionsFromBD(params, token)
    dispatch(SET_QUESTIONS(themesResponse))
}
export const getCategs = (lang) => async (dispatch, getState) => {
    dispatch(SET_LOADING(true))

    const params = prepareCategsParams(lang)
    let token = getCookie('userToken')
    const categsResponse = await getForumCategsFromBD(params, token)
    dispatch(SET_CATEGORIES(categsResponse))
}

export const { SET_QUESTIONS, SET_LOADING, SET_TABSFILTER, SET_CATEGORIES, SET_PAGE, SET_SEARCH, SET_QSORT } = forumQuestionsSlice.actions

export default forumQuestionsSlice.reducer
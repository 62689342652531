import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../../shared/ui/breadcrumbs/Breadcrumbs'
import "./style.scss"

import BG from "../../shared/img/askaquestionBG.png"
import RichText from '../../shared/ui/richText/RichText'
import { useDispatch, useSelector } from 'react-redux'
import { getForumThemesForQuestion, saveNewQuestion, SET_FORMDATA } from '../../store/forum/forumAskaquestionSlice'
import UploadImg from '../../shared/ui/UploadImg/UploadImg'
import { getRandomStr } from '../../shared/functions'
import { useCheckAuth } from '../../shared/functions/useCheckAuth'

export default function AskaquestionPage() {

    // Проверка залогинен пользователь или нет
    useCheckAuth()

    let dispatch = useDispatch()

    let Lang = useSelector(state => state.global.lang)
    let Themes = useSelector(state => state.forumAskaquestion.themes)
    let isNewQSaved = useSelector(state => state.forumAskaquestion.isNewQSaved)
    let formData = useSelector(state => state.forumAskaquestion.formData)

    useEffect(() => {
        dispatch(getForumThemesForQuestion(Lang))
    }, [Lang])

    let breadcrumbsItems = [
        {
            slug: '/',
            title: 'Главная',
        },
        {
            slug: '/forum',
            title: 'Форум',
        },
        {
            slug: '',
            title: 'Задать вопрос',
        },
    ]

    let editFormData = ({field, value, action}) => {
        switch (field) {
            case 'images':
                let images = formData?.images ? [...formData?.images] : []
                if (action == 'add') {
                    images.push({
                        "forumQuestionsImageId": value.forumQuestionsImageId,
                        "forumQuestionsId":"",
                        "value":"",
                        "status":"ENABLE"
                    })
                } else if (action == 'update') {
                    images.forEach((img) => {
                        if (img.forumQuestionsImageId == value.forumQuestionsImageId) {
                            img.value = value.imgHash
                        }
                    })
                }
                dispatch(SET_FORMDATA({field: 'images', value: images}))

                break;
        
            default:
                break;
        }
    }

    console.log(formData)

    return (
        <div className="AskaquestionPage">
            <div className="breadcrumbs_">
                <Breadcrumbs
                    items={breadcrumbsItems}
                />
            </div>

            <div className="wrapper">
                <div className="content">
                    <div className="img">
                        <img src={BG} alt="" />
                    </div>

                    <h1>ЗАДАТЬ <span>вопрос</span></h1>
                    <div className="form">
                        <div className="field">
                            <span className="title">Тема вашего вопроса:</span>
                            <select name="questionTheme" onChange={(e) => dispatch(SET_FORMDATA({field: 'forumId', value: e.target.value}))}>
                                <option disabled selected>Выберите тему вопроса</option>
                                {
                                    Themes?.content?.map((theme) => {
                                        return (
                                            <option key={theme.forumId} value={theme.forumId}>{theme.forumName}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="field">
                            <span className="title">Введите заголовк:</span>
                            <input 
                                type="text"
                                onChange={(e) => dispatch(SET_FORMDATA({field: 'forumQuestionLabel', value: e.target.value}))}
                            />
                        </div>
                        <div className="field">
                            <span className="title">Напишите вопрос:</span>
                            <RichText
                                value={""}
                                setValue={(value) => dispatch(SET_FORMDATA({field: 'forumQuestionContent', value: value}))}
                            />
                        </div>
                        <div className="field">
                            <span className="title">Загрузите изображения:</span>
                            <div className="fieldImgContent">
                                {
                                    formData?.images?.map((img) => {
                                        return (
                                            <UploadImg
                                                key={img.forumQuestionsImageId}
                                                item={formData}
                                                userImg={img.value}
                                                imgId={img.forumQuestionsImageId}
                                                getImg={(imgHash, imgId) => {
                                                    console.log(imgHash)
                                                    dispatch(SET_FORMDATA({
                                                        field: 'images',
                                                        value: {
                                                            forumQuestionsImageId: imgId,
                                                            imgHash: imgHash
                                                        },
                                                        action: 'update'
                                                    }))
                                                }}
                                                deleteImg={() => {
                                                    dispatch(SET_FORMDATA({
                                                        field: 'images',
                                                        value: {
                                                            forumQuestionsImageId: img.forumQuestionsImageId
                                                        },
                                                        action: 'delete'
                                                    }))
                                                }}
                                                id={'categImgUpload'}
                                                allowEdit={true}
                                            />
                                        )
                                    })
                                }
                                
                                <button className="addImg"
                                    disabled={false}
                                    onClick={() => {
                                        dispatch(SET_FORMDATA({
                                            field: 'images',
                                            value: {
                                                forumQuestionsImageId: getRandomStr(10)
                                            },
                                            action: 'add'
                                        }))
                                    }}
                                >
                                    <svg
                                        viewBox="0 0 22 22"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <line
                                            x1="11.1382"
                                            y1="1"
                                            x2="11.1382"
                                            y2="21"
                                            stroke="white"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                        />
                                        <line
                                            x1="1"
                                            y1="10.7891"
                                            x2="21"
                                            y2="10.7891"
                                            stroke="white"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <button type="submit" className="submitBtn" onClick={() => dispatch(saveNewQuestion(Lang))}>Задать Вопрос</button>
                        {
                            isNewQSaved == true
                            ?
                            <div className="QuestionSaved">
                                Спасибо! Ваш вопрос сохранен
                            </div>
                            : isNewQSaved == false 
                            &&
                            <div className="QuestionDontSaved">
                                Ошибка! Ваш вопрос не сохранен. Попробуйте еще раз
                            </div>
                        }
                        
                    </div>
                </div>
            </div>
        </div>
    )
}
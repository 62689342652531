import React, { useState } from 'react'
import Breadcrumbs from '../../shared/ui/breadcrumbs/Breadcrumbs'
import "./style.scss"

import mission1 from "../../shared/img/mission1.jpg"
import mission2 from "../../shared/img/mission2.jpg"
import sliderImage1 from "../../shared/img/aboutSliderImages/sliderImage1.jpg"
import sliderImage2 from "../../shared/img/aboutSliderImages/sliderImage2.jpg"
import sliderImage3 from "../../shared/img/aboutSliderImages/sliderImage3.jpg"
import sliderImage4 from "../../shared/img/aboutSliderImages/sliderImage4.jpg"
import missionVideoPreview from "../../shared/img/missionVideoPreview.jpg"
import { Slider1 } from '../../shared/ui/Slider1'
import { useEffect } from 'react'

export default function AboutPage() {

    let breadcrumbsItems = [
        {
            slug: '/',
            title: 'Главная',
        },
        {
            slug: '/about',
            title: 'О нас',
        },
    ]

    let slidesData = [
        {
            id: 1,
            img: sliderImage2,
        },
        {
            id: 2,
            img: sliderImage1,
        },
        {
            id: 3,
            img: sliderImage3,
        },
        {
            id: 4,
            img: sliderImage4,
        },
    ]

    let [slides, setSlides] = useState([])

    useEffect(() => {
        let arr = slidesData.map((slide) => {
            return (
                <div className="slide" key={slide.id}>
                    <img src={slide.img} />
                </div>
            )
        })
        setSlides(arr)
    }, [])

    return (
        <div className="AboutPage">
            <div className="pageWrapper">
                <Breadcrumbs
                    items={breadcrumbsItems}
                />

                <div className="pageContent">
                    <section className="mission">
                        <div className="row">
                            <div className="text">
                                <span>миссия</span>
                                <h3>Наша миссия</h3>
                                <p>В языковой политике современной Гагаузии принципиально важным остается вопрос развития гагаузского языка. Несмотря на то, что гагаузский язык изучается во всех учебных заведениях автономии, владеет им в полной мере лишь четверть населения автономии, хотя говорят на нем более 70 процентов, преимущественно, это те, для кого гагаузский язык является родным. Платформа «DİL.MD» - это уникальная Платформа, благодаря которой Вы сможете получить доступ к различным инструментам, предполагающим текущую оценку знаний, выбор нужной тематики, использования в процессе обучения эффективных тренажеров, аудио и видеоматериалов для изучения гагаузского языка на профессиональном уровне.</p>
                            </div>
                            <div className="imgs">
                                <div className="img">
                                    <img src={mission1} alt="" />
                                </div>
                                <div className="img">
                                    <img src={mission2} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="text">
                                <h3>НИЦ им. М.В. Маруневич.</h3>
                                <p>Научно-Исследовательский Центр Гагаузии им. М.В. Маруневич (далее – Центр), является некоммерческой организацией публичного права в области науки и инноваций. Основной целью деятельности «Центра» является организация и проведение фундаментальных и прикладных научных исследований, направленных на получение новых знаний в области гагаузоведения: истории, этнографии, языкознания, фольклористики, литературоведения и других гуманитарных наук, способствующих социальному, экономическому и культурному развитию АТО Гагаузия.
                                    Устав Центра утвержден Постановлением Исполнительного Комитета                                                                                      Гагаузии (Гагауз Ери) № 12/4 от 08 августа 2011 и зарегистрирован
                                    Государственной регистрационной Палатой Министерства Юстиции                                                                                   Республики Молдова 21 октября 2011 г.
                                    «Центр» осуществляет свою деятельность в соответствии с действующим законодательством Республики Молдова, в том числе нормативными документами, принятыми органами АТО Гагаузия.
                                    «Центр» осуществляет свою деятельность на всей территории Республики Молдова. Продолжительность ее деятельности во времени не ограничена.
                                    Деятельность «Центра» основывается на принципах законности, отчетности, открытости и самостоятельности.
                                </p>
                            </div>
                            <div className="imgs">
                                <div className="video">
                                    <video src={''} poster={missionVideoPreview}></video>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="slider">
                        <Slider1
                            sliderClass={'aboutPageSlider'}
                            slides={slides}
                        />
                    </section>
                </div>
            </div>
        </div>
    )
}
export let COUNTRIES = [
    'Moldova',
    'USA',
    'Ucraine',
    'Gagauzia',
    'Great Britain',
    'Romania',
    'Russia',
    'Germany',
    'Italy'
]
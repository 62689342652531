import {useEffect, useState} from "react";

export const useValidation = (value, validations) => {
	const [isEmptyError, setEmptyError] = useState(true)
	const [isEmailError, setEmailError] = useState(true)
	const [isCheckboxError, setCheckboxError] = useState(true)
	const [minLengthError, setMinLengthError] = useState(true)
	const [inputValid, setInputValid] = useState(false)

	useEffect(() => {
		for (const validation in validations) {
			switch (validation) {
				case "isEmpty":
					value ? setEmptyError(false) : setEmptyError(true)
					break
				case "minLength":
					value.length > validations[validation] ? setMinLengthError(false) : setMinLengthError(true)
					break
				case "isCheckbox":
					value ? setCheckboxError(false) : setCheckboxError(true)
					break
				case "isEmail":
					const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					if (!re.test(String(value).toLowerCase())) {
						setEmailError(true)
					} else {
						setEmailError(false)
					}
					break
			}
		}
	}, [value])

	useEffect(() => {
		if (!isEmptyError || !isEmailError || !isCheckboxError || !minLengthError) {
			setInputValid(false)
		} else {
			setInputValid(true)
		}
	}, [isEmptyError, isEmailError, isCheckboxError, minLengthError])

	return {
		isEmptyError,
		isEmailError,
		isCheckboxError,
		minLengthError,
		inputValid,
	}
}

export const useInput = (initialValue, validations) => {
	const [value, setValue] = useState(initialValue)
	const [isDirty, setDirty] = useState(false)
	const valid = useValidation(value, validations)

	const onChange = (e) => {
		setValue(e.target.value)
	}

	const onCheckboxChange = (e) => {
		setValue(e.target.checked)
	}

	const onBlur = (e) => {
		setDirty(true)
	}

	return {
		value,
		onChange,
		onBlur,
		onCheckboxChange,
		isDirty,
		...valid,
	}
}
import React, { useEffect, useRef, useState } from 'react'
import "./style.scss"

import playBtn from "../../../../shared/img/playBtn.png"
import sound1 from "../../../sounds/sound1.mp3"
import { CircularAudioLoading } from '../../CircularAudioLoading'

export let PlaySound = () => {

    let playRef = useRef(null)
    let audioRef = useRef(null)

    let [progress, setProgress] = useState(0)
    let [isPlaying, setIsPlaying] = useState(false)

    useEffect(() => {
        var context, analyser, src, array, play, audio, startTimeStamp;
        let myArrayBuffer = {}
        let end

        play = playRef.current
        audio = audioRef.current

        play.onclick = function(){
            if(!context){
                preparation();
            }
            let currTime = context.currentTime
            let duration = myArrayBuffer.duration
            end = currTime + duration
            if(audio.paused){
                let playPromise = audio.play();

                if (playPromise !== undefined) {
                    playPromise.then(function(e) {
                        loop();
                        setIsPlaying(true)
                    }).catch(function(error) {
                        console.log(error)
                        // Automatic playback failed.
                        // Show a UI element to let the user manually start playback.
                    });
                }
            }else{
                audio.pause();
            }
        }

        function preparation(){
            context = new AudioContext();
            myArrayBuffer = context.createBuffer(
                2,
                context.sampleRate * 3 / 2,
                context.sampleRate
            );
            
            analyser = context.createAnalyser();
            src = context.createMediaElementSource(audio);
            src.connect(analyser);
            analyser.connect(context.destination);
            loop();
        }

        function loop(){ 
            if(!audio.paused){
                window.requestAnimationFrame(loop);
            }
            let currTime = context.currentTime
            let duration = myArrayBuffer.duration
            if (currTime >= end) {
                setProgress(0)
                setIsPlaying(false)
            } else {
                let prog = (duration - (end - currTime)) / duration * 100
                setProgress(prog)
            }
            array = new Uint8Array(analyser.frequencyBinCount);
            analyser.getByteFrequencyData(array);
            play.minHeight = (array[40])+"px";
            play.width =  (array[40])+"px";
        }
    }, [playRef, audioRef])

    return (
        <div className="PlaySound">
            <div className="progress">
                <CircularAudioLoading
                    strokeWidth={2}
                    sqSize={98}
                    color={'#2ECC71'}
                    progress={progress}
                />
            </div>
            <div ref={playRef} className="btn">
                <img src={playBtn} alt="" />
            </div>
            <audio src={sound1} ref={audioRef} />
            <span>{isPlaying ? 'Слышите ли вы звук?' : 'Воспроизвести звук'}</span>
        </div>
    )
}
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router"
import { getCookie, parseJwt } from "./index"
import { SET_SIGNIN } from "../../store/registrationForm/registrationFormSlice"
import { SET_LOGGEDUSER } from "../../store/globalSlice"
import { getUserByTokenFromDB } from "../../api/auth"



export function useCheckAuth() {

    let navigate = useNavigate()
    let dispatch = useDispatch()
    let location = useLocation()

    useEffect(() => {
        async function fetchData() {
            let token = getCookie('userToken')
            let user = await getUserByTokenFromDB(token)
            if (user) {
                dispatch(SET_LOGGEDUSER(user))
            } else {
                dispatch(SET_LOGGEDUSER(null))
                dispatch(SET_SIGNIN(true))
                navigate('/')
            }
        }
        fetchData();
    }, [location])
}
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { daysInMonth } from "../../../shared/functions";
import { MuiCalendar } from "../../../shared/ui/MuiCalendar";
import { enrollUserDateToDB, getDatesFromServer, getExamTypesFromServer, getTimesForCurrDayFromServer, SET_MEETINGSID, SET_MEETINGTYPEID, SET_SELECTEDDAYPERIOD, SET_SELECTEDMONTHPERIOD } from "../../../store/examCalendar/examCalendarSlice";
import "./style.scss"

export default function ExamPage() {

	let dispatch = useDispatch()

	let Lang = useSelector(state => state.global.lang)
	let currMonthDates = useSelector(state => state.examCalendar.currMonthDates)
	let currDayTimes = useSelector(state => state.examCalendar.currDayTimes)
	let examTypes = useSelector(state => state.examCalendar.examTypes)
	let meetingsId = useSelector(state => state.examCalendar.meetingsId)

	console.log(examTypes, meetingsId)

	useEffect(() => {
		dispatch(getDatesFromServer(Lang))
		dispatch(getExamTypesFromServer(Lang))
	}, [Lang])

	let daysRef = useRef(null)
	let [CurrYear, setCurrYear] = useState(new Date().getFullYear())
	let [CurrMonth, setCurrMonth] = useState(new Date().getMonth() + 1)
	let [CurrDay, setCurrDay] = useState(new Date().getDate())

	// Делаем запрос на получение записей когда пользователь выбирает дату
	useEffect(() => {
		let today = new Date(new Date().setFullYear(CurrYear, CurrMonth - 1, CurrDay))
		let todayTime = today.getHours() * 60 * 60 * 1000 + today.getMinutes() * 60 * 1000 + today.getSeconds() * 1000
		let dateStart = new Date(today.getTime() - todayTime).toISOString()
		let dateEnd = new Date(new Date(dateStart).getTime() + 24 * 60 * 60 * 1000).toISOString()

		dispatch(SET_SELECTEDDAYPERIOD([
			{
				"key": "meetingDate",
				"operation": ">=",
				"value": dateStart.substring(0, 19)
			},
			{
				"key": "meetingDate",
				"operation": "<=",
				"value": dateEnd.substring(0, 19)
			}
		]))
		dispatch(getTimesForCurrDayFromServer(Lang))
		dispatch(getExamTypesFromServer(Lang))
		dispatch(SET_MEETINGSID(null))
	}, [CurrYear, CurrMonth, CurrDay])

	// Делаем запрос на получение записей когда пользователь меняет месяц или год
	useEffect(() => {
		let dateStart = new Date(new Date().setFullYear(CurrYear, CurrMonth - 1, 1))
		let time = dateStart.getHours() * 60 * 60 * 1000 + dateStart.getMinutes() * 60 * 1000 + dateStart.getSeconds() * 1000
		dateStart = new Date(dateStart.getTime() - time).toISOString()
		let daysCount = daysInMonth(CurrMonth, CurrYear)
		let dateEnd = new Date(new Date(dateStart).getTime() + daysCount * 24 * 60 * 60 * 1000).toISOString()

		dispatch(SET_SELECTEDMONTHPERIOD([
			{
				"key": "meetingDate",
				"operation": ">=",
				"value": dateStart.substring(0, 19)
			},
			{
				"key": "meetingDate",
				"operation": "<=",
				"value": dateEnd.substring(0, 19)
			}
		]))
		dispatch(getDatesFromServer(Lang))
	}, [CurrYear, CurrMonth])

	// определяет свободные и доступные дни
	useEffect(() => {
		let allDaysArr = daysRef.current.querySelectorAll('.examCalendar .react-calendar__month-view__days .react-calendar__tile')
		let daysArr = Object.values(allDaysArr).filter((day) => !day.classList.contains('react-calendar__month-view__days__day--neighboringMonth'))

		daysArr.forEach((day) => {
			let currDayNum = (day.querySelector('abbr') == null) ? day.textContent : day.querySelector('abbr').textContent
			day.innerHTML = currDayNum
			currDayNum = (currDayNum.length == 1) ? ('0' + currDayNum) : currDayNum
			let thisDayTimes = []
			currMonthDates?.forEach((date) => {
				if (currDayNum == new Date(date.meetingDate).getDate()) {
					thisDayTimes.push(date)
				}
			})

			if (day.classList.contains('free')) {
				day.classList.remove('free')
			}
			if (day.classList.contains('busy')) {
				day.classList.remove('busy')
				day.disabled = false
				day.style.opacity = 1
			}

			if (thisDayTimes.length == 0) {
				day.classList.add('busy')
				day.disabled = true
				day.style.opacity = 0.5
				day.innerHTML = currDayNum
			} else {
				let busyTimesCount = 0
				thisDayTimes.forEach((oneTime) => {
					// if (oneTime.activeCount >= availableTimes.length) {
					if (oneTime.activeCount == oneTime.maxCount) {
						busyTimesCount++
					}
				})
				if (busyTimesCount == thisDayTimes.length) {
					day.classList.add('busy')
					day.disabled = true
					day.style.opacity = 0.5
					day.innerHTML = currDayNum
				} else {
					day.classList.add('free')
				}
				/* if (thisDayTimes.length == availableTimes.length) {
					
				} else {
					day.classList.add('free')
				} */
			}
		})
	}, [currMonthDates, examTypes, CurrMonth])

	let [enrollUserError, setEnrollUserError] = useState(false)
	let enrollUser = () => {
		dispatch(enrollUserDateToDB())
	}

	return (
		<div className="ExamPage">
			<div className="examInfo">
				<div className="left">
					<h2>Где пройти экзамен ?</h2>
					<p>Gagauz dilinin kullanmak sferalarını genişletmäk hem avtonomiyada gagauz dilinnän şkola öncesi terbietmek sistemasında çalışan personalın profesional-teoretik uurunu kaldırmak, birleştireräk çalışmakları, aniki çözmää deyni avtonomiyanın önündä duran önemni daavaları millet-kultura strategiyaların, avtonomiyanın bilim hem üüretim-üüredici potenţialın gerçekleştirilmesindä girdireräk eni bilim hem.Gagauz dilinin kullanmak sferalarını genişletmäk hem avtonomiyada gagauz dilinnän şkola öncesi terbietmek sistemasında çalışan personalın profesional-teoretik uurunu kaldırmak, birleştireräk çalışmakları.</p>
					<div className="contactInfo">
						<div className="item">
							<div className="icon">
								<svg width="23" height="31" viewBox="0 0 23 31" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M11.5019 0C5.14931 0 0 5.12297 0 11.4412C0 14.119 0.909472 16.1857 3.00629 19.3105C5.10141 22.4352 11.5019 31 11.5019 31C11.5019 31 17.9025 22.4372 19.9937 19.3105C22.0923 16.1874 23 14.119 23 11.4412C23.0002 5.12297 17.8509 0 11.5019 0ZM11.5019 15.7669C9.10065 15.7669 7.15412 13.8303 7.15412 11.4414C7.15412 9.05586 9.10065 7.11582 11.5019 7.11582C13.9031 7.11582 15.8497 9.0558 15.8497 11.4414C15.8497 13.8303 13.9031 15.7669 11.5019 15.7669Z" fill="#2ECC71"/>
								</svg>
							</div>
							<span>АТО Гагаузия, г.Комрат, ул.Победа 18/A</span>
						</div>
						<div className="item">
							<div className="icon">
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M18.2463 15.8771C17.4622 15.103 16.4833 15.103 15.7042 15.8771C15.1099 16.4664 14.5156 17.0557 13.9313 17.655C13.7715 17.8198 13.6366 17.8548 13.4418 17.7449C13.0573 17.5352 12.6478 17.3654 12.2782 17.1356C10.5552 16.0519 9.11186 14.6585 7.83334 13.0903C7.19907 12.3112 6.63473 11.4772 6.24018 10.5383C6.16028 10.3485 6.17526 10.2236 6.33008 10.0688C6.92439 9.49449 7.50372 8.90517 8.08804 8.31585C8.9021 7.4968 8.9021 6.53791 8.08305 5.71386C7.61859 5.24441 7.15412 4.78494 6.68966 4.31548C6.21022 3.83604 5.73577 3.3516 5.25133 2.87715C4.46724 2.11304 3.48837 2.11304 2.70927 2.88215C2.10997 3.47146 1.53563 4.07576 0.92634 4.65509C0.361994 5.18947 0.0773235 5.84371 0.017393 6.60783C-0.0774971 7.85139 0.22715 9.02503 0.656652 10.1687C1.53563 12.536 2.87408 14.6385 4.4972 16.5663C6.68966 19.1733 9.30663 21.2359 12.3681 22.7242C13.7465 23.3934 15.1748 23.9078 16.728 23.9927C17.7968 24.0526 18.7257 23.7829 19.4699 22.9489C19.9793 22.3796 20.5536 21.8602 21.093 21.3158C21.892 20.5067 21.897 19.5279 21.103 18.7288C20.1541 17.7749 19.2002 16.826 18.2463 15.8771Z" fill="#2ECC71"/>
									<path d="M17.2926 11.8967L19.1355 11.5821C18.8458 9.88902 18.0468 8.3558 16.8332 7.13721C15.5497 5.8537 13.9265 5.04463 12.1386 4.79492L11.8789 6.64778C13.2623 6.84255 14.5208 7.46683 15.5147 8.46068C16.4536 9.39959 17.0679 10.5882 17.2926 11.8967Z" fill="#2ECC71"/>
									<path d="M20.1737 3.8855C18.0462 1.75796 15.3543 0.41452 12.3827 0L12.123 1.85285C14.6901 2.21244 17.0174 3.37609 18.8552 5.20897C20.5982 6.95195 21.7419 9.1544 22.1564 11.5766L23.9993 11.262C23.5148 8.45521 22.1914 5.90816 20.1737 3.8855Z" fill="#2ECC71"/>
								</svg>
							</div>
							<a href="tel:+37379885558">
								<span>+373-79-885-558</span>
							</a>
						</div>
					</div>
				</div>
				<div className="right">
					<div className="map">
						<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d5512.956490488919!2d28.652957111118663!3d46.30033878063685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sru!2s!4v1677755641130!5m2!1sru!2s" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
					</div>
				</div>
			</div>
			<div className="enrollToExamWrapper">
				<div className="enrollToExamTitle">
					<h5>Записаться на экзамен</h5>
					<div className="icon">
						<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
							<circle cx="10.5" cy="10.5" r="10" stroke="#2ECC71"/>
							<path d="M6 9L10.5 13.5L15 9" stroke="#2ECC71" strokeLinecap="round"/>
						</svg>
					</div>
				</div>
				<div className="choozeExamType">
					<h4 className="formTitle">Выберите тип экзамена :</h4>
					<select 
						className="examTypeSelect" 
						name="examType"
						onChange={(e) => {
							dispatch(SET_MEETINGTYPEID([
								{
									key: 'meetingTypeId',
									value: e.target.value
								}
							]))
							dispatch(SET_MEETINGSID(null))
							dispatch(getDatesFromServer(Lang))
							dispatch(getTimesForCurrDayFromServer(Lang))
						}}
					>
						<option selected={true} disabled="disabled">Выберите экзамен</option>
						{
							examTypes?.map((item) => {
								return <option key={item.meetingTypeLanguageId} value={item.meetingTypeLanguageId}>{item.value}</option>
							})
						}
					</select>
				</div>
				<div className="choozeExamDate">
					<h4 className="formTitle">Выберите дату экзамена :</h4>
					<div ref={daysRef} className="calendarWrapper">
						<MuiCalendar
							CalendarClass={'examCalendar'}
							onChangeDate={(date) => {
								setCurrDay(date.getDate())
							}}
							onRangePositionChange={(RangePosition) => {
								console.log(RangePosition)
							}}
							getCurrMonth={setCurrMonth}
							getCurrYear={setCurrYear}
						/>
					</div>
				</div>
				<div className="choozeExamTime">
					<h4 className="formTitle">Выберите время экзамена :</h4>
					<div className="timeWrapper">
						{
							currDayTimes.length > 0 ?
							currDayTimes?.map((time) => {
								let h = new Date(time.meetingDate).getHours()
								let m = new Date(time.meetingDate).getMinutes()
								return (
									<button 
										key={time.meetingsId}
										className={"timeBtn" + (time.activeCount >= 0 ? "" : " busy") + (meetingsId == time.meetingsId ? " selected" : "")}
										disabled={!(time.activeCount < time.maxCount)}
										onClick={() => dispatch(SET_MEETINGSID(time.meetingsId))}
									>{h}:{m}</button>
								)
							})
							:
							<p>Нет доступного времени</p>
						}
					</div>
				</div>
				<button 
					className="enrollToExamBtn"
					onClick={() => enrollUser()}
				>Записаться на экзамен</button>
				{
					enrollUserError ?
						<div className="enrollUserError">Заполните пожадуйста все поля!!!</div>
					: ""
				}
			</div>
		</div>
	)
}
import React, { useEffect, useState } from 'react'
import "./style.scss"

export let AnswerItem = ({ answer, inputType, getAnswer, radioAnswer, selected, allowClick, isTrue, showAnswer }) => {

    let [isChecked, setIsChecked] = useState(false)

    useEffect(() => {
        /* if (radioAnswer !== null) {
            if (radioAnswer.testAnswerId == answer.testAnswerId) {
                setIsChecked(true)
            } else {
                setIsChecked(false)
            }
        } */
        setIsChecked(radioAnswer)
    }, [radioAnswer])

    /* useEffect(() => {
        if (showAnswer) {
            setIsChecked(showAnswer)
        }
    }, [showAnswer]) */

    return (
        <div className={"AnswerItem " + ((isChecked || selected) ? "checked" : "")}
            style={{
                opacity: ((allowClick) ? 1 : 0.5),
                cursor: ((allowClick) ? "pointer" : "default"),
                backgroundColor: ((selected && showAnswer) ? ((isTrue) ? "#2ECC71" : "#FF2323") : "")
            }}
            onClick={() => {
                if (allowClick) {
                    if (inputType == 'CHECKBOX') {
                        let a = !isChecked
                        let obj = {
                            action: a ? 'add' : 'delete',
                            answer: answer,
                        }
                        setIsChecked(a)
                        getAnswer(obj)
                    } else if (inputType == 'radio') {
                        getAnswer(answer)
                    }
                }
            }}
        >
            {
                inputType == 'radio'
                ?
                <div className="radio"></div>
                : inputType == 'CHECKBOX'
                &&
                <div className="checkbox">
                    <div className="checkMark">
                        <svg viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 8.25417L4.00037 12.2539L12.5 0.748047" stroke="black" strokeLinecap="round"/>
                        </svg>
                    </div>
                </div>
            }
            <span>{answer.value}</span>
        </div>
    )
}
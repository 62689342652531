import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { getCookie, getQuestionId, isAnswerCorrect, setCookie } from '../../../shared/functions'
import { useCheckAuth } from '../../../shared/functions/useCheckAuth'
import { QuestionAnswer } from '../../../shared/ui/QuestionAnswer'
import { TestAudio } from '../../../shared/ui/TestAudio'
import { TestTimerWrapper } from '../../../shared/ui/TestTimerWrapper'
import { getTest, SET_TESTTIME, UPDATEANSWERED_FLAG, UPDATEANSWER_TEST, UPDATEUDIOAMOUNT } from '../../../store/test/testSlice'
import sound1 from "../../../shared/sounds/sound1.mp3"
import "./style.scss"




export default function TestQuestions() {

    let params = useParams()
    let dispatch = useDispatch()
    let navigate = useNavigate()
    let QLineRef = useRef(null)
    
    let Lang = useSelector((state) => state.global.lang)
    let test = useSelector((state) => state.test.test)
    let testTime = useSelector((state) => state.test.testTime)

    useEffect(() => {
        dispatch(getTest(params.test_id, Lang))
    }, [Lang])

    let [currQuestion, setCurrQuestion] = useState()
    let [currChapter, setCurrChapter] = useState()
    let [currQLineWidth, setCurrQLineWidth] = useState(0)
    let [currI, setCurrI] = useState()

    useEffect(() => {
        if (params.id) {
            let chapter = params.id.split('_')[0]
            setCurrChapter(chapter)
            let id = params.id.split('_')[1]
            test?.[chapter]?.map((item) => {
                if (item.testQuestionId == id) {
                    setCurrQuestion(item)
                }
            })
        } else {
            if (test?.reading?.length > 0) {
                console.log(test)
                navigate('reading_' + test?.reading[0].testQuestionId)
            }
        }
        
    }, [test, params.id])

    let [audioAmount, setAudioAmount] = useState()
    let updateAudioAmount = (value) => {
        dispatch(UPDATEUDIOAMOUNT({audioAmount: value, currChapter, currQuestion}))
    }
    useEffect(() => {
        dispatch(UPDATEUDIOAMOUNT({audioAmount: 1, currChapter, currQuestion}))
    }, [audioAmount])

    let checkQuestionAnswer = () => {
        console.log(currQuestion)
        if (currQuestion.userAnswer) {
            if (currQuestion.userAnswer.length == 0) {
                dispatch(UPDATEANSWERED_FLAG({answered: false, currChapter, currQuestion}))
            } else {
                dispatch(UPDATEANSWERED_FLAG({answered: true, currChapter, currQuestion}))
            }
        }
    }
    let updateAnswer = (answer) => {
        console.log(answer, currChapter)
        dispatch(UPDATEANSWER_TEST({answer, currChapter, currQuestion}))
    }

    useEffect(() => {
        let allQ = test?.reading?.concat(test?.listening)
        let currI
        allQ?.forEach((elem, i) => {
            if (elem?.testQuestionId == 
                currQuestion?.testQuestionId) {
                currI = i + 1
                setCurrI(currI)
            }
        });
        let Llength = QLineRef.current.clientWidth
        let currQLength = currI / allQ?.length * Llength
        setCurrQLineWidth(currQLength)
    }, [currQuestion, test])

    let saveTimeToCookie = (time) => {
        setCookie('testTime', 'start:' + time?.start + '_end:' + time?.end, time?.end)
    }

    useEffect(() => {
        let timeFromCookie = getCookie('testTime')
        if (timeFromCookie) {
            let arr = timeFromCookie.split('_')
            let start = arr[0].split(':')[1]
            let end = arr[1].split(':')[1]
            let obj = {
                start,
                end,
            }
            dispatch(SET_TESTTIME(obj))
        } else {
            if (test?.testTime) {
                let now = Date.now()
                let obj = {
                    start: now,
                    end: now + test?.testTime * 60 * 1000,
                }
                saveTimeToCookie(obj)
                dispatch(SET_TESTTIME(obj)) 
            }
        }
    }, [test])

    console.log(test)

    return (
        <div className="TestQuestions">
            <div className="TestQuestions_header">
                <div className="logo">HIZLI CONTROL</div>
                <div className="questionsLine" ref={QLineRef}>
                    <div className="innerLine" style={{ width: ((typeof currQLineWidth == 'number') ? currQLineWidth : 0) }}></div>
                </div>
                <TestTimerWrapper
                    testTime={(testTime.start || testTime.end) ? testTime : 0}
                />
            </div>
            <div className="TestContent">
                <div className="left">
                    <div className="top">Разделы</div>
                    <div className="content">
                        {
                            test ?
                            Object.entries(test)?.map((item) => {
                                let elems
                                if (item[0] == 'reading' || item[0] == 'listening') {
                                    return (
                                        <div key={item[0]} className={"row " + ((item[0] == currChapter) ? "selected" : "")}>
                                            <div className="icon">
                                                <svg viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M20.5929 2.22056C20.5916 2.22056 20.5902 2.22056 20.5889 2.22056H19.209V1.74838C19.2164 1.56149 19.0955 1.39353 18.9159 1.34132C17.9579 1.09027 16.9714 0.964389 15.981 0.966832C14.3528 0.966832 12.1343 1.33318 10.4939 3.00211C8.86567 1.3169 6.63094 0.966832 5.00678 0.966832C4.01642 0.964389 3.02992 1.09027 2.07191 1.34132C1.8923 1.39353 1.7714 1.56149 1.77883 1.74838V2.21649H0.407056C0.182259 2.21649 0 2.39875 0 2.62355V15.6249C0 15.8497 0.182259 16.032 0.407056 16.032C0.476967 16.0318 0.545658 16.0135 0.606513 15.979C0.647218 15.9587 4.71777 13.7321 10.4003 15.1649H10.4613H10.498C10.5304 15.1688 10.5632 15.1688 10.5957 15.1649C16.2944 13.7199 20.365 15.9424 20.3894 15.979C20.5153 16.0518 20.6705 16.0518 20.7965 15.979C20.9216 15.9068 20.9991 15.7735 21 15.629V2.62762C21 2.40282 20.8178 2.22056 20.5929 2.22056ZM0.814111 14.9777V3.03467H1.78697V13.2436C1.78443 13.4684 1.9646 13.6527 2.1894 13.6553C2.21138 13.6555 2.23336 13.654 2.25509 13.6507C3.09718 13.5221 3.94737 13.4541 4.79919 13.4472C5.97914 13.4267 7.15416 13.6042 8.27544 13.9723C5.7433 13.6661 3.17488 14.0122 0.814111 14.9777ZM4.79919 12.6371C4.0588 12.6388 3.31928 12.6864 2.5848 12.7796V2.06995C3.37734 1.89339 4.18667 1.80328 4.99864 1.8013C6.52103 1.8013 8.62144 2.15136 10.0787 3.79587L10.0909 13.9356C9.24423 13.382 7.59159 12.6371 4.79919 12.6371ZM10.905 3.79587C12.3623 2.16765 14.4627 1.8013 15.9851 1.8013C16.797 1.80369 17.6063 1.89375 18.3989 2.06995V12.7796C17.6686 12.6855 16.9331 12.6366 16.1967 12.633C13.4206 12.633 11.7639 13.378 10.905 13.9316V3.79587ZM20.1818 14.9777C17.816 14.0095 15.2417 13.6634 12.7042 13.9723C13.8308 13.6029 15.0114 13.4253 16.1967 13.4472C17.0539 13.4519 17.9096 13.5186 18.7571 13.6466C18.9794 13.6803 19.1869 13.5275 19.2207 13.3052C19.224 13.2835 19.2255 13.2615 19.2252 13.2396V3.03467H20.1981L20.1818 14.9777Z" />
                                                </svg>
                                            </div>
                                            <span>{item[0] == 'reading' ? 'Чтение' : item[0] == 'listening' && 'Аудирование'}</span>
                                        </div>
                                    )
                                }
                                return elems
                            })
                            : ""
                        }
                    </div>
                    <div className="bottom"><span>{currChapter == 'reading' ? 1 : 2}</span> из 2</div>
                </div>
                <div className="right mobileRight">
                    <div className="top">Таблица вопросов</div>
                    <div className="content">
                        {
                            test ?
                            Object.entries(test)?.map((item) => {
                                let elems
                                if (item[0] == currChapter) {
                                    elems = item[1]?.map((question) => {
                                        return (
                                            <div key={question.testQuestionId} className={"row " + ((currQuestion?.testQuestionId == question.testQuestionId) ? "selected" : "")}>
                                                <div className={
                                                    "questionIcon " + 
                                                    ((question.answered)
                                                        ?
                                                        (isAnswerCorrect(question)) ? "true" : "false"
                                                        : ""
                                                    )
                                                }></div>
                                                <span>{question.value}</span>
                                            </div>
                                        )
                                    })
                                }
                                return elems
                            })
                            : ""
                        }
                    </div>
                </div>
                <div className="center">
                    <div className="top">
                        <span>Вопрос {currI} - </span>
                        {currQuestion?.value}
                    </div>
                    <div className="questionContent">
                        {
                            currChapter == 'listening' 
                            ?
                            <TestAudio
                                audioFile={sound1}
                                canPlay={(currQuestion?.audioAmount < 2 || !currQuestion?.audioAmount) ? true : false}
                                played={(value) => setAudioAmount(value)}
                                audioAmount={(currQuestion?.audioAmount) ? (currQuestion?.audioAmount - 2) * (-1) : 2}
                            />
                            : ""
                        }
                        <div className="text">
                            {currQuestion?.text}
                        </div>
                        <QuestionAnswer
                            currQuestion={currQuestion}
                            answersArr={currQuestion?.variants}
                            inputType={currQuestion?.questionType}
                            setUserAnswer={updateAnswer}
                            userAnswer={currQuestion?.userAnswer}
                            allowClick={!currQuestion?.answered}
                            showAnswer={currQuestion?.answered}
                        />
                    </div>
                    <div className="btns">
                        <button
                            onClick={() => {
                                let prev = getQuestionId(test, params.id, 'prev')
                                if (prev) {
                                    checkQuestionAnswer()
                                    navigate('/test/' + params.test_id + '/questions/' + prev)
                                }
                            }}
                        >Предыдущий</button>
                        {
                            (test?.reading?.concat(test?.listening).length == currI) ?
                            <button
                                onClick={() => {
                                    checkQuestionAnswer()
                                    navigate('/test/' + params.test_id + '/form')
                                }}
                            >Закончить тест</button>
                            :
                            <button
                                onClick={() => {
                                    let next = getQuestionId(test, params.id, 'next')
                                    if (next) {
                                        checkQuestionAnswer()
                                        navigate('/test/' + params.test_id + '/questions/' + next)
                                    }
                                }}
                            >Следующий</button>
                        }
                    </div>
                </div>
                <div className="right">
                    <div className="top">Таблица вопросов</div>
                    <div className="content">
                        {
                            test ?
                            Object.entries(test)?.map((item) => {
                                let elems
                                if (item[0] == currChapter) {
                                    elems = item[1]?.map((question) => {
                                        return (
                                            <div key={question.testQuestionId} className={"row " + ((currQuestion?.testQuestionId == question.testQuestionId) ? "selected" : "")}>
                                                <div className={
                                                    "questionIcon " + 
                                                    ((question.answered)
                                                        ?
                                                        (isAnswerCorrect(question)) ? "true" : "false"
                                                        : ""
                                                    )
                                                }></div>
                                                <span>{question.value}</span>
                                            </div>
                                        )
                                    })
                                }
                                return elems
                            })
                            : ""
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
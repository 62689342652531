import http from "./index"

export let getQuickCheckInfoFromBD = (id, lang) => {
    return http.get(
        `/time-test-type/` + id,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                "languageCode": lang
            }
        }
    )
    .then(function (response) {
        // handle success
        // console.log(response.data[0])
        if (response.data) {
            return response.data
        }
    })
    .catch(function (error) {
        // handle error
        console.log(error)
    })
    .finally(function () {
        // always executed

    });
}
export let getTestFromBD = (id, lang) => {
    return http.get(
        `/test/test-time/` + id,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                "languageCode": lang
            }
        }
    )
    .then(function (response) {
        // handle success
        // console.log(response.data[0])
        if (response.data) {
            return response.data
        }
    })
    .catch(function (error) {
        // handle error
        console.log(error)
    })
    .finally(function () {
        // always executed

    });
}
export let getCurrTestByIdFromBD = (id, lang) => {
    return http.get(
        `/test/` + id,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                "languageCode": lang
            }
        }
    )
    .then(function (response) {
        // handle success
        // console.log(response.data[0])
        if (response.data) {
            return response.data
        }
    })
    .catch(function (error) {
        // handle error
        console.log(error)
    })
    .finally(function () {
        // always executed

    });
}
export let saveUserTestResultsToBD = (params) => {
    console.log(params)
    return http.post(
        `/account/set-result`,
        params,
        {
            headers: {
                'Access-Control-Allow-Origin': '*'
            }
        }
    )
    .then(function (response) {
        // handle success
        // console.log(response.data[0])
        if (response.data) {
            return response.data
        }
    })
    .catch(function (error) {
        // handle error
        console.log(error)
    })
    .finally(function () {
        // always executed

    });
}
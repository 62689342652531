import React, { useEffect, useState } from 'react'
import { getTimeByTimeStamp } from '../../../functions'
import { TestPlaySound } from '../../TestPlaySound'
import "./style.scss"

export let TestAudio = ({ audioFile, canPlay, played, audioAmount }) => {

    let [TimeLine, setTimeLine] = useState({
        duration: 0,
        currTime: 0
    })
    let [TimeLineStr, setTimeLineStr] = useState()

    useEffect(() => {
        let a = getTimeByTimeStamp(TimeLine)
        setTimeLineStr(a)
    }, [TimeLine])

    useEffect(() => {
        var audio = new Audio();
        audio.src = audioFile;

        audio.onloadedmetadata = function() {
            setTimeLine({
                duration: audio.duration,
                currTime: 0
            })
        };
    }, [audioFile])

    return (
        <div className="TestAudio">
            <div className="play">
                <TestPlaySound
                    audioFile={audioFile}
                    getTimeLine={(timeLine) => {
                        setTimeLine(timeLine)
                    }}
                    played={played}
                    canPlay={canPlay}
                />
            </div>
            <div className="audioText">
                <div className="title">Кол-во воспроизведений : <span>{audioAmount}</span></div>
                <div className="length">{TimeLineStr}</div>
            </div>
        </div>
    )
}
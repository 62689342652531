import React from "react";

import StudentsTable from "./StudentsTable/StudentsTable";
import StudentsSearch from "./StudentsSearch/StudentsSearch";

import "./style.scss"

export default function StudentsPage() {

	return (
		<div className="students-page">
			<div className="students-page__search">
				<StudentsSearch />
			</div>
			<div className="students-page__table">
				<StudentsTable />
			</div>
		</div>
	)
}
import React from "react";
import Breadcrumbs from "../../shared/ui/breadcrumbs/Breadcrumbs";

import "./style.scss"
import AntdAccordion from "../../shared/ui/AntdAccordion";

export default function FaqPage() {

	let breadcrumbsItems = [
		{
			slug: '/',
			title: 'Главная',
		},
		{
			slug: '/faq',
			title: 'FAQ',
		},
	]

	return (
		<div className="faq_wrapper">
			<div className="faq_breadcrumbs">
				<Breadcrumbs items={breadcrumbsItems}/>
			</div>
			<div className="faq_bg">
				Часто задаваемые вопросы
			</div>
			<div className="faq_content">
				<div className="faq_content_accordion">
					<h2 className="faq_content_accordion_title">О платформе DIL</h2>
					<AntdAccordion
						className={'FaqPageAccordion'}
						data={[
							{
								title: '1.	Что такое платформа DİL.MD?',
								text: 'Платформа «DİL.MD» - это уникальная Платформа, благодаря которой Вы сможете получить доступ к различным инструментам, предполагающим текущую оценку знаний, выбор нужной тематики, использования в процессе обучения эффективных тренажеров, аудио и видеоматериалов для изучения гагаузского языка на профессиональном уровне.',
							},
							{
								title: '2.	Какую цель преследует эта платформа?',
								text: 'Если вы поставили перед собой твердую цель выучить гагаузский язык и предпочитаете заниматься самостоятельно (или не можете позволить себе оплату преподавателю), и в то же время хотите попрактиковаться в живом общении с носителями языка или другими людьми, изучающими этот язык, то представленные в обзоре платформа и онлайн площадки для самостоятельного изучения гагаузского языка будут наиболее оптимальным вариантом для вас. ',
							},
							{
								title: '3.	Что предоставляет платформа DİL.MD?',
								text: 'Вам предлагается самостоятельно изучить учебные и дидактические материалы, получать от администратора Платформы советы по улучшению навыков владения гагаузским языком и многое другое.',
							},
							{
								title: '4.	В каком формате проходят занятия?',
								text: 'Изучение гагаузского языка проходит по двум уровням А1 и А2. Занятия проводятся квалифицированными преподавателями в онлайн формате. По окончании уровней А1 и А2 будет продолжено изучение языка по уровням В1 и В2.',
							},
							{
								title: '5.	С каким уровнем знания гагаузского языка лучше приходить на занятия?',
								text: 'С любым! Лучших результатов добиваются пользователи с начальным и средним уровнем языка (А1-В1). Владеющие уровнем С1 либо С2 также смогут узнать много нового.',
							},
						]}
					/>
				</div>
			</div>
		</div>
	)
}
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {useNavigate} from "react-router-dom";
import moment from "moment";

import {useCheckAuth} from "../../shared/functions/useCheckAuth";

import TwitterImg from "../../shared/ui/img/SingleQuestionPage/twitterImg.svg"
import FacebookImg from "../../shared/ui/img/SingleQuestionPage/facebookImg.svg"
import InstagramImg from "../../shared/ui/img/SingleQuestionPage/instagramImg.svg"
import LinkedinImg from "../../shared/ui/img/SingleQuestionPage/linkedinImg.svg"
import ButtonImg from "../../shared/ui/img/SingleQuestionPage/buttonImg.svg"
import QuestionImg from "../../shared/ui/img/SingleQuestionPage/questionImg.png"
import DateIcon from "../../shared/ui/img/SingleQuestionPage/dateIcon.svg"
import UserIcon from "../../shared/ui/img/SingleQuestionPage/userIcon.svg"
import FolderIcon from "../../shared/ui/img/SingleQuestionPage/folderIcon.svg"

import {AnswerButton} from "../../shared/ui/AnswerButton";
import {CollapseButton} from "../../shared/ui/CollapseButton";
import {ShareBlock} from "../../shared/ui/ShareBlock";
import {AnswerBlock} from "../../shared/ui/AnswerBlock";
import UploadImg from "../../shared/ui/UploadImg/UploadImg";
import RichText from "../../shared/ui/richText/RichText";

import Breadcrumbs from "../../shared/ui/breadcrumbs/Breadcrumbs";

import {useDispatch, useSelector} from "react-redux";
import {getDateByTimeStamp, getRandomStr} from "../../shared/functions";
import {getComments, saveNewComment, SET_FORMDATA, SET_FORMDATATOEMPTY} from "../../store/forum/commentsSlice";
import {getQuestionFromDB} from "../../api/forum";


import "./style.scss"


export default function SingleQuestionPage() {

	// Проверка залогинен пользователь или нет
	useCheckAuth()

	let params = useParams()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const comments = useSelector(state => state.comments.comments.content)
	const formData = useSelector(state => state.comments.formData)
	const loadingComments = useSelector(state => state.comments.loading)

	const [showAnswerForm, setShowAnswerForm] = useState(false)
	const [loadingAnswer, setLoadingAnswer] = useState(false)
	const [singleQuestion, setSingleQuestion] = useState({})
	const [loadingQuestion, setLoadingQuestion] = useState(true)

	useEffect( () => {
		async function getSingleQuestion() {
			const response = await getQuestionFromDB(params.question_id.split('_')[1])
			setSingleQuestion(response)
			setLoadingQuestion(false)
		}
		getSingleQuestion();
	}, [params.question_id.split('_')[1]])

	useEffect(() => {
		dispatch(getComments(params.question_id.split('_')[1]))
	}, [loadingAnswer])


	const [showContent, setShowContent] = useState(true)
	let [breadcrumbsItems, setBreadcrumbsItems] = useState([
		{
			slug: '/',
			title: 'Главная',
		},
		{
			slug: '/forum',
			title: 'Форум',
		},
		{
			slug: '/forum/' + params.theme_id,
			title: singleQuestion?.forumName, // Тема форума
		},
		{
			slug: '',
			title: singleQuestion?.forumQuestionLabel,
		},
	])

	useEffect(() => {
		let arr = [...breadcrumbsItems]
		arr[3].title = singleQuestion?.forumQuestionLabel
		arr[2].title = singleQuestion?.forumName
		setBreadcrumbsItems(arr)
	}, [params.question_id, params.theme_id, singleQuestion])


	return (
		<div className="singleQuestion_wrapper">
			<div className="singleQuestion_breadcrumbs">
				<Breadcrumbs items={breadcrumbsItems}/>
			</div>
			{loadingQuestion ? 'загрузка' :
				<>
					<div className="singleQuestion_bg">
						<div className="singleQuestion_bg_content">
							<h1 className="singleQuestion_bg_content_title">{singleQuestion.forumQuestionLabel}</h1>
							<div className="singleQuestion_bg_content_share">
								<span>Поделиться:</span>
								<button>
									<img src={TwitterImg}/>
								</button>
								<button>
									<img src={FacebookImg}/>
								</button>
								<button>
									<img src={InstagramImg}/>
								</button>
								<button>
									<img src={LinkedinImg}/>
								</button>
							</div>
						</div>
						<button className="singleQuestion_bg_button" onClick={() => navigate('/forum/askaquestion')}>
							<img src={ButtonImg}/>
							Задать Вопрос
						</button>
					</div>
					<div className="singleQuestion_content">
						<div className="singleQuestion_content_question">
							<div className="singleQuestion_content_question_header">
								<div className="singleQuestion_content_question_header_content">
									<h4 className="singleQuestion_content_question_header_content_title">{singleQuestion.forumQuestionLabel}</h4>
									<div className="singleQuestion_content_question_header_content_desc">
										<div className="singleQuestion_content_question_header_content_desc_row">
											<img src={DateIcon}/>
											<span>{getDateByTimeStamp(singleQuestion.createdTime, '/')}</span>
										</div>
										<div className="singleQuestion_content_question_header_content_desc_row">
											<img src={UserIcon}/>
											<span>{singleQuestion.fio}</span>
										</div>
										<div className="singleQuestion_content_question_header_content_desc_row">
											<img src={FolderIcon}/>
											<span>{singleQuestion.forumName}</span>
										</div>
									</div>
								</div>
								<AnswerButton text={'Ответить'} func={() => {
									setShowAnswerForm(true)
									dispatch(SET_FORMDATA({
										field: "forumQuestionId",
										value: singleQuestion.forumQuestionId
									}))
									dispatch(SET_FORMDATA({field: "fromReplyId", value: null}))
									dispatch(SET_FORMDATA({field: "images", value: []}))
								}}/>
							</div>
							<div
								className={showContent ? "singleQuestion_content_question_content" : "singleQuestion_content_question_content singleQuestion_content_question_content_hide"}>
								<div
									className="singleQuestion_content_question_content_desc">{singleQuestion.forumQuestionContent}</div>
								{singleQuestion.images.length > 0 ? singleQuestion.images.map((image) => {
									return (
										<img key={image.forumQuestionsImageId} src={image.value}/>
									)
								}) : ''}

							</div>
							<div className="singleQuestion_content_question_footer">
								<CollapseButton func={() => setShowContent(!showContent)} show={showContent}/>
								<ShareBlock shareCount={250} viewsCount={250}/>
							</div>
							<div
								className={showAnswerForm ? "singleAnswer_content_answer_form" : "singleAnswer_content_answer_form_hide"}>
								<form>
									<div className="singleAnswer_content_answer_form_title">Ответить
										пользователю <span>{singleQuestion.fio}</span>
									</div>
									<RichText
										value={""}
										setValue={(value) => dispatch(SET_FORMDATA({field: "comment", value: value }))}
									/>
									<div className="field">
										<span className="title">Добавить медиафайл</span>
										<div className="fieldImgContent">
											{
												formData?.images?.map((img) => {
													console.log(img)
													return (
														<UploadImg
															key={img.forumQuestionsCommentsImageid}
															item={formData}
															userImg={img.value}
															imgId={img.forumQuestionsCommentsImageid}
															getImg={(imgHash, imgId) => {
																dispatch(SET_FORMDATA({
																	field: 'images',
																	value: {
																		forumQuestionsCommentsImageid: imgId,
																		imgHash: imgHash
																	},
																	action: 'update'
																}))
															}}
															deleteImg={() => {
																dispatch(SET_FORMDATA({
																	field: 'images',
																	value: {
																		forumQuestionsCommentsImageid: img.forumQuestionsCommentsImageid
																	},
																	action: 'delete'
																}))
															}}
															id={'categImgUpload'}
															allowEdit={true}
														/>
													)
												})
											}

											<button className="addImg"
													type="button"
													disabled={false}
													onClick={() => {
														dispatch(SET_FORMDATA({
															field: 'images',
															value: {
																forumQuestionsCommentsImageid: getRandomStr(10)
															},
															action: 'add'
														}))
													}}
											>
												<svg
													viewBox="0 0 22 22"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<line
														x1="11.1382"
														y1="1"
														x2="11.1382"
														y2="21"
														stroke="black"
														strokeWidth="2"
														strokeLinecap="round"
													/>
													<line
														x1="1"
														y1="10.7891"
														x2="21"
														y2="10.7891"
														stroke="black"
														strokeWidth="2"
														strokeLinecap="round"
													/>
												</svg>
											</button>
										</div>
									</div>
									<div className="singleAnswer_content_answer_form_buttons">
										<button className="singleAnswer_content_answer_form_cancelButton" type="button"
												onClick={() => {
													setShowAnswerForm(false)
													dispatch(SET_FORMDATATOEMPTY())
												}}>Отмена
										</button>
										<button className="singleAnswer_content_answer_form_submitButton" type="submit"
												onClick={async (event) => {
													event.preventDefault()
													await dispatch(saveNewComment())
													setLoadingAnswer(!loadingAnswer)
													setShowAnswerForm(false)
												}
												}>Ответить
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</>
			}
			{loadingComments ? 'загрузка' :
				comments.slice().sort((a, b) => moment(b.createdTime, 'DD.MM.YY') < moment(a.createdTime, 'DD.MM.YY')? 1 : -1).map((comment) => {
					return <AnswerBlock forumQuestionId={singleQuestion.forumQuestionId} comment={comment}
										key={comment.forumQuestionsCommentId} setLoadingAnswer={setLoadingAnswer} loadingAnswer={loadingAnswer} />
				})
			}
		</div>
	);
};


import React, {useEffect, useState} from "react";

import {useDispatch, useSelector} from "react-redux";
import {getStudents, SET_SEARCH} from "../../../../store/teacherCabinet/teacherStudents/StudentsSlice";

import "./style.scss"


export default function StudentsSearch() {

	const dispatch = useDispatch()

	const lang = useSelector(state => state.global.lang)


	const handleChange = (e) => {
		e.preventDefault()
		let form = e.target
		let formData = []
		Array.from(form).forEach((item) => {
			if (item.tagName == 'INPUT' && item.value !== '') {
				formData.push({
					key: item.name,
					value: item.value
				})
			}
		})
		dispatch(SET_SEARCH(formData))
		dispatch(getStudents(lang))
	}


	return (
		<div className="students-search">
			<form className="searchForm" id="searchForm" onSubmit={handleChange}>
				<div className="item size1">
					<span>ID:</span>
					<input type="text" name='accountId' />
				</div>
				<div className="item size2">
					<span>ФИО</span>
					<input type="text" name='fio'  />
				</div>
				<div className="item size2">
					<span>E-mail</span>
					<input type="text" name='email'  />
				</div>
				{/*<div className="item size3">*/}
				{/*	<span>Статус</span>*/}
				{/*	<input type="text" name='status' onChange={handleChange} />*/}
				{/*</div>*/}
			</form>
			<button className="searchForm__button" type="submit" form="searchForm">Применить</button>
		</div>
	)
}
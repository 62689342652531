import React from "react";

import "./style.scss"
import {useLocation, useParams} from "react-router-dom";

export let LoadingTestPage = ({location, error}) => {

	let params = useParams()

	return (
		<div className="loading-wrapper">
			<div className="loading__container">
				<h1 className="loading__container-title">{location == '/exam/result' ? 'Вы завершили EGZAMEN' : location == '/test/' + params.test_id + '/result' ? 'Вы завершили быструю проверку HIZLI CONTROL' : ''}</h1>
				<span className="loading__container-desc">Мы обрабатываем ваши результаты</span>
				<div className="gradient"></div>
				{error.isError && <div className="error">{error?.text}</div>}
			</div>
		</div>
	)
}
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { setSelectedNewsCategs } from "../../redux_store/globalReducer"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { getCuttedString } from '../../shared/functions'
import { getForumThemes, SET_CATEGSFILTER } from '../../store/forum/forumThemesSlice'
// import { getPopularNews } from '../../functions/helpers'
// import Btn2 from '../ui/btn2/Btn2'
import "./Sidebar.scss"



export default function Sidebar({ news, categs, setsearchQuery, sidebarOpen, setSidebarOpen }) {

    // let filter = useSelector((state) => state.globalReducer.selectedNewsCategs)
    let location = useLocation()
    let navigate = useNavigate()

    let dispatch = useDispatch()

    let categsFilter = useSelector(state => state.forumThemes.categsFilter)
    let Lang = useSelector(state => state.global.lang)

    const onCateg = (value) => {
        let isForum = location.pathname.split('/').length < 3
        if (isForum) {
            let obj
            if (categsFilter?.value == value) {
                obj = null
            } else {
                obj = {
                    key: "forumTypeDetailId",
                    value: value
                }
            }
            dispatch(SET_CATEGSFILTER(obj))
            dispatch(getForumThemes(Lang))
        } else {
            navigate('/forum')
        }
    }

    return (
        <div className={"forumSidebar " + ((sidebarOpen) ? "open" : "")}>
            <div className="close"
                onClick={() => setSidebarOpen(false)}
            >&#x2715;</div>
            <div className="sidebarContent">
                <div className="sidebar_block">
                    <h4 className="title">Поиск</h4>
                    <div className="sidebar_block_content">
                        <form className="sidebar_searchForm" onSubmit={(e) => { e.preventDefault(); setsearchQuery(e.target[0].value) }}>
                            <input 
                                type="text"
                                placeholder="Type here ..."
                            />
                            <button type="submit">
                                <svg viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.72 8.88316C15.72 13.295 12.3685 16.7663 8.36 16.7663C4.3515 16.7663 1 13.295 1 8.88316C1 4.47136 4.3515 1 8.36 1C12.3685 1 15.72 4.47136 15.72 8.88316Z" stroke="#000" strokeWidth="2" />
                                    <line y1="-1" x2="10.2723" y2="-1" transform="matrix(0.685338 0.728225 -0.685338 0.728225 13.2002 15.8962)" stroke="#000" strokeWidth="2" />
                                </svg>
                            </button>
                        </form>
                    </div>
                </div>
                <div className="sidebar_block">
                    <h4 className="title">Темы</h4>
                    <div className="sidebar_block_content">
                        <ul className="sidebar_categs">
                            {
                                categs.content?.map((categ) => {
                                    return (
                                        <li
                                            key={categ.forumTypeDetailId}
                                            className={((categsFilter == null) ?
                                                ''
                                                :
                                                (categsFilter.value == categ.forumTypeDetailId) ? 
                                                    'selected'
                                                    : ''
                                                )}
                                            onClick={() => {
                                                onCateg(categ.forumTypeDetailId)
                                            }}
                                        >
                                            {categ.value}
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
                {/* <div className="sidebar_block">
                    <h4 className="title">Популярное</h4>
                    <div className="sidebar_block_content">
                        {news.map((item, i) => {
                            if (i < 3) {
                                return (
                                    <div key={item.id} className="small_newCard">
                                        <div className="img">
                                            <img src={item.img} alt="" />
                                        </div>
                                        <div className="content">
                                            <h5 className="popTitle">{getCuttedString(item.title, 49)}</h5>
                                            <Link to={item.id} className="button">
                                                <button>Подробнее</button>
                                            </Link>
                                        </div>
                                    </div>
                                )
                            }
                        })}
                    </div>
                </div> */}
            </div>
        </div>
    )
}
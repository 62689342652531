import http from "./index"


export let getCourseLessonsFromBD = (params, token) => {
    return http.post(
        `/lesson/list`,
        params,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'AuthorizationToken': token
            }
        }
    )
    .then(function (response) {
        // handle success
        // console.log(response.data[0])
        if (response.data) {
            return response.data
        }
    })
    .catch(function (error) {
        // handle error
        console.log(error)
    })
    .finally(function () {
        // always executed

    });
}
export let getOneLessonFromBD = (id, token) => {
    return http.get(
        `/lesson/` + id,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'AuthorizationToken': token
            }
        }
    )
    .then(function (response) {
        // handle success
        // console.log(response.data[0])
        if (response.data) {
            return response.data
        }
    })
    .catch(function (error) {
        // handle error
        console.log(error)
    })
    .finally(function () {
        // always executed

    });
}
export let getOneCourseByIdFromBD = (id, token) => {
    return http.get(
        `/course/` + id,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'AuthorizationToken': token
            }
        }
    )
    .then(function (response) {
        // handle success
        // console.log(response.data[0])
        if (response.data) {
            return response.data
        }
    })
    .catch(function (error) {
        // handle error
        console.log(error)
    })
    .finally(function () {
        // always executed

    });
}

export let getStudyingOnlineLessonsFromBD = (params) => {
    return http.post(
        `/meeting/list`,
        params, {
            headers: {
                'Access-Control-Allow-Origin': '*',
            }
        }
    )
        .then(function (response) {
            // handle success
            // console.log(response.data[0])
            if (response.data) {
                return response.data
            }
        })
        .catch(function (error) {
            // handle error
            console.log(error)
        })
        .finally(function () {
            // always executed

        });
}
export let downloadLessonMaterial = (id) => {
    return http.get(
        `/lesson-material/download/${id}`,
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
            }
        }
    )
        .then(function (response) {
            // handle success
            // console.log(response.data[0])
            if (response.data) {
                return response.data
            }
        })
        .catch(function (error) {
            // handle error
            console.log(error)
        })
        .finally(function () {
            // always executed

        });
}
import React from 'react'
import Breadcrumbs from '../../shared/ui/breadcrumbs/Breadcrumbs'
import "./style.scss"

import contactUsBG from "../../shared/img/contactUsBG.png"

export default function ContactsPage() {

    let breadcrumbsItems = [
        {
            slug: '/',
            title: 'Главная',
        },
        {
            slug: '/contacts',
            title: 'Контакты',
        },
    ]

    return (
        <div className="ContactsPage">
            <div className="breadcrumbs_">
                <Breadcrumbs
                    items={breadcrumbsItems}
                />
            </div>
            <div className="contactUs">
                <div className="BG">
                    <img src={contactUsBG} alt="" />
                </div>
                <div className="content">
                    <div className="text">
                        <h1>Свяжитесь с нами</h1>
                        <p>После прохождения теста по Гагаузскогому языку вы получаете оценку в баллах. Часто вы также получаете некоторое представление о том, что означает эта оценка, выраженная в виде уровня владения Гагаузским языком или такого термина как «начинающий» или «продвинутый».</p>
                    </div>
                    <div className="formWrapper">
                        <form>
                            <div className="row">
                                <input type="text" placeholder="Имя" />
                                <input type="text" placeholder="Фамилия" />
                            </div>
                            <div className="row">
                                <input type="text" placeholder="E-mail" />
                                <input type="text" placeholder="Телефон" />
                            </div>
                            <div className="row">
                                <textarea placeholder="Сообщение"></textarea>
                            </div>
                            <button type="submit">Отправить</button>
                        </form>
                    </div>
                </div>
            </div>
            <div className="map">
                <div className="top">
                    <div className="left">
                        <div className="item">
                            <div className="icon">
                                <svg viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.4952 11.1211L15.2339 13.3901C14.3168 14.3104 12.7033 14.3302 11.7665 13.3901L9.50499 11.1211L1.38281 19.2696C1.68515 19.4094 2.01849 19.4929 2.37288 19.4929H24.6274C24.9818 19.4929 25.3151 19.4095 25.6173 19.2697L17.4952 11.1211Z" fill="#2ECC71" />
                                    <path d="M24.6273 0.507812H2.37277C2.01839 0.507812 1.68504 0.591241 1.38281 0.731044L10.0619 9.43896C10.0625 9.43954 10.0632 9.43964 10.0638 9.44022C10.0644 9.44081 10.0645 9.4416 10.0645 9.4416L12.8865 12.273C13.1863 12.5727 13.8139 12.5727 14.1137 12.273L16.9352 9.44207C16.9352 9.44207 16.9359 9.44081 16.9364 9.44022C16.9364 9.44022 16.9377 9.43954 16.9383 9.43896L25.6172 0.730991C25.315 0.591135 24.9817 0.507812 24.6273 0.507812Z" fill="#2ECC71" />
                                    <path d="M0.252394 1.83691C0.0959794 2.15322 0 2.50434 0 2.88045V17.1192C0 17.4953 0.0958739 17.8464 0.252341 18.1627L8.3886 10.0001L0.252394 1.83691Z" fill="#2ECC71" />
                                    <path d="M26.7475 1.83691L18.6113 10.0002L26.7475 18.1629C26.9039 17.8466 26.9999 17.4955 26.9999 17.1193V2.88056C26.9999 2.50434 26.9039 2.15322 26.7475 1.83691Z" fill="#2ECC71" />
                                </svg>
                            </div>
                            <a href="mailto:ana-dili@yahoo.com">ana-dili@yahoo.com</a>
                        </div>
                        <div className="item">
                            <div className="icon">
                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.2463 15.8761C17.4622 15.102 16.4833 15.102 15.7042 15.8761C15.1099 16.4654 14.5156 17.0547 13.9313 17.6541C13.7715 17.8189 13.6366 17.8538 13.4418 17.7439C13.0573 17.5342 12.6478 17.3644 12.2782 17.1347C10.5552 16.0509 9.11186 14.6575 7.83334 13.0893C7.19907 12.3102 6.63473 11.4762 6.24018 10.5373C6.16028 10.3475 6.17526 10.2227 6.33008 10.0678C6.92439 9.49351 7.50372 8.90419 8.08804 8.31487C8.9021 7.49582 8.9021 6.53693 8.08305 5.71289C7.61859 5.24343 7.15412 4.78396 6.68966 4.31451C6.21022 3.83506 5.73577 3.35062 5.25133 2.87617C4.46724 2.11206 3.48837 2.11206 2.70927 2.88117C2.10997 3.47049 1.53563 4.07479 0.92634 4.65411C0.361994 5.1885 0.0773235 5.84274 0.017393 6.60685C-0.0774971 7.85041 0.22715 9.02405 0.656652 10.1677C1.53563 12.535 2.87408 14.6375 4.4972 16.5653C6.68966 19.1723 9.30663 21.2349 12.3681 22.7232C13.7465 23.3924 15.1748 23.9068 16.728 23.9917C17.7968 24.0516 18.7257 23.782 19.4699 22.9479C19.9793 22.3786 20.5536 21.8592 21.093 21.3148C21.892 20.5057 21.897 19.5269 21.103 18.7278C20.1541 17.7739 19.2002 16.825 18.2463 15.8761Z" fill="#2ECC71" />
                                    <path d="M17.2917 11.8967L19.1345 11.5821C18.8449 9.88902 18.0458 8.3558 16.8322 7.13721C15.5487 5.8537 13.9256 5.04463 12.1376 4.79492L11.8779 6.64778C13.2613 6.84255 14.5199 7.46683 15.5137 8.46068C16.4526 9.39959 17.0669 10.5882 17.2917 11.8967Z" fill="#2ECC71" />
                                    <path d="M20.1737 3.8855C18.0462 1.75796 15.3543 0.41452 12.3827 0L12.123 1.85285C14.6901 2.21244 17.0174 3.37609 18.8552 5.20897C20.5982 6.95195 21.7419 9.1544 22.1564 11.5766L23.9993 11.262C23.5148 8.45521 22.1914 5.90816 20.1737 3.8855Z" fill="#2ECC71" />
                                </svg>
                            </div>
                            <a href="tel:+37379885558 ">+373-79-885-558 </a>
                        </div>
                        <div className="item">
                            <div className="icon">
                                <svg viewBox="0 0 23 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.5009 0C5.14833 0 -0.000976562 5.12297 -0.000976562 11.4412C-0.000976562 14.119 0.908495 16.1857 3.00532 19.3105C5.10043 22.4352 11.5009 31 11.5009 31C11.5009 31 17.9015 22.4372 19.9927 19.3105C22.0913 16.1874 22.999 14.119 22.999 11.4412C22.9993 5.12297 17.8499 0 11.5009 0ZM11.5009 15.7669C9.09968 15.7669 7.15314 13.8303 7.15314 11.4414C7.15314 9.05586 9.09968 7.11582 11.5009 7.11582C13.9022 7.11582 15.8487 9.0558 15.8487 11.4414C15.8487 13.8303 13.9022 15.7669 11.5009 15.7669Z" fill="#2ECC71" />
                                </svg>
                            </div>
                            <span>АТО Гагаузия, г.Комрат, ул.Победа 18/A </span>
                        </div>
                    </div>
                    <div className="right">
                        <a href="#">
                            <div className="icon">
                                <svg viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M14.999 30.5C11.0208 30.5 7.20547 28.9196 4.39243 26.1066C1.57938 23.2936 -0.000969062 19.4782 -0.000969062 15.5C-0.00483146 11.6352 1.48316 7.91812 4.1528 5.12357C6.82243 2.32903 10.4677 0.672741 14.3286 0.5H14.999C16.9689 0.5 18.9194 0.887988 20.7393 1.64181C22.5592 2.39563 24.2128 3.50052 25.6056 4.8934C26.9985 6.28628 28.1034 7.93986 28.8572 9.75975C29.611 11.5796 29.999 13.5302 29.999 15.5C29.999 17.4698 29.611 19.4204 28.8572 21.2403C28.1034 23.0601 26.9985 24.7137 25.6056 26.1066C24.2128 27.4995 22.5592 28.6044 20.7393 29.3582C18.9194 30.112 16.9689 30.5 14.999 30.5ZM5.15055 25.3636C6.77194 26.9866 8.76854 28.1847 10.9635 28.8517C13.1585 29.5188 15.4841 29.6343 17.7344 29.1879C19.9847 28.7416 22.0901 27.7472 23.8643 26.2928C25.6385 24.8384 27.0266 22.9689 27.9058 20.85C28.7849 18.731 29.1279 16.4279 28.9044 14.1447C28.6809 11.8615 27.8978 9.66865 26.6245 7.76037C25.3511 5.8521 23.6268 4.28729 21.6043 3.20455C19.5818 2.12181 17.3234 1.55456 15.0293 1.55303C13.1976 1.55253 11.3838 1.91294 9.69147 2.61367C7.99912 3.31439 6.46142 4.3417 5.16623 5.63689C3.87103 6.93209 2.84372 8.46979 2.143 10.1621C1.44228 11.8545 1.08187 13.6683 1.08236 15.5C1.07609 17.3299 1.43086 19.143 2.12634 20.8355C2.82182 22.5281 3.84436 24.0668 5.13539 25.3636H5.15055Z" fill="#2ECC71" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M18.0825 8.22363H11.9158C10.8044 8.22564 9.73896 8.66805 8.95302 9.45399C8.16708 10.2399 7.72466 11.3053 7.72266 12.4168V18.5835C7.72466 19.695 8.16708 20.7603 8.95302 21.5463C9.73896 22.3322 10.8044 22.7747 11.9158 22.7767H18.0825C19.194 22.7747 20.2594 22.3322 21.0453 21.5463C21.8313 20.7603 22.2737 19.695 22.2757 18.5835V12.4168C22.2737 11.3053 21.8313 10.2399 21.0453 9.45399C20.2594 8.66805 19.194 8.22564 18.0825 8.22363ZM20.7908 18.5835C20.7898 19.3015 20.5042 19.9897 19.9965 20.4974C19.4888 21.0051 18.8005 21.2908 18.0825 21.2918H11.9158C11.1979 21.2908 10.5096 21.0051 10.0019 20.4974C9.49417 19.9897 9.20851 19.3015 9.2075 18.5835V12.4168C9.20851 11.6988 9.49417 11.0105 10.0019 10.5028C10.5096 9.99514 11.1979 9.70948 11.9158 9.70847H18.0825C18.8005 9.70948 19.4888 9.99514 19.9965 10.5028C20.5042 11.0105 20.7898 11.6988 20.7908 12.4168V18.5835Z" fill="#2ECC71" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M14.9989 12.1211C14.3306 12.1211 13.6774 12.3193 13.1218 12.6905C12.5661 13.0618 12.133 13.5895 11.8773 14.2069C11.6216 14.8243 11.5547 15.5036 11.685 16.1591C11.8154 16.8145 12.1372 17.4165 12.6097 17.889C13.0823 18.3616 13.6843 18.6834 14.3397 18.8137C14.9952 18.9441 15.6745 18.8772 16.2919 18.6215C16.9093 18.3657 17.437 17.9327 17.8083 17.377C18.1795 16.8214 18.3777 16.1681 18.3777 15.4999C18.3767 14.6041 18.0204 13.7453 17.387 13.1118C16.7535 12.4784 15.8947 12.1221 14.9989 12.1211ZM14.9989 17.3256C14.6378 17.3256 14.2848 17.2186 13.9846 17.018C13.6843 16.8173 13.4503 16.5322 13.3121 16.1986C13.1739 15.865 13.1378 15.4979 13.2082 15.1437C13.2787 14.7895 13.4526 14.4642 13.7079 14.2089C13.9632 13.9535 14.2886 13.7797 14.6427 13.7092C14.9969 13.6388 15.364 13.6749 15.6976 13.8131C16.0312 13.9513 16.3164 14.1853 16.517 14.4855C16.7176 14.7858 16.8247 15.1388 16.8247 15.4999C16.8247 15.9841 16.6323 16.4485 16.2899 16.7909C15.9475 17.1333 15.4831 17.3256 14.9989 17.3256Z" fill="#2ECC71" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M18.7869 10.6553C18.5882 10.6545 18.3937 10.7128 18.2282 10.8226C18.0626 10.9324 17.9334 11.089 17.8568 11.2723C17.7802 11.4557 17.7598 11.6576 17.7981 11.8526C17.8364 12.0475 17.9317 12.2267 18.072 12.3675C18.2122 12.5083 18.391 12.6042 18.5859 12.6433C18.7807 12.6823 18.9827 12.6627 19.1663 12.5868C19.35 12.5109 19.507 12.3823 19.6174 12.2171C19.7279 12.052 19.7869 11.8578 19.7869 11.6591C19.7869 11.3935 19.6816 11.1388 19.4942 10.9506C19.3068 10.7625 19.0524 10.6563 18.7869 10.6553Z" fill="#2ECC71" />
                                </svg>
                            </div>
                        </a>
                        <a href="#">
                            <div className="icon">
                                <svg viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M14.999 30.5C11.0208 30.5 7.20547 28.9196 4.39243 26.1066C1.57938 23.2936 -0.000969062 19.4782 -0.000969062 15.5C-0.00483146 11.6352 1.48316 7.91812 4.1528 5.12357C6.82243 2.32903 10.4677 0.672741 14.3286 0.5V0.5H14.999C18.9773 0.5 22.7926 2.08035 25.6056 4.8934C28.4187 7.70644 29.999 11.5218 29.999 15.5C29.999 19.4782 28.4187 23.2936 25.6056 26.1066C22.7926 28.9196 18.9773 30.5 14.999 30.5ZM5.15055 25.3636C6.77239 26.9871 8.76965 28.1853 10.9653 28.8523C13.161 29.5193 15.4873 29.6343 17.7381 29.1872C19.9889 28.7401 22.0946 27.7447 23.8687 26.2892C25.6428 24.8337 27.0304 22.963 27.9087 20.843C28.7869 18.7229 29.1286 16.419 28.9035 14.1353C28.6784 11.8516 27.8934 9.65869 26.6182 7.75092C25.3429 5.84315 23.6167 4.27942 21.5926 3.1983C19.5685 2.11718 17.3089 1.55207 15.0142 1.55303C13.1812 1.55054 11.3658 1.90948 9.67166 2.60931C7.97756 3.30914 6.43808 4.33614 5.14128 5.63153C3.84448 6.92692 2.81582 8.46529 2.11414 10.1586C1.41247 11.852 1.05156 13.667 1.05206 15.5C1.04977 17.332 1.40931 19.1464 2.11004 20.839C2.81077 22.5317 3.83889 24.0693 5.13539 25.3636H5.15055Z" fill="#2ECC71" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M19.006 8.17462L7.81279 14.6367C7.61482 14.7502 7.45367 14.9182 7.34856 15.1208C7.24345 15.3233 7.19881 15.5518 7.21997 15.779C7.24113 16.0062 7.3272 16.2225 7.46791 16.4021C7.60862 16.5817 7.79803 16.7171 8.01354 16.792L10.9832 17.8261L17.6991 12.5648L13.0401 18.542V22.0193C13.0404 22.1008 13.0647 22.1803 13.1098 22.2481C13.1549 22.3159 13.2189 22.369 13.2938 22.4009C13.3688 22.4327 13.4514 22.4419 13.5315 22.4272C13.6117 22.4126 13.6857 22.3748 13.7446 22.3186L15.4908 20.6481L17.7181 22.6784C17.883 22.824 18.0849 22.9211 18.3015 22.9591C18.5182 22.9971 18.7411 22.9745 18.9457 22.8937C19.1502 22.813 19.3285 22.6773 19.4608 22.5016C19.5931 22.3259 19.6743 22.117 19.6954 21.8981L20.7787 9.3072C20.7953 9.09194 20.7531 8.87621 20.6567 8.68301C20.5604 8.48982 20.4134 8.32639 20.2314 8.21015C20.0495 8.09391 19.8394 8.02922 19.6236 8.02296C19.4078 8.01671 19.1943 8.06912 19.006 8.17462Z" fill="#2ECC71" />
                                </svg>
                            </div>
                        </a>
                        <a href="#">
                            <div className="icon">
                                <svg viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M14.999 30.5C11.0208 30.5 7.20547 28.9196 4.39243 26.1066C1.57938 23.2936 -0.000969062 19.4782 -0.000969062 15.5C-0.00483146 11.6352 1.48316 7.91811 4.1528 5.12357C6.82243 2.32903 10.4677 0.672741 14.3286 0.5H14.999C16.9689 0.5 18.9194 0.887988 20.7393 1.64181C22.5592 2.39563 24.2127 3.50052 25.6056 4.8934C26.9985 6.28628 28.1034 7.93986 28.8572 9.75975C29.611 11.5796 29.999 13.5302 29.999 15.5C29.999 19.4782 28.4187 23.2936 25.6056 26.1066C22.7926 28.9196 18.9773 30.5 14.999 30.5ZM5.15055 25.3636C6.77193 26.9866 8.76854 28.1847 10.9635 28.8517C13.1585 29.5188 15.4841 29.6343 17.7344 29.1879C19.9847 28.7416 22.0901 27.7472 23.8643 26.2928C25.6385 24.8384 27.0266 22.9689 27.9058 20.8499C28.7849 18.731 29.1279 16.4279 28.9044 14.1447C28.6809 11.8615 27.8978 9.66865 26.6245 7.76037C25.3511 5.8521 23.6268 4.28729 21.6043 3.20455C19.5818 2.12181 17.3234 1.55456 15.0293 1.55303C13.1976 1.55253 11.3838 1.91294 9.69147 2.61367C7.99912 3.31439 6.46142 4.3417 5.16622 5.63689C3.87103 6.93209 2.84372 8.46979 2.14299 10.1621C1.44227 11.8545 1.08186 13.6683 1.08236 15.5C1.07609 17.3299 1.43085 19.143 2.12634 20.8355C2.82182 22.5281 3.84436 24.0668 5.13539 25.3636H5.15055Z" fill="#2ECC71" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M19.0031 7.57927L16.666 7.52624C16.2105 7.5161 15.7576 7.59735 15.3341 7.76521C14.9105 7.93306 14.5249 8.18411 14.2001 8.50351C13.872 8.82068 13.6116 9.201 13.4345 9.62156C13.2574 10.0421 13.1674 10.4942 13.1698 10.9505V13.1626H10.9842C10.941 13.1626 10.8995 13.1798 10.869 13.2103C10.8385 13.2409 10.8213 13.2823 10.8213 13.3255V16.1096C10.8213 16.1308 10.8255 16.1518 10.8338 16.1713C10.842 16.1909 10.854 16.2086 10.8692 16.2234C10.8844 16.2383 10.9024 16.2499 10.9221 16.2577C10.9419 16.2654 10.963 16.2692 10.9842 16.2687H13.1546V23.3103C13.1546 23.3535 13.1718 23.3949 13.2023 23.4255C13.2329 23.456 13.2743 23.4732 13.3175 23.4732H16.1887C16.2319 23.4732 16.2733 23.456 16.3039 23.4255C16.3344 23.3949 16.3516 23.3535 16.3516 23.3103V16.2687H18.5599C18.5995 16.269 18.6378 16.255 18.6677 16.2293C18.6977 16.2035 18.7173 16.1677 18.7228 16.1285L19.0826 13.3444C19.0861 13.3221 19.0844 13.2993 19.0779 13.2776C19.0713 13.256 19.06 13.2361 19.0448 13.2194C19.0296 13.2021 19.011 13.1881 18.9901 13.1783C18.9693 13.1685 18.9466 13.1632 18.9236 13.1626H16.3743V11.7573C16.3387 11.3747 16.4437 10.9923 16.6698 10.6815C16.7579 10.59 16.8648 10.5186 16.9832 10.4721C17.1015 10.4257 17.2285 10.4054 17.3554 10.4126H19.0069C19.0281 10.4131 19.0492 10.4094 19.0689 10.4016C19.0887 10.3938 19.1067 10.3822 19.1218 10.3674C19.137 10.3525 19.1491 10.3348 19.1573 10.3153C19.1655 10.2957 19.1698 10.2747 19.1698 10.2535V7.74215C19.1703 7.72029 19.1663 7.69855 19.1581 7.67829C19.1499 7.65803 19.1376 7.63968 19.1219 7.6244C19.1063 7.60912 19.0877 7.59722 19.0672 7.58946C19.0468 7.58169 19.025 7.57822 19.0031 7.57927Z" fill="#2ECC71" />
                                </svg>
                            </div>
                        </a>
                        <a href="#">
                            <div className="icon">
                                <svg viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M14.999 30.5C13.0292 30.5 11.0787 30.112 9.25878 29.3582C7.43889 28.6044 5.78531 27.4995 4.39243 26.1066C2.99955 24.7137 1.89466 23.0601 1.14084 21.2402C0.387018 19.4204 -0.000969062 17.4698 -0.000969062 15.5C-0.00483146 11.6352 1.48316 7.91811 4.1528 5.12357C6.82243 2.32903 10.4677 0.672741 14.3286 0.5H14.999C16.9689 0.5 18.9194 0.887988 20.7393 1.64181C22.5592 2.39563 24.2127 3.50052 25.6056 4.8934C26.9985 6.28628 28.1034 7.93986 28.8572 9.75975C29.611 11.5796 29.999 13.5302 29.999 15.5C29.999 17.4698 29.611 19.4204 28.8572 21.2402C28.1034 23.0601 26.9985 24.7137 25.6056 26.1066C24.2127 27.4995 22.5592 28.6044 20.7393 29.3582C18.9194 30.112 16.9689 30.5 14.999 30.5ZM5.15055 25.3636C6.77193 26.9866 8.76854 28.1847 10.9635 28.8517C13.1585 29.5188 15.4841 29.6343 17.7344 29.1879C19.9847 28.7416 22.0901 27.7472 23.8643 26.2928C25.6385 24.8384 27.0266 22.9689 27.9058 20.8499C28.7849 18.731 29.1279 16.4279 28.9044 14.1447C28.6809 11.8615 27.8978 9.66865 26.6245 7.76037C25.3511 5.8521 23.6268 4.28729 21.6043 3.20455C19.5818 2.12181 17.3234 1.55456 15.0293 1.55303C13.1976 1.55253 11.3838 1.91294 9.69147 2.61367C7.99912 3.31439 6.46142 4.3417 5.16622 5.63689C3.87103 6.93209 2.84372 8.46979 2.14299 10.1621C1.44227 11.8545 1.08186 13.6683 1.08236 15.5C1.07609 17.3299 1.43085 19.143 2.12634 20.8355C2.82182 22.5281 3.84436 24.0668 5.13539 25.3636H5.15055Z" fill="#2ECC71" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M20.0024 9.30664H9.99866C9.13435 9.30664 8.30538 9.64973 7.69387 10.2605C7.08235 10.8713 6.73831 11.6999 6.7373 12.5642V18.4354C6.73831 19.2997 7.08235 20.1283 7.69387 20.7391C8.30538 21.3499 9.13435 21.693 9.99866 21.693H20.0024C20.8664 21.693 21.695 21.3498 22.3059 20.7389C22.9168 20.128 23.26 19.2994 23.26 18.4354V12.5642C23.26 11.7003 22.9168 10.8717 22.3059 10.2608C21.695 9.64985 20.8664 9.30664 20.0024 9.30664ZM12.9646 18.2157V12.7839C12.9648 12.6862 12.9908 12.5902 13.0401 12.5059C13.0895 12.4215 13.1603 12.3517 13.2453 12.3035C13.3304 12.2554 13.4267 12.2307 13.5244 12.2319C13.6222 12.2331 13.7178 12.2602 13.8017 12.3104L18.3471 15.0263C18.4296 15.0748 18.498 15.144 18.5455 15.2271C18.593 15.3101 18.618 15.4041 18.618 15.4998C18.618 15.5955 18.593 15.6895 18.5455 15.7726C18.498 15.8556 18.4296 15.9248 18.3471 15.9733L13.8017 18.6892C13.7181 18.7407 13.6221 18.7688 13.5239 18.7706C13.4258 18.7724 13.3289 18.7479 13.2434 18.6995C13.1579 18.6512 13.0869 18.5808 13.0379 18.4957C12.9889 18.4106 12.9635 18.3139 12.9646 18.2157Z" fill="#2ECC71" />
                                </svg>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="bottom">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1378.24496403803!2d28.655571950420327!3d46.30010672215136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sru!2s!4v1675791419700!5m2!1sru!2s" width="600" height="450" style={{border: 0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
    )
}
import React from 'react'
import "./style.scss"
import LogoImg from "./Logo.svg"

export default function Logo() {
	return (
		<div className="logo">
			<img src={LogoImg} alt="" />
		</div>
	)
}
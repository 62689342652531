import { createSlice } from '@reduxjs/toolkit'
import { getCourseLessonsFromBD, getOneLessonFromBD } from '../../api/studying'
import { getCookie, prepareCourseLessonsParams } from '../../shared/functions'
import { hardCodeCourses } from '../../shared/hardCodeCourses/hardCodeCourses'


const initialState = {
    lessons: [],
    currLesson: {},
    loading: false,
    loading2: false,
}

export const studyingLessonsSlice = createSlice({
    name: 'studyingLessons',
    initialState,
    reducers: {
        SET_LESSONS: (state, action) => {
            state.lessons = action.payload
            state.loading = false
        },
        SET_CURRLESSON: (state, action) => {
            state.currLesson = action.payload
            state.loading2 = false
        },
        SET_LOADING: (state, action) => {
            state.loading = action.payload
        },
        SET_LOADING2: (state, action) => {
            state.loading2 = action.payload
        }
    },
})



export const getCourseLessons = (id) => async (dispatch, getState) => {
    dispatch(SET_LOADING(true))

    const params = prepareCourseLessonsParams(id)
    let token = getCookie('userToken')
    // const lessonsResponse = await getCourseLessonsFromBD(params, token)

    let lessonsResponse = []
    hardCodeCourses.forEach((course) => {
        if (course.courseId == id) {
            lessonsResponse = course.lessons
        }
    })
    dispatch(SET_LESSONS(lessonsResponse))
}
export const getOneLesson = (id) => async (dispatch, getState) => {
    dispatch(SET_LOADING2(true))
    let lessons = getState().studyingLessons.lessons

    let token = getCookie('userToken')
    // const lessonsResponse = await getOneLessonFromBD(id, token)
    const lessonsResponse = lessons.filter((lesson) => lesson.lessonId == id)[0]
    console.log(lessonsResponse)
    dispatch(SET_CURRLESSON(lessonsResponse))
}

export const { SET_LESSONS, SET_CURRLESSON, SET_LOADING, SET_LOADING2 } = studyingLessonsSlice.actions

export default studyingLessonsSlice.reducer
import React, {useEffect, useState} from 'react'
import {Route, Routes, useLocation} from 'react-router-dom'
import {Header} from "../../widgets/header/ui/Header";
import {Footer} from "../../widgets/footer/ui/Footer";
import {MainPage} from "../MainPage";
import AboutPage from "../AboutPage/AboutPage";
import FaqPage from "../FaqPage/FaqPage";
import RatingScalePage from '../RatingScalePage/RatingScalePage';
import ContactsPage from '../ContactsPage/ContactsPage';
import CertificatePage from "../CertificatePage/CertificatePage";
import SolutionPage from '../SolutionPage/SolutionPage';
import RegistrationForm from "../RegistrationForm/RegistrationForm";
import { getCookie, parseJwt, setCookie } from '../../shared/functions';
import { useDispatch } from 'react-redux';
import { SET_LOGGEDUSER } from '../../store/globalSlice';
import PrivateRouter from './privateRouter/privateRouter';

import "./style.scss"
import QuickCheckPage from '../QuickCheckPage/QuickCheckPage';
import CheckAudio from '../TestPages/CheckAudio/CheckAudio';
import TestQuestions from '../TestPages/TestQuestions/TestQuestions';
import Rules from '../TestPages/Rules/Rules';
import FormPage from '../TestPages/Form/Form';
import ResultPage from '../TestPages/Result/Result';

export default function Router() {

	let location = useLocation()
	let dispatch = useDispatch()
	let [CurrLocation, setCurrLocation] = useState(location.pathname)
	let [RootLocation, setRootLocation] = useState(location.pathname)

	let [BGColor, setBGColor] = useState('transparent')
	let locArr = location.pathname.split('/')

	useEffect(() => {
		let locArr = location.pathname.split('/')
		setRootLocation(locArr[1])
		if (locArr.includes('questions')) {
			setBGColor('#C8FFDF')
		} else if (locArr.includes('result')) {
			setBGColor('transparent')
		} else {
			setBGColor('#FFFFFF')
		}
	}, [location])

	return (
		<>
			{(CurrLocation !== '/admin/login')
				&&
				<Header/>
			}
			<div className="allContent" style={{
				backgroundColor: BGColor,
			}}>
				{/* {(CurrLocation !== '/admin/login')
          &&
          <AdminSidebar />
        } */}
				<RegistrationForm />
				<div className="contentPlace">
					<Routes>
						<Route path="/" element={<MainPage />} />
						<Route path="/about" element={<AboutPage />} />
						<Route path="/faq" element={<FaqPage />} />
						<Route path="/ratingscale" element={<RatingScalePage />} />
						<Route path="/contacts" element={<ContactsPage />} />
						<Route path="/certificate" element={<CertificatePage />} />
						<Route path="/solution" element={<SolutionPage />} />
						<Route path="/test" element={<QuickCheckPage />} />
						<Route path="/test/:test_id/checkaudio" element={<CheckAudio />} />
						<Route path="/test/:test_id/questions" element={<TestQuestions />} />
						<Route path="/test/:test_id/questions/:id" element={<TestQuestions />} />
						<Route path="/test/:test_id/rules" element={<Rules />} />
						<Route path="/test/:test_id/form" element={<FormPage />} />
						<Route path="/test/:test_id/result" element={<ResultPage />} />
					</Routes>
					<PrivateRouter/>
				</div>
			</div>
			 {(CurrLocation !== '/admin/login' && !locArr.includes('test') && !locArr.includes('exam'))
				&&
				<Footer />
			}
		</>
	)
}
import axios from "axios";
import env from "../env";

const httpInstance = axios.create({
  // baseURL: 'http://185.215.187.9/gapla-host/api/v1',
  // baseURL: 'http://localhost:8088/gapla-app/api/v1',
  // baseURL: 'https://localhost:8088/api/v1',



  // baseURL: 'https://185.215.187.165:8088/gapla-app/api/v1',
  // baseURL: 'https://185.215.187.165:8088/api/v1',
  baseURL: 'https://dil.md:8088/api/v1',
  // baseURL: 'https://localhost:8080/api/v1',
})

export default httpInstance;
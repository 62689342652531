import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment/moment";

import PastLesson from "./PastLesson/PastLesson";
import FutureLesson from "./FutureLesson/FutureLesson";

import {getAllLessons} from "../../../store/studying/studyingOnlineLessonSlice";

import MuiLoader from "../../../shared/ui/MuiLoader/MuiLoader";

import "./style.scss"

export default function OnlineLesson() {

	let dispatch = useDispatch()
	let navigate = useNavigate()
	const params = useParams()

	let lessons = useSelector(state => state.studyingOnlineLesson.lessons)
	let loading = useSelector(state => state.studyingOnlineLesson.loading)



	useEffect(() => {
		dispatch(getAllLessons(params.courseId))
	}, [])

	console.log(lessons)



	return (
		<div className="online-lesson">
			<div className="online-lesson__content-w">
				<div className="OnlineLessonHeader">
					<h3 className="title">Онлайн Уроки</h3>
					{/*<button className="createLesson"*/}
					{/*		onClick={() => navigate('create')}*/}
					{/*>*/}
					{/*	<span>Посмотреть все</span>*/}
					{/*	<div className="icon">*/}
					{/*		<svg width="31" height="16" viewBox="0 0 31 16" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
					{/*			<path d="M30.7071 8.70711C31.0976 8.31658 31.0976 7.68342 30.7071 7.29289L24.3431 0.928932C23.9526 0.538408 23.3195 0.538408 22.9289 0.928932C22.5384 1.31946 22.5384 1.95262 22.9289 2.34315L28.5858 8L22.9289 13.6569C22.5384 14.0474 22.5384 14.6805 22.9289 15.0711C23.3195 15.4616 23.9526 15.4616 24.3431 15.0711L30.7071 8.70711ZM0 9H30V7H0V9Z" fill="#666666"/>*/}
					{/*		</svg>*/}
					{/*	</div>*/}
					{/*</button>*/}
				</div>
				<div className="OnlineLessonContent">
					<div className="pastLessons">
						{
							loading ?
								<MuiLoader color={'#2ECC71'}/>
								:
								lessons?.slice().sort((a, b) => moment(b.meetingDate, 'DD.MM.YY') < moment(a.meetingDate, 'DD.MM.YY')? 1 : -1).map((lesson) => {
									if (new Date(lesson.meetingDate).getTime() < Date.now()) {
										return (
											<PastLesson
												key={lesson.meetingsId}
												lesson={lesson}
											/>
										)
									}
								})
						}
					</div>
					<div className="futureLessons">
						<h3 className="title">Предстоящие уроки</h3>
						<div className="futureLessons_content">
							{
								loading ?
									<MuiLoader color={'#2ECC71'}/>
									:
									lessons?.map((lesson) => {
										if (new Date(lesson.meetingDate).getTime() > Date.now()) {
											return (
												<FutureLesson
													key={lesson.meetingsId}
													lesson={lesson}
												/>
											)
										}
									})
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
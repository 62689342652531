import http from "./index";

export let getStudentsFromDB = (params) => {
	return http.post(
		`/account/list/view`,
		params, {
			headers: {
				'Access-Control-Allow-Origin': '*',
			}
		}
	)
		.then(function (response) {
			// handle success
			// console.log(response.data[0])
			if (response.data) {
				return response.data
			}
		})
		.catch(function (error) {
			// handle error
			console.log(error)
		})
		.finally(function () {
			// always executed

		});
}
export let getCoursesFromBD = (params, token) => {
	return http.post(
		`/course/page`,
		params, {
			headers: {
				'Access-Control-Allow-Origin': '*',
				'AuthorizationToken': token,
			}
		}
	)
		.then(function (response) {
			// handle success
			// console.log(response.data[0])
			if (response.data) {
				return response.data
			}
		})
		.catch(function (error) {
			// handle error
			console.log(error)
		})
		.finally(function () {
			// always executed

		});
}
export let getEnrolledStudentsFromBD = (params, id) => {
	return http.post(
		`/meeting/participants/` + id,
		params, {
			headers: {
				'Access-Control-Allow-Origin': '*'
			}
		}
	)
		.then(function (response) {
			// handle success
			// console.log(response.data[0])
			if (response.data) {
				return response.data
			}
		})
		.catch(function (error) {
			// handle error
			console.log(error)
		})
		.finally(function () {
			// always executed

		});
}
export let getCourseOnlineLessonsFromBD = (params) => {
	return http.post(
		`/meeting/list`,
		params, {
			headers: {
				'Access-Control-Allow-Origin': '*',
			}
		}
	)
		.then(function (response) {
			// handle success
			// console.log(response.data[0])
			if (response.data) {
				return response.data
			}
		})
		.catch(function (error) {
			// handle error
			console.log(error)
		})
		.finally(function () {
			// always executed

		});
}
export let updateLessonInBD = (params, token) => {
	return http.put(
		`/meeting/lesson-online/update`,
		params, {
			headers: {
				'Access-Control-Allow-Origin': '*',
				'AuthorizationToken': token,
			}
		}
	)
		.then(function (response) {
			// handle success
			// console.log(response.data[0])
			if (response.data) {
				return response.data
			}
		})
		.catch(function (error) {
			// handle error
			console.log(error)
		})
		.finally(function () {
			// always executed

		});
}
export let createLessonInBD = (params, token) => {
	return http.post(
		`/meeting/lesson-online/add`,
		params, {
			headers: {
				'Access-Control-Allow-Origin': '*',
				'AuthorizationToken': token,
			}
		}
	)
		.then(function (response) {
			// handle success
			// console.log(response.data[0])
			if (response.data) {
				return response.data
			}
		})
		.catch(function (error) {
			// handle error
			console.log(error)
		})
		.finally(function () {
			// always executed

		});
}
export let addLessonParticipantsInBD = (params, token) => {
	return http.post(
		`/meeting/teach/link`,
		params, {
			headers: {
				'Access-Control-Allow-Origin': '*',
				'AuthorizationToken': token,
			}
		}
	)
		.then(function (response) {
			// handle success
			// console.log(response.data[0])
			if (response.data) {
				return response.data
			}
		})
		.catch(function (error) {
			// handle error
			console.log(error)
		})
		.finally(function () {
			// always executed

		});
}
export let removeLessonParticipantsInBD = (params, token) => {
	return http.post(
		`/meeting/teach/unlink`,
		params, {
			headers: {
				'Access-Control-Allow-Origin': '*',
				'AuthorizationToken': token,
			}
		}
	)
		.then(function (response) {
			// handle success
			// console.log(response.data[0])
			if (response.data) {
				return response.data
			}
		})
		.catch(function (error) {
			// handle error
			console.log(error)
		})
		.finally(function () {
			// always executed

		});
}

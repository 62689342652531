import React from 'react'
import MuiPagination from '../../../../shared/ui/pagination/Pagination'

export let StudentsPagination = ({ pagination, onPageChange }) => {

    return (
        <div className="StudentsPagination">
            <MuiPagination
                pagesCount={pagination.totalPages}
                setPage={onPageChange}
                currentPage={pagination.pageNumber}
            />
        </div>
    )
}
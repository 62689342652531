import { createSlice } from '@reduxjs/toolkit'
import { getOneCourseByIdFromBD } from '../../api/studying'
import { getCookie } from '../../shared/functions'


const initialState = {
    info: {},
    loading: false,
}

export const studyingInfoSlice = createSlice({
    name: 'studyingInfo',
    initialState,
    reducers: {
        SET_INFO: (state, action) => {
            state.info = action.payload
            state.loading = false
        },
        SET_LOADING: (state, action) => {
            state.loading = action.payload
        }
    },
})



export const getCourseInfo = (id) => async (dispatch, getState) => {
    dispatch(SET_LOADING(true))

    // const params = prepareCourseInfoParams(lang)
    let token = getCookie('userToken')
    const Response = await getOneCourseByIdFromBD(id, token)
    dispatch(SET_INFO(Response))
}

export const { SET_INFO, SET_LOADING } = studyingInfoSlice.actions

export default studyingInfoSlice.reducer
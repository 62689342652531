import { createSlice } from '@reduxjs/toolkit'
import { getCurrExamByIdFromBD, getExamFromBD, getExamIndexInfoFromBD } from '../../api/exam'
import { getQuickCheckInfoFromBD } from '../../api/test'
import { getCookie, getNextTestQuestion, getRandomStr, setCookie } from '../../shared/functions'
import { examHardCode1, examHardCode2, examHardCode3 } from './examHardCode'

const initialState = {
    exam: {},
    examTime: {
        start: null,
        end: null,
    },
    loading: false,
}

export const examSlice = createSlice({
    name: 'exam',
    initialState,
    reducers: {
        SET_EXAM: (state, action) => {
            state.exam = action.payload
            state.loading = false
        },
        UPDATEANSWER_EXAM: (state, action) => {
            state.exam[action.payload.currChapter]?.map((task) => {
                if (task.examTaskId == action.payload.currQuestion.examTaskId) {
                    task.questions.forEach((question) => {
                        if (question.examQuestionId == action.payload.questionId) {
                            question.userAnswer = action.payload.answer
                        }
                    })
                }
            })
        },
        UPDATEANSWERED_FLAG: (state, action) => {
            state.exam[action.payload.currChapter]?.map((task) => {
                if (task.examTaskId == action.payload.currQuestion.examTaskId) {
                    task.questions.forEach((question) => {
                        if (question.userAnswer.length > 0) {
                            question['answered'] = true
                        } else {
                            question['answered'] = false
                        }
                    })
                }
            })
        },
        UPDATEUDIOAMOUNT: (state, action) => {
            state.exam[action.payload.currChapter]?.map((item) => {
                if (item.examTaskId == action.payload.currQuestion.examTaskId) {
                    if (item.audioAmount) {
                        item.audioAmount += action.payload.audioAmount
                    } else {
                        item['audioAmount'] = action.payload.audioAmount
                    }
                }
            })
        },
        SET_LOADING: (state, action) => {
            state.loading = action.payload
        },
        SET_EXAMTIME: (state, action) => {
            state.examTime = action.payload
        }
    },
})

export const getExam = (id, lang) => async (dispatch, getState) => {
    dispatch(SET_LOADING(true))

    let getObjWithIds = (examResponse) => {
        let obj = {...examResponse}
        Object.entries(obj).forEach((field) => {
            if (field[0] == 'reading' || field[0] == 'listening') {
                field[1].forEach((task) => {
                    task.questions.forEach((question) => {
                        question.examQuestionId += getRandomStr(10)
                        question['userAnswer'] = []
                        question.variants.forEach((variant) => {
                            variant.examQuestionAnswerId += getRandomStr(10)
                        })
                    })
                })
            }
        })
        return obj
    }

    // Временная функция
    let getHardCodeExamById = (id) => {
        let exam
        switch (+id) {
            case 1:
                exam = examHardCode1
                break;

            case 2:
                exam = examHardCode2
                break;

            /* case 3:
                exam = examHardCode3
                break; */

            default:
                break;
        }
        return exam
    }

    let examId = getCookie('currExamId')
    if (examId) {
        // let examResponse = await getCurrExamByIdFromBD(examId, lang)
        let examResponse = getHardCodeExamById(examId)
        console.log(examResponse)
        const examInfo = await getExamIndexInfoFromBD(3, lang)
        examResponse['examTime'] = examInfo.testTime
        examResponse = getObjWithIds(examResponse)
        dispatch(SET_EXAM(examResponse))
    } else {
        // let examResponse = await getExamFromBD(id, lang)
        let examResponse = getHardCodeExamById(id)
        const examInfo = await getExamIndexInfoFromBD(3, lang)
        console.log(examResponse, id)
        examResponse['examTime'] = examInfo.testTime
        examResponse = getObjWithIds(examResponse)
        dispatch(SET_EXAM(examResponse))
        setCookie('currExamId', examResponse.examId, Date.now() + examInfo.testTime * 60 * 1000)
    }
}

export const { SET_EXAM, UPDATEANSWER_EXAM, SET_LOADING, SET_EXAMTIME, UPDATEANSWERED_FLAG, UPDATEUDIOAMOUNT } = examSlice.actions

export default examSlice.reducer
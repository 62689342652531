import React, {useEffect, useState} from "react";
import UserImg from "../../../shared/ui/img/TaecherCabinet/userImg.svg"
import CoursCard from "../../UserCabinetPages/Courses/CoursCard/CoursCard";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {getSingleStudent} from "../../../store/teacherCabinet/teacherStudents/StudentsSlice";
import "./style.scss"
import {getDateByTimeStamp} from "../../../shared/functions";


export default function SingleStudentPage() {

	const navigate = useNavigate()
	const dispatch = useDispatch()
	const params = useParams()

	const lang = useSelector(state => state.global.lang)
	const student = useSelector(state => state.students.student)
	console.log(student)

	useEffect(() => {
		dispatch(getSingleStudent(lang, params.id))
	},[])

	// let [canEdit, setCanEdit] = useState(false)
	//
	// const [value, setValue] = useState()
	//
	// const onChange = (e) => {
	// 	setValue(e.target.value)
	// }
	//
	// let getFormData = (e) => {
	// 	e.preventDefault()
	// 	let arr = e.target.querySelectorAll('input')
	// 	let formData = {}
	// 	arr.forEach((input) => {
	// 		if (input.tagName == "INPUT" && input.type != "submit") {
	// 			formData[input.name] = input.value
	// 		}
	// 	})
	// 	console.log(formData)
	// 	// Данные из формы: formData
	// }
	//
	// let studentStatus = false

	return(
		<div className="student__wrapper">
			<div className="student__head">
				<div className="student__head-info">
					<div className="student__head-info-arrow" onClick={() => navigate('/teachercabinet/students')}>
						<svg width="40" height="16" viewBox="0 0 40 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M39 9C39.5523 9 40 8.55228 40 8C40 7.44772 39.5523 7 39 7V9ZM0.292892 7.29289C-0.0976295 7.68342 -0.0976295 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41422 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM39 7L1 7V9L39 9V7Z" fill="white"/>
						</svg>
					</div>
					<img src={UserImg}/>
					<span className="student__head-info-name">{student.fio}</span>
					<span className="student__head-info-visit">Последний визит : <span className="student__head-info-visit student__head-info-visit_bolt">{getDateByTimeStamp(student.lastVisit, '.')}</span></span>
				</div>
				{/*<button type="submit" form="form" className="student__head-button">Сохранить</button>*/}
			</div>
			<div className="student__content">
				{/*<div className="student__content-head">*/}
				{/*	<h2 className="student__content-head-title">Информация об учетной записи пользователя</h2>*/}
				{/*	<button className="student__head-button student__head-button_white">Исключить Ученика</button>*/}
				{/*</div>*/}
				{/*<div className="student__content-form">*/}
				{/*	<form className="form" id="form" onSubmit={getFormData}>*/}
				{/*		<div className="inputs">*/}
				{/*			<div className="left">*/}
				{/*				<div className="inputWrapper">*/}
				{/*					<label className="label">Фамилия</label>*/}
				{/*					<input type="text" name="lastName"  value={value} onChange={(e) => onChange(e)} disabled={!canEdit} defaultValue={"Иванов"} />*/}
				{/*				</div>*/}
				{/*				<div className="inputWrapper">*/}
				{/*					<label className="label">Имя</label>*/}
				{/*					<input type="text" name="Name" value={"Иванов"} disabled={!canEdit} />*/}
				{/*				</div>*/}
				{/*				<div className="inputWrapper">*/}
				{/*					<label className="label">Отчество</label>*/}
				{/*					<input type="text" name="otName" value={"Иванов"} disabled={!canEdit} />*/}
				{/*				</div>*/}
				{/*				<div className="inputWrapper">*/}
				{/*					<label className="label">Страна</label>*/}
				{/*					<input type="text" name="country" value={"Иванов"} disabled={!canEdit} />*/}
				{/*				</div>*/}
				{/*			</div>*/}
				{/*			<div className="right">*/}
				{/*				<div className="inputWrapper">*/}
				{/*					<label className="label">Город</label>*/}
				{/*					<input type="text" name="town" value={"Иванов"} disabled={!canEdit} />*/}
				{/*				</div>*/}
				{/*				<div className="inputWrapper">*/}
				{/*					<label className="label">Дата Рождения</label>*/}
				{/*					<input type="text" name="birthday" value={"25.02.1995"} disabled={!canEdit} />*/}
				{/*				</div>*/}
				{/*				<div className="inputWrapper">*/}
				{/*					<label className="label">ID</label>*/}
				{/*					<input type="text" name="id" value={"123454"} disabled={!canEdit} />*/}
				{/*				</div>*/}
				{/*				<div className="inputWrapper">*/}
				{/*					<label className="label">Статус</label>*/}
				{/*					<input type="text" name="status" value={"Не активный"} disabled={!canEdit} style={ studentStatus ? { color: "#FFFFFF"}: { color: "#FF0000"} }/>*/}
				{/*				</div>*/}
				{/*			</div>*/}
				{/*		</div>*/}
				{/*		{*/}
				{/*			canEdit*/}
				{/*				?*/}
				{/*				''*/}
				{/*				:*/}
				{/*				<button type="button" className="student__head-button"*/}
				{/*					 onClick={() => setCanEdit(true)}*/}
				{/*				>Редактировать</button>*/}
				{/*		}*/}
				{/*	</form>*/}
				{/*</div>*/}
				<div className="student__content-progress">
					<h2 className="student__content-head-title student__content-head-title_margin">Прогресс обучения</h2>
					<CoursCard title={"Курс на степень A1"} category={"A1"} result={86} buttonStyle={{display: 'none'}} style={{border: '2px solid #FFFFFF', margin: '0 auto', boxShadow: "0px 0px 4px 4px rgba(255, 255, 255, 0.35)"}} inProgress={true} />
				</div>
			</div>
		</div>
	)
}
import React, { useState } from "react";
import "./style.scss"
import Button from "../../../shared/ui/Button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getCookie, getDateByTimeStamp } from "../../../shared/functions";
import { updateUserByTokenToDB } from "../../../api/auth";
import { SET_LOGGEDUSER } from "../../../store/globalSlice";
import { SET_SIGNIN } from "../../../store/registrationForm/registrationFormSlice";
import { COUNTRIES } from "../../../shared/directories";
import MuiLoader from "../../../shared/ui/MuiLoader/MuiLoader";


export default function ProfilePage() {

	let dispatch = useDispatch()
	let navigate = useNavigate()

	let [canEdit, setCanEdit] = useState(false)
	let [loading, setLoading] = useState(false)
	const [saveMessage, setSaveMessage] = useState({
		show: false,
		success: false,
		error: false,
		successText: 'Сохранено!',
		errorText: 'Ошибка сохранения! Проверьте введенные данные и попробуйте еще раз'
	})

	let getFormData = (e) => {
		e.preventDefault()
		setLoading(true)
		let arr = e.target.querySelectorAll('.field')
		let formData = {}
		arr.forEach((input) => {
			if (input.tagName == "INPUT" || input.tagName == "SELECT" && input.type != "submit" ) {
				if (input.name == 'password' && input.value !== '') {
					formData[input.name] = input.value
				} else if (input.name !== 'password') {
					if (input.name == 'birthdate') {
						if (input.value) {
							formData[input.name] = new Date(input.value).toISOString()
						} else {
							formData[input.name] = null
						}
					} else {
						if (input.value) {
							formData[input.name] = input.value
						} else {
							formData[input.name] = null
						}
					}
				}
			}
		})

		console.log(formData)
		async function saveUser(formData) {
			let token = getCookie('userToken')
            if (token) {
                let response = await updateUserByTokenToDB(formData, token)
				if (response) {
					setSaveMessage({...saveMessage, show: true, error: false, success: true})
				} else {
					setSaveMessage({...saveMessage, show: true, error: true, success: false})
				}
				setTimeout(() => {
					setSaveMessage({...saveMessage, show: false, error: false, success: false})
				}, 5000)
            } else {
                dispatch(SET_LOGGEDUSER(null))
                dispatch(SET_SIGNIN(true))
                navigate('/')
            }
			setLoading(false)
		}
		saveUser(formData)
	}

	let loggedUser = useSelector(state => state.global.loggedUser)

	return (
		<div className="ProfilePage">
			<form className="form" onSubmit={getFormData}>
				<div className="inputs">
					<div className="left">
						<div className="inputWrapper">
							<label className="label">Фамилия</label>
							<input className="field" type="text" name="fam" placeholder="Иванов" disabled={!canEdit}
								defaultValue={loggedUser?.fam ? loggedUser?.fam : ''}
							/>
						</div>
						<div className="inputWrapper">
							<label className="label">Имя</label>
							<input  className="field" type="text" name="im" placeholder="Иванов" disabled={!canEdit}
								defaultValue={loggedUser?.im ? loggedUser?.im : ''}
							/>
						</div>
						<div className="inputWrapper">
							<label className="label">Отчество</label>
							<input  className="field" type="text" name="ot" placeholder="Иванов" disabled={!canEdit}
								defaultValue={loggedUser?.ot ? loggedUser?.ot : ''}
							/>
						</div>
						<div className="inputWrapper">
							<label className="label">Дата Рождения</label>
							<input  className="field dateField" type="date" name="birthdate" placeholder="Иванов" disabled={!canEdit}
								defaultValue={loggedUser?.birthdate ? getDateByTimeStamp(loggedUser?.birthdate, '-', true) : ''}
							/>
						</div>
						<div className="inputWrapper">
							<label className="label">Пол</label>
							<select 
								className="field userTypeSelect" 
								name="gender"
								defaultValue={loggedUser?.gender ? `${loggedUser?.gender}` : ''}
								disabled={!canEdit}
							>
								<option value="1">Мужской</option>
								<option value="0">Женский</option>
							</select>
						</div>
						<div className="inputWrapper">
							<label className="label">IDNP</label>
							<input  className="field" type="text" name="passportNumber" placeholder="Иванов" disabled={!canEdit}
								defaultValue={loggedUser?.passportNumber ? loggedUser?.passportNumber : ''}
							/>
						</div>
					</div>
					<div className="right">
					<div className="inputWrapper">
							<label className="label">Nickname</label>
							<input  className="field" type="text" name="userName" placeholder="Иванов" disabled={!canEdit}
								defaultValue={loggedUser?.userName ? loggedUser?.userName : ''}
							/>
						</div>
						<div className="inputWrapper">
							<label className="label">Страна</label>
							<select 
								className="field userTypeSelect" 
								name="country"
								defaultValue={loggedUser?.country ? loggedUser?.country : ''}
								disabled={!canEdit}
							>
								{
									COUNTRIES.map((item) => {
										return <option value={item} key={item}>{item}</option>
									})
								}
							</select>
						</div>
						<div className="inputWrapper">
							<label className="label">Город</label>
							<input  className="field" type="text" name="town" placeholder="Иванов" disabled={!canEdit}
								defaultValue={loggedUser?.town ? loggedUser?.town : ''}
							/>
						</div>
						<div className="inputWrapper">
							<label className="label">Электронный адрес</label>
							<input  className="field" type="email" name="email" placeholder="Иванов" disabled={!canEdit}
								defaultValue={loggedUser?.email ? loggedUser?.email : ''}
							/>
						</div>
						<div className="inputWrapper">
							<label className="label">Телефон</label>
							<input  className="field" type="tel" name="phoneNumber" placeholder="60889900" disabled={!canEdit}
								defaultValue={loggedUser?.phoneNumber ? loggedUser?.phoneNumber : ''}
							/>
						</div>
						<div className="inputWrapper">
							<label className="label">Пароль</label>
							<input  className="field" type="password" name="password" placeholder="qwerty123" disabled={!canEdit} />
						</div>
					</div>
				</div>
				{
					canEdit
					?
					<Button
						type={'submit'}
						text={'Сохранить'}
						func={() => {console.log('Сохранить')}}
						style={{
							backgroundColor: '#2ECC71',
							padding: '14px ' + ((window.innerWidth > 500) ? '138px' : '42px'),
							fontFamily: 'Gilroy',
							fontStyle: 'normal',
							fontWeight: 600,
							fontSize: ((window.innerWidth > 500) ? '24px' : '19.2px'),
							lineHeight: ((window.innerWidth > 500) ? '29px' : '23.2px'),
							color: '#FFFFFF',
						}}
					/>
					:
					<div className="button"
						onClick={() => setCanEdit(true)}
					>Редактировать</div>
				}

				{
					loading &&
					<MuiLoader color={'#2ECC71'}/>
				}
				{
					saveMessage.show &&
					<div className="saveMessage">
						{
							saveMessage.success ?
							<span className="success">{saveMessage.successText}</span> :
							<span className="error">{saveMessage.errorText}</span>
						}
					</div>
				}
			</form>
		</div>
	)
}
import React, { useState } from 'react';
// import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import { Menu } from 'antd';

const AntdMenu = ({ Items, rootSubmenuKeys_, onClickFunc, className, defaultSelectedKeys, defaultOpenKeys }) => {

    const items = Items
    const rootSubmenuKeys = rootSubmenuKeys_;
    const [openKeys, setOpenKeys] = useState(['sub1']);
    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };
    return (
        <Menu
            mode="inline"
            className={className}
            openKeys={openKeys}
            onClick={({ item_, key, keyPath, domEvent }) => onClickFunc( keyPath )}
            onOpenChange={onOpenChange}
            items={items}
            selectable={true}
            defaultSelectedKeys={defaultSelectedKeys}
            defaultOpenKeys={defaultOpenKeys}
        />
    );
};
export default AntdMenu;
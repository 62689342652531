import React, {useEffect, useState} from "react";

import GoogleImg from "../../../shared/ui/img/RegistrationForm/googleImg.svg"
import FacebookImg from "../../../shared/ui/img/RegistrationForm/facebookImg.svg"
import AppleImg from "../../../shared/ui/img/RegistrationForm/appleImg.svg"
import VkImg from "../../../shared/ui/img/RegistrationForm/vkImg.svg"
import VisionImg from "../../../shared/ui/img/RegistrationForm/visionImg.svg"

import {useDispatch} from "react-redux";
import {SET_FORMACTIVE, SET_SIGNUP} from "../../../store/registrationForm/registrationFormSlice";
import {useInput} from "../../../shared/functions/useInput"

import "./style.scss"
import { authUser, getUserByTokenFromDB } from "../../../api/auth";
import { useNavigate } from "react-router";
import { parseJwt, setCookie, setCookie2 } from "../../../shared/functions";
import { SET_LOGGEDUSER } from "../../../store/globalSlice";

export default function SignIn() {

	const dispatch = useDispatch()
	const navigate = useNavigate()

	const [showPassword, setShowPassword] = useState(false)
	const [authMessage, setAuthMessage] = useState({
		show: false,
		success: false,
		error: false,
		successText: 'Вы успешно авторизовались!',
		errorText: 'Ошибка авторизации! Проверьте логин или пароль и попробуйте еще раз'
	})

	const email = useInput('', {isEmail: true})
	const password = useInput('', {isEmpty: true, minLength: 0})

	async function getFormData(e) {
		e.preventDefault()
		let form = e.target
		let formData = {}
		Array.from(form).forEach((item) => {
			if (item.matches('.signin-form__input')) {
				formData[item.name] = item.value
			}
		})
		let result = await authUser(formData)
		
		if (result) {
			setAuthMessage({...authMessage, show: true, error: false, success: true})
			let User = await getUserByTokenFromDB(result.token)
			let tokenData = parseJwt(result.token)
			dispatch(SET_LOGGEDUSER({...tokenData, ...User}))
			// setCookie('userToken', result.token, tokenData.exp * 1000)
			setCookie2('userToken', result.token, {expires: tokenData.exp + 52641});
			let path = await User?.roles[0]?.accountRoleName == 'STUDENT' ? '/usercabinet' : User?.roles[0]?.accountRoleName == 'TEACHER' ? '/teachercabinet' : User?.roles[0]?.accountRoleName == 'ADMIN' && '/admincabinet'
			setTimeout(() => {
				dispatch(SET_FORMACTIVE(false))
				setAuthMessage({...authMessage, show: false, error: false, success: false})
				navigate(path)
			}, 1000);
		} else {
			setAuthMessage({...authMessage, show: true, error: true, success: false})
		}
	}

	return (
		<>
			<h2 className="signin-title">С возварщением ! </h2>
			<form className="signin-form" id="form"
				onSubmit={getFormData}
			>
				<label htmlFor="email">Электронный адрес</label>
				{(email.isDirty && email.isEmailError) &&
					<div className="form-page__form-error">Не корректный емайл</div>}
				<input 
					onChange={e => {
						email.onChange(e)
					}}
					value={email.value}
					onBlur={e => email.onBlur(e)}
					type="email"
					name="email"
					placeholder="example@yahoo.com"
					className="signin-form__input"
					id="email"
				/>

				<label htmlFor="password">Пароль</label>
				{(password.isDirty && password.minLengthError) &&
					<div className="form-page__form-error">Не корректная длинна</div>}
				<div className="signin-form-password_container">
					<input 
						onChange={e => {
							password.onChange(e)
						}} 
						value={password.value} 
						onBlur={e => password.onBlur(e)}
						type={showPassword ? "text" : "password"}
						name="password"
						placeholder="Введите пароль"
						className="signin-form__input signin-form__input_last"
						id="password"
					/>
					<button onClick={() => setShowPassword(!showPassword)} type='button' className="signin-form-password_container_button"><img src={VisionImg}/></button>
				</div>
				<button type="button" className="signin-form__button-forgot">Забыли пароль ?</button>
			</form>
			<button disabled={email.inputValid || password.inputValid || password.minLengthError} type="submit"
					className={email.inputValid || password.inputValid || password.minLengthError ? "signin-form__button-submit_disabled signin-form__button-submit" : "signin-form__button-submit"}
					form="form">Войти
			</button>
			{
				authMessage.show &&
				<div className="authMessage">
					{
						authMessage.success ?
						<span className="success">{authMessage.successText}</span> :
						<span className="error">{authMessage.errorText}</span>
					}
				</div>
			}
			
			<span className="signin-divider">или</span>
			<div className="signin-footer">
				<span className="signin-footer__desc">Продолжить с помощью :</span>
				<div className="signin-footer__link">
					<img src={GoogleImg}/>
					<img src={FacebookImg}/>
					<img src={AppleImg}/>
					<img src={VkImg}/>
				</div>
			</div>
			<div className="signin-footer-bottom">
				<span className="signin-footer-bottom-desc">Нет аккаунта ?</span>
				<button className="signin-footer-bottom-btn" onClick={() => dispatch(SET_SIGNUP(true))}>
					<span>Зарагестрироваться</span></button>
			</div>
			<div className="signin-footer-bottom2">
				<span>Я принимаю Условия использования и Уведомление о конфиденциальности DIL. Возникли проблемы со входом в аккаунт? Центр поддержки учащихся</span>
				<br/>
				<span>Этот сайт защищен технологией reCAPTCHA Enterprise, и к нему применяется Политика конфиденциальности и Правилами пользования Google.</span>
			</div>
		</>
	)
}
import React, {useEffect, useState} from "react";
import "./style.scss";
import Logo from "../../../shared/ui/Logo";
import {Link, useNavigate} from "react-router-dom";
import MenuIcon from "../img/menu.svg"
import CloseMenuIcon from "../img/closeMenu.svg"
import InstagramIcon from "../../../shared/ui/img/instagram.svg"
import TelegramIcon from "../../../shared/ui/img/telegram.svg"
import FacebookIcon from "../../../shared/ui/img/facebook.svg"
import YoutubeIcon from "../../../shared/ui/img/youtube.svg"
import LanguageIcon from "../img/language.svg"
import {SignBtnBurger} from "../../../shared/ui/SignBtnBurger";
import {useDispatch, useSelector} from "react-redux";
import { SET_FORMACTIVE, SET_SIGNIN, SET_SIGNUP} from "../../../store/registrationForm/registrationFormSlice";
import SelectLang from "../../../features/SelectLang/ui/SelectLang";
import { deleteCookie, getCookie, getCuttedString, parseJwt } from "../../../shared/functions";
import { SET_LOGGEDUSER } from "../../../store/globalSlice";
import Button from "../../../shared/ui/Button";
import { getUserByTokenFromDB } from "../../../api/auth";

export const Header = () => {

	let navigate = useNavigate()
	const dispatch = useDispatch()

	let [openDropdownMenu, setOpenDropdownMenu] = useState(false)
	let [showSignModal, setshowSignModal] = useState(false)

	let loggedUser = useSelector(state => state.global.loggedUser)

	useEffect(() => {
		async function fetchData() {
            let token = getCookie('userToken')
            if (token) {
				let user = await getUserByTokenFromDB(token)
                dispatch(SET_LOGGEDUSER(user))
            } else {
                dispatch(SET_LOGGEDUSER(null))
                /* dispatch(SET_SIGNIN(true))
                navigate('/') */
            }
        }
        fetchData();
	}, [])

	let logOut = () => {
		deleteCookie('userToken')
		dispatch(SET_LOGGEDUSER(null))
		navigate('/')
	}

	return (
		<>
			<header className="Header">
				<div className="Header_content">
					<div className="Header_left">
						<button className="Menu_button" onClick={() => setOpenDropdownMenu(!openDropdownMenu)}>
							<img className="Menu_icon" src={openDropdownMenu ? CloseMenuIcon : MenuIcon}/>
							<div className="Menu_desc">МЕНЮ</div>
						</button>
					</div>
					<div className="Logo">
						<Link onClick={() => setOpenDropdownMenu(false)} to="/">
							<Logo/>
						</Link>
					</div>
					<div className="Header_right">
						<div className="Header_right_left">
							<Link onClick={() => {setOpenDropdownMenu(false)}} to="/usercabinet/courses">Обучение</Link>
							<Link onClick={() => setOpenDropdownMenu(false)} to="test"><span>Тест</span></Link>
						</div>
						<div className="Header_right_right">
							<SelectLang
								place={'header'}
								icon={LanguageIcon}
							/>
							<div className="Header_signInUp">
								{
									loggedUser !== null
									?
									<button className="loggedUser"
										onClick={() => navigate(loggedUser?.roles[0]?.accountRoleName == 'STUDENT' ? '/usercabinet' : loggedUser?.roles[0]?.accountRoleName == 'TEACHER' ? '/teachercabinet/profile' : loggedUser?.roles[0]?.accountRoleName == 'ADMIN' ? '/admincabinet/profile' : '')}
									>
										<div className="avatar">
											<img src="" alt="" />
										</div>
										<span>{getCuttedString(loggedUser?.email, 13)}</span>
									</button>
									:
									<>
									<button className="SignIn" onClick={() => dispatch(SET_SIGNIN(true))}>Вход</button>
									<span className="slash">/</span>
									<button className="SignUp" onClick={() => dispatch(SET_SIGNUP(true))}>Регистрация</button>
									</>
								}
								
							</div>
							<SignBtnBurger
								onClick={(value) => setshowSignModal(value)}
							/>
							{
								showSignModal &&
								<div className="Header_signInUpMobile">
									<button className="SignIn" onClick={() => dispatch(SET_SIGNIN(true))}>Вход</button>
									<button className="SignUp" onClick={() => dispatch(SET_SIGNUP(true))}>Регистрация</button>
								</div>
							}
						</div>
						{
							loggedUser !== null &&
							<Button
								className={'btn2'}
								type={'button'}
								text={'Выйти'}
								func={() => logOut()}
								style={{
									color: '#2ECC71',
									padding: '8px 10px',
									marginLeft: '20px',
									border: 'none'
								}}
							/>
						}
					</div>
				</div>
			</header>
			<div className={openDropdownMenu ? "Header_dropdown active" : "Header_dropdown"}>
				<div className="Header_dropdown_wrapper">
					<div className="Dropdown_content">
						<div className="Content_left">
							<div className="Left_link">
								<span className="Left_link_number">01</span>
								<Link className="Left_link_desc" onClick={() => setOpenDropdownMenu(false)} to="about">О
									НАС</Link>
							</div>
							<div className="Left_link">
								<span className="Left_link_number">02</span>
								<Link className="Left_link_desc" onClick={() => {setOpenDropdownMenu(false)}} to="/usercabinet/courses">ОБУЧЕНИЕ</Link>
							</div>
							<div className="Left_link">
								<span className="Left_link_number">03</span>
								<Link className="Left_link_desc" onClick={() => setOpenDropdownMenu(false)} to="/test">ТЕСТЫ</Link>
							</div>
							<div className="Left_link">
								<span className="Left_link_number">04</span>
								<Link className="Left_link_desc" onClick={() => setOpenDropdownMenu(false)} to="/contacts">КОНТАКТЫ</Link>
							</div>
							<div className="Left_link">
								<span className="Left_link_number">05</span>
								<Link onClick={() => setOpenDropdownMenu(false)} className="Left_link_desc" to="/forum">ФОРУМ</Link>
							</div>
							<div className="Left_link">
								<span className="Left_link_number">06</span>
								<Link onClick={() => setOpenDropdownMenu(false)} className="Left_link_desc" to="faq">FAQ</Link>
							</div>
						</div>
						<div className="Content_right">
							<div className="Content_right_dil">
								<span className="Content_right_desc">DIL</span>
								<Link className="Right_link_desc" onClick={() => setOpenDropdownMenu(false)}
									  to="ratingscale">Шкала оценки</Link>
								<Link className="Right_link_desc" onClick={() => setOpenDropdownMenu(false)}
									  to="certificate">Сертификация </Link>
								<Link className="Right_link_desc" onClick={() => setOpenDropdownMenu(false)} to="solution">Решение
									для оценки</Link>
								<Link className="Right_link_desc" onClick={() => setOpenDropdownMenu(false)}
									  to="/exam">Экзамен</Link>
							</div>
							<div className="Content_right_contacts">
								<span className="Content_right_desc">КОНТАКТЫ</span>
								<a href="tel:+37379669663"><div className="Content_right_phone" onClick={() => setOpenDropdownMenu(false)}>+373-79-669-663</div></a>
								<a href="mailto:ana-dili@mail.ru"><div className="Content_right_email" onClick={() => setOpenDropdownMenu(false)}>ana-dili@mail.ru</div></a>
							</div>
						</div>
					</div>
					<div className="Dropdown_footer">
						<div className="Dropdown_footer_left">
							<button className="social">
								<img src={InstagramIcon}/>
							</button>
							<button className="social">
								<img src={TelegramIcon}/>
							</button>
							<button className="social">
								<img src={FacebookIcon}/>
							</button>
							<button className="social">
								<img src={YoutubeIcon}/>
							</button>
						</div>
						<div className="Dropdown_footer_right">
							<Link className="Footer_right_desc" onClick={() => setOpenDropdownMenu(false)}
								  to="#">Помощь</Link>
							<Link className="Footer_right_desc" onClick={() => setOpenDropdownMenu(false)} to="#">Технические
								вопросы</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
import React from 'react'
import "./style.scss"
import user1 from "../../../../shared/img/user1.png"
import { getCuttedString } from '../../../../shared/functions'
import { useNavigate } from 'react-router'

export default function PastLesson({ lesson, courseName }) {

    let navigate = useNavigate()

    console.log(lesson)

    return (
        <div className="PastLesson">
            <div className="row">
                <div className="lessonType">
                    <span>{courseName}</span>
                </div>
                <button className="editIcon"
                    onClick={() => navigate('' + lesson.courseId + '_' + lesson.meetingsId)}
                >
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M30.9717 3.00526L28.9854 1.01758C28.332 0.36571 27.447 0 26.5245 0C25.6019 0 24.717 0.36571 24.0636 1.01758L22.3652 2.71655L20.7434 4.33956L4.17375 20.913L4.17762 20.9169V20.9172C4.11323 20.9795 4.06185 21.0545 4.02702 21.1372L1.54747 27.3247L0.0478652 31.0852C-0.0344707 31.2908 -0.00948995 31.5234 0.114366 31.7068C0.238573 31.8902 0.445121 32 0.66644 32C0.750887 32 0.834632 31.9838 0.913098 31.9528L4.67237 30.4527L10.8578 27.9723C10.9405 27.9372 11.0154 27.8861 11.0777 27.8214L11.0816 27.8252L27.6521 11.25L30.2162 8.6851L30.2102 8.67982C31.3013 7.53167 31.8886 6.49968 31.9885 5.53589V5.53554C32.0723 4.57815 31.6947 3.63797 30.972 3.00479L30.9717 3.00526ZM10.6116 26.4088L8.57077 24.3677L24.1982 8.73589L26.239 10.7774L10.6116 26.4088ZM7.62811 23.4244L5.58733 21.3829L21.2148 5.75109L23.2556 7.79607L7.62811 23.4244ZM4.58558 29.0505L2.94834 27.4127L4.88779 22.5705L9.42578 27.11L4.58558 29.0505ZM27.1814 9.83421L22.1582 4.81001L23.3077 3.66223L28.331 8.68715L27.1814 9.83421Z" fill="black"/>
                    </svg>
                </button>
            </div>
            <h5 className="title">{lesson.title}</h5>
            <span>Тема :</span>
            <span>{getCuttedString(lesson?.desctiption != null ? lesson?.desctiption : '', 32)}</span>
            <div className="row">
                <div className="user">
                    <div className="avatar">
                        <img src={lesson.avatar} alt="" />
                    </div>
                    <span className="name">{getCuttedString(lesson.fam != null ? lesson.fam : '', 20)}</span>
                </div>
                <a href={lesson.recordedVideo} target="_blank">
                    <div className="linkIcon">
                        <svg width="31" height="16" viewBox="0 0 31 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M30.7071 8.70711C31.0976 8.31658 31.0976 7.68342 30.7071 7.29289L24.3431 0.928932C23.9526 0.538408 23.3195 0.538408 22.9289 0.928932C22.5384 1.31946 22.5384 1.95262 22.9289 2.34315L28.5858 8L22.9289 13.6569C22.5384 14.0474 22.5384 14.6805 22.9289 15.0711C23.3195 15.4616 23.9526 15.4616 24.3431 15.0711L30.7071 8.70711ZM0 9H30V7H0V9Z" fill="white"/>
                        </svg>
                    </div>
                </a>
            </div>
            <div className="bgImg">
                <svg viewBox="0 0 98 99" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.2823 6.49219L6.50977 22.2247" strokeWidth="12" strokeMiterlimit="10" strokeLinecap="round"/>
                    <path d="M56.3316 8.49023L10.7565 54.9186C9.93035 55.7627 9.93535 57.1112 10.7715 57.9453L44.7901 91.8776C45.6313 92.7167 46.9983 92.7167 47.8395 91.8776L90.856 48.9703C91.6972 48.1312 91.6972 46.7677 90.856 45.9286L75.6292 30.7405C74.788 29.9014 73.421 29.9014 72.5798 30.7405L46.3123 56.9414" strokeWidth="12" strokeMiterlimit="10" strokeLinecap="round"/>
                </svg>
            </div>
        </div>
    )
}
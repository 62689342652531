import React from 'react'

import ShareIcon from "../img/shareIcon.svg"
import ViewsIcon from "../img/viewsIcon.svg"

import "./style.scss"

export let ShareBlock = ({func, style, shareCount, viewsCount}) => {
	return (
		<div className="shareBlock">
			<button className="shareButton" style={style} onClick={() => func()}>
				<img src={ShareIcon}/>
				<span>{shareCount}</span>
			</button>
			<div className="viewsBlock">
				<img src={ViewsIcon}/>
				<span>{viewsCount}</span>
			</div>
		</div>


	)
}
import React from "react";

import DiplomsPhone from "../../../shared/ui/img/UserCabinet/diplomsPhone.png"

import "./style.scss"
import Diplom from "./Diplom/Diplom";


export default function DiplomsPage() {

	return (
		<div className="diploms">
			<div className="diploms__head">
				<div className="diploms__head-left">
					<h2 className="diploms__head-left-title">Как получить диплом ?</h2>
					<span className="diploms__head-left-desc">Gagauz dilinin kullanmak sferalarını genişletmäk hem avtonomiyada gagauz dilinnän şkola öncesi terbietmek sistemasında çalışan personalın profesional-teoretik uurunu kaldırmak, birleştireräk çalışmakları, aniki çözmää deyni avtonomiyanın önündä duran önemni daavaları millet-kultura strategiyaların, avtonomiyanın bilim hem üüretim-üüredici potenţialın gerçekleştirilmesindä girdireräk eni bilim hem pedagogika yaratmaları, proektleri hem tehnologiyaları, angıları doorudulu bütündän regionun uygun soţial uurunda önemni dalların ilerlemesinä hem bilim-üüretim, ekonomika, kultura, soţial potenţialın üüselmesinä, olayların efektivliini üüseltmää neetinnän.</span>
				</div>
				<div className="diploms__head-right">
					<img src={DiplomsPhone}/>
					<ul className="diploms__head-right-list">
						<li>
							<span>01</span>
							Пройдите обучение курса
						</li>
						<li>
							<span>02</span>
							Запишитесь на экзамен
						</li>
						<li>
							<span>03</span>
							Успешно сдайте экзамен
						</li>
						<li>
							<span>04</span>
							Получите диплом, подверждающий ваши знания
						</li>
					</ul>
				</div>
			</div>
			<div className="diploms__wrapper">
				<div className="diploms__wrapper-enroll">
					<h5>Мои дипломы</h5>
					<div className="icon">
						<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
							<circle cx="10.5" cy="10.5" r="10" stroke="#2ECC71"/>
							<path d="M6 9L10.5 13.5L15 9" stroke="#2ECC71" strokeLinecap="round"/>
						</svg>
					</div>
				</div>
				<h2 className="diploms__head-left-title diploms__head-left-title_margin">Мои дипломы :</h2>
				<div className="diploms__wrapper-diploms">
					<Diplom />
					<Diplom />
					<Diplom />
					<Diplom />
					<Diplom />
					<Diplom />
					<Diplom />
				</div>
			</div>

		</div>
	)
}
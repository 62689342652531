import React, { useState } from 'react'
import "./style.scss"

export let SaveIcon = () => {

    let [color, setColor] = useState(null)

    return (
        <div className="saveIcon"
            onClick={() => setColor((color) ? null : '#000')}
        >
            <svg viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.5 0.5H11.5C12.0523 0.5 12.5 0.947715 12.5 1.5V14.9537C12.5 15.7834 11.5479 16.2522 10.8903 15.7463L7.71942 13.3072C7.00052 12.7542 5.99948 12.7542 5.28058 13.3072L2.10971 15.7463C1.45209 16.2522 0.5 15.7834 0.5 14.9537V1.5C0.5 0.947715 0.947715 0.5 1.5 0.5Z" fill={color} stroke="black" />
            </svg>
        </div>
    )
}
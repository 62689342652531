import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { AnswerItem } from '../../AnswerItem'
import { ExamAnswerItem } from '../../ExamAnswerItem/ui/ExamAnswerItem'
import "./style.scss"

export let ExamQuestionAnswer = ({ question, setUserAnswer, userAnswer, showAnswer }) => {

    let params = useParams()

    let getAnswer = (answer) => {
        if (question?.questionType == 'RADIO') {
            setUserAnswer([answer], question.examQuestionId)
        } else if (question?.questionType == 'CHECKBOX') {
            if (answer.action == 'delete') {
                if (userAnswer) {
                    console.log(userAnswer)
                    let arr = userAnswer.filter((item) => item.examQuestionAnswerId !== answer.answer.examQuestionAnswerId)
                    setUserAnswer([...arr], question.examQuestionId)
                }
            } else if (answer.action == 'add') {
                if (userAnswer) {
                    setUserAnswer([...userAnswer, answer.answer], question.examQuestionId)
                } else {
                    setUserAnswer([answer.answer], question.examQuestionId)
                }
            }
        }
    }

    let [ansIds, setAnsIds] = useState([])

    useEffect(() => {
        if (question.userAnswer) {
            let arr = []
            question.userAnswer.forEach(item => {
                arr.push(item.examQuestionAnswerId)
            });
            setAnsIds(arr)
        }
    }, [userAnswer])
    
    /* let [currOptionStyle, setCurrOptionStyle] = useState({})

    useEffect(() => {
        let obj = {}
        if (question?.userAnswer?.length != 0) {
            obj['backgroundColor'] = '#21C5EF'
            obj['color'] = '#fff'
            obj['border'] = 'none'
            obj['borderRadius'] = '10px'
        }
        obj['opacity'] = ((!question.answered) ? 1 : 0.5)
        obj['cursor'] = ((!question.answered) ? "pointer" : "default")
        setCurrOptionStyle(obj)
    }, [question]) */

    return (
        <div className="answers">
            {/* {
                exerciseViewType == '1' || exerciseViewType == '2' ?
                question.variants?.map((item) => {
                    return (
                        <ExamAnswerItem
                            key={item.examQuestionAnswerId}
                            answer={item}
                            inputType={question?.questionType}
                            exerciseViewType={exerciseViewType}
                            getAnswer={getAnswer}
                            radioAnswer={userAnswer?.includes(item)}
                            selected={ansIds.includes(item.examQuestionAnswerId)}
                            allowClick={!question.answered}
                        />
                    )
                })
                :
                <select className="questionTypeSelect"
                    onChange={(e) => getAnswer(e.target.value)}
                    style={currOptionStyle}
                    disabled={question.answered}
                >
                    {
                        question.variants.map((answer) => {
                            if (question.userAnswer.length == 0) {
                                return (
                                    <option key={answer.examQuestionAnswerId} value={answer.examQuestionAnswerId}>{answer.text}</option>
                                )
                            } else {
                                return (
                                    <option selected={question?.userAnswer[0].examQuestionAnswerId == answer.examQuestionAnswerId ? true : false} key={answer.examQuestionAnswerId} value={answer.examQuestionAnswerId}>{answer.value}</option>
                                )
                            }
                        })
                    }
                </select>
            } */}
            {
                question.variants?.map((item) => {
                    return (
                        <ExamAnswerItem
                            key={item.examQuestionAnswerId}
                            answer={item}
                            inputType={question?.questionType}
                            exerciseViewType={'1'}
                            getAnswer={getAnswer}
                            radioAnswer={userAnswer?.includes(item)}
                            selected={ansIds.includes(item.examQuestionAnswerId)}
                            allowClick={!question.answered}
                        />
                    )
                })
            }
        </div>
    )
}
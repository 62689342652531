import React from 'react'
import Breadcrumbs from '../../shared/ui/breadcrumbs/Breadcrumbs'
import "./style.scss"

import aboutTestImg from "../../shared/img/larm-rmah-AEaTUnvneik-unsplash 1.jpg"
import scaleTitleBG from "../../shared/img/scaleTitleBG.jpg"
import levelsBG from "../../shared/img/levelsBG.png"

export default function RatingScalePage() {

    let breadcrumbsItems = [
        {
            slug: '/',
            title: 'Главная',
        },
        {
            slug: '/ratingscale',
            title: 'Шкала оценки',
        },
    ]

    return (
        <div className="RatingScalePage">
            <div className="breadcrumbs_">
                <Breadcrumbs
                    items={breadcrumbsItems}
                />
            </div>
            <section className="levelsTitle">
                <img src={scaleTitleBG} alt="" />
                <h1>Система уровней владения языком</h1>
            </section>
            <section className="aboutTest">
                <div className="left">
                    <span>обзор</span>
                    <h2>О системе</h2>
                    <p>При разработке Европейской системы уровней проводились обширные исследования в разных странах, методики оценки опробовались на практике. В результате пришли к согласию по вопросу о количестве уровней, выделяемых для организации процесса изучения языка и оценки степени владения им. Существует 6 крупных уровней, которые представляют собой более низкие и более высокие подуровни в классической трехуровневой системе, включающей в себя базовый, средний и продвинутые уровни. Схема уровней построена по принципу последовательного разветвления. Она начинается с разделения системы уровней на три крупных уровня - А, В и С.</p>
                </div>
                <div className="right">
                    <div className="img">
                        <img src={aboutTestImg} alt="" />
                    </div>
                </div>
            </section>
            <section className="levels">
                <div className="BG top">
                    <img src={levelsBG} alt="" />
                </div>
                <div className="BG bottom">
                    <img src={levelsBG} alt="" />
                </div>
                <div className="row">
                    <h3>A1 (Уровень выживания)</h3>
                    <p>Понимаю и могу употребить в речи знакомые фразы и выражения, необходимые для выполнения конкретных задач. Могу представиться/ представить других, задавать/ отвечать на вопросы о месте жительства, знакомых, имуществе. Могу участвовать в несложном разговоре, если собеседник говорит медленно и отчетливо и готов оказать помощь.</p>
                </div>
                <div className="row">
                    <h3>A2 (Предпороговый уровень)</h3>
                    <p>Понимаю отдельные предложения и часто встречающиеся выражения, связанные с основными сферами жизни (например, основные сведения о себе и членах своей семьи, покупках, устройстве на работу и т.п.). Могу выполнить задачи, связанные с простым обменом информации на знакомые или бытовые темы. В простых выражениях могу рассказать о себе, своих родных и близких, описать основные аспекты повседневной жизни.</p>
                </div>
                <div className="row">
                    <h3>В1 (Пороговый уровень)</h3>
                    <p>Понимаю основные идеи четких сообщений, сделанных на литературном языке на разные темы, типично возникающие на работе, учебе, досуге и т.д. Умею общаться в большинстве ситуаций, которые могут возникнуть во время пребывания в стране изучаемого языка. Могу составить связное сообщение на известные или особо интересующие меня темы. Могу описать впечатления, события, надежды, стремления, изложить и обосно¬вать свое мнение и планы на будущее.</p>
                </div>
                <div className="row">
                    <h3>В2 (Пороговый продвинутый уровень)</h3>
                    <p>Понимаю общее содержание сложных текстов на абстрактные и конкретные темы, в том числе узкоспециальные тексты. Говорю достаточно быстро и спонтанно, чтобы постоянно общаться с носителями языка без особых затруднений для любой из сторон. Я умею делать четкие, подробные сообщения на различные темы и изложить свой взгляд на основную проблему, показать преимущество и недостатки разных мнений.</p>
                </div>
                <div className="row">
                    <h3>С1 (Уровень профессионального владения)</h3>
                    <p>Понимаю объемные сложные тексты на различную тематику, распознаю скрытое значение. Говорю спонтанно в быстром темпе, не испытывая затруднений с подбором слов и выражений. Гибко и эффективно использую язык для общения в научной и профессиональной деятельности. Могу создать точное, детальное, хорошо выстроенное сообщение на сложные темы, демонстрируя владение моделями организации текста, средствами связи и объединением его элементов.</p>
                </div>
                <div className="row">
                    <h3>A2 (Уровень владения в совершенстве)</h3>
                    <p>Понимаю практически любое устное или письменное сообщение, могу составить связный текст, опираясь на несколько устных и письменных источников. Говорю спонтанно с высоким темпом и высокой степенью точности, подчеркивая оттенки значений даже в самых сложных случаях.</p>
                </div>
            </section>
            <section className="gradesScale">
                <h3>Шкала оценок</h3>
                <div className="content">
                    <div className="row1">
                        <div className="item">
                            <span>{'<A1'}</span>
                        </div>
                        <div className="item">
                            <span>{'A1'}</span>
                        </div>
                        <div className="item">
                            <span>{'A2'}</span>
                        </div>
                        <div className="item">
                            <span>{'B1'}</span>
                        </div>
                        <div className="item">
                            <span>{'<B2'}</span>
                        </div>
                        <div className="item">
                            <span>{'C1'}</span>
                        </div>
                        <div className="item">
                            <span>{'C2'}</span>
                        </div>
                    </div>
                    <div className="row2">
                        <div className="item">
                            <span>1 - 10</span>
                        </div>
                        <div className="item">
                            <span>11 - 30</span>
                        </div>
                        <div className="item">
                            <span>31 - 40</span>
                        </div>
                        <div className="item">
                            <span>41 - 50</span>
                        </div>
                        <div className="item">
                            <span>51 - 60</span>
                        </div>
                        <div className="item">
                            <span>61 - 70</span>
                        </div>
                        <div className="item">
                            <span>71 - 100</span>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
import React from 'react'
import scaleTitleBG from "../../shared/img/scaleTitleBG.jpg"
import Breadcrumbs from '../../shared/ui/breadcrumbs/Breadcrumbs'
import "./style.scss"

import solutionAdvantageIcon1 from "../../shared/img/solutionAdvantageIcon1.svg"
import solutionAdvantageIcon2 from "../../shared/img/solutionAdvantageIcon2.svg"
import solutionAdvantageIcon3 from "../../shared/img/solutionAdvantageIcon3.svg"
import solutionAdvantageIcon4 from "../../shared/img/solutionAdvantageIcon4.svg"

import ancheta from "../../shared/img/ancheta.png"
import book from "../../shared/img/book.png"
import examen from "../../shared/img/examen.png"

export default function SolutionPage() {

    let breadcrumbsItems = [
        {
            slug: '/',
            title: 'Главная',
        },
        {
            slug: '/solution',
            title: 'Решение для оценки',
        },
    ]

    return (
        <div className="SolutionPage">
            <div className="breadcrumbs_">
                <Breadcrumbs
                    items={breadcrumbsItems}
                />
            </div>
            <section className="SolutionTitle">
                <img src={scaleTitleBG} alt="" />
                <h1>Решение для оценки</h1>
            </section>
            <section className="advantages">
                <p>С помощью платформы DIL вы можете легко оценить уровень владения английским языком ваших студентов или сотрудников, а также обучить их любому уровню Гагаузского языка!</p>
                <div className="itemsWrapper">
                    <div className="item">
                        <div className="icon">
                            <img src={solutionAdvantageIcon1} alt="" />
                        </div>
                        <span>Опрос <span>сотрудников</span> или студентов</span>
                    </div>
                    <div className="line"></div>
                    <div className="item">
                        <div className="icon">
                            <img src={solutionAdvantageIcon2} alt="" />
                        </div>
                        <span>Опрос <span>сотрудников</span> или студентов</span>
                    </div>
                    <div className="line"></div>
                    <div className="item">
                        <div className="icon">
                            <img src={solutionAdvantageIcon3} alt="" />
                        </div>
                        <span>Опрос <span>сотрудников</span> или студентов</span>
                    </div>
                    <div className="line"></div>
                    <div className="item">
                        <div className="icon">
                            <img src={solutionAdvantageIcon4} alt="" />
                        </div>
                        <span>Опрос <span>сотрудников</span> или студентов</span>
                    </div>
                </div>
            </section>
            <section className="steps">
                <div className="row">
                    <div className="img">
                        <img src={ancheta} alt="" />
                    </div>
                    <div className="text">
                        <span>шаг 1</span>
                        <h3>Зарегестриуйтесь на платформе</h3>
                        <p>После прохождения теста по Гагаузскогому языку вы получаете оценку в баллах. Часто вы также получаете некоторое представление о том, что означает эта оценка, </p>
                    </div>
                </div>
                <div className="row">
                    <div className="img">
                        <img src={book} alt="" />
                    </div>
                    <div className="text">
                        <span>шаг 2</span>
                        <h3>Пройдите курс в разделе “Обучение”</h3>
                        <p>После прохождения теста по Гагаузскогому языку вы получаете оценку в баллах. Часто вы также получаете некоторое представление о том, что означает эта оценка, </p>
                    </div>
                </div>
                <div className="row">
                    <div className="img">
                        <img src={examen} alt="" />
                    </div>
                    <div className="text">
                        <span>шаг 3</span>
                        <h3>Запишитесь и пройдите экзамен</h3>
                        <p>После прохождения теста по Гагаузскогому языку вы получаете оценку в баллах. Часто вы также получаете некоторое представление о том, что означает эта оценка, </p>
                    </div>
                </div>
            </section>
        </div>
    )
}
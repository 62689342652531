/* import React from 'react'
import "./RichText.scss"

import '@vaadin/rich-text-editor';

export default function RichText() {

    return (
        <vaadin-rich-text-editor
            style={{
                maxHeight: '400px',
                backgroundColor: '#fff'
            }}
            value="${this.richText}"
        ></vaadin-rich-text-editor>
    )
} */

import React, { Component } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./RichText.scss"
import { useEffect } from 'react';


export default class RichText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: props.value,
            editorState: null,
        }
        const contentBlock = htmlToDraft(this.props.value);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.state.editorState = editorState
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.value !== state.text) {
            const contentBlock = htmlToDraft(props.value);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                state.editorState = editorState
            }
            state.text = props.value
        } else {
        }
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
        this.props.setValue(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    };

    

    render() {
        const { editorState } = this.state;
        return (
            <Editor
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={this.onEditorStateChange}
                toolbar={{
                    /* options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'], */
                    options: ['inline', 'blockType', 'list', 'textAlign', 'remove', 'history'],
                    inline: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
                        bold: { className: undefined },
                        italic: { className: undefined },
                        underline: { className: undefined },
                        strikethrough: { className: undefined },
                        monospace: { className: undefined },
                        superscript: { className: undefined },
                        subscript: { className: undefined },
                    },
                    blockType: {
                        inDropdown: false,
                        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                    },
                    fontSize: {
                        /* options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96], */
                        options: [],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                    },
                    fontFamily: {
                        /* options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'], */
                        options: [],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                    },
                    list: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        /* options: ['unordered', 'ordered', 'indent', 'outdent'], */
                        options: ['unordered', 'ordered'],
                        unordered: { className: undefined },
                        ordered: { className: undefined },
                        indent: { className: undefined },
                        outdent: { className: undefined },
                    },
                    textAlign: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['left', 'center', 'right', 'justify'],
                        left: { className: undefined },
                        center: { className: undefined },
                        right: { className: undefined },
                        justify: { className: undefined },
                    },
                    colorPicker: {
                        className: undefined,
                        component: undefined,
                        popupClassName: undefined,
                        colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                            'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                            'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                            'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                            'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                            'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
                    },
                    link: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        popupClassName: undefined,
                        dropdownClassName: undefined,
                        showOpenOptionOnHover: true,
                        defaultTargetOption: '_self',
                        options: ['link', 'unlink'],
                        link: { className: undefined },
                        unlink: { className: undefined },
                        linkCallback: undefined
                    },
                    emoji: {
                        className: undefined,
                        component: undefined,
                        popupClassName: undefined,
                        emojis: [
                            '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
                            '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
                            '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
                            '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
                            '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
                            '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
                            '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
                            '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
                            '✅', '❎', '💯',
                        ],
                    },
                    embedded: {
                        className: undefined,
                        component: undefined,
                        popupClassName: undefined,
                        embedCallback: undefined,
                        defaultSize: {
                            height: 'auto',
                            width: 'auto',
                        },
                    },
                    image: {
                        className: undefined,
                        component: undefined,
                        popupClassName: undefined,
                        urlEnabled: true,
                        uploadEnabled: true,
                        alignmentEnabled: true,
                        uploadCallback: undefined,
                        previewImage: false,
                        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                        alt: { present: false, mandatory: false },
                        defaultSize: {
                            height: 'auto',
                            width: 'auto',
                        },
                    },
                    remove: { className: undefined, component: undefined },
                    history: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['undo', 'redo'],
                        undo: { className: undefined },
                        redo: { className: undefined },
                    },
                }}
            />
        )
    }
}
import React from "react";

import DiplomImg from "../../../../shared/ui/img/UserCabinet/diplomImg.png"

import "./style.scss"


export default function Diplom() {

	return (
		<div className="diplom">
			<img src={DiplomImg}/>
			<div className="diplom__footer">
				<span>Диплом A1</span>
				<button>Скачать диплом (PDF)</button>
			</div>
		</div>
	)
}
import React, { useEffect, useState } from 'react'
import "./style.scss"
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router'
import { getItem } from '../../shared/ui/Menu/functions'
import AntdMenu from '../../shared/ui/Menu/Menu'
import StudyingNote from "./StudyingNote/StudyingNote";
import CourseInfo from "./CourseInfo/CourseInfo";
import StudyingLesson from './StudyingLesson/StudyingLesson'
import OnlineLesson from "./OnlineLesson/OnlineLesson";
import { useCheckAuth } from '../../shared/functions/useCheckAuth'
import { useDispatch, useSelector } from 'react-redux'
import { getCourseLessons } from '../../store/studying/studyingLessonsSlice'

export default function Studying() {

    // Проверка залогинен пользователь или нет
    useCheckAuth()

    let params = useParams()
    let navigate = useNavigate()
    let location = useLocation()
    let dispatch = useDispatch()

    // let Lang = useSelector(state => state.global.lang)
    let lessons = useSelector(state => state.studyingLessons.lessons)

    useEffect(() => {
        dispatch(getCourseLessons(params.courseId))
    }, [])

    let [openedKey, setOpenedKey] = useState()
    let [selectedKey, setSelectedKey] = useState()
    let [sidebarOpen, setSidebarOpen] = useState(false)

    useEffect(() => {
        let locArr = location.pathname.split('/')
        if (locArr.length == 5) {
            setOpenedKey(locArr[2])
            setSelectedKey(locArr[2])
        } else if (locArr.length == 6) {
            setOpenedKey(locArr[2])
            setSelectedKey(locArr[3])
        } else if (locArr.length == 3) {
            navigate('lessons/')
        }
    }, [location])

    useEffect(() => {
        let locArr = location.pathname.split('/')
        if (locArr.length == 5) {
            locArr[4] = lessons[0]?.lessonId
            navigate(locArr.join('/'))
        }
    }, [lessons])

    let openPage = (pathArr) => {
        console.log(pathArr)
        let arr = pathArr.reverse()
        let path = arr.join('/')
        navigate(path)
    }

    let getLessonsArr = () => {
        return lessons?.map((lesson) => {
            return (
                getItem(lesson.lessonName, lesson.lessonId, <div className="sidebarLessonCircle"></div>)
            )
        })
    }


    return (
        <div className="Studying">
            <div className="StudyingSidebar_w">
                <div className={"StudyingSidebar " + ((sidebarOpen) ? "sidebarOpen" : "")}>
                    <div className="close"
                        onClick={() => setSidebarOpen(false)}
                    >
                        <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <line x1="1" y1="-1" x2="19.1804" y2="-1" transform="matrix(0.668965 0.743294 -0.668965 0.743294 1 2)" stroke="#4E4E4E" strokeWidth="2" strokeLinecap="round"/>
                            <line x1="1" y1="-1" x2="19.1804" y2="-1" transform="matrix(0.668965 -0.743294 0.668965 0.743294 1.55469 17)" stroke="#4E4E4E" strokeWidth="2" strokeLinecap="round"/>
                        </svg>
                    </div>
                    <div className="sidebarCourse">
                        <span>{lessons[0]?.course.courseName}</span>
                    </div>
                    <AntdMenu
                        className={'StudyingSidebarMenu'}
                        Items={[
                            getItem('Уроки', 'lessons', '', getLessonsArr()),
                            getItem('Онлайн урок', 'onlinelesson', ''),
                            getItem('Материалы курса', 'materials', ''),
                            getItem('Информация о курсе', 'courseinfo', ''),
                        ]}
                        rootSubmenuKeys_={[]}
                        onClickFunc={openPage}
                        defaultSelectedKeys={[((selectedKey) ? selectedKey : "lessons")]}
                        defaultOpenKeys={[((openedKey) ? openedKey : "lessons")]}
                    />
                    <button className="changeCourse"
                        onClick={() => navigate('/usercabinet/courses')}
                    >Сменить курс</button>
                    <button className="changeCourse"
                        onClick={() => navigate('/exam/' + params.courseId + '/rules')}
                    >Пройти экзамен</button>
                </div>
            </div>
            <div className="openSidebarBtn"
                onClick={() => setSidebarOpen(true)}
            >
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div className="StudyingContent">
                <Routes>
                    <Route path="lessons/:id" element={<StudyingLesson/>} />
                    <Route path="materials" element={<StudyingNote />} />
                    <Route path="courseinfo" element={<CourseInfo />} />
                    <Route path="onlinelesson" element={<OnlineLesson />} />
                </Routes>
            </div>
        </div>
    )
}
export let getCuttedString = (str = '', limit) => {
    let newStr = ''
    if (limit > str.length) {
        return str
    } else {
        for (let i = 0; i < limit; i++) {
            newStr += str[i]
        }
        return newStr + '...'
    }
}

export let getNextTestQuestion = (test, currId) => {
    let nextId
    Object.entries(test).forEach((arr) => {
        arr[1].forEach((item, i) => {
            if (i == arr[1].length - 1) {
                
            } else {
                if (item.id == currId) {
                    nextId = arr[1][i + 1].id
                }
            }
        })
    })
    return nextId
}
export let getPrevTestQuestion = (test, currId) => {
    let prevId
    Object.entries(test).forEach((arr) => {
        arr[1].forEach((item, i) => {
            if (i == 0) {
                
            } else {
                if (item.id == currId) {
                    prevId = arr[1][i - 1].id
                }
            }
        })
    })
    return prevId
}

export let getQuestionId = (test, currSlug, direction) => {
    let chapter = currSlug.split('_')[0]
    let id = currSlug.split('_')[1]
    let slugID
    test[chapter].map((item, i) => {
        if (item.testQuestionId == id) {
            if (direction == 'next') {
                if (chapter == 'reading' && i == test[chapter].length - 1) {
                    slugID = 'listening' + '_' + test['listening'][0].testQuestionId
                } else if (chapter == 'listening' && i == test[chapter].length - 1) {
                    //
                } else {
                    slugID = chapter + '_' + test[chapter][i + 1].testQuestionId
                }
            } else if (direction == 'prev') {
                if (chapter == 'reading' && i == 0) {
                    //
                } else if (chapter == 'listening' && i == 0) {
                    slugID = 'reading' + '_' + test['reading'][test['reading'].length - 1].testQuestionId
                } else {
                    slugID = chapter + '_' + test[chapter][i - 1].testQuestionId
                }
            }
        }
    })
    return slugID
}
export let getTaskId = (test, currSlug, direction) => {
    let chapter = currSlug.split('_')[0]
    let id = currSlug.split('_')[1]
    let slugID
    test[chapter].map((item, i) => {
        if (item.examTaskId == id) {
            if (direction == 'next') {
                if (chapter == 'reading' && i == test[chapter].length - 1) {
                    slugID = 'listening' + '_' + test['listening'][0].examTaskId
                } else if (chapter == 'listening' && i == test[chapter].length - 1) {
                    //
                } else {
                    slugID = chapter + '_' + test[chapter][i + 1].examTaskId
                }
            } else if (direction == 'prev') {
                if (chapter == 'reading' && i == 0) {
                    //
                } else if (chapter == 'listening' && i == 0) {
                    slugID = 'reading' + '_' + test['reading'][test['reading'].length - 1].examTaskId
                } else {
                    slugID = chapter + '_' + test[chapter][i - 1].examTaskId
                }
            }
        }
    })
    return slugID
}

export let isAnswerCorrect = (question) => {
    let correct = false
    let correctAnswers = question.variants.filter((item) => item.isCorrect)
    let corrAnsCount = correctAnswers.length
    let userCorrAnsCount = 0
    correctAnswers.forEach(item => {
        question?.userAnswer?.forEach(ITEM => {
            if (item.testAnswerId == ITEM.testAnswerId) {
                userCorrAnsCount++
            }
        })
    });
    // console.log(corrAnsCount, userCorrAnsCount)
    if (corrAnsCount == userCorrAnsCount) {
        correct = true
    }
    return correct
}


export function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}
export function setCookie(cname, cvalue, extimestamp) {
    const d = new Date();
    d.setTime(extimestamp);
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
export function setCookie2(name, value, options = {}) {

    options = {
      path: '/',
      // при необходимости добавьте другие значения по умолчанию
      ...options
    };
  
    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }
  
    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in options) {
      updatedCookie += "; " + optionKey;
      let optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += "=" + optionValue;
      }
    }
  
    document.cookie = updatedCookie;
    console.log(updatedCookie)
}
export function deleteCookie(name) {
    setCookie(name, "", {
      'max-age': -1
    })
}

export let getTimeByTimeStamp = (TimeLine) => {
    let duration = TimeLine.duration * 1000, currTime = TimeLine.currTime * 1000
    let func = (timestamp) => {
        let minutes, seconds, left
        if (timestamp >= 60000) {
            left = timestamp % 60000
            minutes = (timestamp - left) / 60000
            seconds = +(left / 1000).toFixed()
        } else {
            minutes = 0
            seconds = +(timestamp / 1000).toFixed()
        }
        return minutes + ':' + seconds
    }
    
    return func(currTime) + '/' + func(duration)
}


export let getRandomStr = (number) => {
    let range = (start, end) => [...Array(end - start).keys(), end - start].map(n => start + n);
    let A = range(65, 90); // A-Z
    let a = range(97, 122); // a-z
    let dig = range(48, 57); // 0-9
    let all = A.concat(a).concat(dig); // Склеиваем в один
    let str = '';

    for (let i = 0; i < number; i++) {
        str += String.fromCharCode(all[Math.floor(Math.random() * all.length)]); // Берём случайное число, преобразуем в соответствующий символ и добавляем к строке
    }
    return str;
}

// Возвращает дату в произвольном формате (можно выбрать разделитель)
export let getDateByTimeStamp = (timestamp, divider, reverse = false) => {
    let date = new Date(timestamp);
    let fullDate = reverse ?
    date.getFullYear() + divider + (((date.getMonth() + 1) < 10) ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + divider + ((date.getDate() < 10) ? '0' + date.getDate() : date.getDate())
    :
    ((date.getDate() < 10) ? '0' + date.getDate() : date.getDate()) + divider + (((date.getMonth() + 1) < 10) ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + divider + date.getFullYear()
    return fullDate
}
// Возвращает период времени между start и end. Например: 10:00 - 15:30
export let getTimePeriod = (time) => {
    // end, start - Date Objects. new Date('2022-10-11 15:21:00')
    return time.getHours() + ':' + time.getMinutes()
}


export let prepareCommentsParams = (filter, id) => {
    return {
        page: 0,
        size: 100000,
        criteria: [
            {
                key: "languageCode",
                value: "RU"
            },
            {
                key:  "forumQuestionId",
                value: id
            },
            ...filter

        ]
    }
}

export let prepareUserCabinetCoursesParams = (lang) => {
    return {
        page: 1,
        size: 100,
        params: {
            languageCode: lang
        },
    }
}

export let prepareTeacherCabinetStudentsParams = (pagination, lang, filter) => {
    return {
        page: pagination.pageNumber - 1,
        size: 17,
        params: {
            languageCode: lang
        },
        criteria: [
            {
                key:  "role",
                value: "STUDENT",
            },
            ...filter
        ]
    }
}

export let prepareTeacherCabinetStudentParams = ( lang, filter) => {
    return {
        page: 0,
        size: 17,
        params: {
            languageCode: lang
        },
        criteria: [
            {
                key:  "role",
                value: "STUDENT",
            },
            filter

        ]
    }
}


export function prepareForumThemesParams(pagination, filter, sort, lang) {
    return {
        page: pagination.pageNumber - 1,
        size: 12,
        criteria: [
            {
                key: "languageCode",
                value: lang
            },
            ...filter,
        ],
        ...sort
    }
}
export function prepareForumQuestionsParams(pagination, filter, sort, lang, id) {
    return {
        page: pagination.pageNumber - 1,
        size: 12,
        criteria: [
            {
                key: "languageCode",
                value: lang
            },
            {
                key: "forumId",
                value: id
            },
            ...filter,
        ],
        ...sort
    }
}
export function prepareCategsParams(lang) {
    return {
        page: 0,
        size: 100,
        criteria: [
            {
                key: "languageCode",
                value: lang
            },
        ]
    }
}
export function prepareCoursesParams() {
    return {
        page: 0,
        size: "100"
    }
}
export function prepareEnrolledStudentsParams() {
    return {
        page: 0,
        size: "100",
        sortDir: "ASC"
    }
}
export function prepareCourseOnlineLessonsParams(lang, id) {
    return {
        "criteria":[
            {
                "key": "languageCode",
                "value": lang
            },
            {
                "key": "type",
                "value": "Online lesson"
            },
            {
                "key": "courseId",
                "value": id
            }
        ]
    }
}
export function prepareCourseOnlineLessonParams(lang, id, lessonId) {
    return {
        "criteria":[
            {
                "key": "languageCode",
                "value": lang
            },
            {
                "key": "type",
                "value": "Online lesson"
            },
            {
                "key": "courseId",
                "value": id
            },
            {
                "key": "meetingsId",
                "value": lessonId
            }
        ]
    }
}

export function prepareStudyingOnlineLessonsParams(lang, id) {
    return {
        "criteria":[
            {
                "key": "languageCode",
                "value": lang
            },
            {
                "key": "type",
                "value": "Online lesson"
            },
            {
                "key":"courseId",
                "value": id
            }
        ]
    }
}
export function prepareCourseLessonsParams(id) {
    return {
        params: {
            courseId: id
        }
    }
}
export function prepareCourseInfoParams(id, lang) {
    return {
        page: 0,
        size: 100,
        criteria: [
            {
                key: "languageCode",
                value: lang
            },
        ]
    }
}
export function prepareParamsForExamCalendar(selectedDate, meetingTypeId, lang) {
    /* let arr = [...selectedDate]
    arr.forEach((item) => {
        item.value = item.value.substring(1, 19)
    })
    console.log(arr) */
    return {
        criteria: [
            {
                key: "languageCode",
                value: lang
            },
            {
                key: "type",
                value: "Exam"
            },
            ...meetingTypeId,
            ...selectedDate
        ]
    }
}
export function prepareParamsForExamTypes(lang) {
    return {
        page: 0,
        size: 1000000,
        criteria: [
            {
                key: "languageCode",
                value: lang
            },
            {
                key: "type",
                value: "Exam"
            }
        ]
    }
}

export function parseJwt(token) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export function daysInMonth (month, year) {
    return new Date(year, month, 0).getDate();
}
import React, { useEffect } from 'react'
import "./style.scss"

import user1 from "../../../../shared/img/user1.png"
import MuiPagination from '../../../../shared/ui/pagination/Pagination'
import { useDispatch, useSelector } from 'react-redux'
import { getStudents, SET_PAGE, SET_SEARCH } from '../../../../store/chooseUserSlice'
import MuiLoader from '../../../../shared/ui/MuiLoader/MuiLoader'
import { SET_ADDEDSTUDENTS, SET_DELSTUDENTS } from '../../../../store/teacherCabinet/teacherOneLesson/teacherOneLessonSlice'

export default function ChoozeStudentModal({ setshowAddModal }) {

    let dispatch = useDispatch()

    useEffect(() => {
        document.addEventListener('keydown', function(event){
            if (event.keyCode == 27) {
                setshowAddModal(false)
            }
        })
    }, [])
    
    const students = useSelector(state => state.chooseUser.students)
	const pagination = useSelector(state => state.chooseUser.pagination)
	const loading = useSelector(state => state.chooseUser.loading)
	const lang = useSelector(state => state.global.lang)
	const delStudents = useSelector(state => state.teacherOneLesson.delStudents)

	const onPageChange = (pageNumber) => {
		dispatch(SET_PAGE(pageNumber))
		dispatch(getStudents(lang))
	}

    const handleChange = (e) => {
		e.preventDefault()
		let form = e.target
		let formData = []
		Array.from(form).forEach((item) => {
			if (item.tagName == 'INPUT' && item.value !== '') {
				formData.push({
					key: item.name,
					value: item.value
				})
			}
		})
		dispatch(SET_SEARCH(formData))
		dispatch(getStudents(lang))
	}

	useEffect(() => {
		dispatch(getStudents(lang))
	}, [])

    return (
        <div className="ChoozeStudentModal">
            <div className="bg"
                onClick={() => setshowAddModal(false)}
            >
                <div className="close"
                    onClick={() => setshowAddModal(false)}
                >
                    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.237305" y="1.67334" width="2.10526" height="20" rx="1.05263" transform="rotate(-45 0.237305 1.67334)" fill="white"/>
                        <rect x="14.1421" width="2.10526" height="20" rx="1.05263" transform="rotate(45 14.1421 0)" fill="white"/>
                    </svg>
                </div>
            </div>
            <div className="header">
                <form id="searchStudent" onSubmit={handleChange}>
                    <div className="col">
                        <span className="label">ID</span>
                        <input type="text" name='accountId' />
                    </div>
                    <div className="col">
                        <span className="label">ФИО</span>
                        <input type="text" name='fio'  />
                    </div>
                    <div className="col">
                        <span className="label">E-mail</span>
                        <input type="text" name='email'  />
                    </div>
                </form>
                <button type="submit" form="searchStudent">Поиск</button>
            </div>
            <div className="content">
                <div className="table">
                    <div className="table-header">
                        <div className="table-tr">
                            <div className="table-td img"></div>
                            <div className="table-td id">ID</div>
                            <div className="table-td fio">ФИО</div>
                            <div className="table-td email">E-mail</div>
                            <div className="table-td btn"></div>
                        </div>
                    </div>
                    <div className="table-body">
                        {loading ? (
                            <div className="table-tr noRows">
                                <MuiLoader color={'#2ECC71'}/>
                            </div>
                        ) : students?.length > 0 ? (
                            students?.map((student) => {
                                return (
                                    <div className="table-tr" key={student.accountId}>
                                        <div className="table-td img">
                                            <div className="rowImg">
                                                <img src={student.avatar} alt="" />
                                            </div>
                                        </div>
                                        <div className="table-td id">{student.accountId}</div>
                                        <div className="table-td fio">{student.fio}</div>
                                        <div className="table-td email">{student.email}</div>
                                        <div className="table-td btn">
                                            <div className="addStudentBtn"
                                                onClick={() => {
                                                    let obj = {...student}
                                                    obj.birthdate = new Date(obj.birthDate).toISOString()
                                                    let arr = obj.fio.split(' ')
                                                    obj.fam = arr[0]
                                                    obj.im = arr[1]
                                                    obj.ot = arr[2]
                                                    console.log(obj)
                                                    dispatch(SET_ADDEDSTUDENTS(obj))
                                                    if (delStudents.includes(student.accountId)) {
                                                        dispatch(SET_DELSTUDENTS({action: 'remove', value: student.accountId}))
                                                    }
                                                    setshowAddModal(false)
                                                }}
                                            >
                                                <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <line x1="18.5" y1="1" x2="18.5" y2="34" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                                    <line x1="34" y1="17.2031" x2="1" y2="17.2031" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        ) : (
                            <div className="table-tr noRows">
                                Нет студентов
                            </div>
                        )}
                    </div>
                </div>
                <div className="pagination">
                    <MuiPagination
                        customSX={
                            (window.innerWidth > 500)
                            ?
                            {
                                '& .MuiPagination-ul': {
                                    gap: '0.4375vw'
                                },
                                '& .MuiPaginationItem-root.MuiPaginationItem-page': {
                                    width: '1.5625vw',
                                    height: '1.5625vw',
                                    backgroundColor: '#00637F',
                                    borderRadius: '50%',
                                    fontFamily: 'Gilroy',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    fontSize: '0.9vw',
                                    lineHeight: '1.25vw',
                                    textAlign: 'center',
                                    color: '#fff',
                                    /* margin: '0 0.27778vw', */
                                    cursor: 'pointer',
                                    transition: '0.3s',
                                },

                                '& .MuiPaginationItem-root.MuiPaginationItem-page.Mui-selected': {
                                    backgroundColor: '#20C4F3',
                                    color: '#fff',
                                },
                                '& .MuiPaginationItem-root.MuiPaginationItem-previousNext': {

                                    width: '1.5625vw',
                                    height: '1.5625vw',
                                    backgroundColor: '',
                                    borderRadius: '50%',

                                    '& .MuiSvgIcon-root': {
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: '50%',
                                        fill: '#fff',
                                    }
                                }
                            }
                            :
                            {
                                '& .MuiPagination-ul': {
                                    gap: '10px'
                                },
                                '& .MuiPaginationItem-root.MuiPaginationItem-page': {
                                    width: '30px',
                                    height: '30px',
                                    backgroundColor: '#fff',
                                    borderRadius: '50%',
                                    fontFamily: 'Gilroy',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    fontSize: '18px',
                                    lineHeight: '24px',
                                    textAlign: 'center',
                                    color: '#000000',
                                    cursor: 'pointer',
                                    transition: '0.3s',
                                },

                                '& .MuiPaginationItem-root.MuiPaginationItem-page.Mui-selected': {
                                    backgroundColor: '#20C4F3',
                                    color: '#fff',
                                },
                                '& .MuiPaginationItem-root.MuiPaginationItem-previousNext': {

                                    width: '30px',
                                    height: '30px',
                                    backgroundColor: '#00637F',
                                    borderRadius: '50%',

                                    '& .MuiSvgIcon-root': {
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: '50%',
                                        fill: '#fff',
                                    }
                                }
                            }
                        }
                        pagesCount={pagination.totalPages}
                        setPage={onPageChange}
                        currentPage={pagination.pageNumber}
                    />
                </div>
            </div>
        </div>
    )
}
import React from "react";

import PhoneImg from "../../../../shared/ui/img/UserCabinet/coursCardPhoneImg.png"
import {CircularAudioLoading} from "../../../../shared/ui/CircularAudioLoading";

import "./style.scss"

export default function CoursCard({title, category, buttonText, onClick , style,  content}) {

	return (
		<div className="cours-card" style={style}>

			<div className="cours-card__left">
				<img src={PhoneImg}/>
				<span>{category}</span>
			</div>

			<div className="cours-card__right">
				<h4 className="cours-card__right-title">{title}</h4>
				<span className="cours-card__right-desc">{content}</span>
				<div className="cours-card__right-button-container">
					<button className="cours-card__right-button" onClick={onClick}>{buttonText}</button>
				</div>
			</div>

		</div>
	)
}
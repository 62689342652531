import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {getComments, saveNewComment, SET_FORMDATA, SET_FORMDATATOEMPTY} from "../../../../store/forum/commentsSlice";

import DateIcon from "../../img/SingleQuestionPage/dateIcon.svg";
import UserIcon from "../../img/SingleQuestionPage/userIcon.svg";
import {AnswerButton} from "../../AnswerButton";
import {CollapseButton} from "../../CollapseButton";
import {ShareBlock} from "../../ShareBlock";
import RichText from "../../richText/RichText";
import UploadImg from "../../UploadImg/UploadImg";
import {getCuttedString, getDateByTimeStamp, getRandomStr} from "../../../functions";

import "./style.scss"

export let AnswerBlock = ({comment, forumQuestionId, loadingAnswer, setLoadingAnswer}) => {



	const dispatch = useDispatch()
	let commentRef = useRef()
	let fromReplyComentRef = useRef()

	useEffect(() => {
		if (comment.fromReplyComentid !== null) {
			let fromReplyComentText = new DOMParser().parseFromString('<div>' + comment.fromReplyComent + '</div>', "text/html").getElementsByTagName('div')[0]
			fromReplyComentRef.current.appendChild(fromReplyComentText)
		}
	},[])

	useEffect(() => {
		let commentText =  new DOMParser().parseFromString('<div>' + comment.forumQuestionComment + '</div>', "text/html").getElementsByTagName('div')[0]
		// setText([commentText])
		commentRef.current.appendChild(commentText)
	},[])


	const [showContent, setShowContent] = useState(false)
	const [showAnswerForm, setShowAnswerForm] = useState(false)
	const formData = useSelector(state => state.comments.formData)


	return (
		<div className="singleAnswer_content">
			<div className="singleAnswer_content_answer">
				<div className="singleAnswer_content_answer_header">
					<div className="singleAnswer_content_answer_header_content">
						<div className="singleAnswer_content_answer_header_content_desc">
							<div className="singleAnswer_content_answer_header_content_desc_row">
								<img src={UserIcon}/>
								<span>{comment.fio}</span>
							</div>
							<div className="singleAnswer_content_answer_header_content_desc_row">
								<img src={DateIcon}/>
								<span>{getDateByTimeStamp(comment.createdTime, '/')}</span>
							</div>
						</div>
					</div>
					<AnswerButton text={'Ответить'} func={() => {
						setShowAnswerForm(true)
						dispatch(SET_FORMDATA({field: "fromReplyId", value: comment.forumQuestionsCommentId}))
						dispatch(SET_FORMDATA({field: "forumQuestionId", value: forumQuestionId}))
						//dispatch(SET_FORMDATA({field: "images", value: []}))
					}} style={showAnswerForm ? {display: "none"} : {display: "block"}}/>
				</div>
				<div className={!showContent && comment.fromReplyComentid == null ? "singleAnswer_content_answer_content singleAnswer_content_answer_content_hidden" : "singleAnswer_content_answer_content"}>
					<div className="singleAnswer_content_answer_content_title" ref={commentRef}></div>
					{comment.images.length > 0 ? comment.images.map((image) => {
						return (
							<img key={image.forumQuestionsCommentsImageid} src={image.value}/>
						)
					}) : ''}
					{comment.fromReplyComentid !== null
						?
						<div
							className={showContent ? "singleAnswer_content_answer_content_desc" : "singleAnswer_content_answer_content_desc singleAnswer_content_answer_content_desc_hide"}>
							<div className="singleAnswer_content_answer_content_desc_text" ref={fromReplyComentRef}>
								{/*{showContent ? comment.fromReplyComent : getCuttedString(comment.fromReplyComent, 260)}*/}

								{/*{image ? '...' : ''}*/}
							</div>

							{/*<img src={image}/>*/}
						</div>
						: ''
					}
				</div>
				<div className="singleQuestion_content_question_footer">
					<CollapseButton func={() => setShowContent(!showContent)} show={showContent}/>
					<ShareBlock shareCount={250} viewsCount={250}/>
				</div>
				<div className={showAnswerForm ? "singleAnswer_content_answer_form" : "singleAnswer_content_answer_form_hide"}>
					<form>
						<div className="singleAnswer_content_answer_form_title">Ответить пользователю <span>{comment.fio}</span>
						</div>
						<RichText
							value={""}
							setValue={(value) => dispatch(SET_FORMDATA({field: "comment", value: value}))}
						/>
						<div className="field">
							<span className="title">Добавить медиафайл</span>
							<div className="fieldImgContent">
								{
									formData?.images?.map((img) => {
										//console.log(img)
										return (
											<UploadImg
												key={img.forumQuestionsCommentsImageid}
												item={formData}
												userImg={img.value}
												imgId={img.forumQuestionsCommentsImageid}
												getImg={(imgHash, imgId) => {
													dispatch(SET_FORMDATA({
														field: 'images',
														value: {
															forumQuestionsCommentsImageid: imgId,
															imgHash: imgHash
														},
														action: 'update'
													}))
												}}
												deleteImg={() => {
													dispatch(SET_FORMDATA({
														field: 'images',
														value: {
															forumQuestionsCommentsImageid: img.forumQuestionsCommentsImageid
														},
														action: 'delete'
													}))
												}}
												id={getRandomStr(10)}
												allowEdit={true}
											/>
										)
									})
								}

								<button className="addImg"
										type="button"
										disabled={false}
										onClick={() => {
											dispatch(SET_FORMDATA({
												field: 'images',
												value: {
													forumQuestionsCommentsImageid: getRandomStr(10)
												},
												action: 'add'
											}))
										}}
								>
									<svg
										viewBox="0 0 22 22"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<line
											x1="11.1382"
											y1="1"
											x2="11.1382"
											y2="21"
											stroke="black"
											strokeWidth="2"
											strokeLinecap="round"
										/>
										<line
											x1="1"
											y1="10.7891"
											x2="21"
											y2="10.7891"
											stroke="black"
											strokeWidth="2"
											strokeLinecap="round"
										/>
									</svg>
								</button>
							</div>
						</div>
						<div className="singleAnswer_content_answer_form_buttons">
							<button className="singleAnswer_content_answer_form_cancelButton" type="button" onClick={() => {
								setShowAnswerForm(false)
								dispatch(SET_FORMDATATOEMPTY())
							}}>Отмена</button>
							<button className="singleAnswer_content_answer_form_submitButton" type="submit" onClick={async (e) => {
								e.preventDefault()
								await dispatch(saveNewComment())
								//dispatch(getComments(forumQuestionId))
								setLoadingAnswer(!loadingAnswer)
							}
							}>Ответить
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	)
}